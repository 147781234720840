import Layout from "components/Layout/Layout";
import React from "react";

import Wishes from "./Wishes";
import { WishesContainerProps as Props } from "./Wishes.types";

const WishesContainer: React.FC<Props> = props => {
  return (
    <Layout>
      <Wishes />
    </Layout>
  );
};

WishesContainer.defaultProps = {};

export default WishesContainer;
