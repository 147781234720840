// Notifications services
import api, { buildHeaders } from "../Api";
import { Notification } from "./notifications.service.types";

export const fetchNotifications = async (
  take = 5,
  skip = 0
): Promise<Notification[]> => {
  return await api
    .get(`/user/notifications?take=${take}&skip=${skip}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    });
};

export async function markNotificationAsRead(notificationId: number) {
  return await api.post(
    `/user/notifications`,
    {
      notificationId
    },
    { headers: await buildHeaders() }
  );
}
