import Layout from "components/Layout/Layout";
import React from "react";

import Calendar from "./Calendar";
import { CalendarContainerProps as Props } from "./Calendar.types";

const CalendarContainer: React.FC<Props> = props => {
  return (
    <Layout>
      <Calendar />
    </Layout>
  );
};

CalendarContainer.defaultProps = {};

export default CalendarContainer;
