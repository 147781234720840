import { Button, Popconfirm } from "antd";
import React, { useState } from "react";

import SubcategoryItem from "./SubcategoryItem";
import InputModal from "components/InputModal";
import { Category } from "services/Categories/categories.service.types";
import {
  useCreateSubcategory,
  useDeleteCategory,
  useEditCategory
} from "services/Categories/categories.service.hooks";

type CategoryItemProps = {
  category: Category;
};

const CategoryItem = ({ category }: CategoryItemProps) => {
  const [isEditNameModalOpen, setIsEditNameModalOpen] = useState(false);
  const [isAddSubcategoryModalOpen, setIsAddSubcategoryModalOpen] =
    useState(false);

  const { mutate: editCategory } = useEditCategory();
  const { mutate: deleteCategory } = useDeleteCategory();
  const { mutate: createSubcategory } = useCreateSubcategory();

  const { id, name, subcategories } = category;

  return (
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-lg">{name}</h1>
        <div className="flex justify-end items-center py-3">
          <Button onClick={() => setIsAddSubcategoryModalOpen(true)}>
            Crear subcategoría
          </Button>
          <Button onClick={() => setIsEditNameModalOpen(true)}>
            Editar categoría
          </Button>
          <Popconfirm
            placement="topLeft"
            title="Seguro desea eliminar?"
            onConfirm={() => deleteCategory(id)}
            okText="Si"
            cancelText="No"
          >
            <Button>Eliminar categoría</Button>
          </Popconfirm>
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        {subcategories.length ? (
          subcategories.map(s => <SubcategoryItem subcategory={s} key={s.id} />)
        ) : (
          <p className="py-5 text-gray-800">No hay subcategorías</p>
        )}
      </div>
      <InputModal
        title="Editar nombre de categoría"
        open={isEditNameModalOpen}
        onConfirm={async (newName: string) => {
          setIsEditNameModalOpen(false);
          await editCategory({ id, newName });
        }}
        onCancel={() => setIsEditNameModalOpen(false)}
        initialValue={name}
        placeholder="Nombre"
      />
      <InputModal
        title="Agregar subcategoría"
        open={isAddSubcategoryModalOpen}
        onConfirm={async newName => {
          setIsAddSubcategoryModalOpen(false);
          await createSubcategory({ categoryId: id, name: newName });
        }}
        onCancel={() => setIsAddSubcategoryModalOpen(false)}
        placeholder="Nombre"
      />
    </div>
  );
};

export default CategoryItem;
