import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette } = variables;

const Styles = styled.div`
  .Index {
    &__container {
      margin: 0 40px;

      @media ${devices.mobileL} {
        margin: 0 60px;
      }

      @media ${devices.tablet} {
        margin: 0 80px;
      }

      @media ${devices.laptop} {
        width: calc(100% - 40px);
        max-width: 1200px;
        margin: 0px auto;
      }
    }

    &__auc_platform {
      background: #ebe7e7;
      padding: 24px 0;
      text-align: center;
      margin: 40px 0 20px;

      h1 {
        font: italic 400 32px/1.1 "Times New Roman";
        margin-bottom: 10px;
      }

      p {
        font: 400 17px/25px "Times New Roman";
        max-width: 880px;
        margin: 0 auto;
      }

      &__contianer {
        max-width: 1030px;
        width: 100%;
      }
    }

    &__background {
      background-color: ${palette.dividerColor};
    }
  }

  .swiper-slide {
    filter: brightness(0.4);
    transform: scale(0.85);
    transition: transform 0.3s ease-in-out;
    transition: filter 0.3s ease-in-out;
  }
  .swiper-slide-active {
    filter: brightness(1);
    transform: scale(1);
    transition: all 0.3s ease-in-out;
  }

  .swiper-button-prev {
    left: 125px;
  }

  .swiper-button-next {
    right: 125px;
  }
`;

export default Styles;
