import { Tab } from "@headlessui/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Avatar } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import EmptyState from "components/emptyState/EmptyState";
import { CONSTANTS } from "config/constants";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useMyAuctionsAccess } from "services/Auctioners/Auctioners.service.hooks";

import Styles from "./ListAuctionsBuyer.styles";
import { ListAuctionsBuyerProps } from "./ListAuctionsBuyer.types";
const { IMAGE_PLACEHOLDER } = CONSTANTS;

function ListAuctionsBuyer(props: ListAuctionsBuyerProps) {
  const { className = "" } = props;
  const [auctionState, setAuctionState] = useState(2);
  const { data: auctionList, isLoading } = useMyAuctionsAccess(auctionState);

  const renderTabs = () => {
    return (
      <Tab.List className="ListAuctionsBuyer__container--tabs">
        <Tab
          className={({ selected }) =>
            `ListAuctionsBuyer__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
          name="Aprobado"
          onClick={() => setAuctionState(2)}
        >
          Aprobado
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
        <Tab
          className={({ selected }) =>
            `ListAuctionsBuyer__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
          name="Pendiente"
          onClick={() => setAuctionState(1)}
        >
          Pendiente
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
        <Tab
          className={({ selected }) =>
            `ListAuctionsBuyer__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
          name="Rechazado"
          onClick={() => setAuctionState(4)}
        >
          Rechazado
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
      </Tab.List>
    );
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small">Imagen</StyledTableCell>
              <StyledTableCell align="left">Título</StyledTableCell>
              <StyledTableCell align="left">Descripción</StyledTableCell>
              <StyledTableCell align="left">Prima</StyledTableCell>
              <StyledTableCell align="left">Fecha de registro</StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Ver información
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionList?.map((item, key: number) => {
              const { auction, updated_at } = item ?? {};
              const { title, description, prima, id } = auction ?? {};
              const { url_logo_1, url_logo_2, url_logo_3 } = auction ?? {};
              const calculatedPrima = prima?.[0]?.prima ?? 0.2;
              const photo =
                url_logo_1 ?? url_logo_2 ?? url_logo_3 ?? IMAGE_PLACEHOLDER;

              return (
                <StyledTableRow key={`${`item.item_id`}-${key}`}>
                  <StyledTableCell component="th" scope="row">
                    <Avatar alt="Lote Image" src={photo} variant="rounded" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${title ?? ""}`.slice(0, 30)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {description?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {calculatedPrima}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dayjs(updated_at).format("DD/MM/YYYY HH:mm a")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Button
                      variant="text"
                      rounded="true"
                      size="small"
                      startIcon={<VisibilityIcon />}
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        window.open(`/auction/${id}`);
                      }}
                    >
                      Ver subasta
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderContent = () => {
    return (
      <>
        <div className="ListAuctionsBuyer__container--items">
          {auctionList && auctionList?.length > 0 ? (
            renderTable()
          ) : (
            <EmptyState />
          )}
        </div>
      </>
    );
  };

  return (
    <Styles className={`ListAuctionsBuyer ${className}`}>
      {isLoading && <CircularProgress />}
      <Tab.Group>
        {renderTabs()}
        <Tab.Panels className="Auctioners__container--fields">
          <Tab.Panel>{renderContent()}</Tab.Panel>
          <Tab.Panel>{renderContent()}</Tab.Panel>
          <Tab.Panel>{renderContent()}</Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Styles>
  );
}

export default ListAuctionsBuyer;
