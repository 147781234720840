import { Tab } from "@headlessui/react";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import Layout from "components/Layout/Layout";
import SellerBids from "components/SellerBids/SellerBids";
import UserBids from "components/UserBids/UserBids";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React from "react";

import Styles from "./MyBids.styles";

function MyBids() {
  const { user } = useAppSelector(state => state.authReducer);
  const { isLoading } = useGlobals();

  const renderTabs = () => {
    return (
      <Tab.List className="MyBids__container--tabs">
        {user && user.rol === 5 ? (
          <Tab
            className={({ selected }) =>
              selected ? "text-primary" : "text-black"
            }
          >
            Mis ofertas como vendedor
            <hr className="w-[180px] h-[4px] primary" />
          </Tab>
        ) : (
          ""
        )}
        <Tab
          className={({ selected }) =>
            selected ? "text-primary" : "text-black"
          }
        >
          Mis ofertas como comprador
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
      </Tab.List>
    );
  };

  const renderContent = () => {
    return (
      <Tab.Group>
        {renderTabs()}
        <Tab.Panels className="MyBids__container--fields">
          {user && user.rol === 5 ? (
            <Tab.Panel>
              <SellerBids />
            </Tab.Panel>
          ) : (
            ""
          )}
          <Tab.Panel>
            <UserBids />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    );
  };

  return (
    <Layout>
      <Styles className="MyBids">
        {isLoading ? <CircularProgress /> : renderContent()}
      </Styles>
    </Layout>
  );
}

export default MyBids;
