import Calendar from "containers/Calendar/Calendar";
import useGlobals from "context/globals/globals.hooks";
import React, { useCallback } from "react";

import AdminClientList from "./AdminClientList/AdminClientList";
import Results from "./AdminClientList/Results/Results";
import Styles from "./AdminDashboard.styles";
import { AdminDashboardProps as Props } from "./AdminDashboard.types";
import CategoriesContainer from "./Categories/Container";
import CurrencyContainer from "./Currency/Currency.container";
import WishesContainer from "./Wishes/Wishes.container";

const AdminDashboard: React.FC<Props> = props => {
  const { selectedIndex } = useGlobals();

  const renderContent = useCallback(() => {
    switch (selectedIndex) {
      case 0:
        return <Calendar />;
      case 1:
        return <AdminClientList />;
      case 2:
        return <Results />;
      case 3:
        return <WishesContainer />;
      case 4:
        return <CurrencyContainer />;
      case 5:
        return <CategoriesContainer />;
      default:
        return <>Empty</>;
    }
  }, [selectedIndex]);

  return <Styles className="AdminDashboard">{renderContent()}</Styles>;
};

AdminDashboard.defaultProps = {};

export default AdminDashboard;
