import { BuildCircleOutlined } from "@mui/icons-material";
import CustomModal from "components/CustomModal/CustomModal";
import SelectComponent from "components/Globals/selectComponent/SelectComponent";
import React from "react";

import Styles from "./ModalSettings.styles";
import { ModalSettingsProps as Props } from "./ModalSettings.types";

const ModalSettings: React.FC<Props> = props => {
  const { modal, setModal, selectList } = props;

  return (
    <CustomModal
      isOpen={modal}
      onClose={() => setModal(!modal)}
      ariaLabelledby="Ajustes para subastas en tiempo real"
    >
      <Styles className="ModalSettings">
        <div className="ModalSettings__container">
          <div className="ModalSettings__container--title">
            <BuildCircleOutlined
              fontSize="large"
              className="ModalSettings__component--icon"
            />
            <p className="ModalSettings__text--title">Configuraciones</p>
          </div>

          <SelectComponent
            selectList={selectList}
            errors={{}}
            register={() => {}}
          />
        </div>
      </Styles>
    </CustomModal>
  );
};

export default ModalSettings;
