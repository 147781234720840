import { Avatar, MenuItem } from "@mui/material";
import useGlobals from "context/globals/globals.hooks";
import React from "react";
import history from "routes/history";
import { useMarkNotificationAsRead } from "services/notifications/notifications.service.hooks";

import Styles from "./NotificationCard.styles";
import { NotificationCardProps as Props } from "./NotificationCard.types";

const NotificationCard: React.FC<Props> = props => {
  const { notification } = props;
  const { message, owner_name, owner_photo, readed, title, id } =
    notification ?? {};

  const { setCurrentNotification } = useGlobals();
  const { mutateAsync, reset } = useMarkNotificationAsRead();

  const handleMarkAsRead = async () => {
    setCurrentNotification(notification);
    if (!id) return;
    await mutateAsync(id);
    reset();
    history.push("/panel/notificaciones");
  };

  return (
    <Styles
      className={`NotificationCard`}
      readed={readed}
      onClick={handleMarkAsRead}
    >
      <MenuItem>
        <Avatar
          alt="Remy Sharp"
          src={owner_photo ?? "/images/LiveLogoRounded.png"}
          className="NotificationCard__avatar"
        />
        <div className="NotificationCard__content">
          <h2>{`${owner_name?.slice(0, 20) ?? "LiveSubastas"}: ${title?.slice(
            0.2
          )}`}</h2>
          <p>{`${message?.slice(0, 42) ?? ""}...`}</p>
        </div>
      </MenuItem>
    </Styles>
  );
};

NotificationCard.defaultProps = {};

export default NotificationCard;
