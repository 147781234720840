import { variables } from "config/variables";
import styled from "styled-components";

import { CurrencyCardStyledProps as Props } from "./CurrencyCard.types";
const { palette, fonts, shadows } = variables;

const CurrencyCardStyled = styled.div<Props>`
  box-shadow: ${shadows.shadowCard};
  padding: 8px 16px;
  border-radius: 4px;
  border-top: 1px solid ${palette.primary};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 320px;
  min-height: 172px;
  background-color: ${palette.white};

  * > h3 {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 14px !important;
    text-decoration: none;
  }

  * > p {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 12px !important;
    text-align: justify;
  }

  .CurrencyCard {
    &__content {
      width: 100%;
    }

    &__section {
      padding: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:last-child) {
        border-bottom: 1px solid ${palette.dividerColor};
      }
    }
  }
`;

export const checkStyles = {
  color: `${palette.blackTable}`,
  backgroundColor: `${palette.white}`,
  "&.Mui-checked ": {
    color: `${palette.primary}`
  },
  "&:hover": {
    color: `${palette.white}`,
    backgroundColor: `${palette.primary}`,
    boxShadow: `8px ${palette.neutro.custom2}`
  }
};

export default CurrencyCardStyled;
