import { TextField } from "@mui/material";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts } = variables;

const TextFieldStyles = styled(TextField)(() => ({
  display: "flex",
  borderRadius: 4,

  ".MuiInputBase-input": {
    fontFamily: `${fonts["Poppins-Md"]}`
  },

  ".MuiFormLabel-root": {
    fontFamily: `${fonts["Poppins-Md"]}`,
    color: `${palette.primaryLight}`,
    "&:after": {
      color: `${palette.primaryLight}`
    },
    ".Mui-focused": {
      color: `${palette.primaryLight}`
    },
    ".Mui-activated": {
      color: `${palette.primaryLight}`
    }
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: `${palette.primary}`
  }
}));

export default TextFieldStyles;
