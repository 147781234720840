import React from "react";

import { CircularProgressStyles } from "./CircularProgress.styles";
import { Container, Styles } from "./CircularProgress.styles";

const CircularProgress = () => {
  return (
    <Styles>
      <Container>
        <CircularProgressStyles />
      </Container>
    </Styles>
  );
};

export default CircularProgress;
