import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { fonts, palette } = variables;

const Styles = styled.div`
  flex: 1;
  width: 120%;

  * > p {
    color: ${palette.grey};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 12px;
  }

  .MyPay {
    &__h4 {
      font-size: 14px;

      @media ${devices.mobileL} {
        font-size: 17px;
      }
    }

    &__payment-container {
      display: flex;
      flex-direction: column;
    }

    &__payment {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 16px;
      margin-bottom: 16px;

      &__title {
        font-size: 25px;
        font-family: ${fonts["Poppins-El"]};
        font-weight: 600;
        letter-spacing: 0.4px;
        text-decoration: none;
        display: inline-block;
        border-bottom: 4px;
        letter-spacing: 0.8px;
        font-variant: all-petite-caps;

        @media ${devices.mobileL} {
          font-size: 18px;
        }

        @media ${devices.laptop} {
          font-size: 25px;
        }
      }

      &__item {
        flex: 1;
        padding: 16px;
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        margin: 8px;
        display: block;
        top: 0px;
        position: relative;
        z-index: 0;
        overflow: hidden;
        width: 240px;
        min-width: 240px;
        max-width: 240px;

        &:hover {
          cursor: pointer;
          transition: all 0.2s ease-out;
          box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
          top: -4px;
          border: 1px solid #cccccc;
          background-color: white;
        }

        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: -16px;
          right: -16px;
          background: #ff7300;
          height: 32px;
          width: 32px;
          border-radius: 32px;
          transform: scale(2);
          transform-origin: 50% 50%;
          transition: transform 0.15s ease-out;
        }

        &:hover:before {
          transform: scale(2.15);
        }

        &__cell {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;

          &--img {
            width: 40px;
            height: 40px;
          }
        }

        &-title {
          font-size: 16px;
          font-weight: bold;
        }

        &-address {
          font-size: 14px;
          font-weight: bold;
          color: #1c1c1c;
        }
      }
    }

    &__modal {
      &__form {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.5rem;

        @media ${devices.tablet} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          width: 100%;
        }
      }
    }

    &__container {
      &--button {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export default Styles;
