import Layout from "components/Layout/Layout";
import React from "react";

import UserChat from "./UserChat";
import { UserChatContainerProps as Props } from "./UserChat.types";

const UserChatContainer: React.FC<Props> = props => {
  return (
    <Layout hideFooter>
      <UserChat />
    </Layout>
  );
};

UserChatContainer.defaultProps = {};

export default UserChatContainer;
