import styled from "styled-components";

import { AddItemByBatchStyledProps as Props } from "./AddItemByBatch.types";

const AddItemByBatchStyled = styled.div<Props>`
  margin-bottom: 24px;

  .AddItemByBatch {
  }
`;

export default AddItemByBatchStyled;
