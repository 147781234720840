import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts } = variables;

const ModalSettingsStyled = styled.div`
  .ModalSettings {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 32px;

      &--title {
        display: flex;
        gap: 8px;
        flex-direction: row;
        align-items: center;
      }
    }

    &__component {
      &--icon {
        font-size: 40px;
        fill: ${palette.primary};
      }
    }

    &__text {
      &--title {
        font-size: 32px;
        font-family: ${fonts["Poppins-Lt"]};
        font-weight: 540;
        letter-spacing: 0.24px;
      }
    }
  }
`;

export default ModalSettingsStyled;
