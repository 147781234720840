import { Skeleton } from "@mui/material";
import React from "react";

import Styles from "./SkeletonText.styles";
import { SkeletonTextProps as Props } from "./SkeletonText.types";

const SkeletonText: React.FC<Props> = props => {
  const { fontSize, width } = props;

  return (
    <Styles className="SkeletonText">
      <Skeleton
        variant="text"
        sx={{ fontSize: `${fontSize}px` }}
        width={width ?? "100%"}
      />
    </Styles>
  );
};

SkeletonText.defaultProps = {};

export default SkeletonText;
