export const CONSTANTS = {
  OT_API_KEY: process.env["REACT_APP_OT_API_KEY"],
  APP_CLIENT_ID: process.env["REACT_APP_APP_CLIENT_ID"],
  POOL_ARN: process.env["REACT_APP_POOL_ARN"],
  POOL_ID: process.env["REACT_APP_POOL_ID"],
  MERCADO_PAGO_KEY: process.env["REACT_APP_MERCADO_PAGO_KEY"],
  COMMENT_TYPE: {
    start: 0,
    advice: 1,
    warning: 2,
    close: 3,
    pause: 4,
    hardWarning: 5,
    simpleChat: 6,
    default: -1
  },
  ACCESS_TYPE: {
    pendant: 1,
    approved: 2,
    limited: 3,
    rejected: 4
  },
  INITIAL_TIME: 27000,
  INTERVAL: 1000,
  NORMAL: 1,
  SALA: 2,
  TELEFONICA: 3,
  BID_LIST_LENGTH: 50,
  BAJO_MESA: 5,
  // CSS Break Points
  BREAKPOINTS: {
    mobile: 420,
    tablet: 768,
    desktop: 1024,
    wide: 1440,
    hd: 2560
  },
  MIN_10: 600000,
  HOUR_1: 3600000,
  DAY_1: 86400000,
  PAGE_SIZE: 10,
  IMAGE_PLACEHOLDER:
    "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png",
  SKELETON_ARRAY: [1, 2, 3, 4, 5, 6, 7],
  htmlRegex:
    "https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)"
};
