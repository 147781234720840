const DashboardSidebarStyles = {
  BoxStyles: {
    variant1: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    },
    variant2: {
      width: "90px",
      px: 2,
      display: { xs: "block", md: "none" }
    },
    variant3: {
      flexGrow: 1,
      mt: 32
    }
  },
  DrawerStyles: {
    variant1: {
      backgroundColor: "neutral.200",
      color: "neutral.100",
      width: 280
    },
    variant2: {
      /* zIndex: (theme) => theme.zIndex.appBar + 100, */
    }
  }
};

export { DashboardSidebarStyles };
