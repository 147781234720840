import styled from "styled-components";

import { variables } from "../../../config/variables";

const { palette, fonts, shadows } = variables;

const Styles = styled.div`
  width: 360px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  padding: 8px;
  box-shadow: ${shadows.shadowCard};
  position: relative;
  overflow: hidden;
  margin: 8px;

  .ItemCard {
    &__content {
      position: relative;
      border-radius: 8px;
      width: 344px;
      height: 224px;
      margin: auto;
      overflow: hidden;
      cursor: pointer;

      &--overlay {
        background: ${palette.primaryBackgroundLight};
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        -webkit-transition: all 0.4s ease-in-out 0s;
        -moz-transition: all 0.4s ease-in-out 0s;
        transition: all 0.4s ease-in-out 0s;
      }

      &--image {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }

      &--details {
        position: absolute;
        text-align: center;
        padding-left: 1em;
        padding-right: 1em;
        width: 100%;
        top: 50%;
        left: 50%;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 24px;
      color: ${palette.colorDegradedTree};
      font-family: ${fonts["Poppins-Lt"]};

      &--details {
        color: ${palette.colorDegradedTree};
        font-size: 24px;
        font-family: ${fonts["Poppins-Rg"]};
      }
    }

    &__name {
      font-size: 16px;
      font-family: ${fonts["Poppins-El"]};
      font-weight: bold;
      text-transform: capitalize;
      margin-top: 8px;
    }

    &__checkbox {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 99;
    }

    &__remove {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 99;
    }
  }

  .ItemCard__content:hover {
    .ItemCard__content--overlay {
      opacity: 1;
    }

    .ItemCard__content--details {
      top: 50%;
      left: 50%;
      opacity: 1;
    }
  }

  .fadeIn-top {
    top: 20%;
  }
`;

export const checkStyles = {
  color: `${palette.primaryLight}`,
  backgroundColor: `${palette.white}`,
  "&.Mui-checked ": {
    color: `${palette.primary}`
  },
  "&:hover": {
    color: `${palette.primary}`,
    backgroundColor: `${palette.white}`,
    boxShadow: `8px ${palette.neutro.custom2}`
  }
};

export default Styles;
