import { TextField } from "@mui/material";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts } = variables;

const TextFieldStyled = styled(TextField)(() => ({
  display: "flex",
  borderRadius: 4,
  width: "55%",

  "@media (max-width: 480px)": {
    width: "100%"
  },

  ".MuiInputBase-input": {
    fontFamily: `${fonts["Poppins-El"]}`,
    fontWeight: 540,
    letterSpacing: "0.6px",
    marginTop: "4px"
  },
  ".MuiFormLabel-root": {
    fontFamily: `${fonts["Poppins-Md"]}`,
    color: `${palette.primaryLight}`,
    fontSize: "18px",
    letterSpacing: "0.4px",
    fontWeight: 400,

    "&:after": {
      color: `${palette.primaryLight}`
    },
    ".Mui-focused": {
      color: `${palette.primaryLight}`
    },
    ".Mui-activated": {
      color: `${palette.primaryLight}`
    }
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: `${palette.blackLightTwo}`,
    borderBottom: "1px solid"
  },
  ".MuiFormHelperText-root": {
    fontFamily: `${fonts["Poppins-Rg"]}`
  }
}));

export default TextFieldStyled;
