import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { SwiperImagesStyledProps as Props } from "./SwiperImages.types";

const { palette, fonts } = variables;

const SwiperImagesStyled = styled.div<Props>`
  position: relative;
  .swiper-button-next,
  .swiper-button-prev {
    color: ${palette.primary};
    display: none !important;
  }

  .swiper-slide {
    width: auto;
  }

  .SwiperImages {
    &__component {
      height: 240px;

      @media ${devices.laptop} {
        height: 380px;
      }

      @media ${devices.laptopL} {
        height: 500px;
      }

      @media ${devices.desktop} {
        height: 620px;
      }

      &--image {
        height: 90%;
        width: 70%;
        margin: auto;
        object-fit: contain;

        @media ${devices.tablet} {
          height: 80%;
          width: 70%;
        }
      }

      &--stream {
        &-container {
          position: absolute;
          display: inline-block;
          display: flex;
          align-items: center;
          z-index: 320;
          gap: 8px;
        }

        &-text {
          font-size: 14px;
          font-weight: 640;
          font-family: ${fonts["Poppins-El"]};

          @media ${devices.laptop} {
            font-size: 16px;
          }
        }

        &-icon {
          font-size: 16px;

          @media ${devices.laptop} {
            font-size: 24px;
          }
        }
      }
    }
  }
`;

export default SwiperImagesStyled;
