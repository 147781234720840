import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentChatId: -1
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    updateCurrentChatId: (state, action) => {
      state.currentChatId = action.payload.id;
    },
    resetChat: state => {
      state.currentChatId = -1;
    }
  }
});
