import { palette } from "config/variables";
import styled from "styled-components";

import { SellerBidsStyledProps as Props } from "./SellerBids.types";

const SellerBidsStyled = styled.div<Props>`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 96%;
  margin: 24px auto;

  .SellerBids {
    &__link {
      color: ${palette.primaryLight};
      text-decoration: underline;
      display: block;

      &:hover {
        cursor: pointer;
      }
    }
    &__breadCrumb {
      margin-bottom: 16px;
    }

    &__cell {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
    }

    &__ddsButton {
      padding: 0;
      margin-left: 4px;
    }
  }
`;

export default SellerBidsStyled;
