import { Prima } from "./AddPrima.types";

export const primaInitialData: Prima[] = [
  { id: 0, rangoInferior: 0, rangoSuperior: undefined, prima: 0.2 },
  {
    id: 1,
    rangoInferior: undefined,
    rangoSuperior: undefined,
    prima: undefined
  },
  {
    id: 2,
    rangoInferior: undefined,
    rangoSuperior: undefined,
    prima: undefined
  }
];

export const exampleData = [
  { id: 0, rangoInferior: 0, rangoSuperior: 2000, prima: "17%" },
  { id: 1, rangoInferior: 2001, rangoSuperior: 6000, prima: "15%" },
  { id: 2, rangoInferior: 6001, rangoSuperior: 100000, prima: "13%" }
];

export const findFirstInvalidRange = (data: Prima[]) => {
  for (let i = 0; i < data.length; i++) {
    const currentRange = data[i];
    const nextRange = data[i + 1];

    if (
      currentRange.rangoInferior !== undefined &&
      currentRange.rangoSuperior !== undefined &&
      +currentRange.rangoInferior >= +currentRange.rangoSuperior
    ) {
      return { idx: i, val: "rangoSuperior" };
    }

    if (
      nextRange !== undefined &&
      nextRange.rangoInferior !== undefined &&
      currentRange.rangoSuperior !== undefined &&
      +currentRange.rangoSuperior >= +nextRange.rangoInferior
    ) {
      return { idx: i + 1, val: "rangoInferior" };
    }
  }

  return {
    idx: undefined,
    val: undefined
  };
};
