import styled from "styled-components";

import { variables } from "../../../config/variables";

const { palette, fonts } = variables;

const Styles = styled.div`
  display: flex;
  width: 100%;

  .AuctionDetails {
    &__content {
      display: flex;
      justify-content: space-between;
      flex: 1;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      width: 64%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    &__subtitle {
      font-size: 16px;
      font-family: ${fonts["Poppins-El"]};
      color: ${palette.gray};
      font-weight: 160;
      line-height: 20px;
      letter-spacing: 0.6px;
    }

    &__title {
      font-size: 20px;
      font-family: ${fonts["Poppins-Lt"]};
      font-weight: 400;
    }

    &__user-data {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__image {
      width: 128px;
      height: 128px;
      border-radius: 8px;
      border: 1px solid ${palette.dividerColor};
      margin: 16px 16px 16px 0;
      object-fit: contain;
      transition: all 0.3s ease-in-out;
      padding: 2px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      position: relative;

      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }

    &__description {
      font-size: 16px;
      font-family: ${fonts["Poppins-Rg"]};
    }

    &__actions-container {
      padding-left: 16px;
      border-left: 1px solid ${palette.dividerColor};
      width: 32%;

      @media (max-width: 768px) {
        border-top: 1px solid ${palette.dividerColor};
        margin-top: 64px;
        padding-top: 12px;
        padding-left: 10;
        border-left: 0;
        width: 100%;
      }
    }

    &__buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
    }

    &__buttons {
      background-color: ${palette.primary};
      color: ${palette.white};
      padding: 8px 0;
      width: 120px;
      margin-right: 16px;
      border-radius: 8px;
      font-size: 14px;
      font-family: ${fonts["Poppins-Lt"]};

      &--primary {
        background-color: ${palette.primary};
        color: ${palette.white};
      }

      &--secondary {
        background-color: ${palette.white};
        color: ${palette.primary};
        border: 1px solid ${palette.primary};
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    &__divider {
      margin: 16px 0;
    }
  }
`;

export default Styles;
