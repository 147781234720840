import { variables } from "config/variables";
import styled from "styled-components";

import { TemporizerStyledProps as Props } from "./Temporizer.types";

const { palette, fonts } = variables;

const TemporizerStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;

  .Temporizer {
    &__countdownSection {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__containerSection {
      display: flex;
      width: 220px;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__timeSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 24px;

      &__h2 {
        font-family: ${fonts["Poppins-Rg"]};
        font-size: 20px;
        color: ${palette.blackCharcoal};
      }

      &__h3 {
        font-family: ${fonts["Poppins-Rg"]};
        font-size: 14px;
        color: ${palette.gray.second1};
        padding-bottom: 8px;
      }

      &__p {
        font-family: ${fonts["Poppins-Lt"]};
        font-size: 12px;
        color: ${palette.blackCharcoal};
      }
    }
  }
`;

export default TemporizerStyled;
