import styled from "styled-components";

import { SkeletonTextStyledProps as Props } from "./SkeletonText.types";

const SkeletonTextStyled = styled.div<Props>`
  .SkeletonText {
  }
`;

export default SkeletonTextStyled;
