import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import React from "react";

import Styles, { style } from "./CustomModal.styles";
import { ModalProps as Props } from "./CustomModal.types";

const CustomModal: React.FC<Props> = props => {
  const { children, isOpen, className = "", onClose } = props;
  const { ariaLabelledby } = props;

  return (
    <Styles className={`Modal ${className}`}>
      <Modal open={isOpen} onClose={onClose} aria-labelledby={ariaLabelledby}>
        <Box sx={style}>{children}</Box>
      </Modal>
    </Styles>
  );
};

export default CustomModal;
