import { MainContainer, Sidebar, Search } from "@chatscope/chat-ui-kit-react";
//@ts-ignore
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useAppSelector } from "hooks";
import { Channel } from "pusher-js";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { pusher } from "services/pusher/pusherApi";

import Styles, { searchStyle } from "./UserChat.styles";
import { UserChatProps as Props } from "./UserChat.types";
import UserChatContainer from "./UserChatContainer/UserChatContainer";
import UserConversationList from "./UserConversationList/UserConversationList";

const UserChat: React.FC<Props> = props => {
  const queryClient = useQueryClient();
  const [searchChat, setSearchChat] = useState("");
  const { currentChatId } = useAppSelector(state => state.chatReducer);

  let channel: Channel = pusher.subscribe("");
  if (currentChatId)
    channel = pusher.subscribe(`liveauction_conversation_${currentChatId}`);

  channel.bind("App\\Events\\SendReplyEvent", async (e: any) => {
    await queryClient.invalidateQueries("userChat");
    await queryClient.refetchQueries("userChat");
  });

  // const repliesList = useFetchUserChatByIdPaginated();

  // const { fetchNextPage, hasNextPage } = repliesList;
  // const { isLoading, isFetchingNextPage } = repliesList;
  // const { refetch, repliesPages } = repliesList;

  // const loadMoreActivitiesHandler = useCallback(() => {
  //   if (!hasNextPage) return;
  //   fetchNextPage();
  // }, [fetchNextPage, hasNextPage]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     loadMoreActivitiesHandler();
  //   }, 5000);
  // }, [repliesPages, loadMoreActivitiesHandler, isFetchingNextPage]);

  return (
    <Styles className="UserChat">
      <MainContainer responsive style={styles} className="UserChat__container">
        <Sidebar position="left" scrollable={true}>
          <Search
            placeholder="Buscar..."
            style={searchStyle}
            onChange={e => setSearchChat(e)}
          />
          <UserConversationList searchChat={searchChat} />
        </Sidebar>

        <UserChatContainer />
      </MainContainer>
      <img src="/images/logo.png" alt="logo" className="UserChat__logo" />
    </Styles>
  );
};

UserChat.defaultProps = {};

export default UserChat;
