import useGlobals from "context/globals/globals.hooks";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const RouteUser = ({ component: Component, ...props }) => {
  const { user } = useSelector(state => state.authReducer);
  const { isLoading } = useGlobals();

  if (isLoading) return null;

  return (
    <Route
      {...props}
      render={props =>
        user?.rol ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
export default RouteUser;
