import { variables } from "config/variables";
import styled from "styled-components";

import { ClientInfoDetailsStyledProps as Props } from "./ClientInfoDetails.types";

const { fonts, palette, shadows } = variables;

const ClientInfoDetailsStyled = styled.div<Props>`
  font-family: ${fonts["Poppins-Lt"]};
  flex: 1;
  display: flex;
  align-items: flex-start;

  * > h3 {
    font-size: 16px;
    line-height: 24px;
  }

  * > h4 {
    font-size: 12px;
    line-height: 16px;
    font-family: ${fonts["Poppins-Md"]};
  }

  * > p {
    font-size: 12px;
    line-height: 16px;
  }

  .ClientInfoDetails {
    &__title {
      color: ${palette.white};
      background-color: ${palette.blackTable};
      display: block;
      width: 100%;
      text-align: center;
      height: 32px;
      padding-top: 4px;
      margin-bottom: 12px;
    }

    &__link {
      font-size: 14px;
      color: ${palette.primaryLight};
      text-decoration: underline;
      display: block;
      margin-top: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    &__reviewCard {
      box-shadow: ${shadows.shadowCard};
      width: 100%;
      background-color: ${palette.white};
    }

    &__comments {
      background-color: ${palette.grCustom1.c2};
      border-top: 2px solid ${palette.primary};
      border-radius: 8px;
      flex: 1;
      margin: 0 32px;
      overflow: hidden;
      box-shadow: ${shadows.shadowCard};
    }

    &__clientSection {
      max-width: 24%;
    }

    &__clientInfo {
      background-color: ${palette.white};
      border-top: 2px solid ${palette.primary};
      border-radius: 8px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: ${shadows.shadowCard};

      &__avatar {
        border: 1px solid ${palette.dividerColor};
      }

      &__section {
        display: flex;
        width: 100%;
        flex: 1;
        justify-content: space-between;
        margin: 8px 0;
        padding: 4px 0;
        border-top: 1px solid ${palette.dividerColor};
      }
    }

    &__clientExtraInfo {
      background-color: ${palette.white};
      border-bottom: 1px solid ${palette.primary};
      border-radius: 8px;
      padding-bottom: 12px;
      overflow: hidden;
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: ${shadows.shadowCard};

      &__section {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        justify-content: space-between;
        margin: 8px 0;
        padding: 4px 12px;

        &:not(:last-child) {
          border-bottom: 1px solid ${palette.dividerColor};
        }
      }
    }
  }
`;

export const iconStyle = {
  color: palette.primary,
  width: 0.05,
  height: 0.05,
  mr: 1
};

export default ClientInfoDetailsStyled;
