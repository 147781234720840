import { useAppSelector } from "hooks";
import React from "react";
import Countdown from "react-countdown-simple";

import Styles from "./Temporizer.styles";
import { TemporizerProps as Props } from "./Temporizer.types";

const Temporizer: React.FC<Props> = props => {
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const { date, date_ini, state } = currentAuction ?? {};
  const hasStarted = state !== 1;
  const utcDateTime = date ?? date_ini ?? "";

  if (hasStarted) return null;
  return (
    <Countdown
      targetDate={utcDateTime}
      renderer={({ days, hours, minutes, seconds }) => (
        <Styles className="Temporizer__countdownSection">
          <h2 className="Temporizer__timeSection__h3">
            Tiempo restante para el inicio de la subasta
          </h2>
          <div className="Temporizer__containerSection">
            <div className="Temporizer__timeSection">
              <h2 className="Temporizer__timeSection__h2">{days}</h2>
              <p className="Temporizer__timeSection__p">Días</p>
            </div>
            <div className="Temporizer__timeSection">
              <h2 className="Temporizer__timeSection__h2">{hours}</h2>
              <p className="Temporizer__timeSection__p">Horas</p>
            </div>
            <div className="Temporizer__timeSection">
              <h2 className="Temporizer__timeSection__h2">{minutes}</h2>
              <p className="Temporizer__timeSection__p">Min</p>
            </div>
            <div className="Temporizer__timeSection">
              <h2 className="Temporizer__timeSection__h2">{seconds}</h2>
              <p className="Temporizer__timeSection__p">Segs</p>
            </div>
          </div>
        </Styles>
      )}
    />
  );
};

Temporizer.defaultProps = {};

export default Temporizer;
