import CancelIcon from "@mui/icons-material/Cancel";
import PaidIcon from "@mui/icons-material/Paid";
import { IconButton, Tooltip } from "@mui/material";
import ItemCard from "components/Auctioners/itemCard/ItemCard";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import InputText from "components/Globals/InputText/InputText";
import Layout from "components/Layout/Layout";
import { CONSTANTS } from "config/constants";
import { useAppSelector } from "hooks";
import parse from "html-react-parser";
import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { rejectBidService } from "services/Auctioners/Auctioners.service";
import { offerAuction } from "services/Auctioners/Auctioners.service";
import { useGetItemOfferById } from "services/Auctioners/Auctioners.service.hooks";
import { useGetItemById } from "services/Auctioners/Auctioners.service.hooks";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import { Item as IItem } from "services/Auctioners/Auctioners.service.types";
import { useGetUserDataById } from "services/Auth/Auth.services.hooks";
import { successful, fail } from "utils/Swal";

import CustomImageList from "./Item.screen.helpers";
import Styles from "./Item.screen.styles";

const { IMAGE_PLACEHOLDER } = CONSTANTS;

const Item = () => {
  //@ts-ignore
  const { id, auctionId } = useParams();
  const { user } = useAppSelector(state => state.authReducer);
  const { data: auctionData } = useGetAuctionById(auctionId);
  const { data: itemData, refetch: refetchItem } = useGetItemById(id);
  const { data: offerList, refetch: refetchList } = useGetItemOfferById(id, 1);
  const { prima: primas, user_id } = auctionData ?? {};
  const { items } = auctionData ?? {};

  const { data: seller, isLoading } = useGetUserDataById(user_id);
  const { name: username, country, city, photo, logo } = seller ?? {};
  const image = photo ?? logo;

  const { name, description } = itemData ?? {};
  const { photos, price_base } = itemData ?? {};
  const { price_estimated1, price_estimated2 } = itemData ?? {};
  const actualPrice = offerList?.[0]?.ActualPrice ?? price_base ?? 0;
  const lastUserBidId = offerList?.[0]?.user_id;
  const theLastBidIsMine = lastUserBidId === user?.id;

  const [currentPhoto, setCurrentPhoto] = useState(IMAGE_PLACEHOLDER);

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm();

  const submitHandler = (values: FieldValues) => {
    try {
      const newOffer = parseInt(values.offert);
      if (newOffer < actualPrice)
        throw new Error(
          "La puja a realizar debe ser mayor que la última oferta generada."
        );
      offerAuction({
        item_id: parseInt(id),
        auction_id: parseInt(auctionId),
        offert: parseInt(values.offert)
      })
        .then(() => {
          successful("Oferta realizada con exito");
          refetchItem();
          refetchList();
        })
        .catch(error => {
          console.error(error.error);
          throw new Error("Hubo un error al realizar la oferta");
        });
    } catch (error) {
      fail(error.message);
    }
  };

  const handleReject = async () => {
    try {
      await rejectBidService(parseInt(id), parseInt(auctionId));
      successful("Oferta cancelada con exito");
      refetchItem();
      refetchList();
    } catch (error) {
      fail("Ocurrió un problema, inténtelo nuevamente en los próximos minutos");
    }
  };

  const handleNavigateToAuctionProfile = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/auctionProfile/${user_id}`);
  };

  const renderItemContainer = () => {
    return (
      <div className="Item__section">
        <h2 className="Item__sectionTitle">Lotes relacionados</h2>
        <div className="Item__items-container">
          {items?.map((item: IItem, idx: number) => (
            <ItemCard item={item} auctionId={auctionId} key={idx} />
          ))}
        </div>
      </div>
    );
  };

  const renderItemInfo = () => {
    return (
      <div className="Item__info-container">
        <h2 className="Item__info-title">{name}</h2>
        <h3 className="Item__text">{`Estimado entre $${price_estimated1} - $${price_estimated2}.`}</h3>
        <h2 className="Item__sectionTitle">{`Precio base de: $ ${actualPrice}`}</h2>
        <div className="Item__bid-section">
          <InputText
            item={{
              label: "offert",
              type: "number",
              placeholder: "Tu oferta máxima:",
              required: true
            }}
            defaultValue={actualPrice}
            errors={errors}
            register={register}
          />
          <ButtonLarge
            variant="contained"
            startIcon={
              <PaidIcon className="AuctionButtons__component--button-icon" />
            }
            className="Item__button--secondary"
            disabled={itemData?.status !== 2}
            onClick={handleSubmit(submitHandler)}
          >
            Realizar puja
          </ButtonLarge>
          {theLastBidIsMine && (
            <Tooltip title="Cancelar puja">
              <IconButton data-tag="allowRowEvents" onClick={handleReject}>
                <CancelIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <div className="Item__user-section">
        <img
          className="Item__user-image"
          alt="userLogo"
          src={image ? image : IMAGE_PLACEHOLDER}
          onClick={handleNavigateToAuctionProfile}
        />
        <div className="Item__user-data">
          <h2 className="Item__sectionTitle">{username}</h2>
          <h2 className="Item__text">
            {country
              ? `${country} | ${city ?? ""}`
              : `Camarillo, CA, United States`}
          </h2>
        </div>
        <div className="Item__buttons-container">
          <button className="Item__buttons Item__buttons--primary">
            Seguir
          </button>
          <button className="Item__buttons Item__buttons--secondary">
            Preguntar
          </button>
        </div>
      </div>
    );
  };

  const renderItemDescription = () => {
    return (
      <div className="Item__section-user">
        <div className="Item__description">
          <h2 className="Item__sectionTitle">Descripción del lote</h2>
          <div className="Item__text">{parse(description ?? "")}</div>
        </div>
        {renderUserInfo()}
      </div>
    );
  };

  const renderItemPrima = () => {
    return (
      <div className="Item__description-section">
        <h2 className="Item__sectionTitle">Prima</h2>
        {primas?.map(item => {
          const { prima = "0", rangoInferior, rangoSuperior } = item ?? {};
          const primaVal = `${+prima * 100}%`;
          const text = rangoSuperior
            ? `${primaVal} desde $${rangoInferior} hasta un máximo de $${rangoSuperior}`
            : `${primaVal} desde $${rangoInferior} sin límite.`;
          return <p>{text}</p>;
        })}
      </div>
    );
  };

  const renderDivider = <hr className="Item__divider" />;

  return (
    <Layout>
      <Styles className="Item">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div className="Item__container">
            <div className="Item__image-section">
              {CustomImageList({ photos, setCurrentPhoto })}
              <img
                className="Item__image"
                alt={name}
                src={photos?.[0]?.photo ? photos?.[0]?.photo : currentPhoto}
                loading="lazy"
              />
              {renderItemInfo()}
            </div>
            {renderDivider}
            {renderItemDescription()}
            {renderDivider}
            {renderItemPrima()}
            {renderDivider}
            {renderItemContainer()}
            {renderDivider}
          </div>
        )}
      </Styles>
    </Layout>
  );
};
export default Item;
