// AuctionAccess services

import api, { buildHeaders } from "../Api";
import { AccessList, AuctionAccess } from "./auctionAccess.service.types";
import { ManageAccessPayload } from "./auctionAccess.service.types";

export const fetchAuctionAccess = async (
  auctionId?: number
): Promise<AuctionAccess> => {
  return await api
    .get(`/auction/access/${auctionId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
};

export const showAuctionApplies = async (
  auctionId?: number
): Promise<AccessList[]> => {
  return await api
    .get(`/auction/access/seller/${auctionId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchAuctionAccessCode = async (
  auctionId?: number
): Promise<string> => {
  return await api
    .get(`/auction/access/seller/codeaccess/${auctionId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const getAuctionCodeAccess = async (
  auctionId?: number
): Promise<string> => {
  return await api
    .get(`/auction/access/seller/codeaccess/${auctionId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const checkAuctionAccessCode = async (
  auctionId?: number,
  accessCode?: string
): Promise<number> => {
  return await api
    .get(`/auction/access/check/${auctionId}?code_access=${accessCode}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.status;
    })
    .catch(error => console.error(error));
};

export async function manageAuctionAccess(payload: ManageAccessPayload) {
  const { accessId, access, auctionId } = payload;
  return await api
    .post(`/auction/access/seller/${auctionId}`, {
      access_id: accessId,
      access
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}
