export const mySellerForm = [
  {
    label: "name_business",
    type: "text",
    placeholder: "Nombre de empresa",
    rules: {
      required: "Por favor, escriba un nombre."
    }
  },
  {
    label: "phone",
    type: "number",
    placeholder: "Teléfono",
    rules: {
      required: "Por favor, coloque su teléfono."
    }
  },
  {
    label: "location",
    type: "text",
    placeholder: "Ubicación",
    rules: {
      required: "Por favor, coloque su ubicación."
    }
  },
  {
    label: "url_web",
    type: "text",
    placeholder: "Dirección web"
  },
  {
    label: "url_RRSS",
    type: "text",
    placeholder: "Dirección web RRSS"
  },
  {
    label: "logo",
    type: "file",
    placeholder: "Logo"
  }
];
