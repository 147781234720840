import { variables } from "config/variables";
import styled from "styled-components";

import { AddCreditCardStyledProps as Props } from "./AddCreditCard.types";

const { palette, fonts } = variables;

const AddCreditCardStyled = styled.div<Props>`
  height: 70vh;

  * > h1 {
    color: ${palette.primary};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 22px;
    margin-top: 20px;
  }

  * > h3 {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 16px;
  }

  * > p {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 14px;
  }

  .AddCreditCard {
    &__content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-around;
    }
  }
`;

export default AddCreditCardStyled;
