import TuneIcon from "@mui/icons-material/Tune";
import { Skeleton } from "@mui/material";
import AuctionButtons from "components/AuctionButtons/AuctionButtons";
import Temporizer from "components/Auctioners/Temporizer/Temporizer";
import AuctionDetails from "components/Auctioners/auctionDetails/AuctionDetails";
import LoginModal from "components/Dashboard/components/LoginModal/LoginModal";
import RegisterModal from "components/Dashboard/components/RegisterModal/RegisterModal";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import ProgressComponent from "components/Globals/ProgressComponent/ProgressComponent";
import SkeletonText from "components/Globals/SkeletonComponent/SkeletonText/SkeletonText";
import ModalEnd from "components/liveAuction/ModalEnd/ModalEnd";
import ModalSettings from "components/liveAuction/ModalSettings/ModalSettings";
import SwiperImages from "components/liveAuction/SwiperImages/SwiperImages";
import RightBids from "components/liveAuction/rightBids/RightBids";
import { CONSTANTS } from "config/constants";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import { useCallback, useState } from "react";
import { useMemo } from "react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateLastBid } from "redux/actions/auctionActions";
import { createComment } from "services/Auctioners/Auctioners.service";
import { useCheckActionStatus } from "services/Auctioners/Auctioners.service.hooks";
import { useCategories } from "services/Categories/categories.service.hooks";
import { useGetItemOfferById } from "services/Auctioners/Auctioners.service.hooks";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import { pusher } from "services/pusher/pusherApi";
import { Scrollbar, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { selectList } from "./LiveAuction.helpers";
import Styles from "./LiveAuction.styles";

const { BID_LIST_LENGTH, IMAGE_PLACEHOLDER } = CONSTANTS;
const { SKELETON_ARRAY } = CONSTANTS;

const LiveAuction = () => {
  //@ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();

  const { refetch } = useCheckActionStatus(id);
  const [modal, setModal] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);
  const [modalError, setModalError] = useState(false);
  const { data: categories, isLoading: isLoadingCategories } = useCategories();
  const { user } = useAppSelector(state => state.authReducer);
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const { id: auctionId } = currentAuction ?? {};

  const { data: auctionData, isLoading } = useGetAuctionById(
    id ?? currentAuction?.id
  );

  const { reset } = useForm();
  const channel = pusher.subscribe(`liveauction${auctionId ?? id}`);
  const { user_id: ownerId } = auctionData ?? {};
  const { items } = auctionData ?? {};
  const { categories_id, sub_categories_id, state } = auctionData ?? {};
  const hasStarted = state !== 1;
  const { currentItem } = useGlobals();
  const categoryFind = categories?.find(
    (category: any) => category.id === categories_id
  );

  const subCategoryFind = categoryFind?.subcategories?.find(
    (subcategory: any) => subcategory?.id === sub_categories_id
  );

  const { data: offerData = [], isLoading: offerLoading } = useGetItemOfferById(
    currentItem?.id ?? auctionData?.items?.[0]?.id,
    BID_LIST_LENGTH
  );

  const tempItems = items;

  const { id: uid } = user ?? {};
  const isOwner = uid === ownerId;

  const onOpenCloseModalLogin = () => {
    setModalLogin(!modalLogin);
    setModalRegister(false);
    reset({
      email: "",
      password: ""
    });
  };

  const onOpenCloseModalRegister = () => {
    setModalRegister(!modalRegister);
    setModalLogin(false);
    reset({
      email: "",
      password: "",
      name: "",
      terms: false
    });
  };

  const createAdvice = useCallback(
    async (comment: string, type: number) => {
      await createComment(comment, id, type);
    },
    [id]
  );

  const handleNavigate = () => {
    window.scrollTo({ top: 100, behavior: "smooth" });
  };

  useEffect(() => {
    refetch();
    if (Object.keys(offerData).length === 0) {
      return;
    }
    const lastBid = offerData?.[offerData?.length - 1];
    dispatch(updateLastBid(lastBid));
  }, [dispatch, offerData, refetch]);

  useEffect(() => {
    handleNavigate();
    return handleNavigate();
  }, [id]);

  const auctionButtonsData = useMemo(() => {
    return {
      selectList,
      auctionId: id ?? auctionId,
      auctionData,
      createAdvice,
      offerData,
      currentItem,
      setModalLogin
    };
  }, [id, auctionId, auctionData, createAdvice, offerData, currentItem]);

  const rightBidsData = useMemo(() => {
    return {
      auctionId: id ?? auctionId,
      offerData,
      items,
      currentItem
    };
  }, [id, auctionId, offerData, items, currentItem]);

  const renderRightBidsComponent = () => {
    const { lot } = currentItem ?? {};

    const renderTitle = () =>
      isLoading || offerLoading ? (
        <>
          <SkeletonText fontSize={16} />
          <SkeletonText fontSize={24} />
        </>
      ) : (
        lot &&
        items?.length && (
          <>
            <p className="LiveAuction__right-bids--text">{`${lot ?? 0} de ${
              items?.length ?? 99
            } lotes restantes`}</p>
            <ProgressComponent value={lot ?? 0} max={items.length} />
          </>
        )
      );

    return (
      <div className="LiveAuction__right-bids--container">
        <RightBids {...rightBidsData} isLoading={isLoading}>
          {hasStarted ? (
            <div className="LiveAuction__right-bids--container-lots">
              {renderTitle()}
            </div>
          ) : (
            <div className="LiveAuction__right-bids--container-temporizer">
              <Temporizer />
            </div>
          )}
        </RightBids>
      </div>
    );
  };

  const renderCategory = () => {
    const renderCategoryFind = (text?: string) =>
      isLoadingCategories ? (
        <SkeletonText fontSize={32} />
      ) : (
        <p className="LiveAuction__category--text-light">{text ?? ""}</p>
      );

    return (
      <div className="LiveAuction__category--container LiveAuction__container--background">
        <div className="LiveAuction__category--container-components">
          <img
            src="/images/logo.png"
            alt="Logo LiveSubastas"
            className="LiveAuction__category--image"
          />
          <TuneIcon
            fontSize="inherit"
            className="LiveAuction__category--icon"
            onClick={() => setModal(!modal)}
          />
        </div>
        <div>
          <div className="LiveAuction__category--text-container">
            <p className="LiveAuction__category--text-normal">Categoría: </p>
            {renderCategoryFind(categoryFind?.name)}
          </div>
          <div className="LiveAuction__category--text-container">
            {subCategoryFind?.name && (
              <p className="LiveAuction__category--text-normal">
                Subcategoría:
              </p>
            )}
            {renderCategoryFind(subCategoryFind?.name)}
          </div>
        </div>
      </div>
    );
  };

  const renderCarrouselLots = () => {
    const renderSlides = () =>
      isLoading ? (
        <>
          {SKELETON_ARRAY.map(item => (
            <SwiperSlide key={item}>
              <Skeleton variant="rounded" width={360} height={100} />
            </SwiperSlide>
          ))}
        </>
      ) : (
        <>
          {tempItems?.map((item, idx) => {
            const { images, photos, lot, id, name } = item ?? {};
            const { price_base, price_estimated1, price_estimated2 } =
              item ?? {};
            const image = images?.[0] ?? photos?.[0];

            const renderTitleStatus = () =>
              currentItem?.id === id && (
                <p className="LiveAuction__carrouselLots--card-live">En vivo</p>
              );

            const styleClass = () => {
              if (currentItem?.id === id)
                return "LiveAuction__carrouselLots--card-current";
              else return "LiveAuction__carrouselLots--card-past";
            };

            return (
              <SwiperSlide
                key={idx}
                className={`LiveAuction__carrouselLots--container-slide ${styleClass()}`}
              >
                <div className="LiveAuction__carrouselLots--card-container">
                  <img
                    className="LiveAuction__carrouselLots--card-image"
                    src={image?.photo ?? IMAGE_PLACEHOLDER}
                    alt={`${lot} - Lote ${lot}`}
                  />
                  <div className="LiveAuction__carrouselLots--card-content LiveAuction__carrouselLots--card-text">
                    {renderTitleStatus()}
                    <p>{`${lot}: ${name}`}</p>
                    <p>{`$ ${price_base} - $ ${
                      price_estimated2 ?? price_estimated1
                    }`}</p>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </>
      );

    return (
      <div className="LiveAuction__container--background LiveAuction__carrouselLots--container-swiper">
        <Swiper
          spaceBetween={48}
          slidesPerView={"auto"}
          navigation={true}
          scrollbar={true}
          modules={[Navigation, Scrollbar]}
        >
          {renderSlides()}
        </Swiper>
      </div>
    );
  };

  const renderImagesLot = () => {
    const { images, name, lot } = currentItem ?? {};

    const renderTitle = () =>
      isLoading ? (
        <Skeleton variant="text" sx={{ fontSize: "32px", width: "50%" }} />
      ) : (
        <h1 className="LiveAuction__imagesLot--title">
          {`${lot ?? "-"}: ${name ?? "-"}`}
        </h1>
      );

    const renderSwiper = () =>
      isLoading ? (
        <Skeleton variant="rounded" width={"100%"} height={700} />
      ) : (
        images?.length && <SwiperImages images={images} numberWatchers={243} />
      );

    return (
      <div className="LiveAuction__imagesLot--container-global LiveAuction__container--background">
        <div className="LiveAuction__imagesLot--container-content">
          <div>
            {renderTitle()}
            {/* TODO: Pending number watcher */}
            {renderSwiper()}
          </div>
          <AuctionDetails auctionData={auctionData} />
        </div>
      </div>
    );
  };

  channel?.bind("App\\Events\\FinalizeAuctionEvent", () => {
    setModalError(true);
  });

  const renderContent = () => {
    const styleClass = () =>
      isOwner ? "" : "LiveAuction__container--bottom-right_noOwner";

    return (
      <>
        {isLoading && <CircularProgress />}
        <div className="LiveAuction__layout">
          <div className="LiveAuction__layout--container">
            <div className="LiveAuction__container--components">
              <div className="LiveAuction__container--top">
                {renderCategory()}
                {renderCarrouselLots()}
              </div>
              <div className="LiveAuction__container--bottom">
                <div
                  className={`LiveAuction__container--bottom-right ${styleClass()}`}
                >
                  {renderImagesLot()}
                  <AuctionButtons {...auctionButtonsData} />
                </div>
                <div className="LiveAuction__container--bottom-left">
                  {renderRightBidsComponent()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalSettings
          modal={modal}
          setModal={setModal}
          selectList={auctionButtonsData.selectList}
        />
        <RegisterModal
          modalRegister={modalRegister}
          setModalRegister={setModalRegister}
          onOpenCloseModalLogin={onOpenCloseModalLogin}
        />
        <LoginModal
          setModalLogin={setModalLogin}
          modalLogin={modalLogin}
          onOpenCloseModalRegister={onOpenCloseModalRegister}
          redirect={false}
        />

        <ModalEnd
          modal={modalError}
          setModal={() => {
            setModalError(!modalError);
            const daddy = window.self;
            daddy.opener = window.self;
            daddy.close();
          }}
        />
      </>
    );
  };

  return <Styles className="LiveAuction">{renderContent()}</Styles>;
};

export default LiveAuction;
