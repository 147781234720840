import { variables } from "config/variables";
import styled from "styled-components";

import { CalendarFiltersStyledProps as Props } from "./CalendarFilters.types";
const { palette, fonts, shadows } = variables;

const CalendarFiltersStyled = styled.div<Props>`
  width: 20%;
  border-right: 1px solid ${palette.chipGrey};
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-shadow: ${shadows.shadowCard};
  background-color: ${palette.white};

  @media (max-width: 1024px) {
    width: 25%;
    padding-right: 8px;
  }

  @media (max-width: 768px) {
    width: 35%;
    padding-right: 4px;
  }

  @media (max-width: 480px) {
    width: 100%;
    padding: 0 12px;
    border: none;
    margin-bottom: 24px;
  }

  .CalendarFilters {
    &__section-title {
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.blackCharcoal};
      font-size: 14px;
    }

    &__actionsContainer {
      display: flex;
      flex: 1;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
      }
    }

    &__rangePicker {
      border-radius: 4px;
      font-size: 12px;
      line-height: 24px;
      padding: 4px 12px;
      border-color: #cbcbcb;
      height: 40px;
    }

    &__divider {
      margin: 16px 0;
    }
  }
`;

export default CalendarFiltersStyled;
