import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { UploadMultipleFileImagesStyledProps as Props } from "./UploadMultipleFileImages.types";

const { palette, fonts, shadows } = variables;

const UploadMultipleFileImagesStyled = styled.div<Props>`
  width: 40%;
  position: relative;
  background-color: ${palette.grCustom1.c2};
  border-bottom: 1px solid ${palette.dividerColor};
  border-top: 1px solid ${palette.primary};
  box-shadow: ${shadows.shadowCard};
  border-radius: 8px;
  margin: 24px 0;
  min-height: 240px;
  max-height: 540px;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4px;

  @media screen and (max-width: 1366px) {
    width: 48%;
  }

  @media screen and (max-width: 768px) {
    width: 96%;
    align-self: center;
    margin: 0;
  }

  .UploadMultipleFileImages {
    &__container {
      border-radius: 8px;
      width: 96%;
      min-height: 180px;
      padding: 12px;
      margin-top: 12px;
      align-self: center;
      border: 1px dashed ${palette.primary};
    }

    &__buttonsContainer {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      padding-top: 2px;
    }

    &__subTitle {
      font-size: 14px !important;
      text-align: start;
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.blackCharcoal};
      letter-spacing: 0.8px;
      opacity: 0.8;

      @media ${devices.mobileL} {
        font-size: 16px;
      }

      @media ${devices.tablet} {
        font-size: 18px;
      }
    }

    &__bold {
      font-size: 12px !important;
      text-align: start;
      font-family: ${fonts["Poppins-Bd"]};
      color: ${palette.blackCharcoal};
      letter-spacing: 0.8px;
      opacity: 0.8;
      margin-bottom: 8px;

      @media ${devices.mobileL} {
        font-size: 14px;
      }

      @media ${devices.tablet} {
        font-size: 16px;
      }
    }

    &__paragraph {
      font-size: 10px !important;
      text-align: start;
      font-family: ${fonts["Poppins-Rg"]};
      color: ${palette.blackCharcoal};
      opacity: 0.6;

      @media ${devices.mobileL} {
        font-size: 14px;
      }

      @media ${devices.tablet} {
        font-size: 16px;
      }
    }
  }
`;

export default UploadMultipleFileImagesStyled;
