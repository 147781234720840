import { variables } from "config/variables";
import styled from "styled-components";

import { WishesStyledProps as Props } from "./Wishes.types";
const { palette, fonts, shadows } = variables;

const WishesStyled = styled.div<Props>`
  width: calc(100% - 40px);
  max-width: 1142px;
  min-height: 80vh;
  margin-bottom: -100px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  box-shadow: ${shadows.shadowCard};
  border: 2px solid ${palette.whiteDividerColor};
  background-color: ${palette.white};
  border-radius: 12px;
  padding: 24px;

  @media screen and (max-width: 1366px) {
    width: 80%;
  }

  .Wishes {
    &__container-title {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &__title {
      font-family: ${fonts["Poppins-El"]};
      color: ${palette.black};
      font-weight: bold;
      letter-spacing: 0.1rem;
      font-size: 2.5rem;
      font-variant: all-small-caps;
    }

    &__description {
      font-family: ${fonts["Poppins-El"]};
      font-size: 14px;
      color: ${palette.blackCharcoal};
      margin: 24px 7% 0 7%;
    }

    &__form {
      margin: 64px 0;
      display: flex;
      flex-direction: column;
      gap: 32px;

      label {
        font-size: 20px;
        font-variant: all-petite-caps;
      }
    }

    &__fields {
      display: flex;
      gap: 16px;
    }

    &__button {
      align-self: center;
      margin-top: 40px;
    }
  }
`;

export default WishesStyled;
