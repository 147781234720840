import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { ApplicantListStyledProps as Props } from "./ApplicantList.types";

const { palette, fonts } = variables;

const ApplicantListStyled = styled.div<Props>`
  flex: 1;
  display: flex;
  height: 100vh;
  min-height: 60vh;
  width: 96%;
  margin: 24px auto;
  flex-direction: column;

  .ApplicantList {
    &__title {
      font-size: 16px;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      color: ${palette["primary"]};
      font-weight: 600;
      letter-spacing: 0.8px;
      margin-bottom: 16px;

      @media ${devices.tablet} {
        margin-bottom: 24px;
      }

      @media ${devices.laptop} {
        font-size: 18px;
      }

      @media ${devices.laptopL} {
        font-size: 20px;
      }

      @media ${devices.desktop} {
        font-size: 24px;
      }
    }
  }
`;

export default ApplicantListStyled;
