import styled from "styled-components";

import { UserBidsStyledProps as Props } from "./UserBids.types";

const UserBidsStyled = styled.div<Props>`
  flex: 1;
  display: flex;
  height: 100vh;
  width: 96%;
  margin: 24px auto;

  .UserBids {
  }
`;

export default UserBidsStyled;
