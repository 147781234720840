import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { MenuItem, Select, SelectChangeEvent, Switch } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import useGlobals from "context/globals/globals.hooks";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  setCategoryToAuctionHouse,
  toggleFeaturedToAuctionHouse
} from "services/Auctioners/Auctioners.service";
import { useCategories } from "services/Categories/categories.service.hooks";
import { Category } from "services/Categories/categories.service.types";
import { useFetchAllAuctionHouses } from "services/Interviews/interviews.service.hooks";
import { fail } from "utils/Swal";

import Styles from "./AuctionHousesList.styles";
import { AuctionHousesListProps as Props } from "./AuctionHousesList.types";

const AuctionHousesList: React.FC<Props> = props => {
  const { setSelectedClient, setSelectedSubIndex } = useGlobals();
  const { data: auctionList, isLoading, refetch } = useFetchAllAuctionHouses();
  const { data: categoriesList, isLoading: isLoadingCategories } =
    useCategories();
  const [isTogglingFeatured, setIsTogglingFeatured] = useState(false);

  const handleChangeCategory = async (
    event: SelectChangeEvent,
    id?: number
  ) => {
    const categoryId = event.target.value as string;
    try {
      await setCategoryToAuctionHouse(id, categoryId);
      refetch();
    } catch (error) {
      fail("Hubo un error al asignar la categoría");
      // console.log(
      //   "🚀 ~ file: AuctionHousesList.tsx:46 ~ handleChangeCategory ~ error:",
      //   error
      // );
    }
  };

  const toggleFeaturedChange = async (id?: number) => {
    setIsTogglingFeatured(true);
    try {
      await toggleFeaturedToAuctionHouse(id);
      await refetch();
    } catch (error) {
      fail("Hubo un error al cambiar el estado de destacada");
    }
    setIsTogglingFeatured(false);
  };

  const renderCategory = (category: Category, id?: number) => {
    return (
      <Select
        labelId="categoryId"
        id="categoryId"
        value={`${category.id}`}
        label=""
        onChange={a => handleChangeCategory(a, id)}
        sx={{ backgroundColor: "white", height: 30 }}
      >
        {categoriesList?.map(category => (
          <MenuItem value={category?.id} key={category?.id}>
            {category?.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  useEffect(() => {
    setSelectedClient(undefined);
  }, [setSelectedClient]);

  return (
    <Styles className="AuctionHousesList">
      {(isLoading || isLoadingCategories) && <CircularProgress />}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small" align="left">
                Nombre
              </StyledTableCell>
              <StyledTableCell align="left">Ubicación</StyledTableCell>
              <StyledTableCell align="left">Teléfono</StyledTableCell>
              <StyledTableCell align="left">Categoría asignada</StyledTableCell>
              <StyledTableCell align="left">Fecha de creación</StyledTableCell>
              <StyledTableCell align="left">Destacada</StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionList?.map((item, key) => {
              const { name_business, category_id, user_id, id } = item ?? {};
              const { updated_at, phone, location, featured } = item ?? {};
              const category = categoriesList?.find(
                category => category.id === category_id
              );

              return (
                <StyledTableRow key={`${`item.item_id`}-${key}`}>
                  <StyledTableCell align="left">
                    {(name_business ?? "").slice(0, 32)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {location?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">{phone ?? ""}</StyledTableCell>
                  <StyledTableCell align="left">
                    {categoriesList &&
                      renderCategory(category ?? categoriesList?.[0], user_id)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {updated_at
                      ? dayjs(updated_at).format("DD/MM/YYYY HH:mm a")
                      : "--:--"}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Switch
                      checked={featured ?? false}
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={a => toggleFeaturedChange(id)}
                      disabled={isTogglingFeatured || isLoading}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="center" size="small">
                    <Button
                      variant="text"
                      rounded="true"
                      size="small"
                      startIcon={<AccountBoxIcon />}
                      onClick={() => {
                        setSelectedClient(item?.user_id);
                        setSelectedSubIndex(1);
                      }}
                    >
                      Ver vendedor
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Styles>
  );
};

AuctionHousesList.defaultProps = {};

export default AuctionHousesList;
