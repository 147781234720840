import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Styles from "./SwiperImages.styles";
import { SwiperImagesProps as Props } from "./SwiperImages.types";

const SwiperImages: React.FC<Props> = props => {
  const { images } = props;

  return (
    <Styles className="SwiperImages">
      <span className="SwiperImages__component--stream-container">
        {/* TODO: Pending number watcher */}
        <RemoveRedEyeOutlinedIcon className="SwiperImages__component--stream-icon" />{" "}
        <p className="SwiperImages__component--stream-text">5</p>
      </span>
      <Swiper
        pagination={{
          type: "fraction"
        }}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className="SwiperImages__component">
            <img
              src={image?.photo}
              alt={image?.title}
              className="SwiperImages__component--image"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Styles>
  );
};

SwiperImages.defaultProps = {};

export default SwiperImages;
