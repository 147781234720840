import styled from "styled-components";

import { variables } from "../../config/variables";

const { palette } = variables;

const Styles = styled.div`
  .Auction {
    &__container {
      display: flex;
      flex-direction: column;
    }

    &__images-container {
      display: flex;
      justify-content: space-between;
      width: 88%;
      margin: 32px auto;
    }

    &__image {
      width: 32%;
      max-width: 420px;
      max-height: 260px;
      border-radius: 8px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      padding: 8px;
      position: relative;
      margin: 8px;
      object-fit: contain;

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
    &__divider {
      width: 88%;
      height: 1px;
      background-color: ${palette.dividerColor};
      margin: 16px auto;
    }

    &__item-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 30%;
      margin: 16px;
    }

    &__items-container {
      display: flex;
      flex-wrap: wrap;
      width: 88%;
      margin: 0 auto;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;

export default Styles;
