import { palette } from "config/variables";
import styled from "styled-components";

import { UserChatStyledProps as Props } from "./UserChat.types";
import { colorHeader } from "./UserChatContainer/UserChatContainer.styles";

const UserChatStyled = styled.div<Props>`
  height: 84.9vh;
  position: relative;
  overflow: hidden;
  margin-top: 23px;

  .cs-search__search-icon {
    color: ${palette.primary};
  }

  .UserChat {
    &__logo {
      position: absolute;
      left: calc(50% - 73px);
      top: 48%;
      opacity: 24%;
      z-index: 1;
      width: 400px;
    }
  }
  .cs-search {
    &__input {
      background-color: ${palette.neutro.custom};
    }
  }
`;

export const searchStyle = {
  ...colorHeader,
  borderColor: palette.primary,
  borderWidth: 1
};

export default UserChatStyled;
