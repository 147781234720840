import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import Layout from "components/Layout/Layout";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetSellerReviews } from "services/Auctioners/Auctioners.service.hooks";
import { useGetSellerItems } from "services/Auctioners/Auctioners.service.hooks";
import { useGetSellerInfo } from "services/Auctioners/Auctioners.service.hooks";
import { fail } from "utils/Swal";

import SellerProfile from "./SellerProfile";
import { SellerProfileContainerProps as Props } from "./SellerProfile.types";

const SellerProfileContainer: React.FC<Props> = props => {
  //@ts-ignore
  const { id } = useParams();
  const { data, isLoading, isError } = useGetSellerInfo(id);
  const { data: items, isLoading: itemsLoading } = useGetSellerItems();
  const { data: reviews, isLoading: reviewsLoading } = useGetSellerReviews(id);

  useEffect(() => {
    if (isError) {
      fail("Hubo un error al obtener los datos del vendedor.");
      setTimeout(() => {
        document.location.reload();
      }, 2000);
    }
  }, [isError]);

  return (
    <Layout>
      {(isLoading || itemsLoading || reviewsLoading) && <CircularProgress />}
      <SellerProfile sellerProfile={data} items={items} reviews={reviews} />
    </Layout>
  );
};

SellerProfileContainer.defaultProps = {};

export default SellerProfileContainer;
