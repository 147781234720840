import { Tab } from "@headlessui/react";
import { Skeleton } from "@mui/material";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import Layout from "components/Layout/Layout";
import MyCurrency from "components/user/myCurrency/MyCurrency";
import MyDirection from "components/user/myDirection/MyDirection";
import MyPay from "components/user/myPay/MyPay";
import MyProfile from "components/user/myProfile/MyProfile";
import MySeller from "components/user/mySeller/MySeller";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React from "react";
import { useGetSellerData } from "services/Auth/Auth.services.hooks";
import { useFetchUserChats } from "services/userChat/userChat.service.hooks";
import { useFetchUserChatById } from "services/userChat/userChat.service.hooks";

import Styles from "./Profile.styles";

const Profile = () => {
  const { user } = useAppSelector(state => state.authReducer);
  const { isLoading } = useGlobals();
  useFetchUserChatById();
  useFetchUserChats("");

  const { rol } = user ?? {};
  const { data: seller } = useGetSellerData(user?.id);

  const renderTabs = () => {
    return (
      <Tab.List className="Profile__container--tabs">
        {user && user.rol === 5 ? (
          <Tab
            className={({ selected }) =>
              `Profile__component--tabs ${
                selected ? "text-primary" : "text-black"
              }`
            }
          >
            Mi perfil de vendedor
          </Tab>
        ) : (
          ""
        )}
        <Tab
          className={({ selected }) =>
            `Profile__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
        >
          Mi Perfil
        </Tab>
        <Tab
          className={({ selected }) =>
            `Profile__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
        >
          Mis Pagos
        </Tab>
        <Tab
          className={({ selected }) =>
            `Profile__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
        >
          Mis Direcciones
        </Tab>
        <Tab
          className={({ selected }) =>
            `Profile__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
        >
          Mi Moneda
        </Tab>
      </Tab.List>
    );
  };

  if (isLoading) return <CircularProgress />;

  const renderImage = () => {
    return isLoading ? (
      <Skeleton variant="circular" className="MySeller__image--skeleton" />
    ) : (
      seller?.logo && (
        <img
          className="Profile__container--src"
          src={seller?.logo}
          alt="logo"
        />
      )
    );
  };

  return (
    <Layout>
      <Styles className="Profile">
        <div className="Profile__container">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className="Profile__title">Configuración De Cuenta</h1>
            {rol === 5 && (
              <div className="Profile__container--image">{renderImage()}</div>
            )}
          </div>
          <Tab.Group>
            {renderTabs()}
            <Tab.Panels className="Profile__container--fields">
              {user && user.rol === 5 ? (
                <Tab.Panel>
                  <MySeller />
                </Tab.Panel>
              ) : (
                ""
              )}
              <Tab.Panel>
                <MyProfile />
              </Tab.Panel>
              <Tab.Panel>
                <MyPay />
              </Tab.Panel>
              <Tab.Panel>
                <MyDirection />
              </Tab.Panel>
              <Tab.Panel>
                <MyCurrency />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Styles>
    </Layout>
  );
};
export default Profile;
