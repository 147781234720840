import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ReorderIcon from "@mui/icons-material/Reorder";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Avatar, Collapse, List } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { CONSTANTS } from "config/constants";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import useGlobals from "context/globals/globals.hooks";
import React, { useState } from "react";
import { useGetAuctionCategories } from "services/Auctioners/Auctioners.service.hooks";
import { AuctionCategory } from "services/Auctioners/Auctioners.service.types";
import { notifyAuctionHouseWish } from "services/wishes/wishes.service";
import { notifyWishByCategory } from "services/wishes/wishes.service";
import { fail } from "utils/Swal";

import Styles, { categoryListStyle } from "./NotifyWishes.styles";
import { NotifyWishesProps as Props } from "./NotifyWishes.types";

const { IMAGE_PLACEHOLDER } = CONSTANTS;

const NotifyWishes: React.FC<Props> = props => {
  const { currentWish } = props;
  const { setSelectedIndex, setSelectedClient } = useGlobals();
  const { setSelectedSubIndex } = useGlobals();
  const [currentCategory, setCurrentCategory] = useState<number | undefined>(
    undefined
  );
  const { data: categoriesList, isLoading } = useGetAuctionCategories();
  const [currentLoading, setCurrentLoading] = useState<boolean>(false);

  const handleNotify = async (userId?: number) => {
    if (!currentWish) fail("Debes seleccionar un deseo el cual notificar");
    try {
      setCurrentLoading(true);
      await notifyAuctionHouseWish(currentWish, userId);
      setCurrentLoading(false);
    } catch (error) {
      setCurrentLoading(false);
      fail("Hubo un error al notificar a la casa de subastas");
    }
  };

  const handleNotifyByCategory = async (categoryId?: number) => {
    if (!currentWish) fail("Debes seleccionar un deseo el cual notificar");
    try {
      setCurrentLoading(true);
      await notifyWishByCategory(currentWish, categoryId);
      setCurrentLoading(false);
    } catch (error) {
      setCurrentLoading(false);
      fail("Hubo un error al notificar a la casa de subastas");
    }
  };

  const renderListItem = (category: AuctionCategory) => {
    const { name, id } = category ?? {};
    const open = currentCategory === id;

    return (
      <div key={id}>
        <ListItemButton onClick={() => setCurrentCategory(id)}>
          <ListItemIcon>
            <ReorderIcon />
          </ListItemIcon>
          <ListItemText primary={name ?? ""} />
          <Button
            startIcon={<NotificationsActiveIcon />}
            variant="text"
            rounded="true"
            size="small"
            onClick={() => handleNotifyByCategory(id)}
            loading={currentLoading}
            disabled={currentLoading}
            sx={{ paddingVertical: 0 }}
          >
            Notificar
          </Button>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              {renderTable(category)}
            </ListItemButton>
          </List>
        </Collapse>
      </div>
    );
  };

  const renderCategoriesList = () => {
    return (
      <List
        sx={categoryListStyle}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {categoriesList?.map(category => renderListItem(category))}
      </List>
    );
  };

  const handleNavigateToUser = (id?: number) => {
    setSelectedClient(id);
    setSelectedIndex(1);
    setSelectedSubIndex(1);
  };

  const renderTable = (category: AuctionCategory) => {
    const { sellers } = category ?? {};
    if (sellers && sellers.length <= 0) return <h3>Categoría vacía</h3>;
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small" align="left">
                Casa de subastas
              </StyledTableCell>
              <StyledTableCell align="center">Acciones</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sellers?.map((item, key) => {
              const { name_business } = item ?? {};
              const { logo, user_id } = item ?? {};
              const photo = logo ?? IMAGE_PLACEHOLDER;

              return (
                <StyledTableRow key={`${`item.item_id`}-${key}`}>
                  <StyledTableCell align="left">
                    <div className="NotifyWishes__cell">
                      <Avatar alt="Lote Image" src={photo} variant="rounded" />
                      <p
                        className="NotifyWishes__link"
                        onClick={() => handleNavigateToUser(user_id)}
                      >
                        {(name_business ?? "").slice(0, 32)}
                      </p>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell align="center" size="small">
                    <Button
                      startIcon={<NotificationsActiveIcon />}
                      variant="contained"
                      rounded="true"
                      size="small"
                      onClick={() => handleNotify(user_id)}
                      loading={currentLoading}
                      disabled={currentLoading}
                    >
                      Notificar
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Styles className="NotifyWishes">
      {isLoading && <CircularProgress />}
      {renderCategoriesList()}
    </Styles>
  );
};

NotifyWishes.defaultProps = {};

export default NotifyWishes;
