import Layout from "components/Layout/Layout";
import React from "react";

import ApplicantList from "./ApplicantList";
import { ApplicantListContainerProps as Props } from "./ApplicantList.types";

const ApplicantListContainer: React.FC<Props> = props => {
  return (
    <Layout>
      <ApplicantList />;
    </Layout>
  );
};

ApplicantListContainer.defaultProps = {};

export default ApplicantListContainer;
