import api, { buildHeaders } from "../Api";
import { Brand, Category, Subcategory } from "./categories.service.types";

export async function getCategories(): Promise<Category[]> {
  return await api
    .get(`category/complete`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export async function createCategory(name: string): Promise<boolean> {
  return await api
    .post(`admi/category`, { name }, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export async function deleteCategory(id: number): Promise<boolean> {
  return await api
    .delete(`admi/category/${id}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

interface EditCategoryProps {
  id: number;
  newName: string;
}

export async function editCategory({
  id,
  newName
}: EditCategoryProps): Promise<boolean> {
  return await api
    .patch(
      `admi/category/${id}`,
      { name: newName },
      {
        headers: await buildHeaders()
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export async function getSubcategories(id: number): Promise<Subcategory[]> {
  return await api
    .get(`category/sub/${id}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

type CreateSubcategoryProps = {
  name: string;
  categoryId: number;
};

export async function createSubcategory({
  name,
  categoryId
}: CreateSubcategoryProps): Promise<boolean> {
  return await api
    .post(
      `admi/category/sub`,
      { name, category_id: categoryId },
      {
        headers: await buildHeaders()
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export async function deleteSubcategory(id: number): Promise<boolean> {
  return await api.delete(`admi/category/sub/${id}`, {
    headers: await buildHeaders()
  });
}

type EditSubcategoryProps = {
  id: number;
  newName: string;
};

export async function editSubcategory({
  id,
  newName
}: EditSubcategoryProps): Promise<boolean> {
  return await api
    .patch(
      `admi/category/sub/${id}`,
      { name: newName, sub_category_id: id },
      {
        headers: await buildHeaders()
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export async function getBrands(): Promise<Brand[]> {
  return await api
    .get(`mark`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

type CreateBrandProps = {
  name: string;
  subcategoryId: number;
};

export async function createBrand({
  name,
  subcategoryId
}: CreateBrandProps): Promise<boolean> {
  return await api
    .post(
      `admi/mark`,
      { name, sub_category_id: subcategoryId },
      { headers: await buildHeaders() }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

export async function deleteBrand(id: number): Promise<boolean> {
  return await api
    .delete(`admi/mark/${id}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}

type EditBrandProps = {
  id: number;
  name: string;
  subcategoryId: number;
};

export async function editBrand({
  id,
  name,
  subcategoryId
}: EditBrandProps): Promise<boolean> {
  return await api
    .patch(
      `admi/mark/${id}`,
      { name, sub_category_id: subcategoryId },
      {
        headers: await buildHeaders()
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw error;
    });
}
