import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ChatIcon from "@mui/icons-material/Chat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { variables } from "config/variables";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import useGlobals from "context/globals/globals.hooks";
import dayjs from "dayjs";
import React from "react";
import { User } from "services/Auth/Auth.services.types";
import { useBlockUnBlockMutation } from "services/Interviews/interviews.service.hooks";
import { useAllClients } from "services/Interviews/interviews.service.hooks";
import { useCreateChat } from "services/userChat/userChat.service.hooks";
import { fail, successful } from "utils/Swal";

import ClientInfoDetails from "../ClientInfoDetails/ClientInfoDetails";
import Styles from "./AdminClientsList.styles";
import { AdminClientsListProps as Props } from "./AdminClientsList.types";
const { palette } = variables;

const AdminClientsList: React.FC<Props> = props => {
  const { mutateAsync, reset } = useCreateChat();
  const { data: clientList, isLoading, refetch } = useAllClients();

  const { selectedClient, setSelectedClient } = useGlobals();
  const { mutateAsync: blockUnblockMutation, reset: blockReset } =
    useBlockUnBlockMutation();

  const handleCreateChat = async (uid: number) => {
    try {
      await mutateAsync(uid);
      reset();
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.open(`/panel/chat`);
    } catch (error) {
      fail("Error al crear el chat con el usuario");
    }
  };

  const handleBlockUnblock = async (uid?: number) => {
    try {
      if (!uid) throw new Error("Error bloquear o desbloquear cliente");
      await blockUnblockMutation(uid);
      successful("Estado modificado");
      blockReset();
      refetch();
    } catch (error) {
      fail("Error bloquear o desbloquear cliente");
    }
  };

  const renderRol = (rol: number) => {
    switch (rol) {
      case 1:
        return "Comprador";
      case 5:
        return "Vendedor";
      case 10:
        return "Admin";
      default:
        return "Comprador";
    }
  };

  const renderRolColor = (rol: number) => {
    switch (rol) {
      case 1:
        return "info";
      case 5:
        return "info";
      case 10:
        return "error";
      default:
        return "success";
    }
  };

  const renderActions = (item: User) => {
    const { id, status } = item ?? {};

    return (
      <>
        {status !== 1 ? (
          <Tooltip title="Desbloquear usuario">
            <IconButton onClick={() => {}} sx={{ borderRadius: 500 }}>
              <CheckCircleIcon
                fontSize="medium"
                sx={{
                  marginRight: 1,
                  color: palette.chipSuccess
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Bloquear usuario">
            <IconButton onClick={() => handleBlockUnblock(id)}>
              <DoDisturbIcon
                fontSize="medium"
                sx={{
                  color: palette.chipOrange,
                  marginRight: 1
                }}
              />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Chat con cliente">
          <IconButton onClick={() => handleCreateChat(id)}>
            <ChatIcon
              fontSize="medium"
              sx={{
                marginRight: 1,
                color: palette.three
              }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="medium" align="left">
                Nombre
              </StyledTableCell>
              <StyledTableCell align="left">Correo</StyledTableCell>
              <StyledTableCell align="left">Teléfono</StyledTableCell>
              <StyledTableCell align="left">País</StyledTableCell>
              <StyledTableCell align="left">Ocupación</StyledTableCell>
              <StyledTableCell align="left">Fecha de registro</StyledTableCell>
              <StyledTableCell align="center" size="small" width={16}>
                Rol
              </StyledTableCell>
              <StyledTableCell align="center" size="small" width={16}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Ver información
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientList?.map((item, key) => {
              const { name, surname, email } = item ?? {};
              const { country, ocupation, status } = item ?? {};
              const { phone, updated_at, rol } = item ?? {};

              return (
                <StyledTableRow key={`${`item.item_id`}-${key}`}>
                  <StyledTableCell align="left">
                    {`${name ?? ""} ${surname ?? ""}`.slice(0, 30)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {email?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">{phone ?? ""}</StyledTableCell>
                  <StyledTableCell align="left">
                    {country?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {ocupation?.slice(0, 30) ?? ""}
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {dayjs(updated_at).format("DD/MM/YYYY HH:mm a")}
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    <Chip
                      label={renderRol(rol)}
                      variant={rol === 5 ? "filled" : "outlined"}
                      color={renderRolColor(rol)}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    <Chip
                      label={status !== 1 ? "Bloqueado" : "Aprobado"}
                      variant={status !== 1 ? "filled" : "outlined"}
                      color={status !== 1 ? "error" : "success"}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Button
                      variant="text"
                      rounded="true"
                      size="small"
                      startIcon={<AccountBoxIcon />}
                      onClick={() => setSelectedClient(item?.id)}
                    >
                      Ver usuario
                    </Button>
                  </StyledTableCell>
                  <StyledTableCell align="right" size="small">
                    {renderActions(item)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Styles className="AdminClientsList">
      {isLoading && <CircularProgress />}
      {selectedClient ? <ClientInfoDetails /> : renderTable()}
    </Styles>
  );
};

AdminClientsList.defaultProps = {};

export default AdminClientsList;
