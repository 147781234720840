import LoginModal from "components/Dashboard/components/LoginModal/LoginModal";
import React, { useState } from "react";

const Login = () => {
  const [, setModalLogin] = useState(false);

  return (
    <div className="bg-[url('/src/assets/images/auctioneers.jpeg')] h-screen lg:h-screen bg-cover bg-center opacity-100 bg-scroll">
      <LoginModal
        modalLogin={true}
        setModalLogin={setModalLogin}
        onOpenCloseModalRegister={() => {}}
        customTitle="como administrador"
        hideRegister
        customRoute="/admin/"
      />
    </div>
  );
};
export default Login;
