import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatIcon from "@mui/icons-material/Chat";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HomeIcon from "@mui/icons-material/Home";
import TopicIcon from "@mui/icons-material/Topic";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Breadcrumbs, Link, TextField, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { ModalStyles } from "components/Auctioners/createAuction/CreateAuction.styles";
import CustomModal from "components/CustomModal/CustomModal";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import TableFilters from "components/TableFilters/TableFilters";
import { CONSTANTS } from "config/constants";
import { variables } from "config/variables";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import useGlobals from "context/globals/globals.hooks";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { WinnedAuction } from "services/Auctioners/Auctioners.service.types";
import { markCommissionAsPayed } from "services/Bids/Bids.service";
import { editCommission } from "services/Bids/Bids.service";
import { useFetchAdminWinnedLotesById } from "services/Bids/Bids.services.hooks";
import { useFetchAdminWinnedLotes } from "services/Bids/Bids.services.hooks";
import { useCreateChat } from "services/userChat/userChat.service.hooks";
import { fail, successful } from "utils/Swal";

import Styles from "./Results.styles";
import { ResultsProps as Props } from "./Results.types";

const { IMAGE_PLACEHOLDER } = CONSTANTS;
const { palette } = variables;

const Results: React.FC<Props> = props => {
  const [openModalCommission, setOpenModalCommission] = useState(false);
  const { setSelectedIndex, setSelectedClient } = useGlobals();
  const { setCurrentAuction, currentAuction } = useGlobals();
  const { selectedSubIndex, setSelectedSubIndex } = useGlobals();
  const commissionValue = useRef<any>(undefined);
  const currentInterview = useRef<number | undefined>(undefined);
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("0");
  const [date, setDate] = useState("");
  const [auctionType, setAuctionType] = useState(dayjs().format("1"));
  const [country, setCountry] = useState("1");
  const { mutateAsync, reset } = useCreateChat();

  const fetchWinnedAuctions = useFetchAdminWinnedLotes(
    auctionType,
    date,
    query,
    status,
    country
  );
  const { data: auctionList = [] } = fetchWinnedAuctions;
  const { refetch, isLoading } = fetchWinnedAuctions;
  const fetchWinnedItems = useFetchAdminWinnedLotesById();
  const { data: itemList, isLoading: isSubLoading } = fetchWinnedItems;

  const handleNavigate = (itemId: number, auctionId: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/item/${auctionId}/${itemId}`);
  };

  const payCommission = async (auctionId: number) => {
    try {
      await markCommissionAsPayed(auctionId);
      refetch();
    } catch (error) {
      fail("Error al marcar la comisión como cobrada");
    }
  };

  const handleCreateChat = async (uid: number) => {
    try {
      await mutateAsync(uid);
      reset();
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.open(`/panel/chat`);
    } catch (error) {
      fail("Error al crear el chat con el usuario");
    }
  };

  const handleEditCommission = async () => {
    try {
      if (currentInterview.current && commissionValue.current)
        await editCommission({
          user_id: currentInterview.current,
          commission: +commissionValue.current
        });
      refetch();
      successful("Se creó la comisión con éxito");
    } catch (error) {
      fail("Error al editar la comisión del vendedor");
    }
  };

  const renderSingleHeader = () => {
    const { id: auctionId, totalSales } = currentAuction ?? {};
    const { totalCommission, commission } = currentAuction ?? {};
    const { commission: commissionValue } = commission ?? {};
    const { url_logo_1 } = currentAuction ?? {};
    const { auction_house, country } = currentAuction ?? {};
    const { commission_status, exec_time } = currentAuction ?? {};

    const { name: countryName } = country ?? {};
    const { name_business, logo } = auction_house ?? {};
    const photo = logo ?? url_logo_1 ?? IMAGE_PLACEHOLDER;

    return (
      <StyledTableRow key={`${auctionId}-007`}>
        <StyledTableCell align="left">
          <div className="Results__cell">
            <Avatar alt="Lote Image" src={photo} variant="rounded" />
            <p
              className="Results__link"
              onClick={() => handleNavigateToUser(auctionId)}
            >
              {(name_business ?? "").slice(0, 24)}
            </p>
          </div>
        </StyledTableCell>
        <StyledTableCell align="left">
          {(countryName ?? "").slice(0, 24)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {`$ ${totalSales?.toFixed(2)}`}
        </StyledTableCell>

        <StyledTableCell align="left">
          <p
            className="Results__link"
            onClick={() => {
              currentInterview.current = auctionId;
              setOpenModalCommission(true);
            }}
          >
            {`$ ${totalCommission?.toFixed(2)} - (${
              (commissionValue ?? 0) * 100
            }%)`}
          </p>
        </StyledTableCell>
        <StyledTableCell align="left">
          {dayjs(exec_time).format("DD/MM/YYYY HH:mm a")}
        </StyledTableCell>
        <StyledTableCell align="left" size="small">
          <Chip
            label={commission_status}
            variant={commission_status !== "Pendiente" ? "filled" : "outlined"}
            color={commission_status === "Pendiente" ? "success" : "info"}
          />
        </StyledTableCell>
        <StyledTableCell align="center" size="small">
          <Button
            onClick={() => setCurrentAuction(undefined)}
            startIcon={<VisibilityOffIcon />}
          >
            Quitar DDS
          </Button>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const renderBreadCrumb = () => {
    return (
      <div
        className="Results__breadCrumb"
        role="presentation"
        onClick={() => {}}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/admin"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => {
              setSelectedIndex(1);
              setSelectedSubIndex(0);
            }}
          >
            <TopicIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Resultados
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            {selectedSubIndex === 0
              ? "Subastas en vivo"
              : "Subastas cronometradas"}
          </Link>
          {currentAuction && (
            <Link
              underline="always"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => setCurrentAuction(undefined)}
            >
              <ArrowBackIcon />
              {currentAuction.auction_house?.name_business}
            </Link>
          )}
        </Breadcrumbs>
      </div>
    );
  };

  const handleNavigateToUser = (id?: number) => {
    setSelectedClient(id);
    setSelectedIndex(1);
    setSelectedSubIndex(1);
  };

  const renderActions = (auction: WinnedAuction) => {
    const { id: auctionId, commission_status, user_id } = auction ?? {};

    return (
      <div className="Results__actions">
        {auctionId && commission_status === "Pendiente" && (
          <Tooltip title="Marcar comisión como cobrada">
            <CheckCircleOutlineIcon
              fontSize="medium"
              sx={{
                color: palette.buttonColor,
                cursor: "pointer",
                marginRight: 1
              }}
              onClick={() => payCommission(auctionId)}
            />
          </Tooltip>
        )}
        {user_id && (
          <Tooltip title="Chatear con la casa de subastas">
            <ChatIcon
              fontSize="medium"
              sx={{
                color: palette.chipSuccess,
                cursor: "pointer"
              }}
              onClick={() => handleCreateChat(user_id)}
            />
          </Tooltip>
        )}
        <Tooltip title="Ver detalles de la subasta">
          <Button
            onClick={() => setCurrentAuction(auction)}
            variant="contained"
            size="small"
            className="Results__ddsButton"
            // startIcon={<VisibilityIcon />}
          >
            DDS
          </Button>
        </Tooltip>
      </div>
    );
  };

  const requestCommissionValue = () => {
    return (
      <CustomModal
        isOpen={openModalCommission}
        onClose={() => setOpenModalCommission(!openModalCommission)}
        ariaLabelledby="Modal para solicitar la comisión."
      >
        <ModalStyles className="ModalStyles">
          <Typography variant="h5" component="h5">
            Editar Comisión de venta
          </Typography>
          <TextField
            label="Comisión de venta Ej. 0.4"
            variant="standard"
            ref={commissionValue}
            onChange={e => {
              commissionValue.current = e.currentTarget.value;
            }}
            className="AdminClientListRequests__textField"
            sx={{ width: "220px", margin: "16px 0" }}
          />
          <Button
            onClick={() => {
              setOpenModalCommission(!openModalCommission);
              handleEditCommission();
            }}
            variant="contained"
          >
            Confirmar
          </Button>
        </ModalStyles>
      </CustomModal>
    );
  };

  const renderSubTable = () => {
    if (typeof currentAuction === "undefined") return null;
    return (
      <TableContainer component={Paper} className="mt-2">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small">Imagen</StyledTableCell>
              <StyledTableCell size="small" align="left">
                Número de lote
              </StyledTableCell>
              <StyledTableCell align="left">Nombre del lote</StyledTableCell>
              <StyledTableCell align="left">Precio pagado</StyledTableCell>
              <StyledTableCell align="left">Comisión</StyledTableCell>
              <StyledTableCell align="left">Fecha de venta</StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList?.map((winner, key) => {
              const { item, user } = winner ?? {};
              const { images, auction, name, lot } = item ?? {};
              const { auction_house, commission } = auction ?? {};

              const { user_id: auctionId } = auction_house ?? {};
              const { id: uid } = user ?? {};
              const { commission: commissionValue = 0 } = commission ?? {};
              const [{ photo }] = images ?? [{}];

              return (
                <StyledTableRow key={`${winner.item_id}-${key}`}>
                  <StyledTableCell component="th" scope="row">
                    <Avatar alt="Lote Image" src={photo} variant="rounded" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${item?.auction_id}A-${lot}I`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <p
                      className="Results__link"
                      onClick={() => handleNavigateToUser(uid)}
                    >
                      {name?.slice(0, 24)}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`$${winner?.price}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <p
                      className="Results__link"
                      onClick={() => {
                        currentInterview.current = auctionId;
                        setOpenModalCommission(true);
                      }}
                    >
                      {`$${((winner?.price ?? 0) * commissionValue).toFixed(
                        2
                      )} - (${commissionValue * 100}%)`}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {dayjs(winner?.updated_at).format("DD/MM/YYYY HH:mm a")}
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    <Button
                      startIcon={<VisibilityIcon />}
                      onClick={() =>
                        handleNavigate(winner?.item_id, item?.auction_id)
                      }
                    >
                      Ver Lote
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Casa de subastas</StyledTableCell>
              <StyledTableCell align="left">País</StyledTableCell>
              <StyledTableCell align="left">Ganancias totales</StyledTableCell>
              <StyledTableCell align="left">Comisiones totales</StyledTableCell>
              <StyledTableCell align="left">Fecha de venta</StyledTableCell>
              <StyledTableCell align="center" width={16}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {typeof currentAuction !== "undefined"
              ? renderSingleHeader()
              : null}
            {typeof currentAuction === "undefined" &&
              auctionList?.map((auction, key) => {
                const { id: auctionId, totalSales } = auction ?? {};
                const { totalCommission, commission } = auction ?? {};
                const { commission: commissionValue } = commission ?? {};
                const { url_logo_1 } = auction ?? {};
                const { auction_house, commission_status } = auction ?? {};
                const { country, exec_time } = auction ?? {};

                const { name: countryName } = country ?? {};
                const { name_business, logo } = auction_house ?? {};
                const photo = logo ?? url_logo_1 ?? IMAGE_PLACEHOLDER;

                return (
                  <StyledTableRow key={`${auctionId}-${key}`}>
                    <StyledTableCell align="left">
                      <div className="Results__cell">
                        <Avatar
                          alt="Lote Image"
                          src={photo}
                          variant="rounded"
                        />
                        <p
                          className="Results__link"
                          onClick={() => handleNavigateToUser(auctionId)}
                        >
                          {(name_business ?? "").slice(0, 24)}
                        </p>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {(countryName ?? "").slice(0, 24)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {`$ ${totalSales.toFixed(2)}`}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p
                        className="Results__link"
                        onClick={() => {
                          currentInterview.current = auctionId;
                          setOpenModalCommission(true);
                        }}
                      >
                        {`$ ${totalCommission.toFixed(2)} - (${
                          commissionValue * 100
                        }%)`}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {dayjs(exec_time).format("DD/MM/YYYY HH:mm a")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Chip
                        label={commission_status}
                        variant={
                          commission_status !== "Pendiente"
                            ? "filled"
                            : "outlined"
                        }
                        color={
                          commission_status === "Pendiente" ? "success" : "info"
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {renderActions(auction)}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderChildren = () => {
    return (
      <>
        <TableFilters
          setStatus={setStatus}
          status={status}
          setCountry={setCountry}
          country={country}
          setQuery={setQuery}
          query={query}
          date={date}
          setDate={setDate}
          auctionType={auctionType}
          setAuctionType={setAuctionType}
        />
        {renderTable()}
        {renderSubTable()}
      </>
    );
  };

  useEffect(() => {
    if (selectedSubIndex === 1) setAuctionType("2");
    if (selectedSubIndex === 0) setAuctionType("1");
  }, [selectedSubIndex]);

  return (
    <Styles className="Results">
      {(isLoading || isSubLoading) && <CircularProgress />}
      {renderBreadCrumb()}
      {renderChildren()}
      {requestCommissionValue()}
    </Styles>
  );
};

Results.defaultProps = {};

export default Results;
