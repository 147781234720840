// eslint-disable-next-line import/order
import { Dashboard, Receipt } from "@mui/icons-material";

const items = [
  {
    /*  href: ROUTE.home, */
    icon: <Dashboard />,
    title: "Dashboard"
  },
  {
    /* href: ROUTE.invoice.home, */
    icon: <Receipt />,
    title: "Invoice"
  }
];

export { items };
