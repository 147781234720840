import Rating from "@mui/material/Rating";
import Button from "components/Globals/Button/Button";
import { CONSTANTS } from "config/constants";
import { variables } from "config/variables";
import React, { useEffect, useState } from "react";
import { addFavorite } from "services/Auctioners/Auctioners.service";
import { useGetMyFavorites } from "services/Auctioners/Auctioners.service.hooks";
import { useGetFavoriteById } from "services/Auctioners/Auctioners.service.hooks";

import Styles from "./SellerPresentation.styles";
import { SellerPresentationProps as Props } from "./SellerPresentation.types";
const { IMAGE_PLACEHOLDER } = CONSTANTS;

const { palette } = variables;

const SellerPresentation: React.FC<Props> = props => {
  const { sellerProfile } = props;

  const [isFavorite, setIsFavorite] = useState(false);

  const { name_business, logo, reviews, avg_reviews } = sellerProfile ?? {};
  const { followers, id } = sellerProfile ?? {};

  const { data } = useGetFavoriteById(id);
  const { data: responseFavorite } = data ?? {};
  const { refetch } = useGetMyFavorites();

  const handleAddFavorite = async () => {
    setIsFavorite(!isFavorite);
    await addFavorite(id, { type: "PERSON" });
    refetch();
  };

  useEffect(() => {
    if (responseFavorite) setIsFavorite(responseFavorite);
  }, [id, responseFavorite]);

  return (
    <Styles className="SellerPresentation">
      <img
        className="SellerPresentation__image"
        src={logo ? logo : IMAGE_PLACEHOLDER}
        alt="Logo1"
      />
      <div className="SellerPresentation__content">
        <h2 className="SellerPresentation__title">{name_business}</h2>
        <div className="SellerPresentation__section">
          <Rating
            name="read-only"
            value={avg_reviews}
            precision={0.5}
            readOnly
            style={{ color: palette.primary }}
          />
          <h4>{`${reviews} Reseñas`}</h4>
        </div>
        <div className="SellerPresentation__section">
          <Button
            variant={!isFavorite ? "outlined" : "contained"}
            rounded="true"
            size="medium"
            onClick={handleAddFavorite}
          >
            {isFavorite ? "Seguir" : "Dejar de seguir"}
          </Button>
          <h3>{`${followers} Seguidores`}</h3>
        </div>
      </div>
    </Styles>
  );
};

SellerPresentation.defaultProps = {};

export default SellerPresentation;
