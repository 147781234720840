import styled from "@emotion/styled";
import { AppBar } from "@mui/material";
import { devices } from "config/devices";
import { variables } from "config/variables";

const { palette, fonts } = variables;

const DashboardNavbarRoot = styled(AppBar)(() => ({
  backgroundColor: palette.blackTable,
  color: palette.dark
}));

const NavbarStyles = {
  root: {
    backgroundColor: "primary.800",
    paddingTop: "8px"
  },
  ToolbarStylesRoot: {
    justifyContent: "space-between",
    overflowX: "auto",
    padding: "0 32px"
  },
  LinkStyles: {
    p: 1,
    flexShrink: 0,
    fontFamily: fonts["Poppins-El"],
    textDecoration: "none"
  },
  ToolbarStyles: {
    minHeight: 48,
    left: 0,
    px: 2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [`@media screen and ${devices.laptopL}`]: {
      minHeight: 54
    },
    [`@media screen and ${devices.tablet}`]: {
      minHeight: 64
    }
  },
  IconButtonStyles: {
    variant1: {
      display: "inline-flex",
      [`@media screen and ${devices.tablet}`]: {
        display: "none"
      }
    }
  },
  variant2: { p: 0 },
  SpanStyles: { marginRight: 10 },
  BoxStyles: {
    variant1: {
      width: "160px",
      px: 2,
      display: "block",
      cursor: "pointer",
      [`@media screen and ${devices.tablet}`]: {
        width: "192px"
      }
    },
    variant2: { flexGrow: 1 },
    variant3: { flexGrow: 0 },
    variant4: {
      display: "none",
      [`@media screen and ${devices.tablet}`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginLeft: "320px",
        width: "100%",
        gap: "4px",
        color: "white"
      },
      [`@media screen and ${devices.laptop}`]: {
        gap: "8px"
      },
      [`@media screen and ${devices.laptopL}`]: {
        gap: "16px"
      }
    }
  },
  MenuStyles: { mt: "40px" },
  MenuItemStyles: { fontFamily: fonts["Poppins-El"] }
};

const GuestLinksStyles = styled.div`
  .guestLinksStyle {
    &__container {
      display: flex;
      gap: 4px;
    }
  }
`;

export { NavbarStyles, DashboardNavbarRoot, GuestLinksStyles };
