import { LinearProgress, Rating } from "@mui/material";
import { variables } from "config/variables";
import React from "react";

import Styles from "./ChartReviews.styles";
import { ChartReviewsProps as Props } from "./ChartReviews.types";
const { palette } = variables;

const ChartReviews: React.FC<Props> = props => {
  const { sellerProfile } = props;
  const { reviews, one_starts, two_starts, avg_reviews } = sellerProfile ?? {};
  const { three_starts, fourth_starts, five_starts } = sellerProfile ?? {};

  const renderQualification = (stars: number) => {
    let percent = (one_starts ?? 0) / (reviews ?? 1);
    let total = one_starts;

    switch (stars) {
      case 1:
        percent = (one_starts ?? 0) / (reviews ?? 1);
        total = one_starts;
        break;
      case 2:
        percent = (two_starts ?? 0) / (reviews ?? 1);
        total = two_starts;
        break;
      case 3:
        percent = (three_starts ?? 0) / (reviews ?? 1);
        total = three_starts;
        break;
      case 4:
        percent = (fourth_starts ?? 0) / (reviews ?? 1);
        total = fourth_starts;
        break;
      case 5:
        percent = (five_starts ?? 0) / (reviews ?? 1);
        total = five_starts;
        break;

      default:
        break;
    }

    return (
      <div className="ChartReviews__section" key={stars}>
        <h2>{`${stars} Estrellas`}</h2>
        <LinearProgress
          variant="determinate"
          value={percent * 100}
          sx={{ height: 16, width: "72%", maxWidth: "72%" }}
        />
        <h3>{`${Math.round(percent * 100)}%`}</h3>
        <h4>{`(${total})`}</h4>
      </div>
    );
  };

  return (
    <Styles className="ChartReviews">
      <div className="ChartReviews__rating">
        <Rating
          name="read-only"
          value={avg_reviews}
          precision={0.5}
          readOnly
          style={{ color: palette.primary }}
        />
        <h3>{`${reviews} Reseñas`}</h3>
      </div>
      <div className="ChartReviews__stars">
        {[1, 2, 3, 4, 5].map(val => renderQualification(val))}
      </div>
    </Styles>
  );
};

ChartReviews.defaultProps = {};

export default ChartReviews;
