import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { LiveAuctionStyledProps as Props } from "./LiveAuction.types";
const { palette, fonts, shadows } = variables;

const Styles = styled.div<Props>`
  .swiper-button-next,
  .swiper-button-prev {
    color: ${palette.primary};
    display: none !important;
  }

  .swiper-slide {
    width: auto;
  }

  .LiveAuction {
    &__layout {
      background-color: ${palette.greyBackground};
      height: 100%;
      width: 100%;
      overflow-y: hidden;
      position: fixed;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &--container {
        width: 99%;
        height: 98%;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      width: 60%;

      &--background {
        background-color: ${palette.whiteBackground};
      }

      &--components {
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: inherit;

        @media ${devices.laptop} {
          gap: 12px;
        }
      }

      &--top {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 8px;
      }

      &--bottom {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 100%;

        &-right {
          flex: 1 1 65%;
          width: 65%;
          max-width: 65%;
          height: calc(100% - 297px);

          @media ${devices.laptop} {
            flex: 1 1 70%;
            width: 70%;
            max-width: 70%;
            height: calc(100% - 337px);
          }

          @media ${devices.laptopL} {
            flex: 1 1 70%;
            width: 70%;
            max-width: 70%;
            height: calc(100% - 337px);
          }

          @media ${devices.desktop} {
            height: calc(100% - 322px);
          }

          &_noOwner {
            height: calc(100% - 301px);

            @media ${devices.laptop} {
              height: calc(100% - 311px);
            }

            @media ${devices.laptopL} {
              height: calc(100% - 326px);
            }

            @media ${devices.desktop} {
              height: calc(100% - 311px);
            }
          }
        }

        &-left {
          width: 35%;
          flex: 0 0 35%;
          max-width: 35%;
          height: calc(100% - 109px);

          @media ${devices.laptop} {
            width: 30%;
            flex: 0 0 30%;
            max-width: 30%;
            height: calc(100% - 119px);
          }

          @media ${devices.desktop} {
            height: calc(100% - 104px);
          }
        }
      }
    }

    &__right-bids {
      &--container {
        flex: 1 1 auto;
        width: 100%;
        box-shadow: ${shadows.shadowCard};
        background-color: ${palette.white};
        height: 100%;

        &-lots {
          display: flex;
          width: 100%;
          flex-direction: column;
          border-bottom: 1px solid ${palette.dividerColor};
        }

        &-temporizer {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          padding-bottom: 4px;
          border-bottom: 1px solid ${palette.dividerColor};
        }
      }

      &--text {
        font-size: 14px;
        font-family: ${fonts["Poppins-Lt"]};
        text-align: center;

        @media ${devices.laptop} {
          font-size: 16px;
        }
      }
    }

    &__category {
      &--text {
        &-normal {
          font-family: ${fonts["Poppins-El"]};
          font-size: 14px;
          font-weight: 600;

          @media ${devices.laptop} {
            font-size: 16px;
            font-weight: 600;
          }
        }

        &-light {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: ${fonts["Poppins-El"]};

          @media ${devices.laptop} {
            font-size: 16px;
            line-height: 20px;
          }
        }

        &-container {
          display: flex;
          justify-content: space-between;
          width: 100%;

          @media ${devices.laptopL} {
            width: 80%;
          }
        }
      }

      &--image {
        width: 120px;

        @media ${devices.laptop} {
          width: 200px;
        }
      }

      &--icon {
        color: ${palette.grey};
        font-size: 24px;
        cursor: pointer;
      }

      &--container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 260px;
        max-height: 100%;
        height: auto;
        padding: 16px;
        gap: 16px;

        @media ${devices.laptop} {
          width: 320px;
        }

        &-components {
          display: flex;
          width: auto;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    &__carrouselLots {
      &--container {
        &-swiper {
          display: flex;
          width: calc(100% - 206px);

          @media ${devices.laptop} {
            width: calc(100% - 294px);
          }
        }

        &-slide {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      &--card {
        &-container {
          display: flex;
          gap: 16px;
          align-items: center;
          width: 360px;
          height: auto;
        }

        &-text {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-family: ${fonts["Poppins-El"]};

          @media ${devices.laptop} {
            font-size: 16px;
            font-weight: 600;
          }
        }

        &-image {
          width: 100%;
          max-width: 180px;
          height: 100%;
          max-height: 100px;
          transition: 0.8s;
          border-radius: 8px;
          object-fit: contain;

          &:hover {
            transform: scale(1.02);
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
        }

        &-current {
          border: 2px solid ${palette.primary};
        }

        &-past {
          opacity: 0.5;
          border: 1px dashed ${palette.primaryBackgroundLight};
        }

        &-live {
          color: ${palette.liveRed};
          font-family: ${fonts["Poppins-Md"]};
        }
      }
    }

    &__imagesLot {
      &--container {
        &-global {
          padding: 8px 0 0 8px;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          overflow: auto;
          height: 100%;
          padding-right: 0;

          @media ${devices.laptop} {
            padding: 16px 0 0 16px;
          }
        }

        &-content {
          flex: 1 1 auto;
          display: flex;
          gap: 16px;
          flex-direction: column;
          overflow: auto;
          padding: 0px 0px 30px;
        }
      }

      &--title {
        font-size: 16px;
        font-family: ${fonts["Poppins-Rg"]};
        margin-bottom: 8px;

        @media ${devices.laptop} {
          font-size: 20px;
          margin-bottom: 16px;
        }

        @media ${devices.desktop} {
          font-size: 24px;
        }
      }
    }

    &__endAuction {
      &--layout {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        width: 100vw;
      }
    }
  }
`;

export default Styles;
