import { devices } from "config/devices";
import styled from "styled-components";

import Button from "../Button/Button";
import { ButtonLargeStyledProps as Props } from "./ButtonLarge.types";

const ButtonLargeStyled = styled(Button)((props: Props) => ({
  fontSize: "14px",
  minWidth: "240px",
  [`@media ${devices.mobileL}`]: {
    fontSize: "16px"
  }
}));

export default ButtonLargeStyled;
