import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts } = variables;

const Styles = styled.div`
  .ListAuctions {
    &__container {
      &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media ${devices.mobileL} {
          justify-content: space-between;
        }
      }

      &--input {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        padding: 16px;

        @media ${devices.tablet} {
          padding-top: 0;
          padding-right: 24px;
          padding-left: 24px;
        }

        @media ${devices.laptop} {
          padding-top: 0;
          padding-right: 40px;
          padding-left: 40px;
        }
      }
    }

    &__label {
      display: block;
      font-size: 16px;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      font-weight: 560;
      color: ${palette.primary};

      @media ${devices.mobileL} {
        font-size: 18px;
      }
      @media ${devices.laptop} {
        font-size: 20px;
      }

      @media ${devices.desktop} {
        font-size: 24px;
      }
    }

    &__input {
      font-family: ${fonts["Poppins-Rg"]};
      font-size: 16px;
      font-weight: 320;
      line-height: 24px;
      letter-spacing: 1.2px;
    }
  }
`;

export default Styles;
