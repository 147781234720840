import { useAppSelector } from "hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fail } from "utils/Swal";

import { getUserExtraData, getCurrencyTypes } from "./Auth.service";
import { register, tokenizeCard } from "./Auth.service";
import { getSellerData } from "./Auth.service";
import { getUserDataById } from "./Auth.service";
import { login } from "./Auth.service";
import { LoginData, SignInResponse } from "./Auth.services.types";
import { RegisterData, RegisterResponse } from "./Auth.services.types";

export const useIsLogged = () => {
  const { user } = useAppSelector(state => state.authReducer);
  const isAuthenticated = !!user?.id;

  return { isAuthenticated };
};

export const useSignIn = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMutation<SignInResponse, unknown, LoginData>(login, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["signIn", "user"]);
    },
    onError: () => {
      console.error("Error de autentificación");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries(["signIn", "user"]);
    }
  });
};

export const useRegister = () => {
  const queryClient = useQueryClient();

  return useMutation<RegisterResponse, unknown, RegisterData>(register, {
    onMutate: async () => {
      await queryClient.cancelQueries(["register, user"]);
    },
    onError: () => {
      console.error("Error al registrar nuevo usuario");
    },
    onSettled: () => {
      queryClient.invalidateQueries(["register, user"]);
    }
  });
};

export const useGetUserDataById = (id?: number) => {
  return useQuery(["users", id], () => getUserDataById(id), {
    enabled: !!id,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los datos del usuario");
    }
  });
};

export const useGetSellerData = (userId?: number) => {
  const { user: reduxUser } = useAppSelector(state => state.authReducer);
  return useQuery(["seller"], () => getSellerData(userId), {
    enabled: !!userId && reduxUser?.rol === 5,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los datos del usuario");
    }
  });
};

export const useGetUserExtraData = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["user-data"], () => getUserExtraData(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los datos del usuario");
    }
  });
};

export const useGetCurrencyTypes = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["currencies"], () => getCurrencyTypes(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los datos del usuario");
    }
  });
};

export const useTokenizeCard = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryClient = useQueryClient();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMutation<any, unknown, string>(tokenizeCard, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(["payment"]);
    },
    onError: () => {
      console.error("Error al agregar la tarjeta");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries(["payment"]);
    }
  });
};
