import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { ListPricesStyledProps as Props } from "./ListPrices.types";

const { palette, fonts } = variables;

const ListPricesStyled = styled.div<Props>`
  .ListPrices {
    &__container {
      &--button {
        display: flex;
        padding: 24px 0;
        justify-content: center;
      }
    }

    &__text {
      &--title {
        font-size: 16px;
        text-align: center;
        font-family: ${fonts["Poppins-El"]};
        color: ${palette["primary"]};
        font-weight: 600;
        letter-spacing: 0.8px;
        margin-bottom: 16px;

        @media ${devices.tablet} {
          margin-bottom: 24px;
        }

        @media ${devices.laptop} {
          font-size: 18px;
        }

        @media ${devices.laptopL} {
          font-size: 20px;
        }

        @media ${devices.desktop} {
          font-size: 24px;
        }
      }
    }
  }
`;

export default ListPricesStyled;
