export const handleValueDefault = (type: any) => {
  switch (type) {
    case "text":
      return "";
    case "file":
      return {};

    case "number":
      return "";

    case "password":
      return "";

    default:
      return "";
  }
};
