import { FormControl, Select } from "@mui/material";
import React from "react";

import { FormControlStyles } from "./Select.styles";
import { FormHelperTextStyles, InputLabelStyles } from "./Select.styles";
import { InputStyles } from "./Select.styles";
import { SelectProps as Props } from "./Select.types";

const SelectCustom: React.FC<Props> = props => {
  const { field, fieldState, children, config, style } = props;

  return (
    <FormControl
      fullWidth
      error={fieldState.invalid}
      sx={FormControlStyles}
      variant="standard"
      style={style}
    >
      <InputLabelStyles id={`${field?.name}-select-label`}>
        {config.label}
      </InputLabelStyles>
      <Select
        labelId={`${field?.name}-select-label`}
        label={config.label}
        id={`${field?.name}-select`}
        input={<InputStyles />}
        {...field}
        style={style}
      >
        {children}
      </Select>
      <FormHelperTextStyles>{fieldState.error?.message}</FormHelperTextStyles>
    </FormControl>
  );
};

SelectCustom.defaultProps = {};

export default SelectCustom;
