import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { fonts, palette } = variables;

const Styles = styled.div`
  background-color: ${palette.white};

  .Auctioners {
    &__container {
      width: calc(100% - 40px);
      max-width: 1142px;
      margin: 5em auto !important;

      @media ${devices.mobileL} {
        margin: 56px 0px;
      }

      @media ${devices.tablet} {
        margin: 48px 0px;
      }

      @media ${devices.laptop} {
        margin-left: 128px;
        margin-right: 128px;
      }

      @media ${devices.desktop} {
        padding-left: 640px;
        padding-right: 640px;
      }

      &--tabs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 3em;
        gap: 8px;
        flex-direction: row;
        align-items: start;

        @media ${devices.mobileL} {
          margin-top: 32px;
        }

        @media ${devices.tablet} {
          justify-content: space-between;
          margin-top: 48px;
        }
      }

      &--fields {
        margin-top: 3em;

        @media ${devices.mobileL} {
          margin-top: 32px;
        }

        @media ${devices.tablet} {
          margin-top: 48px;
        }
      }
    }

    &__title {
      font-size: 1.5rem;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      font-weight: bold;
      letter-spacing: 0.1rem;
      display: flex;
      align-items: start;
      font-variant: all-small-caps;

      @media ${devices.tablet} {
        font-size: 1.875rem;
      }

      @media ${devices.laptop} {
        font-size: 2.5rem;
      }

      @media ${devices.desktop} {
        font-size: 32px;
      }
    }

    &__tabs {
      font-size: 16px;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      font-weight: 600;
      letter-spacing: 0.4px;
      text-decoration: none;
      display: inline-block;
      border-bottom: 4px;
      letter-spacing: 0.8px;

      @media ${devices.mobileL} {
        font-size: 18px;
      }

      @media ${devices.laptop} {
        font-size: 20px;
      }

      @media ${devices.desktop} {
        font-size: 24px;
      }

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 1.6px;
        background: ${palette.chipGrey};
        transition: width 0.3s;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
`;

export default Styles;
