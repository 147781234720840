import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatIcon from "@mui/icons-material/Chat";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import TopicIcon from "@mui/icons-material/Topic";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Breadcrumbs, Link } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import TableFilters from "components/TableFilters/TableFilters";
import { CONSTANTS } from "config/constants";
import { variables } from "config/variables";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import useGlobals from "context/globals/globals.hooks";
import dayjs from "dayjs";
import { useAppSelector } from "hooks";
import React, { useState } from "react";
import { recalculateWinner } from "services/Bids/Bids.service";
import { receivedItem } from "services/Bids/Bids.service";
import { useFetchSellerWinnedLotesById } from "services/Bids/Bids.services.hooks";
import { useFetchSellerWinnedLotes } from "services/Bids/Bids.services.hooks";
import { WinnedLotes } from "services/Bids/Bids.types";
import { useCreateChat } from "services/userChat/userChat.service.hooks";
import { fail, successful } from "utils/Swal";

import { calculatePrimaForPrice } from "./SellerBids.helper";
import Styles from "./SellerBids.styles";
import { SellerBidsProps as Props } from "./SellerBids.types";

const { palette } = variables;
const { IMAGE_PLACEHOLDER } = CONSTANTS;

const SellerBids: React.FC<Props> = props => {
  const { user } = useAppSelector(state => state.authReducer);
  const { rol, id } = user ?? {};

  const { setCurrentAuction, currentAuction } = useGlobals();
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("0");
  const [date, setDate] = useState("");
  const [auctionType, setAuctionType] = useState(dayjs().format("1"));
  const [country, setCountry] = useState("1");
  const { mutateAsync, reset } = useCreateChat();

  const fetchWinnedAuctions = useFetchSellerWinnedLotes(
    auctionType,
    date,
    query
  );
  const { data: auctionList = [] } = fetchWinnedAuctions;
  const { refetch, isLoading } = fetchWinnedAuctions;
  const fetchWinnedItems = useFetchSellerWinnedLotesById();
  const {
    data: itemList,
    isLoading: isSubLoading,
    refetch: refetchId
  } = fetchWinnedItems;

  const handleNavigate = (itemId: number, auctionId?: number) => {
    if (!auctionId || !itemId) {
      fail(
        "No has seleccionado un lote o subasta para navegar hacia sus detalles."
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/item/${auctionId}/${itemId}`);
  };

  const handleNavigateAuction = (auctionId?: number) => {
    if (auctionId) {
      fail(
        "No has seleccionado un lote o subasta para navegar hacia sus detalles."
      );
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/auction/${auctionId}`);
  };

  const rejectItem = async (itemId: number, userId: number) => {
    if (rol === 5) {
      try {
        await recalculateWinner(itemId);
        refetch();
        refetchId();
        successful("Cambio de estado exitoso");
      } catch (error) {
        fail("Cambio de estado fallido");
      }
    }
  };

  const checkReceivedItem = async (itemId: number, userId: number) => {
    if (rol === 5) {
      try {
        await receivedItem(itemId);
        refetch();
        refetchId();
        successful("Cambio de estado exitoso");
      } catch (error) {
        fail("Cambio de estado fallido");
      }
    }
  };

  const handleCreateChat = async (uid: number) => {
    try {
      mutateAsync(uid);
      reset();
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.open(`/panel/chat`);
      //TODO: set current chat id on redux
    } catch (error) {
      fail("Error al crear el chat con el usuario");
    }
  };

  const renderActions = (lote: WinnedLotes) => {
    const { item_id, user_id, status } = lote ?? {};
    const { id: auctionId } = currentAuction ?? {};

    return (
      <div>
        {user_id !== id ? (
          <Tooltip title="Chat entre comprador y vendedor">
            <ChatIcon
              fontSize="medium"
              sx={{
                marginRight: 1,
                color: palette.chipSuccess,
                cursor: "pointer"
              }}
              onClick={() => handleCreateChat(user_id)}
            />
          </Tooltip>
        ) : null}
        <Tooltip title="Información del lote">
          <InfoIcon
            fontSize="medium"
            sx={{
              marginRight: 1,
              color: palette.primary,
              cursor: "pointer"
            }}
            onClick={() => handleNavigate(item_id, auctionId)}
          />
        </Tooltip>
        {rol === 5 && (
          <Tooltip title="Recalcular nuevo ganador del lote">
            <DoDisturbIcon
              fontSize="medium"
              sx={{
                color: palette.chipOrange,
                marginRight: 1,
                cursor: "pointer"
              }}
              onClick={() => rejectItem(item_id, user_id)}
            />
          </Tooltip>
        )}
        {rol === 5 && status !== 1 && (
          <Tooltip title="Marcar lote como entregado">
            <CheckCircleOutlineIcon
              fontSize="medium"
              sx={{
                color: palette.buttonColor,
                cursor: "pointer"
              }}
              onClick={() => checkReceivedItem(item_id, user_id)}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const renderSingleHeader = () => {
    const { id: auctionId, totalSales } = currentAuction ?? {};
    const { totalCommission, commission } = currentAuction ?? {};
    const { commission: commissionValue } = commission ?? {};
    const { url_logo_1, title } = currentAuction ?? {};
    const { auction_house, country } = currentAuction ?? {};
    const { commission_status, exec_time } = currentAuction ?? {};

    const { name: countryName } = country ?? {};
    const { logo } = auction_house ?? {};
    const photo = url_logo_1 ?? logo ?? IMAGE_PLACEHOLDER;

    return (
      <StyledTableRow key={`${auctionId}-007`}>
        <StyledTableCell align="left">
          <div className="SellerBids__cell">
            <Avatar alt="Lote Image" src={photo} variant="rounded" />
            <p
              className="SellerBids__link"
              onClick={() => handleNavigateAuction(auctionId)}
            >
              {(title ?? "").slice(0, 24)}
            </p>
          </div>
        </StyledTableCell>
        <StyledTableCell align="left">
          {(countryName ?? "").slice(0, 24)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {`$ ${totalSales?.toFixed(2)}`}
        </StyledTableCell>

        <StyledTableCell align="left">
          {`$ ${totalCommission?.toFixed(2)} - (${
            (commissionValue ?? 0) * 100
          }%)`}
        </StyledTableCell>
        <StyledTableCell align="left">
          {dayjs(exec_time).format("DD/MM/YYYY HH:mm a")}
        </StyledTableCell>
        <StyledTableCell align="left" size="small">
          <Chip
            label={commission_status}
            variant={commission_status !== "Pendiente" ? "filled" : "outlined"}
            color={commission_status === "Pendiente" ? "success" : "info"}
          />
        </StyledTableCell>
        <StyledTableCell align="center" size="small">
          <Button
            onClick={() => setCurrentAuction(undefined)}
            startIcon={<VisibilityOffIcon />}
          >
            Quitar DDS
          </Button>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const renderBreadCrumb = () => {
    return (
      <div
        className="SellerBids__breadCrumb"
        role="presentation"
        onClick={() => {}}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/admin"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            <TopicIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Resultados
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
          >
            {auctionType === "1"
              ? "Subastas en vivo"
              : "Subastas cronometradas"}
          </Link>
          {currentAuction && (
            <Link
              underline="always"
              sx={{ display: "flex", alignItems: "center" }}
              color="inherit"
              onClick={() => setCurrentAuction(undefined)}
            >
              <ArrowBackIcon />
              {currentAuction.auction_house?.name_business}
            </Link>
          )}
        </Breadcrumbs>
      </div>
    );
  };

  const renderSubTable = () => {
    if (typeof currentAuction === "undefined") return null;
    return (
      <TableContainer component={Paper} className="mt-2">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small">Imagen</StyledTableCell>
              <StyledTableCell size="small" align="left">
                Número de lote
              </StyledTableCell>
              <StyledTableCell align="left">Nombre del lote</StyledTableCell>
              <StyledTableCell align="left">Precio pagado</StyledTableCell>
              <StyledTableCell align="left">
                Comisión del comprador
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList?.map((winner, key) => {
              const { item, price = 0 } = winner ?? {};
              const { images, auction, name, lot } = item ?? {};
              const { prima } = auction ?? {};

              const calculatedPrima = calculatePrimaForPrice(prima, price);

              const [{ photo }] = images ?? [{}];

              return (
                <StyledTableRow key={`${winner.item_id}-${key}`}>
                  <StyledTableCell component="th" scope="row">
                    <Avatar alt="Lote Image" src={photo} variant="rounded" />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${item?.auction_id}A-${lot}I`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {name?.slice(0, 24)}
                  </StyledTableCell>
                  <StyledTableCell align="left">{`$${price}`}</StyledTableCell>
                  <StyledTableCell align="left">
                    {`$${((winner?.price ?? 0) * calculatedPrima).toFixed(
                      2
                    )} - (${calculatedPrima * 100}%)`}
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    {renderActions(winner)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">Título de subasta</StyledTableCell>
              <StyledTableCell align="left">País</StyledTableCell>
              <StyledTableCell align="left">Ganancias totales</StyledTableCell>
              <StyledTableCell align="left">Comisiones totales</StyledTableCell>
              <StyledTableCell align="left">Fecha de venta</StyledTableCell>
              <StyledTableCell align="center" width={16}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {typeof currentAuction !== "undefined"
              ? renderSingleHeader()
              : null}
            {typeof currentAuction === "undefined" &&
              auctionList?.map((auction, key) => {
                const { id: auctionId, totalSales } = auction ?? {};
                const { totalCommission, commission } = auction ?? {};
                const { commission: commissionValue } = commission ?? {};
                const { url_logo_1, title } = auction ?? {};
                const { auction_house, commission_status } = auction ?? {};
                const { country, exec_time } = auction ?? {};

                const { name: countryName } = country ?? {};
                const { logo } = auction_house ?? {};
                const photo = url_logo_1 ?? logo ?? IMAGE_PLACEHOLDER;

                return (
                  <StyledTableRow key={`${auctionId}-${key}`}>
                    <StyledTableCell align="left">
                      <div className="SellerBids__cell">
                        <Avatar
                          alt="Lote Image"
                          src={photo}
                          variant="rounded"
                        />
                        <p
                          className="SellerBids__link"
                          onClick={() => handleNavigateAuction(auctionId)}
                        >
                          {(title ?? "").slice(0, 24)}
                        </p>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {(countryName ?? "").slice(0, 24)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {`$ ${totalSales.toFixed(2)}`}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {`$ ${totalCommission.toFixed(2)} - (${
                        commissionValue * 100
                      }%)`}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {dayjs(exec_time).format("DD/MM/YYYY HH:mm a")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Chip
                        label={commission_status}
                        variant={
                          commission_status !== "Pendiente"
                            ? "filled"
                            : "outlined"
                        }
                        color={
                          commission_status === "Pendiente" ? "success" : "info"
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="Ver detalles de la subasta">
                        <Button
                          onClick={() => setCurrentAuction(auction)}
                          variant="contained"
                          size="small"
                          className="Results__ddsButton"
                        >
                          DDS
                        </Button>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderChildren = () => {
    return (
      <>
        <TableFilters
          setStatus={setStatus}
          status={status}
          setCountry={setCountry}
          country={country}
          setQuery={setQuery}
          query={query}
          date={date}
          setDate={setDate}
          auctionType={auctionType}
          setAuctionType={setAuctionType}
          sellerUser
        />
        {renderTable()}
        {renderSubTable()}
      </>
    );
  };

  return (
    <Styles className="SellerBids">
      {(isLoading || isSubLoading) && <CircularProgress />}
      {renderBreadCrumb()}
      {renderChildren()}
    </Styles>
  );
};

SellerBids.defaultProps = {};

export default SellerBids;
