import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts, shadows } = variables;

const Styles = styled.div`
  .AddItem {
    &__batchContainer {
      width: 100%;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 16px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__batchSection {
      background-color: ${palette.grCustom1.c2};
      border-radius: 8px;
      width: 40%;
      height: 244px;
      max-height: 244px;
      padding: 12px;
      margin: 24px 0;
      border-top: 1px solid ${palette.primary};
      border-bottom: 1px solid ${palette.dividerColor};
      box-shadow: ${shadows.shadowCard};
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 1366px) {
        width: 48%;
      }

      @media screen and (max-width: 768px) {
        width: 96%;
        height: 240px;
        align-self: center;
        margin: 0;
      }
    }

    &__warningSection {
      background-color: ${palette.grCustom1.c2};
      border-radius: 8px;
      width: 100%;
      min-height: 240px;
      max-height: 540px;
      overflow-y: scroll;
      padding: 12px;
      margin-bottom: 24px;
      border-top: 1px solid ${palette.primary};
      border-bottom: 1px solid ${palette.dividerColor};
      box-shadow: ${shadows.shadowCard};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media screen and (max-width: 1366px) {
        width: 48%;
      }

      @media screen and (max-width: 768px) {
        width: 96%;
        align-self: center;
        margin: 0;
      }
    }

    &__buttonSection {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
    }

    &__items-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__itemsSection {
      border-top: 1px solid ${palette.dividerColor};
      padding-top: 12px;
    }

    &__paragraph {
      font-size: 12px;
      text-align: start;
      font-family: ${fonts["Poppins-Rg"]};
      color: ${palette.blackCharcoal};
      opacity: 0.6;

      @media ${devices.mobileL} {
        font-size: 14px;
      }

      @media ${devices.tablet} {
        font-size: 16px;
      }
    }

    &__subTitle {
      font-size: 14px;
      text-align: start;
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.blackCharcoal};
      letter-spacing: 0.8px;
      text-decoration: underline;
      text-underline-offset: 4px;
      opacity: 0.8;

      @media ${devices.mobileL} {
        font-size: 16px;
      }

      @media ${devices.tablet} {
        font-size: 18px;
      }
    }

    &__title {
      font-size: 16px;
      text-align: start;
      margin-bottom: 32px;
      font-family: ${fonts["Poppins-El"]};
      font-weight: 480;
      letter-spacing: 0.8px;

      @media ${devices.mobileL} {
        font-size: 20px;
      }

      @media ${devices.tablet} {
        font-size: 24px;
      }
    }

    &__reset {
      display: none;
    }

    &__form {
      &--item {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 24px;
        width: 100%;

        @media ${devices.laptop} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      &--buttons {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        width: 100%;
        gap: 24px;

        margin-top: 2rem /* 32px */;
        margin-bottom: 8rem /* 128px */;

        @media ${devices.laptop} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: unset;
        }
      }
    }

    &__button {
      &:hover {
        transform: scale(1.05);
        background-color: ${palette.primaryLight};
        color: ${palette.white};
      }
    }

    &__container {
      &--button {
        display: flex;
        padding: 24px 0;
        justify-content: center;
        gap: 24px;
        flex-direction: column;

        @media ${devices.laptop} {
          flex-direction: row;
        }
      }
    }
  }
`;

export default Styles;
