import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetCurrencyTypes } from "services/Auth/Auth.services.hooks";

import { updateUserCurrency } from "../../../redux/actions/authActions";
import { successful } from "../../../utils/Swal";
import Styles from "./MyCurrency.styles";

const MyCurrency = () => {
  const dispatch = useDispatch();
  //@ts-ignore
  const { currency } = useSelector(state => state.authReducer);

  const { data: currencies = [] } = useGetCurrencyTypes();

  const handleChange = () => {
    dispatch(updateUserCurrency(currency));
    successful("Actualización de moneda exitosa");
  };

  const renderCurrencies = () => {
    return (
      <div className="MyCurrency__currency-container">
        <h2 className="MyCurrency__currency__title">Tipos de moneda</h2>
        <div className="MyCurrency__currency">
          {currencies?.map((item: any, idx: number) => {
            return (
              <div
                className={`MyCurrency__currency__item ${
                  currency?.id === item?.id
                    ? "MyCurrency__currency__item--active2"
                    : ""
                } ${
                  currency?.id === item?.id
                    ? "MyCurrency__currency__item--active"
                    : ""
                }`}
                key={idx}
              >
                <div className="MyCurrency__currency__text-container">
                  <div className="MyCurrency__currency__item-title text-primary">
                    <h3 className="text-primary">{item?.name}</h3>
                  </div>
                  <div className="MyCurrency__currency__item-address">
                    <p>Tipo de cambio: {item?.price}</p>
                  </div>
                </div>
                <button
                  onClick={() =>
                    dispatch(
                      updateUserCurrency({
                        id: item?.id,
                        name: item?.name,
                        price: item?.price
                      })
                    )
                  }
                  className={`MyCurrency__currency__button ${
                    currency?.id === item?.id
                      ? "MyCurrency__currency__button--desactive"
                      : ""
                  }`}
                  disabled={currency?.id === item?.id}
                >
                  {currency?.id === item?.id ? "Actual" : "Seleccionar"}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Styles className="MyCurrency">
      {renderCurrencies()}
      <div className="MyCurrency__container--button">
        <ButtonLarge
          onClick={() => handleChange()}
          className="hover:bg-hprimary"
          variant="contained"
        >
          Actualizar Moneda
        </ButtonLarge>
      </div>
    </Styles>
  );
};
export default MyCurrency;
