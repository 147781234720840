// Validation utility function and data
import { Rule } from "antd/lib/form";

export const validSpecialCharacters = "*.!@$%^&(){}[]:;<>,.?/~_+-=|\\";
export const fieldMinLength = 2;
export const fieldMaxLength = 50;
export const passwordMinLength = 8;

const t = {
  fieldRequired: "Este campo es requerido",
  includesLowerCase: "Se necesita al menos un carácter en minúscula",
  includesUpperCase: "Se necesita al menos un carácter en mayúscula",
  includesNumber: "Se necesita al menos un carácter numérico",
  includesSpecialCharacter: (validSpecialCharacters: string) =>
    `Incluye por lo menos un carácter especial ${validSpecialCharacters}`,
  noSpecialCharacterAllowed: (validSpecialCharacters: string) =>
    `Los caracteres especiales ${validSpecialCharacters} no están permitidos`,
  minPasswordLength: (min: number) => `Ingresa al menos ${min} caracteres`,
  stringMinLength: (min: number) => `Ingresa al menos ${min} caracteres`,
  stringMaxLength: (max: number) => `Ingresa como máximo ${max} caracteres`,
  whiteSpaceOnly: "El campo no puede solo incluir caracteres en blanco",
  noWhiteSpaceAllowed: "El campo no puede incluir caracteres en blanco",
  lettersAndWhitespace:
    "El campo solo debería incluir caracteres alfabéticos y espacios",
  lettersNumbersAndWhitespace:
    "El campo solo debería incluir caracteres alfabéticos, numéricos y espacios",
  validEmail: "Ingresa un correo electrónico valido",
  pattern: (pattern: string) => `El campo no concuerda ${pattern}`
};

export const customPattern = (pattern: string): Rule => {
  return {
    pattern: new RegExp(pattern),
    message: t.pattern(pattern)
  };
};

export const requiredField = (): Rule => {
  return {
    required: true,
    message: t.fieldRequired
  };
};

export const includesALowerCase = (): Rule => {
  return {
    pattern: new RegExp("^(?=.*[a-z])"),
    message: t.includesLowerCase
  };
};

export const includesAUpperCase = (): Rule => {
  return {
    pattern: new RegExp("^(?=.*[A-Z])"),
    message: t.includesUpperCase
  };
};

export const includesANumber = (): Rule => {
  return {
    pattern: new RegExp("^(?=.*[0-9])"),
    message: t.includesNumber
  };
};

export const includesASpecialCharacter = (): Rule => {
  return {
    pattern: new RegExp("^(?=.*[!@#$%^&*()_+\\-=[\\]{};':\"\\\\|,.<>\\/?])"),
    message: t.includesSpecialCharacter(validSpecialCharacters)
  };
};

export const notSpecialCharacterAllowed = (): Rule => {
  return {
    pattern: new RegExp("^(?!.*[!@#$%^&*()_+\\-=[\\]{};':\"\\\\|,.<>\\/?])"),
    message: t.noSpecialCharacterAllowed(validSpecialCharacters)
  };
};

export const notSpecialCharacterAllowedExceptHyphen = (): Rule => {
  return {
    pattern: new RegExp("^(?!.*[!@#$%^&*()_+\\=[\\]{};':\"\\\\|,.<>\\/?])"),
    message: t.noSpecialCharacterAllowed(validSpecialCharacters)
  };
};

export const minPasswordLength = (): Rule => {
  const min = passwordMinLength;
  return {
    min,
    message: t.minPasswordLength(min)
  };
};

export const stringMinLength = (minLength?: number): Rule => {
  const min = minLength ?? fieldMinLength;
  return {
    min,
    message: t.stringMinLength(min)
  };
};

export const stringMaxLength = (maxLength?: number): Rule => {
  const max = maxLength ?? fieldMaxLength;
  return {
    max,
    message: t.stringMaxLength(max)
  };
};

export const noWhitespaceOnly = (): Rule => {
  return {
    whitespace: true,
    message: t.whiteSpaceOnly
  };
};

export const noWhitespaceAllowed = (): Rule => {
  return {
    pattern: /^\S*$/,
    message: t.noWhiteSpaceAllowed
  };
};

export const onlyLettersAndWhiteSpace = (): Rule => {
  return {
    pattern: /^[a-zA-ZÑñáéíóúÁÉÍÓÚ]+([\s][a-zA-ZÑñáéíóúÁÉÍÓÚ]+)*$/,
    message: t.lettersAndWhitespace
  };
};

export const onlyLettersNumbersAndWhiteSpace = (): Rule => {
  return {
    pattern: /^[a-zA-ZÑñáéíóúÁÉÍÓÚ0-9]+([\s][a-zA-ZÑñáéíóúÁÉÍÓÚ0-9]+)*$/,
    message: t.lettersNumbersAndWhitespace
  };
};

export const validEmail = (): Rule => {
  return {
    type: "email",
    message: t.validEmail
  };
};

export const passwordValidations: Rule[] = [
  requiredField(),
  includesALowerCase(),
  includesAUpperCase(),
  includesANumber(),
  includesASpecialCharacter(),
  minPasswordLength(),
  stringMaxLength()
];

export const nameValidations = (required = true): Rule[] => {
  const rules = [
    onlyLettersAndWhiteSpace(),
    stringMinLength(),
    stringMaxLength()
  ];
  if (required) rules.push(requiredField());
  return rules;
};

export const stringValidations = (required = true): Rule[] => {
  const rules = [
    stringMinLength(),
    stringMaxLength(),
    noWhitespaceAllowed(),
    notSpecialCharacterAllowed()
  ];
  if (required) rules.push(requiredField());
  return rules;
};

export const numberValidations = (required = true): Rule[] => {
  const rules = [includesANumber()];
  if (required) rules.push(requiredField());
  return rules;
};

export const objectNameValidations = (required = true): Rule[] => {
  const rules = [
    onlyLettersNumbersAndWhiteSpace(),
    stringMinLength(),
    stringMaxLength()
  ];
  if (required) rules.push(requiredField());
  return rules;
};

export const currencyValidations = (required = true): Rule[] => {
  const rules = [];
  if (required) rules.push(requiredField());
  return rules;
};

export const priceListValidations = (required = true): Rule[] => {
  const rules = [];
  if (required) rules.push(requiredField());
  return rules;
};

export const isUrl = (text: string) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(text);
};
