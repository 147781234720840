import { Box } from "@mui/material";
import { DashboardNavbar } from "components/Dashboard/DashboardNavbar/DashboardNavbar";
import { DashboardSidebar } from "components/Dashboard/DashboardSidebar/DashboardSidebar";
import Footer from "components/Footer/Footer";
import React, { useState } from "react";

import { DashboardLayoutRoot, DashboardStyles } from "./Layout.styles";

const Layout = (props: any) => {
  const { children, hideFooter } = props;

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <DashboardLayoutRoot>
        <Box sx={DashboardStyles}>
          {children}
          {!hideFooter ? <Footer /> : null}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        open={isSidebarOpen}
      />
    </>
  );
};
export default Layout;
