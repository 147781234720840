import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import NotificationBell from "components/NotificationBell/NotificationBell";
import { RiAuctionFill, RiHandCoinFill, RiHeart3Fill } from "react-icons/ri";
const settings = ["Mi cuenta", "Salir"];

const MENU = [
  {
    icon: <RiAuctionFill />,
    title: "Subastas",
    to: "/panel/subastas"
  },
  {
    icon: <NotificationBell />,
    title: "Notificaciones"
    // to: "/panel/notificaciones"
  },
  {
    icon: <RiHandCoinFill />,
    title: "Mis ofertas",
    to: "/panel/mis-pujas"
  },
  {
    icon: <RiHeart3Fill />,
    title: "Favoritos",
    to: "/panel/favoritos"
  },
  {
    icon: <AutoAwesomeIcon size="small" />,
    title: "Deseos",
    to: "/panel/deseos"
  }
];

const sections = [
  { title: "Arte", url: "#" },
  { title: "Joyeria y Relojes", url: "#" },
  { title: "Fotografia", url: "#" },
  { title: "Arte Oriental", url: "#" },
  { title: "Antigueades", url: "#" },
  { title: "Numismatica", url: "#" },
  { title: "Memoravilla", url: "#" },
  { title: "NFT", url: "#" },
  { title: "Mueble", url: "#" },
  { title: "Otras Categorias", url: "#" }
];

export { settings, MENU, sections };
