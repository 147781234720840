import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { TextFieldFileStyledProps as Props } from "./TextFieldFile.types";

const { palette, fonts } = variables;

const TextFieldFileStyled = styled.div<Props>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;

  .TextFieldFile {
    &__container {
      &--wrapper {
        position: relative;
        overflow: hidden;
        width: 240px;
        cursor: pointer;
        margin: auto;

        @media ${devices.mobileL} {
          margin: unset;
        }

        &-input {
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          min-height: 100%;
          cursor: inherit;
          display: block;
          z-index: 1000;

          &::-webkit-file-upload-button {
            display: none;
          }
        }
      }

      &--image {
        display: flex;
        justify-content: center;
        margin: auto;
        margin-top: 16px;
        width: 240px;
        max-height: 240px;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 1px 4px 0 hsla(0, 0%, 0%, 0.1),
          0 1px 2px 0 hsla(0, 0%, 0%, 0.06);
        object-fit: cover;
        object-position: center right;
        // padding: 8px;
        margin: 16px auto;
        border-radius: 50%;
        pointer-events: none;
      }

      &--imageAuction {
        border-radius: inherit;
      }
    }

    &__component {
      &--button {
        width: 85%;
        padding: 4px 10px;
        border-radius: 16px;
        cursor: pointer;
        font-size: 16px;
        font-family: ${fonts["Poppins-El"]};
        letter-spacing: 0.4px;
        font-weight: 540;
        line-height: 1.4375em;
        margin-top: 4px;
      }

      &--image {
        object-fit: contain;
        width: 360px;
        height: 240px;
        transition: all 0.5s;

        &:hover {
          transform: scale(1.02);
        }
      }
    }

    &__text {
      &--label {
        font-size: 16px !important;
        font-family: ${fonts["Poppins-Md"]};
        color: ${palette.primaryLight};
        font-weight: 400;
        letter-spacing: 0.4px;
      }
    }
  }
`;

export default TextFieldFileStyled;
