import React from "react";

import { Styles } from "./MenuItem.styles";
import { MenuItemProps } from "./MenuItem.types";

const MenuItem = (props: MenuItemProps) => {
  const { children } = props;

  return <Styles {...props}> {children}</Styles>;
};

export default MenuItem;
