import React, { useState } from "react";
import { Button } from "antd";

import BrandItem from "./BrandItem";
import BrandModal from "./BrandModal";
import {
  useBrands,
  useCategories,
  useCreateBrand
} from "services/Categories/categories.service.hooks";

const Brands: React.FC = () => {
  const [isAddBrandModalOpen, setIsAddBrandModalOpen] = useState(false);

  const { data: categories } = useCategories();
  const { data: brands, isLoading } = useBrands();
  const { mutate: createBrand } = useCreateBrand();

  const options = categories
    ?.filter(c => c.subcategories.length)
    .map(c => ({
      value: c.id,
      label: c.name,
      children: c.subcategories.map(s => ({
        value: s.id,
        label: s.name
      }))
    }));

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="my-5 text-xl">Marcas</h1>
        <Button onClick={() => setIsAddBrandModalOpen(true)}>
          Crear marca
        </Button>
      </div>
      <div className="flex flex-col gap-1 w-full">
        {brands?.length ? (
          brands.map(b => <BrandItem brand={b} options={options} />)
        ) : isLoading ? (
          <p>Cargando...</p>
        ) : (
          <p>No hay marcas</p>
        )}
      </div>
      <BrandModal
        title="Crear marca"
        key={isAddBrandModalOpen ? "open" : "close"}
        isOpen={isAddBrandModalOpen}
        onConfirm={({ name, subcategoryId }) => {
          setIsAddBrandModalOpen(false);
          createBrand({
            name,
            subcategoryId
          });
        }}
        onCancel={() => setIsAddBrandModalOpen(false)}
        options={options}
      />
    </>
  );
};

export default Brands;
