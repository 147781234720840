import styled from "styled-components";

import { variables } from "../../../config/variables";

const { palette, fonts } = variables;

const Styles = styled.div`
  display: flex;
  align-items: flex-start;
  width: 88%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }

  .AuctionInfo {
    &__link {
      color: ${palette.primaryLight};
      text-decoration: underline;
      display: block;
    }

    &__content {
      display: flex;
      flex: 1;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
      }
    }

    &__buttonsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto 0 auto 16px;
      flex: 1;
      height: 100%;
      gap: 16px;
    }

    &__finalizeContainer {
      position: fixed;
      right: 40px;
      bottom: 40px;
      z-index: 99;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 64%;

      @media (max-width: 768px) {
        width: 88%;
        margin: 0 auto;
      }
    }

    &__subtitle {
      font-size: 18px;
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.gray};
    }

    &__title {
      font-size: 28px;
      font-family: ${fonts["Poppins-Md"]};
      margin: 16px 0;
    }

    &__user-data {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__image {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin: 16px 16px 16px 0;
      object-fit: contain;

      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }

    &__description {
      font-size: 16px;
      font-family: ${fonts["Poppins-Rg"]};
    }

    &__actions-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto 0 auto 16px;
      flex: 1;
      height: 100%;
      border-left: 1px solid;
      gap: 16px;
      border-color: ${palette.dividerColor};

      @media (max-width: 768px) {
        border-left: 0;
        width: 100%;
        margin: 0 auto;
      }
    }

    &__buttons {
      background-color: ${palette.primary};
      color: ${palette.white};
      padding: 16px;
      width: 60%;
      margin: 16px 0;
      border-radius: 8px;
      font-size: 16px;
      font-family: ${fonts["Poppins-Lt"]};

      &--primary {
        background-color: ${palette.primary};
        color: ${palette.white};
      }

      &--secondary {
        background-color: ${palette.white};
        color: ${palette.primary};
        border: 1px solid ${palette.primary};
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;

export default Styles;
