import { useQuery, useQueryClient } from "react-query";
import { useMutation } from "react-query";

import { deleteCurrency, fetchCurrencies } from "./currency.service";
import { postNewCurrency, putCurrency } from "./currency.service";
import { CurrencyPayload } from "./currency.service.types";

export const useFetchCurrencies = () => {
  return useQuery(["currency"], () => fetchCurrencies(), {
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener las monedas registradas.");
    }
  });
};

export const useCreateCurrency = () => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, CurrencyPayload>(postNewCurrency, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("currency");
    },
    onError: () => {
      fail("Hubo un error al crear la moneda");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("currency");
    }
  });
};

export const useUpdateCurrency = () => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, CurrencyPayload>(putCurrency, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("currency");
    },
    onError: () => {
      fail("Hubo un error al crear la moneda");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("currency");
    }
  });
};

export const useDeleteCurrency = () => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, number | undefined>(deleteCurrency, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("currency");
    },
    onError: () => {
      fail("Hubo un error al crear la moneda");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("currency");
    }
  });
};
