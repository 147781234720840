import { variables } from "config/variables";
import styled from "styled-components";

import { devices } from "../../../config/devices";

const { palette, fonts, shadows } = variables;

const Styles = styled.div`
  width: 100%;
  padding: 4px;
  box-shadow: ${shadows.shadowCard};
  border-radius: 8px;
  margin: 8px;
  position: relative;
  overflow: hidden;
  background-color: ${palette.white};

  @media ${devices.mobileL} {
    width: 100%;
  }

  @media ${devices.laptop} {
    width: 48%;
  }

  .AuctionCard {
    &__link {
      color: ${palette.primaryLight};
      text-decoration: underline;
      display: block;
      margin-top: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    &__reuseModal {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__container {
      display: flex;
      gap: 4px;
    }

    &__image-container--main {
      width: 60%;
      height: 240px;
      flex: 1;
      overflow: hidden;
      position: relative;
      transition: all 0.5s;
    }

    &__image-container--secondary {
      display: flex;
      flex-direction: column;
      flex: 0.6;
      width: 40%;
      height: 240px;
      overflow: hidden;
      position: relative;
      gap: 4px;
    }

    &__image {
      width: 100%;
      object-fit: contain;
      border: 1px solid ${palette.white};
      transition: 0.4s;

      &--main {
        height: 100%;
      }

      &--secondary {
        height: 50%;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        border-radius: 8px;
      }
    }

    &__info-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;

      @media ${devices.tablet} {
        justify-content: space-around;
      }

      @media ${devices.laptop} {
        justify-content: space-between;
      }
    }

    &__logo {
      display: none;
      border-radius: 8px;
      overflow: hidden;
      width: 50px;
      height: 80px;
      margin-left: 5px;
      object-fit: fill;
      transition: all 0.4s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        border-radius: 8px;
      }

      @media ${devices.mobileL} {
        display: block;
      }
    }

    &__info__text {
      display: flex;
      flex-direction: column;
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: auto 8px;
    }

    &__title {
      font-size: 14px;
      font-family: ${fonts["Poppins-Rg"]};
      color: ${palette.black};
      margin-bottom: 8px;
      font-weight: 200;
      text-align: center;
      white-space: nowrap;

      @media ${devices.mobileL} {
        font-size: 18px;
      }
    }

    &__starts {
      font-size: 8px;
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.colorDegraded};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
      text-align: center;

      @media ${devices.mobileL} {
        font-size: 12px;
      }
    }

    &__button {
      font-size: 14px;
      padding: 4px 10px;
      box-shadow: none;

      @media ${devices.mobileL} {
        font-size: 14px;
      }
    }

    &__float {
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 99;

      &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background: ${palette.floatTextColor};
        padding: 4px;
        border-radius: 8px;

        &--icon {
          display: flex;
        }
      }

      &__content > * {
        /* color: ${palette.colorDegradedTree}; */
        color: ${palette.white};
        font-family: ${fonts["Poppins-El"]};
      }

      &__text {
        font-size: 8px;

        @media ${devices.mobileL} {
          font-size: 12px;
        }

        &__variant {
          font-size: 12px;
          font-family: ${fonts["Poppins-Lt"]};
          margin: 0;

          @media ${devices.mobileL} {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export const modalStyles = {
  width: 400
};

export default Styles;
