import { validationRulesTwo } from "components/Auctioners/createAuction/CreateAuction.helper";
import { validationRules } from "components/Auctioners/createAuction/CreateAuction.helper";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import SelectCustom from "components/Globals/Select/Select";
import SelectMenuItem from "components/Globals/SelectMenuItem/SelectMenuItem";
import TextField from "components/Globals/TextField/TextField";
import TextFieldFile from "components/Globals/TextFieldFile/TextFieldFile";
import React from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useCategories } from "services/Categories/categories.service.hooks";
import { usePostNewWish } from "services/wishes/wishes.service.hooks";
import { fail, successful } from "utils/Swal";

import Styles from "./Wishes.styles";
import { WishesProps as Props } from "./Wishes.types";

const Wishes: React.FC<Props> = props => {
  const { control, watch, handleSubmit, reset: resetForm } = useForm();
  const { data: categories } = useCategories();
  const { mutateAsync, reset, isLoading } = usePostNewWish();

  const watchCategoriesName = watch("category");
  const subCategories =
    categories?.find(c => c.name === watchCategoriesName)?.subcategories ?? [];

  const handleForm = async (values: FieldValues) => {
    try {
      const formData = new FormData();
      formData.append(
        "description",
        `Titulo: ${values.title}.\nDescripción: ${values.description}.\nCategoría y sub categoría: ${values.category} - ${values.sub_category}`
      );
      const files: File[] = [];
      if (values.file_1[0]) files.push(values.file_1[0]);
      if (values.file_2[0]) files.push(values.file_2[0]);
      if (values.file_3[0]) files.push(values.file_3[0]);
      for (let x = 0; x < files.length; x++) {
        formData.append("files", files[x]);
      }
      await mutateAsync(formData);
      reset();
      resetForm();
      successful("El deseo fue registrado exitosamente.");
    } catch (error) {
      fail(
        "Ocurrió un error al registrar su deseo, vuélvalo a intentar en unos minutos."
      );
    }
  };

  return (
    <Styles className="Wishes">
      <div className="Wishes__container-title">
        <h4 className="Wishes__title">Deseos</h4>
      </div>
      <p className="Wishes__description">
        En esta sección puedes realizar tus pedidos sobre un lote deseado,
        bríndanos la descripción del objeto que deseas comprar, nosotros lo
        buscaremos por ti y te notificaremos cuando esté próximo a subastarse.
      </p>
      <form onSubmit={handleSubmit(handleForm)} className="Wishes__form">
        <Controller
          name="title"
          control={control}
          rules={validationRules.title}
          defaultValue={""}
          render={({ field, fieldState }) => (
            <TextField
              field={field}
              fieldState={fieldState}
              config={{
                type: "text",
                label: "Titulo",
                variant: "standard",
                margin: "dense",
                fullWidth: true,
                focused: true
              }}
              style={{ width: "100%" }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          defaultValue={""}
          render={({ field, fieldState }) => (
            <TextField
              field={field}
              fieldState={fieldState}
              config={{
                type: "text",
                label: "Descripción",
                variant: "standard",
                margin: "dense",
                multiline: true,
                rows: 2,
                fullWidth: true,
                focused: true
              }}
              style={{ width: "100%" }}
            />
          )}
        />
        <div className="Wishes__fields">
          {categories && categories?.length > 0 ? (
            <Controller
              name="category"
              control={control}
              defaultValue={"Arte"}
              render={({ field, fieldState }) => (
                <SelectCustom
                  field={field}
                  fieldState={fieldState}
                  config={{
                    label: "Categoria principal"
                  }}
                  style={{ width: "100%!important" }}
                >
                  {categories?.map((category: any) => (
                    <SelectMenuItem value={category?.name} key={category?.id}>
                      {category?.name}
                    </SelectMenuItem>
                  ))}
                </SelectCustom>
              )}
            />
          ) : null}
          {subCategories?.length > 0 ? (
            <Controller
              name="sub_category"
              control={control}
              rules={validationRulesTwo.subcategory}
              render={({ field, fieldState }) => (
                <SelectCustom
                  field={field}
                  fieldState={fieldState}
                  config={{
                    label: "Sub Categoria"
                  }}
                >
                  <SelectMenuItem value={0}>
                    Selecciona una opción
                  </SelectMenuItem>
                  {subCategories?.map((category: any) => (
                    <SelectMenuItem value={category?.name} key={category?.id}>
                      {category?.name}
                    </SelectMenuItem>
                  ))}
                </SelectCustom>
              )}
            />
          ) : null}
        </div>
        <div className="Wishes__fields">
          <Controller
            name="file_1"
            control={control}
            defaultValue={{}}
            render={({ field }) => (
              <TextFieldFile
                field={field}
                config={{ label: "Imagen de referencia 1" }}
                imagenAction={false}
              />
            )}
          />

          <Controller
            name="file_2"
            control={control}
            defaultValue={{}}
            render={({ field }) => (
              <TextFieldFile
                field={field}
                config={{ label: "Imagen de referencia 2" }}
                imagenAction={false}
              />
            )}
          />

          <Controller
            name="file_3"
            control={control}
            defaultValue={{}}
            render={({ field }) => (
              <TextFieldFile
                field={field}
                config={{ label: "Imagen de referencia 3" }}
                imagenAction={false}
              />
            )}
          />
        </div>
        <ButtonLarge
          type="submit"
          variant="contained"
          className="Wishes__button"
          disabled={isLoading}
          loading={isLoading}
          size="small"
        >
          Registrar deseo
        </ButtonLarge>
      </form>
    </Styles>
  );
};

Wishes.defaultProps = {};

export default Wishes;
