import React from "react";

import DataTableCustom from "./Table.styles";
import { TableProps as Props } from "./Table.types";

const Table: React.FC<Props> = props => {
  const { columns, data, onRowClicked } = props;

  return (
    <DataTableCustom
      columns={columns}
      data={data}
      responsive
      onRowClicked={onRowClicked}
    />
  );
};

Table.defaultProps = {};

export default Table;
