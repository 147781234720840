import { Auth } from "aws-amplify";
import axios from "axios";

const SERVER_URL = process.env["REACT_APP_SERVER_URL"];
export const axiosDefault = axios.create({
  baseURL: SERVER_URL,
  headers: {
    Platform: "WEB",
    "Content-Type": "application/json",
    Authorization: `Bearer no_token`,
    responseType: "json"
  }
});

axiosDefault.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

async function getIdToken() {
  try {
    const session = await Auth.currentSession();
    const idToken = session.getAccessToken().getJwtToken();
    return idToken;
  } catch (error) {
    return "no_token";
  }
}

export const buildHeaders = async () => {
  const token = await getIdToken();

  return {
    Platform: "WEB",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    responseType: "json"
  };
};

export default axiosDefault;
