import { variables } from "config/variables";
import styled from "styled-components";

import { UserChatContainerStyledProps as Props } from "./UserChatContainer.types";
const { palette, fonts } = variables;

const UserChatContainerStyled = styled.div<Props>`
  display: flex;
  height: calc(100%-48px);
  width: 100%;

  & > p {
    color: ${palette.white};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 14px;
    text-align: justify;
  }

  .UserChatContainer {
    &__text {
      color: ${palette.blackCharcoal} !important;

      &--incoming {
        color: ${palette.white} !important;
      }

      &--outgoing {
        color: ${palette.blackCharcoal}!important;
      }
    }

    &__link {
      color: ${palette.primary};
      text-decoration: underline;
      display: block;
      margin-top: 8px;

      &:hover {
        cursor: pointer;
      }
    }

    &__message-incoming {
      color: ${palette.white};
      background-color: ${palette.primary};
    }
  }

  .cs-message {
    &--incoming {
      color: ${palette.white};
      div.cs-message__content-wrapper * {
        background-color: ${palette.blackCharcoal};
      }
      border-radius: 0.7em !important;
    }
  }

  .cs-conversation-header {
    &__user-name {
      background-color: ${palette.neutro.custom};
    }
    &__info {
      background-color: ${palette.neutro.custom};
    }
  }

  .cs-button {
    color: ${palette.primary};
  }

  .cs-message-indicator {
    z-index: 99999;
  }

  .cs-typing-indicator__text {
    color: ${palette.primary};
  }

  .cs-typing-indicator__dot {
    color: ${palette.primary};
    background-color: ${palette.primary};
  }

  .cs-message__content {
    z-index: 999;
    color: ${palette.blackCharcoal};
    background-color: #ff730036; // ${palette.white};
    // border: 1px solid ${palette.primaryLight};
    // box-shadow: 9px 10px 10px -5px rgba(0, 0, 0, 0.5);
    border-radius: 0.7em !important;
  }

  .cs-message-input {
    &__content-editor {
      background-color: ${palette.neutro.custom};

      &-wrapper {
        background-color: ${palette.neutro.custom};
      }
    }
  }
`;

export const colorHeader = {
  backgroundColor: palette.neutro.custom
};

export const colorChat = {
  color: palette.primary
};

export default UserChatContainerStyled;
