import { Prima } from "components/Auctioners/AddPrima/AddPrima.types";

export const calculatePrimaForPrice = (primaObj?: Prima[], price?: number) => {
  if (!primaObj || !price) return 0.2;
  for (let i = 0; i < primaObj.length; i++) {
    const rango = primaObj[i];
    if (
      // @ts-ignore
      price >= rango.rangoInferior &&
      // @ts-ignore
      (rango.rangoSuperior === null || price <= rango.rangoSuperior)
    ) {
      return rango.prima ?? 0.2;
    }
  }
  return primaObj?.[primaObj?.length - 1].prima ?? 0.2;
};
