import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { fonts, palette } = variables;

const Styles = styled.div`
  .Profile {
    &__container {
      width: calc(100% - 40px);
      max-width: 1200px;
      margin: 0px auto;
      margin-top: 4rem;
      font-family: ${fonts["Poppins-Rg"]};
      padding: 0 1rem;

      @media ${devices.tablet} {
        padding: 0 2rem;
      }

      @media ${devices.laptopL} {
        padding: 0 4rem;
      }

      &--tabs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1rem;
        gap: 0.5rem;

        @media ${devices.mobileL} {
          margin-top: 2rem;
        }

        @media ${devices.tablet} {
          // justify-content: space-between;
          // height: 26vh;
          margin-top: 3rem;
          display: flex;
          flex-direction: column;
          width: 22%;
          float: left;
          align-items: start;
        }
      }

      &--fields {
        margin-top: 1rem;

        @media ${devices.mobileL} {
          margin-top: 2rem;
        }

        @media ${devices.tablet} {
          margin-top: 3rem;
          float: right;
          width: 70%;
        }
      }

      &--image {
        display: flex;
        justify-content: center;
        width: 90px;
        height: 90px;
        max-height: 240px;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        align-self: center;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 1px 4px 0 hsla(0, 0%, 0%, 0.1),
          0 1px 2px 0 hsla(0, 0%, 0%, 0.06);
        object-fit: contain;
        object-position: center;
        padding: 8px;
        border-radius: 50%;
        pointer-events: none;

        img {
          margin: 16px;
        }
      }
    }

    &__component {
      &--tabs {
        font-size: 1rem;
        text-align: center;
        font-family: ${fonts["Poppins-El"]};
        font-weight: 600;
        letter-spacing: 0.1rem;
        text-decoration: none;
        display: inline-block;
        border-bottom: 0.4rem;

        @media ${devices.mobileL} {
          font-size: 1.125rem;
        }

        @media ${devices.laptop} {
          font-size: 1.22rem;
        }

        @media ${devices.desktop} {
          font-size: 1.5rem;
        }

        &::after {
          content: "";
          display: block;
          width: 0;
          height: 0.1rem;
          background: ${palette.chipGrey};
          transition: width 0.3s;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }

    &__title {
      font-size: 1.5rem;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      // color: ${palette["primary"]};
      font-weight: bold;
      letter-spacing: 0.1rem;
      display: flex;
      align-items: start;
      font-variant: all-small-caps;

      @media ${devices.tablet} {
        font-size: 1.875rem;
      }

      @media ${devices.laptop} {
        font-size: 2.5rem;
      }

      @media ${devices.desktop} {
        font-size: 32px;
      }
    }

    &__text {
      &--primary {
        color: ${palette.primary};
      }

      &--default {
        color: ${palette.gray.primary};
      }
    }

    &__background {
      background-color: ${palette.custom};
    }
  }
`;

export default Styles;
