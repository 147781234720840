import React from "react";

import Styles from "./CardImage.styles";
import { CardImageProps } from "./CardImage.types";

const CardImage = (props: CardImageProps) => {
  const { src, link } = props;

  return (
    <Styles src={src}>
      <div className="CardImage__image" />
      <a
        href={link}
        target="_blank"
        style={{
          pointerEvents: !link ? "none" : "inherit",
          cursor: !link ? "default" : "pointer",
          height: "100%",
          zIndex: 100
        }}
        rel="noreferrer"
      >
        <div className="CardImage__content" />
      </a>
    </Styles>
  );
};

export default CardImage;
