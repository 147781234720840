// Interviews services
import { SellerReview } from "services/Auctioners/Auctioners.service.types";
import { ClientInfo, User } from "services/Auth/Auth.services.types";
import { SellerInfoData } from "services/Auth/Auth.services.types";

import api, { buildHeaders } from "../Api";
import { Interview, ManageInterview } from "./interviews.service.types";

export const fetchInterviews = async (
  take = 50,
  skip = 0
): Promise<Interview[]> => {
  return await api
    .get(`/admi/interviews?take=${take}&skip=${skip}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    });
};

export const fetchAllAuctionHouses = async (): Promise<SellerInfoData[]> => {
  return await api
    .get(`/admi/sellers`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    });
};

export const fetchAllClients = async (take = 50, skip = 0): Promise<User[]> => {
  return await api
    .get(`/admi/users?take=${take}&skip=${skip}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    });
};

export const fetchClientInfo = async (id?: number): Promise<ClientInfo> => {
  return await api
    .get(`/admi/users/${id}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    });
};

export const fetchClientComments = async (
  id?: number
): Promise<SellerReview[]> => {
  return await api
    .get(`/admi/users/${id}/comments`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    });
};

export async function approveOrRejectSeller(payload: ManageInterview) {
  return await api.post(`/admi/interviews/active`, payload, {
    headers: await buildHeaders()
  });
}

export async function blockUnblockClient(uid?: number) {
  return await api.post(
    `/admi/auth/lockUser`,
    { user_id: uid },
    {
      headers: await buildHeaders()
    }
  );
}
