import useAuth from "context/auth/auth.hooks";
import { useAppSelector } from "hooks";
import { useQuery, useQueryClient } from "react-query";
import { useInfiniteQuery, useMutation } from "react-query";
import { useIsLogged } from "services/Auth/Auth.services.hooks";

import { fetchUserChats, fetchUserChatById } from "./userChat.service";
import { postSendMessage, postCreateChat } from "./userChat.service";
import { Reply, SendMessage } from "./userChat.service.types";

export const useFetchUserChats = (query: string) => {
  const { isAuthenticated } = useIsLogged();
  const { isAnonymous } = useAuth();
  const { user } = useAppSelector(state => state.authReducer);
  const { id } = user ?? {};

  return useQuery(["userChat", id, query], () => fetchUserChats(query), {
    enabled: isAuthenticated && !!id && !isAnonymous,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los chats de usuario");
    }
  });
};

export const useFetchUserChatByIdPaginated = () => {
  const { isAuthenticated } = useIsLogged();
  const { currentChatId } = useAppSelector(state => state.chatReducer);

  const repliesList = useInfiniteQuery(
    ["activities"],
    ({ pageParam = "" }) => {
      return fetchUserChatById(currentChatId, pageParam);
    },
    {
      getNextPageParam: lastPage => {
        return lastPage.replies.length > 0
          ? lastPage.replies[lastPage.replies.length - 1].id
          : undefined;
      },
      enabled: isAuthenticated && currentChatId !== -1,
      staleTime: 15 * 1000 * 60
    }
  );
  const replies: Reply[] = [];
  repliesList.data?.pages.forEach(page =>
    page.replies.forEach(reply => replies.push(reply))
  );

  return {
    ...repliesList,
    repliesPages: replies
  };
};

export const useFetchUserChatById = () => {
  const { isAuthenticated } = useIsLogged();
  const { currentChatId } = useAppSelector(state => state.chatReducer);

  return useQuery(
    ["userChat", currentChatId],
    () => fetchUserChatById(currentChatId),
    {
      enabled: isAuthenticated && currentChatId !== -1,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener el chat seleccionado");
      }
    }
  );
};

export const useSendMessage = () => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, SendMessage>(postSendMessage, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("userChat");
    },
    onError: () => {
      fail("Hubo un error al enviar el mensaje");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("userChat");
    }
  });
};

export const useCreateChat = () => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, number>(postCreateChat, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("userChat");
    },
    onError: () => {
      fail("Hubo un error al enviar el mensaje");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("userChat");
    }
  });
};
