import React, { useEffect, useState } from "react";
import { Button, message } from "antd";

import Brands from "./Brands";
import Categories from "./Categories";

const CategoriesContainer: React.FC = () => {
  const [selectedSection, setSelectedSection] = useState<
    "categories" | "brands"
  >("categories");

  useEffect(() => {
    message.config({
      top: 80
    });
  }, []);

  return (
    <div className="w-full">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <Button
            className="mr-2"
            type={selectedSection === "categories" ? "primary" : "default"}
            onClick={() => setSelectedSection("categories")}
          >
            Categorías
          </Button>
          <Button
            type={selectedSection === "brands" ? "primary" : "default"}
            onClick={() => setSelectedSection("brands")}
          >
            Marcas
          </Button>
        </div>
      </div>
      {selectedSection === "categories" ? <Categories /> : <Brands />}
    </div>
  );
};

export default CategoriesContainer;
