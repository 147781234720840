import React from "react";

import CarouselClients from "components/Carousel/CarouselClients/CarouselClients";
import Layout from "components/Layout/Layout";
import { useAllAuctions } from "services/Auctioners/Auctioners.service.hooks";
import { useFeaturedSellers } from "services/Bids/Bids.services.hooks";
import FeaturedAuctioneer from "modules/Home/FeaturedAuctioneer";
import FeaturedLiveAuction from "modules/Home/FeaturedLiveAuction";
import FeaturedAuctionCarousel from "modules/Home/FeaturedAuctionCarousel";
import HomeSection from "modules/Home/HomeSection";

import { clients } from "./Home.helper";
import Styles from "./Home.styles";

const Index = () => {
  const { data: pendingAuctions, isLoading: isLoadingPendingAuctions } =
    useAllAuctions(1, 100, 0);
  const { data: liveAuctions, isLoading: isLoadingLiveAuctions } =
    useAllAuctions(2, 100, 0);
  const { data: featuredSellers, isLoading: isLoadingFeaturedSellers } =
    useFeaturedSellers();

  return (
    <Layout>
      <Styles>
        <CarouselClients data={clients} />

        <div className="max-w-6xl mx-auto pt-10">
          <HomeSection
            title="Subastadores destacados"
            buttonText="Ver todos »"
            className="pb-16"
            isLoading={isLoadingFeaturedSellers}
            isHidden={!featuredSellers?.length}
          >
            <div className="grid grid-cols-4 h-40 gap-x-3">
              {featuredSellers?.map((seller, i) => (
                <FeaturedAuctioneer auctioneer={seller} key={i} />
              ))}
            </div>
          </HomeSection>

          <HomeSection
            title="Subastas destacadas"
            buttonText="Ver todas »"
            buttonRoute="/panel/subastas"
            isLoading={isLoadingPendingAuctions}
            isHidden={!pendingAuctions?.length}
          >
            <FeaturedAuctionCarousel auctions={pendingAuctions ?? []} />
          </HomeSection>

          <HomeSection
            title={`Subastas en vivo (${liveAuctions?.length})`}
            buttonText="Ver todas »"
            buttonRoute="/panel/subastas"
            subtitle="Obras de arte y antigüedades excepcionales"
            className="pb-16"
            isLoading={isLoadingLiveAuctions}
            isHidden={!liveAuctions?.length}
          >
            <div className="grid grid-cols-4 gap-5">
              {liveAuctions?.map((liveAuction, i) => (
                <FeaturedLiveAuction liveAuction={liveAuction} key={i} />
              ))}
            </div>
          </HomeSection>
        </div>
      </Styles>
    </Layout>
  );
};

export default Index;
