export const myDirectionsForm = [
  {
    label: "address",
    type: "text",
    placeholder: "Dirección",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "country",
    type: "text",
    placeholder: "País",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "city",
    type: "text",
    placeholder: "Ciudad",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "state",
    type: "text",
    placeholder: "Estado",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "postal_code",
    type: "number",
    placeholder: "Código postal",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "apt",
    type: "text",
    placeholder: "Apt (Apartado postal apartamento piso)",
    rules: {
      required: "Campo requerido"
    }
  }
];
