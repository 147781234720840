import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { useAppSelector } from "hooks";
import React from "react";
import { useDeleteCurrency } from "services/currency/currency.service.hooks";

import Styles, { checkStyles } from "./CurrencyCard.styles";
import { CurrencyCardProps as Props } from "./CurrencyCard.types";

const CurrencyCard: React.FC<Props> = props => {
  const { currency, setCurrentCurrency } = props;
  const { mutateAsync, isLoading } = useDeleteCurrency();
  const { user } = useAppSelector(state => state.authReducer);
  const { rol } = user ?? {};
  const { id, name, price, code } = currency;

  const handleDelete = async () => {
    try {
      if (rol !== 10)
        throw new Error("Solo un administrador puede eliminar una moneda");
      mutateAsync(id);
    } catch (error) {
      fail("Error al eliminar la moneda");
    }
  };

  const handleEdit = () => {
    setCurrentCurrency(currency);
  };

  const renderActions = () => {
    if (rol !== 10) return;
    return (
      <div>
        <Tooltip title="Editar" className="ItemCard__remove">
          <IconButton
            sx={checkStyles}
            onClick={() => handleEdit()}
            disabled={id === 1}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar" className="ItemCard__remove">
          <IconButton
            sx={checkStyles}
            onClick={() => handleDelete()}
            disabled={id === 1}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <Styles className="CurrencyCard">
      {isLoading && <CircularProgress />}
      {renderActions()}

      <div className="CurrencyCard__content">
        <div className="CurrencyCard__section">
          <h3>Nombre</h3>
          <h3>{name}</h3>
        </div>
        <div className="CurrencyCard__section">
          <p>Código</p>
          <p>{code}</p>
        </div>
        <div className="CurrencyCard__section">
          <p>Tipo de cambio</p>
          <p>{price}</p>
        </div>
      </div>
    </Styles>
  );
};

CurrencyCard.defaultProps = {};

export default CurrencyCard;
