import React, { ReactNode } from "react";

import Button from "components/Globals/Button/Button";
import { NavLink } from "react-router-dom";

const package_json = require("../../../package.json");

const Footer = () => {
  return (
    <div>
      <div className="flex flex-col bg-[#E3E3E3] py-8 mt-32">
        <div className="max-w-4xl mx-auto text-center flex flex-col items-center gap-y-8">
          <span className="text-sm font-Poppins-Rg text-[rgba(0,0,0,0.85)] leading-6">
            Creá tus subastas en línea, en vivo y cronometradas de Latino
            América. Explore los catálogos de subastas y haga una oferta en
            tiempo real por obras de arte y antigüedades excepcionales de las
            mejores casas de subastas y distribuidores. Nuevas subastas son
            añadidas diariamente.
          </span>
          <Button
            size="small"
            variant="contained"
            rounded="true"
            id="Login"
            className="font-Poppins-Rg text-[#fafafa] shadow-none w-fit text-sm py-3 px-6 rounded-full"
          >
            Convertite en vendedor
          </Button>
        </div>
      </div>
      <div className="font-Poppins-Rg grid grid-cols-3 max-w-4xl mx-auto py-10 gap-x-8">
        <LinkGroup title="Compañía">
          <li>Acerca de</li>
          <li>Noticias</li>
          <li>Carreras</li>
          <li>Ayuda</li>
          <li>Comentarios</li>
        </LinkGroup>
        <LinkGroup title="Subastas">
          <NavLink to="calendario" className="text-inherit hover:text-new-200">
            <li>Calendario de subasta</li>
          </NavLink>
          <li>Directorio de la casa de subasta</li>
          <li>Resultados del precio de subasta</li>
          <li>Subastas cerca de mí</li>
          <li>Como funcionan las subastas</li>
        </LinkGroup>
        <LinkGroup title="Venta">
          <a href="#some" className="text-inherit hover:text-new-200">
            <li>Inicio de sesión del subastador</li>{" "}
          </a>
          <NavLink
            to="registro-vendedor"
            className="text-inherit hover:text-new-200"
          >
            <li>Conviertete en vendedor</li>
          </NavLink>
          <li>Consignar un artículo</li>
          <li>Centro de recursos para vendedores</li>
          <li>Reseñas del vendedor</li>
          <li>Por qué vender</li>
          <li>Version {package_json.version}</li>
        </LinkGroup>
      </div>
    </div>
  );
};

type LinkGroupProps = {
  title: string;
  children: ReactNode;
};

const LinkGroup = ({ title, children }: LinkGroupProps) => {
  return (
    <div className="flex flex-col gap-y-3">
      <span className="text-new-200 text-2xl">{title}</span>
      <ul className="mt-2 text-[#666] text-sm leading-5 flex flex-col gap-y-2 cursor-pointer">
        {children}
      </ul>
    </div>
  );
};

export default Footer;
