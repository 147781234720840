import { variables } from "config/variables";
import styled from "styled-components";

import { ChartReviewsStyledProps as Props } from "./ChartReviews.types";

const { fonts, palette } = variables;

const ChartReviewsStyled = styled.div<Props>`
  width: 72%;
  margin: 64px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  font-family: ${fonts["Poppins-Lt"]};

  * > h2 {
    font-size: 16px;
    line-height: 24px;
    min-width: 120px;
  }

  * > h3 {
    font-size: 16px;
    line-height: 24px;
  }

  * > h4 {
    font-size: 14px;
    line-height: 20px;
    color: ${palette.gray.primary};
  }

  .ChartReviews {
    &__section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 12px 0;
    }

    &__rating {
      display: flex;
      flex-direction: column;
      width: 16%;
      align-items: flex-start;
      gap: 16px;
    }

    &__stars {
      flex: 1;
    }
  }
`;

export default ChartReviewsStyled;
