import { IconButton, Tooltip } from "@mui/material";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import Table from "components/Globals/Table/Table";
import { useAppSelector } from "hooks";
import React, { useMemo } from "react";
import { TableColumn } from "react-data-table-component";
import { useDispatch } from "react-redux";
import { updateCurrentAuctionStep } from "redux/actions/auctionActions";
import { removePrice } from "services/Auctioners/Auctioners.service";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import { Price } from "services/Auctioners/Auctioners.service.types";
import { successful } from "utils/Swal";

import Styles from "./ListPrices.styles";
import { ListPricesProps as Props } from "./ListPrices.types";

const ListPrices: React.FC<Props> = props => {
  const { prices: data, toDelete } = props;

  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const { refetch } = useGetAuctionById(currentAuction?.id);

  const dispatch = useDispatch();

  const handleDelete = async (data: Price) => {
    try {
      await removePrice(data?.id).then(() => {
        refetch();
      });
    } catch (error) {
      fail("Error al eliminar el precio seleccionado");
    }
  };

  const handleSend = () => {
    successful("Se guardó la escala de precios correctamente");
    document.getElementsByName("Etapa4")?.forEach(e => {
      e.click();
    });
    dispatch(updateCurrentAuctionStep(3));
  };

  const columns: TableColumn<Price>[] = useMemo(
    () => [
      {
        name: "Precio",
        selector: row => row?.price ?? 0,
        sortable: true,
        ignoreRowClick: true,
        cell: row => <>${row?.price}</>
      },
      {
        name: "Oferta",
        selector: row => row?.offert ?? 0,
        sortable: true,
        ignoreRowClick: true,
        cell: row => <>${row?.offert}</>
      },
      {
        name: "Acciones",
        ignoreRowClick: true,
        center: true,
        omit: !toDelete,
        cell: () => (
          <Tooltip title="Eliminar">
            <IconButton data-tag="allowRowEvents">
              <img
                src="/images/icons/DeleteSvg.svg"
                alt="Icono eliminar"
                data-tag="allowRowEvents"
              />
            </IconButton>
          </Tooltip>
        )
      }
    ],
    [toDelete]
  );

  return (
    <Styles className="ListPrices">
      <h2 className="ListPrices__text--title">
        Tabla visual de incremento de puja
      </h2>
      {data ? (
        <Table data={data} columns={columns} onRowClicked={handleDelete} />
      ) : null}
      {toDelete && (
        <div className="ListPrices__container--button">
          <ButtonLarge onClick={handleSend} variant="contained">
            Terminar de agregar precios
          </ButtonLarge>
        </div>
      )}
    </Styles>
  );
};

ListPrices.defaultProps = {
  toDelete: false
};

export default ListPrices;
