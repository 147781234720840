import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts, shadows } = variables;

const Styles = styled.div`
  shadow-bow: ${shadows.shadowCard};
  border-radius: 8px;
  background-color: ${palette.grCustom1.c2};
  border: 1px solid ${palette.borderColor};
  padding: 0 12px;
  min-height: 48vh;

  &:hover {
    shadow-bow: ${shadows.shadowCardHover};
  }

  .ListAuctionsBuyer {
    &__container {
      &--items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media ${devices.mobileL} {
          justify-content: space-between;
        }
      }

      &--tabs {
        font-size: 16px;
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        font-family: ${fonts["Poppins-Sb"]};
        text-align: center;
        gap: 16px;

        @media ${devices.tablet} {
          justify-content: flex-start;
          font-size: 18px;
        }
      }

      &--input {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;
        padding: 16px;

        @media ${devices.tablet} {
          padding-top: 0;
          padding-right: 24px;
          padding-left: 24px;
        }

        @media ${devices.laptop} {
          padding-top: 0;
          padding-right: 40px;
          padding-left: 40px;
        }
      }
    }

    &__component {
      &--tabs {
        font-size: 16px;
        text-align: center;
        font-family: ${fonts["Poppins-El"]};
        font-weight: 600;
        letter-spacing: 0.4px;
        text-decoration: none;
        display: inline-block;
        border-bottom: 4px;
        letter-spacing: 0.8px;

        @media ${devices.mobileL} {
          font-size: 16px;
        }

        @media ${devices.laptop} {
          font-size: 18px;
        }

        @media ${devices.desktop} {
          font-size: 24px;
        }
      }
    }

    &__label {
      display: block;
      font-size: 16px;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      font-weight: 560;
      color: ${palette.primary};

      @media ${devices.mobileL} {
        font-size: 18px;
      }
      @media ${devices.laptop} {
        font-size: 20px;
      }

      @media ${devices.desktop} {
        font-size: 24px;
      }
    }

    &__input {
      font-family: ${fonts["Poppins-Rg"]};
      font-size: 16px;
      font-weight: 320;
      line-height: 24px;
      letter-spacing: 1.2px;
    }
  }
`;

export default Styles;
