export const myProfileForm = [
  {
    label: "name",
    type: "text",
    placeholder: "Nombre",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "surname",
    type: "text",
    placeholder: "Apellido",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "num_doc",
    type: "number",
    placeholder: "Documento",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "phone",
    type: "number",
    placeholder: "Teléfono",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "email",
    type: "email",
    placeholder: "Email",
    disabled: true,
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "ocupation",
    type: "text",
    placeholder: "Profesión",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "country",
    type: "text",
    placeholder: "Pais",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "city",
    type: "text",
    placeholder: "Ciudad",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "state",
    type: "text",
    placeholder: "Estado",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "postal_code",
    type: "number",
    placeholder: "Código postal",
    rules: {
      required: "Campo requerido"
    }
  },
  {
    label: "facebook",
    type: "link",
    placeholder: "Facebook"
    /* required: false */
  },
  {
    label: "instagram",
    type: "link",
    placeholder: "Instagram"
    /* required: false */
  }
];
