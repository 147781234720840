import { variables } from "config/variables";
import styled from "styled-components";

import { devices } from "../../../config/devices";

const { fonts } = variables;

const Styles = styled.div`
  .MyCurrency {
    &__currency-container {
      display: flex;
      flex-direction: column;
    }

    &__currency {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 32px;

      &__title {
        font-size: 16px;
        font-family: ${fonts["Poppins-El"]};
        font-weight: 600;
        letter-spacing: 0.4px;
        text-decoration: none;
        display: inline-block;
        border-bottom: 4px;
        letter-spacing: 0.8px;
        font-variant: all-petite-caps;

        @media ${devices.mobileL} {
          font-size: 18px;
        }

        @media ${devices.laptop} {
          font-size: 25px;
        }
      }

      &__item {
        flex: 1;
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 16px;
        border: 1px solid #e6e6e6;
        margin: 8px;

        &:hover {
          border: 1px dashed #fd8c2d;
        }

        &--active {
          border: 1px solid #ff7400;
        }

        &--active2 {
          border: 1px dashed #ff7400;
        }

        &-title {
          font-size: 16px;
          font-weight: bold;
        }

        &-address {
          font-size: 14px;
          font-weight: bold;
          color: #1c1c1c;
        }
      }

      &__button {
        padding: 4px;
        border-radius: 4px;
        border: 1px solid #ff7400;
        color: #1c1c1c;

        &--desactive {
          background-color: #ff7400;
          color: white;

          &:hover {
            transform: scale(1);
          }
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      &__text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }
    }

    &__container {
      &--button {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export default Styles;
