import CustomModal from "components/CustomModal/CustomModal";
import React from "react";

import Styles from "./ModalEnd.styles";
import { ModalEndProps as Props } from "./ModalEnd.types";

const ModalEnd: React.FC<Props> = props => {
  const { modal, setModal } = props;

  return (
    <CustomModal
      isOpen={modal}
      onClose={() => setModal(!modal)}
      ariaLabelledby="Modal de subasta finalizada"
    >
      <Styles>
        <h1 className="ModalEnd__center ModalEnd__title">
          ¡La subasta finalizo!
        </h1>
        {/* <img
          className="ModalEnd__image"
          src="/images/bid_end.jpg"
          alt="Imagen de subasta finalizada"
        /> */}
        <p className="ModalEnd__center">
          Recuerda que puedes participar en otras subastas
        </p>
      </Styles>
    </CustomModal>
  );
};

export default ModalEnd;
