import React from "react";

import Styles from "./NavItem.styles";
import { MenuItemProps } from "./Navtem.types";

const NavItem = (props: MenuItemProps) => {
  const { children, icon } = props;

  return (
    <Styles {...props}>
      <span className="NavItem__icon">{icon}</span>
      <span className="NavItem__icon-buttons__text">{children}</span>
    </Styles>
  );
};

export default NavItem;
