// Notifications services
import api, { buildHeaders } from "../Api";
import { Wish } from "./wishes.service.types";

export async function postNewWish(wish: FormData) {
  return await api.post(`/wishes`, wish, {
    headers: await buildHeaders()
  });
}

export async function editWish(wishData: Wish) {
  const { id, description } = wishData;
  return await api.post(
    `/wishes/${id}`,
    { description },
    {
      headers: await buildHeaders()
    }
  );
}

export const notifyWishByCategory = async (
  wishId?: number,
  categoryId?: number
) => {
  return await api
    .get(`/wishes/notification/category/${categoryId}/${wishId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
};

export const notifyAuctionHouseWish = async (
  wishId?: number,
  userId?: number
) => {
  return await api
    .get(`/wishes/notification/${userId}/${wishId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
};

export const downloadWishPDF = async (wishId?: number) => {
  window.open(
    `https://dev.api.livesubastas.com/api/wishes/exportpdf/${wishId}`
  );
  return;
};

export const changeWishState = async (wishId?: number): Promise<Wish> => {
  return await api
    .get(`/wishes/reviewed/${wishId}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchAdminAllWishes = async (
  take = 100,
  skip = 0
): Promise<Wish[]> => {
  return await api
    .get(`/wishes?take=${take}&skip=${skip}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchWishDetails = async (wishId?: number): Promise<Wish> => {
  return await api
    .get(`/wishes/${wishId}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};
