import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts, shadows } = variables;

const Styles = styled.div`
  width: 100%;

  .SelectComponent {
    &__label {
      color: ${palette.primary};
      display: block;
      margin-bottom: 4px;
      font-family: ${fonts["Poppins-Lt"]};
      font-size: 16px;
      font-weight: 540;

      @media ${devices.laptopL} {
        font-size: 18px;
      }
    }

    &__input {
      padding: 10px 14px;
      line-height: 20px;
      word-spacing: 1.6px;
      color: ${palette.gray.second1};
      width: 100%;
      border-radius: 16px;
      border-width: 1px;
      appearance: none;
      font-family: ${fonts["Poppins-El"]};

      @media ${devices.mobileL} {
        width: auto;
      }

      &:focus-visible {
        box-shadow: ${shadows.inputHover};
        outline: none;
      }
    }
  }
`;

export default Styles;
