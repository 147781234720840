import useGlobals from "context/globals/globals.hooks";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { updateCurrentAuction } from "redux/actions/auctionActions";
import { useIsLogged } from "services/Auth/Auth.services.hooks";

import { fail } from "../../utils/Swal";
import { getMyAuctionsAccess } from "./Auctioners.service";
import { checkAuctionStatus } from "./Auctioners.service";
import { getAuctionCommentsById } from "./Auctioners.service";
import { getOfferAuction } from "./Auctioners.service";
import { getSellerReviews } from "./Auctioners.service";
import { getSellerItems } from "./Auctioners.service";
import { getSellerInfo } from "./Auctioners.service";
import { getCurrentItem } from "./Auctioners.service";
import { getAllAuctions } from "./Auctioners.service";
import { getFavoriteById } from "./Auctioners.service";
import { getItemById, getMyFavorites } from "./Auctioners.service";
import { getAuctionCategories } from "./Auctioners.service";
import { getAuctionById } from "./Auctioners.service";
import { getMyAuctions } from "./Auctioners.service";
import { Item } from "./Auctioners.service.types";

export const useGetAuctionCategories = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["all_categories"], () => getAuctionCategories(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener las categorías");
    }
  });
};

export const useMyAuctions = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["my-auctions"], () => getMyAuctions(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener las subastas");
    }
  });
};

export const useMyAuctionsAccess = (status: number) => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(
    ["my-auctions-access", status],
    () => getMyAuctionsAccess(status),
    {
      enabled: isAuthenticated,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener las subastas");
      }
    }
  );
};

export const useAllAuctions = (
  status?: number,
  limit?: number,
  skip?: number
) => {
  return useQuery(
    ["auctions", status, limit, skip],
    () => getAllAuctions(status, limit, skip),
    {
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener las subastas");
      }
    }
  );
};

export const useGetAuctionById = (id?: number) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useIsLogged();
  return useQuery(["auction", id], () => getAuctionById(id), {
    enabled: isAuthenticated && !!id,
    staleTime: 15 * 1000 * 60,
    onSuccess: response => {
      dispatch(updateCurrentAuction(response));
    },
    onError: () => {
      fail("Hubo un error al obtener la subasta");
    }
  });
};

export const useCheckActionStatus = (id?: number) => {
  const { isAuthenticated } = useIsLogged();
  const queryClient = useQueryClient();
  return useQuery(["check", id], () => checkAuctionStatus(id), {
    enabled: isAuthenticated && !!id,
    staleTime: 15 * 1000 * 60,
    onSuccess: () => {
      queryClient.invalidateQueries("auction");
      queryClient.invalidateQueries("auction");
      queryClient.invalidateQueries("currentItem");
    }
  });
};

export const useGetMyFavorites = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["favorites"], () => getMyFavorites(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener mis favoritos");
    }
  });
};

export const useGetFavoriteById = (id?: number) => {
  const { isAuthenticated } = useIsLogged();
  return useQuery([id, "favorites"], () => getFavoriteById(id), {
    enabled: isAuthenticated && !!id,
    staleTime: 15 * 1000 * 60
  });
};

export const useGetItemById = (id: number) => {
  const { isAuthenticated } = useIsLogged();
  return useQuery([id, "item"], () => getItemById(id), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60
  });
};

export const useGetItemOfferById = (id?: number, limit = 5) => {
  const { isAuthenticated } = useIsLogged();

  return useQuery([id, "offer", limit], () => getOfferAuction(id, limit), {
    enabled: isAuthenticated && !!id,
    staleTime: 5 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los datos del usuario");
    }
  });
};

// export const getCurrentItemHook =  (
//   items: Item[],
//   dispatch: Dispatch<any>,
//   itemId?: number,
//   finalize?: string
// ) => {

//     const currentLote = items.find(val => val.id === itemId);
//     if (currentLote) currentLote.finalize_item = finalize;
//     dispatch(updateCurrentItem(currentLote));

// };

export const useGetCurrentItem = (auctionId?: number, items?: Item[]) => {
  const { isAuthenticated } = useIsLogged();
  const { setCurrentItem } = useGlobals();
  const response = useQuery(
    [auctionId, "currentItem"],
    async ({ signal }) => getCurrentItem(auctionId, signal),
    {
      enabled: isAuthenticated && !!auctionId,
      staleTime: 5 * 1000 * 60
    }
  );
  const { data } = response ?? {};
  const { lot, finalize_item: newFinalize } = data ?? {};

  // FIXME: Check this shit
  const current = items?.[(lot ?? 1) - 1];
  if (current && typeof newFinalize !== "undefined") {
    // current.finalize_item = newFinalize;
  }
  setCurrentItem(current);
  return {
    ...response,
    data: current
  };
};

export const useGetSellerInfo = (sellerId?: number) => {
  const { isAuthenticated } = useIsLogged();
  return useQuery([sellerId, "seller"], () => getSellerInfo(sellerId), {
    enabled: isAuthenticated && !!sellerId,
    staleTime: 5 * 1000 * 60
  });
};

export const useGetSellerItems = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["sellerItems"], () => getSellerItems(), {
    enabled: isAuthenticated,
    staleTime: 5 * 1000 * 60
  });
};

export const useGetAuctionComments = (id?: number) => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(
    ["auction", "comments", id],
    () => getAuctionCommentsById(id),
    {
      enabled: isAuthenticated && !!id,
      staleTime: 5 * 1000 * 60
    }
  );
};

export const useGetSellerReviews = (sellerId?: number) => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(
    ["sellerReviews", sellerId],
    () => getSellerReviews(sellerId),
    {
      enabled: isAuthenticated && !!sellerId,
      staleTime: 5 * 1000 * 60
    }
  );
};
