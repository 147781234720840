import { Avatar as CAvatar, Conversation } from "@chatscope/chat-ui-kit-react";
import { ConversationList } from "@chatscope/chat-ui-kit-react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { useAppSelector } from "hooks";
import React from "react";
import { useDispatch } from "react-redux";
import { updateCurrentChatId } from "redux/actions/sliceActions";
import { useFetchUserChats } from "services/userChat/userChat.service.hooks";

import Styles, { selectedChat, chatStyle } from "./UserConversationList.styles";
import { chatStyles } from "./UserConversationList.styles";
import { UserConversationListProps as Props } from "./UserConversationList.types";

const UserConversationList: React.FC<Props> = props => {
  const { searchChat } = props;
  const { data, isLoading, isFetched } = useFetchUserChats(searchChat);
  const { user } = useAppSelector(state => state.authReducer);
  const dispatch = useDispatch();

  const { currentChatId } = useAppSelector(state => state.chatReducer);

  if (isLoading && !isFetched) return <CircularProgress />;

  return (
    <Styles className="UserConversationList">
      {isLoading && <CircularProgress />}
      <ConversationList>
        {data?.map((chat, idx) => {
          const { otherUser, lastReply, id } = chat ?? {};
          const { name: userName, surname } = otherUser ?? {};
          const { reply, file_url, user_id } = lastReply ?? {};
          const otherName = `${userName ?? ""} ${surname ?? ""}`;

          return (
            <Conversation
              key={idx}
              name={otherName}
              lastSenderName={user_id === user?.id ? user?.name : userName}
              info={reply ? reply : file_url ? <FileCopyIcon /> : "..."}
              onClick={() => dispatch(updateCurrentChatId({ id }))}
              style={currentChatId === id ? selectedChat : chatStyle}
            >
              <CAvatar>
                <Avatar sx={chatStyles}>{userName?.slice(0, 1)}</Avatar>
              </CAvatar>
            </Conversation>
          );
        })}
      </ConversationList>
    </Styles>
  );
};

UserConversationList.defaultProps = {};

export default UserConversationList;
