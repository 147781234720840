import { useAppSelector } from "hooks";
import React, { createContext, useMemo, useState } from "react";
import { ContextDevTool } from "react-context-devtool";
import { useQueryClient } from "react-query";
import { WinnedAuction } from "services/Auctioners/Auctioners.service.types";
import { Item } from "services/Auctioners/Auctioners.service.types";
import { Notification } from "services/notifications/notifications.service.types";
import { pusher } from "services/pusher/pusherApi";

import { GlobalsProviderProps as Props } from "./globals.context.types";
import { GlobalsProviderValue } from "./globals.context.types";

// @ts-ignore
export const GlobalsContext = createContext<GlobalsProviderValue>();

const GlobalsProvider: React.FC<Props> = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfViewers, setNumberOfViewers] = useState(0);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<
    Notification | undefined
  >(undefined);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedSubIndex, setSelectedSubIndex] = useState(0);
  const [fromEdit, setFromEdit] = useState(false);
  const [selectedClient, setSelectedClient] = useState<number | undefined>(
    undefined
  );
  const [currentItem, setCurrentItem] = useState<Item | undefined>(undefined);
  const [currentAuction, setCurrentAuction] = useState<
    WinnedAuction | undefined
  >(undefined);
  const queryClient = useQueryClient();
  const { user } = useAppSelector(state => state.authReducer);
  const { id } = user ?? {};
  // useRefreshToken();

  const value: GlobalsProviderValue = useMemo(() => {
    return {
      isLoading,
      setIsLoading,
      openNotifications,
      setOpenNotifications,
      currentNotification,
      setCurrentNotification,
      selectedIndex,
      setSelectedSubIndex,
      setSelectedIndex,
      selectedSubIndex,
      currentItem,
      setCurrentItem,
      fromEdit,
      setFromEdit,
      selectedClient,
      setSelectedClient,
      currentAuction,
      setCurrentAuction,
      numberOfViewers,
      setNumberOfViewers
    };
  }, [
    isLoading,
    openNotifications,
    currentNotification,
    selectedIndex,
    selectedSubIndex,
    currentItem,
    fromEdit,
    selectedClient,
    currentAuction,
    numberOfViewers
  ]);

  const channel = pusher.subscribe(`liveauction-notification-${id}`);
  channel.bind("App\\Events\\NewNotification", () => {
    queryClient.invalidateQueries("notifications");
  });

  return (
    <GlobalsContext.Provider value={value}>
      <ContextDevTool
        context={GlobalsContext}
        id="globals"
        displayName="Globals"
      />
      {props.children}
    </GlobalsContext.Provider>
  );
};

export default GlobalsProvider;
