import { variables } from "config/variables";
import styled from "styled-components";

import { ProgressComponentStyledProps as Props } from "./ProgressComponent.types";

const { palette } = variables;

const ProgressComponentStyled = styled.div<Props>`
  progress[value] {
    width: 100%;

    -webkit-appearance: none;
    appearance: none;
  }

  progress[value]::-webkit-progress-bar {
    height: 8px;
    border-radius: 16px;
    background-color: ${palette.dividerColor};
  }

  progress[value]::-webkit-progress-value {
    height: 8px;
    border-radius: 16px;
    background-color: ${palette.primaryLight};
  }
`;

export default ProgressComponentStyled;
