import * as yup from "yup";

export const schema = yup.object().shape({
  email: yup
    .string()
    .email("Correo electrónico inválido")
    .required("Campo requerido."),
  verify: yup
    .string()
    .min(6, "El código de verificación debe tener 6 caracteres.")
});
