import { OpenInNew } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Favorite from "@mui/icons-material/Favorite";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { CONSTANTS } from "config/constants";
import { useAppSelector } from "hooks";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { removeItemService } from "services/Auctioners/Auctioners.service";
import { addFavorite } from "services/Auctioners/Auctioners.service";
import { useGetMyFavorites } from "services/Auctioners/Auctioners.service.hooks";
import { useGetFavoriteById } from "services/Auctioners/Auctioners.service.hooks";
import { confirm, successful } from "utils/Swal";

import Styles, { checkStyles } from "./ItemCard.styles";
import { ItemCardProps } from "./ItemCard.types";

const { IMAGE_PLACEHOLDER } = CONSTANTS;

function ItemCard(props: ItemCardProps) {
  const { item, auctionId, className, miniver } = props;
  const { name, price_base, images, id, user_id } = item ?? {};
  const { price_estimated1, price_estimated2 } = item ?? {};
  const { photo } = images?.[0] ?? {};
  const { data } = useGetFavoriteById(id);
  const { data: responseFavorite } = data ?? {};
  const { refetch } = useGetMyFavorites();
  const { user } = useAppSelector(state => state.authReducer);
  const queryClient = useQueryClient();

  const [isFavorite, setIsFavorite] = useState(false);

  const handleAddFavorite = async () => {
    setIsFavorite(!isFavorite);
    await addFavorite(id, { type: "PRODUCT" });
    refetch();
  };

  const handleNavigate = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/item/${auctionId}/${id}`);
  };

  const handleDelete = async (id: number) => {
    try {
      confirm().then(async () => {
        await removeItemService(id).then(() => {
          queryClient.refetchQueries(["auction"]);
          successful("Lote eliminado correctamente");
        });
      });
    } catch (error) {
      fail("Error al eliminar el lote seleccionado");
    }
  };

  const renderRemoveLote = () => {
    if (user_id !== user?.id) return;
    return (
      <Tooltip title="Eliminar" className="ItemCard__remove">
        <Checkbox
          icon={<CloseIcon fontSize="small" />}
          checkedIcon={<CloseIcon fontSize="small" />}
          sx={checkStyles}
          onChange={() => handleDelete(id)}
        />
      </Tooltip>
    );
  };

  const renderFavoriteAction = () =>
    !miniver ? (
      <Tooltip title="Añadir a favoritos" className="ItemCard__checkbox">
        <Checkbox
          icon={<FavoriteBorder fontSize="small" />}
          checkedIcon={<Favorite fontSize="small" />}
          sx={checkStyles}
          onChange={() => handleAddFavorite()}
          checked={isFavorite}
        />
      </Tooltip>
    ) : null;

  useEffect(() => {
    if (responseFavorite) setIsFavorite(responseFavorite);
  }, [id, responseFavorite]);

  return (
    <Styles className={`ItemCard ${className}`}>
      {renderFavoriteAction()}
      {renderRemoveLote()}
      <div className="ItemCard__content" onClick={handleNavigate}>
        <div className="ItemCard__content--overlay" />
        <img
          className="ItemCard__content--image"
          src={photo ?? IMAGE_PLACEHOLDER}
          alt={name}
        />
        <div className="ItemCard__content--details ItemCard__text ItemCard__text--details fadeIn-top">
          <span>
            <OpenInNew /> Ver detalles
          </span>
        </div>
      </div>
      {!miniver ? (
        <>
          <h2 className="ItemCard__text ItemCard__name">{name}</h2>
          <h3 className="ItemCard__text">{`Estimado. $${price_estimated1}-$${price_estimated2}`}</h3>
          <h4 className="ItemCard__text">{`Base. $${price_base}`}</h4>
        </>
      ) : null}
    </Styles>
  );
}

export default ItemCard;
