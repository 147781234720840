import { variables } from "config/variables";
import styled from "styled-components";

import { ModalSuccessStyledProps as Props } from "./ModalSuccess.types";
const { palette, fonts } = variables;

const ModalSuccessStyled = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 64vh;
  max-width: 640px;
  gap: 24px;
  position: relative;
  padding: 2% 4%;

  & > h1 {
    color: ${palette.primary};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 22px;
    margin-top: 20px;
  }

  * > h3 {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 16px;
  }

  & > p {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 14px;
    text-align: justify;
  }

  * > p {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 14px;
    text-align: justify;
  }

  .ModalSuccess {
    &__buttons {
      bottom: 24px;
      display: flex;
      width: 64%;
      justify-content: space-around;
      gap: 16px;
    }

    &__dataContainer {
      width: 100%;
    }

    &__image {
      width: 280px;
    }
  }
`;

export default ModalSuccessStyled;
