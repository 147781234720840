import styled from "styled-components";

import { devices } from "../../../../config/devices";
import { variables } from "../../../../config/variables";
import { CardImageStyledProps } from "./CardImage.types";

const { palette, fonts } = variables;

const Styles = styled.div<CardImageStyledProps>`
  width: inherit;
  height: inherit;

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .CardImage {
    &__image {
      width: 100%;
      height: 530px;
      position: absolute;
      background-image: url(${props => props.src});
      background-size: inherit;
      background-repeat: no-repeat;
      background-position: center;
      z-index: 0;
    }

    &__content {
      z-index: 99;
      align-items: flex-end;
      justify-content: flex-start;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;

      @media ${devices.tablet} {
        margin-left: 16%;
      }

      @media ${devices.desktop} {
        width: auto;
      }
    }

    &__text {
      font-family: ${fonts["Poppins-El"]};
      color: ${palette.white};
      font-weight: 540;
      text-align: center;

      @media ${devices.tablet} {
        text-align: initial;
      }
    }

    &__date {
      font-size: 14px;

      @media ${devices.mobileL} {
        font-size: 20px;
      }
    }

    &__title {
      font-size: 24px;

      @media ${devices.mobileL} {
        font-size: 36px;
      }

      @media ${devices.tablet} {
        font-size: 40px;
      }

      @media ${devices.laptop} {
        font-size: 48px;
      }
    }

    &__paragraph {
      font-size: 12px;

      @media ${devices.mobileL} {
        font-size: 16px;
      }
    }
  }
`;

export default Styles;
