import React from "react";

import Styles from "./SelectMenuItem.styles";
import { SelectMenuItemProps as Props } from "./SelectMenuItem.types";

const SelectMenuItem: React.FC<Props> = props => {
  const { children } = props;

  return (
    <Styles className="SelectMenuItem" {...props}>
      {children}
    </Styles>
  );
};

SelectMenuItem.defaultProps = {};

export default SelectMenuItem;
