import { auctionsSlice } from "../slice/auctionsSlice";

export const {
  updateCurrentAuction,
  updateCurrentPrice,
  updateLastBid,
  updateTimer,
  resetAuctionsRedux,
  updateCurrentAuctionStep,
  updateLastComment,
  updateAccessCode
} = auctionsSlice.actions;
