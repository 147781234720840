import LoginModal from "components/Dashboard/components/LoginModal/LoginModal";
import RegisterModal from "components/Dashboard/components/RegisterModal/RegisterModal";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import AddCreditCard from "components/user/myPay/AddCreditCard/AddCreditCard";
import { CONSTANTS } from "config/constants";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateCurrentAuction } from "redux/actions/auctionActions";
import { getCurrentItem } from "services/Auctioners/Auctioners.service";
import { useGetAuctionComments } from "services/Auctioners/Auctioners.service.hooks";
import { useGetUserExtraData } from "services/Auth/Auth.services.hooks";
import { useGetSellerData } from "services/Auth/Auth.services.hooks";
import { useFetchAuctionAccess } from "services/auctionAccess/auctionAccess.service.hooks";

import history from "../../../routes/history";
import ModalError from "../ModalError/ModalError";
import ModalSuccess from "../ModalSuccess/ModalSuccess";
import Temporizer from "../Temporizer/Temporizer";
import { handleDirectNavigate } from "./AuctionInfo.helpers";
import { handleNavigate } from "./AuctionInfo.helpers";
import Styles from "./AuctionInfo.styles";
import { AuctionInfoProps } from "./AuctionInfo.types";
const { ACCESS_TYPE } = CONSTANTS;
const { pendant, approved, rejected, limited } = ACCESS_TYPE;

const { IMAGE_PLACEHOLDER } = CONSTANTS;

const AuctionInfo = (props: AuctionInfoProps) => {
  const { auctionData } = props ?? {};
  const [modalError, setModalError] = useState<boolean>(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);
  const [modalCreditCard, setModalCreditCard] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [isLoadingAccessible, setIsLoadingAccessible] = useState<boolean>();
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const { user } = useAppSelector(state => state.authReducer);
  const { setFromEdit } = useGlobals();
  const { id: userId } = user ?? {};
  const { id: reduxCurrenAuctionId } = currentAuction ?? {};
  const {
    title,
    streamer,
    date,
    id: auctionId,
    user_id,
    country
  } = auctionData ?? {};
  const { name } = country ?? {};
  const { date_ini, date_end, description, access, state, type } =
    auctionData ?? {};
  const { data: seller, isLoading } = useGetSellerData(user_id);
  const { data: extraData } = useGetUserExtraData();
  const { refetch } = useGetAuctionComments(auctionId ?? reduxCurrenAuctionId);
  const { payment } = extraData ?? {};
  const dispatch = useDispatch();
  const { reset } = useForm();
  const { name_business, location } = seller ?? {};
  const { photo, logo, user_id: sellerId } = seller ?? {};
  const image = photo ?? logo;
  const dates =
    streamer && date_ini && date_end ? `${date_ini} - ${date_end}` : date;
  const hasStarted = state === 1;
  const { data: accessData, isLoading: accessLoading } =
    useFetchAuctionAccess(auctionId);

  const isLogged = !!user && Object.keys(user)?.length > 0;
  const hasCreditCard = payment !== undefined && payment?.length > 0;
  const isOwner = user_id === user?.id;

  const onOpenCloseModalLogin = () => {
    setModalLogin(!modalLogin);
    setModalRegister(false);
    reset({
      email: "",
      password: ""
    });
  };

  const onOpenCloseModalRegister = () => {
    setModalRegister(!modalRegister);
    setModalLogin(false);
    reset({
      email: "",
      password: "",
      name: "",
      terms: false
    });
  };

  const handleApplyToAccess = (auctionId?: number, type?: number) => {
    switch (type) {
      case approved:
        handleNavigate(auctionId, state);
        break;
      case pendant:
        setModalSuccess(!modalSuccess);
        break;
      case rejected || limited:
        fail("Tu solicitud de acceso ha sido rechazada.");
        break;
      default:
        setModalSuccess(!modalSuccess);
        break;
    }
  };

  const handleCheckList = () => {
    if (!isLogged) {
      setModalLogin(true);
      return;
    }
    if (
      !modalLogin &&
      !modalRegister &&
      !hasCreditCard &&
      isLogged &&
      (access === 2 || access === 3)
    ) {
      setModalCreditCard(true);
      return;
    }
    handleApplyToAccess(auctionId, accessData?.data);
  };

  const handleAuctionRegister = (newAccess?: number) => {
    const _access = newAccess ?? access;

    setIsLoadingAccessible(true);
    refetch();
    getCurrentItem(auctionId)
      .then(() => {
        dispatch(updateCurrentAuction(auctionData));
        switch (_access) {
          case 1:
          case 4:
            handleDirectNavigate(auctionId);
            break;
          case 2:
          case 3:
            handleCheckList();
            break;
          default:
            break;
        }
      })
      .catch(() => {
        setModalError(true);
      })
      .finally(() => {
        setIsLoadingAccessible(false);
      });
  };

  const handleNavigateToAuctionProfile = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/auctionProfile/${sellerId}`);
  };

  const handleGoBack = () => {
    setFromEdit(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push(`/panel/subastas/${true}`);
  };

  const handleEdit = () => {
    dispatch(updateCurrentAuction(auctionData));
    handleGoBack();
  };

  const renderButtons = () => {
    if (state === 3) return null;
    return (
      <div className="AuctionInfo__buttonsContainer">
        {userId === sellerId && state === 1 && (
          <ButtonLarge variant="contained" onClick={handleEdit} size="medium">
            Editar subasta
          </ButtonLarge>
        )}

        {type !== 2 ? (
          <ButtonLarge
            variant={hasStarted ? "contained" : "outlined"}
            size="medium"
            onClick={() => handleAuctionRegister(1)}
            loading={accessLoading || isLoadingAccessible}
          >
            Ingresar a la subasta
          </ButtonLarge>
        ) : null}

        {access === 2 || access === 3 ? (
          <ButtonLarge
            variant={!hasStarted ? "contained" : "outlined"}
            onClick={() => handleAuctionRegister()}
            size="medium"
            loading={accessLoading || isLoadingAccessible}
            id="RegisterAuction"
          >
            Registrarse a la subasta
          </ButtonLarge>
        ) : null}

        {access === 2 && isOwner ? (
          <ButtonLarge
            variant={"text"}
            onClick={() => history.push(`/applicantList/${auctionId}`)}
            size="medium"
            loading={accessLoading || isLoadingAccessible}
            id="RegisterAuction"
          >
            Ver lista de aplicantes
          </ButtonLarge>
        ) : null}

        {!hasStarted && (
          <ButtonLarge variant="outlined" size="medium">
            Agregar a calendario
          </ButtonLarge>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="AuctionInfo__content">
        <div className="AuctionInfo__container">
          <h4 className="AuctionInfo__subtitle">
            {`${
              dates
                ? `${dates} - ${location}`
                : "Jun 10, 2022 12:00 PM GMT-5" +
                  " | " +
                  "New York, NY, United States"
            } - ${name ?? ""}`}
          </h4>
          <h1 className="AuctionInfo__title">
            {title ?? "Modern Decorative Art & Design"}
          </h1>
          <div className="AuctionInfo__user-data">
            <img
              className="AuctionInfo__image"
              alt="userLogo"
              src={image ? image : IMAGE_PLACEHOLDER}
              onClick={handleNavigateToAuctionProfile}
            />
            <h2 className="AuctionInfo__subtitle">{name_business ?? ""}</h2>
          </div>
          <h3 className="AuctionInfo__description">
            {description
              ? parse(description)
              : "All items start at just $1!? We have Watches, Jewelry, Gems, Coins, Memorabilia, Art, and much more to win!! Bid early and Bid often with SAA!!"}
          </h3>
        </div>
        <div className="AuctionInfo__actions-container">
          <Temporizer />
          {renderButtons()}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (
      !modalLogin &&
      !modalRegister &&
      !hasCreditCard &&
      isLogged &&
      (access === 2 || access === 3)
    ) {
      setModalCreditCard(true);
    }
  }, [access, hasCreditCard, isLogged, modalLogin, modalRegister]);

  return (
    <Styles className="AuctionInfo">
      {isLoading && <CircularProgress />}
      {renderContent()}
      <ModalSuccess
        modalSuccess={modalSuccess}
        setModalSuccess={setModalSuccess}
      />
      <RegisterModal
        modalRegister={modalRegister}
        setModalRegister={setModalRegister}
        onOpenCloseModalLogin={onOpenCloseModalLogin}
      />
      <LoginModal
        setModalLogin={setModalLogin}
        modalLogin={modalLogin}
        onOpenCloseModalRegister={onOpenCloseModalRegister}
        redirect={false}
      />
      <AddCreditCard
        modalCreditCard={modalCreditCard}
        setModalCreditCard={setModalCreditCard}
      />
      <ModalError modalError={modalError} setModalError={setModalError} />
    </Styles>
  );
};

export default AuctionInfo;
