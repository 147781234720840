import { useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { message } from "antd";
import { MessageType } from "antd/lib/message";

import {
  createCategory,
  getCategories,
  editCategory,
  deleteCategory,
  createSubcategory,
  deleteSubcategory,
  editSubcategory,
  getBrands,
  createBrand,
  deleteBrand,
  editBrand
} from "./categories.service";

export const useCategories = () => {
  return useQuery(["categories"], () => getCategories(), {
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener las categorias.");
    }
  });
};

export const useCreateCategory = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(createCategory, {
    onMutate: async () => {
      hideLoading.current = message.loading("Creando categoría...", 0);
      await queryClient.cancelQueries("categories");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("categories");
      message.success("Categoria creada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al crear categoría");
    }
  });
};

export const useEditCategory = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(editCategory, {
    onMutate: async () => {
      hideLoading.current = message.loading("Editando categoría...", 0);
      await queryClient.cancelQueries("categories");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("categories");
      message.success("Categoria editada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al editar categoría");
    }
  });
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(deleteCategory, {
    onMutate: async () => {
      hideLoading.current = message.loading("Eliminando categoría...", 0);
      await queryClient.cancelQueries("categories");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("categories");
      await queryClient.invalidateQueries("brands");
      message.success("Categoria eliminada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al eliminar categoría");
    }
  });
};

export const useCreateSubcategory = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(createSubcategory, {
    onMutate: async () => {
      hideLoading.current = message.loading("Creando subcategoría...", 0);
      await queryClient.cancelQueries("categories");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("categories");
      message.success("Subcategoria creada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al crear subcategoría");
    }
  });
};

export const useEditSubcategory = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(editSubcategory, {
    onMutate: async () => {
      hideLoading.current = message.loading("Editando subcategoría...", 0);
      await queryClient.cancelQueries("categories");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("categories");
      await queryClient.invalidateQueries("brands");
      message.success("Subcategoria editada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al editar subcategoría");
    }
  });
};

export const useDeleteSubcategory = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(deleteSubcategory, {
    onMutate: async () => {
      hideLoading.current = message.loading("Eliminando subcategoría...", 0);
      await queryClient.cancelQueries("categories");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("categories");
      await queryClient.invalidateQueries("brands");
      message.success("Subcategoria eliminada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al eliminar subcategoría");
    }
  });
};

export const useBrands = () => {
  return useQuery(["brands"], () => getBrands(), {
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener las marcas.");
    }
  });
};

export const useCreateBrand = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(createBrand, {
    onMutate: async () => {
      hideLoading.current = message.loading("Creando marca...", 0);
      await queryClient.cancelQueries("brands");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("brands");
      message.success("Marca creada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al crear marca");
    }
  });
};

export const useDeleteBrand = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(deleteBrand, {
    onMutate: async () => {
      hideLoading.current = message.loading("Eliminando marca...", 0);
      await queryClient.cancelQueries("brands");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("brands");
      message.success("Marca eliminada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al eliminar marca");
    }
  });
};

export const useEditBrand = () => {
  const queryClient = useQueryClient();
  const hideLoading = useRef<MessageType>();

  return useMutation(editBrand, {
    onMutate: async () => {
      hideLoading.current = message.loading("Editando marca...", 0);
      await queryClient.cancelQueries("brands");
    },
    onSuccess: async () => {
      hideLoading?.current?.();
      await queryClient.invalidateQueries("brands");
      message.success("Marca editada");
    },
    onError: () => {
      hideLoading?.current?.();
      message.error("Error al editar marca");
    }
  });
};
