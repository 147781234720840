import { FieldValues } from "react-hook-form";
import { Dispatch } from "redux";
import { updateCurrentAuctionStep } from "redux/actions/auctionActions";
import { updateCurrentAuction } from "redux/actions/auctionActions";
import { updateAuction } from "services/Auctioners/Auctioners.service";
import { createAuction } from "services/Auctioners/Auctioners.service";
import { CreateAuction } from "services/Auctioners/Auctioners.service.types";
import { UpdateAuction } from "services/Auctioners/Auctioners.service.types";
import { fail, successful } from "utils/Swal";

export const formList = [
  {
    label: "date",
    type: "datetime-local",
    placeholder: "Fecha y Hora",
    required: false,
    variant: "outlined",
    focused: true
  }
];

export const formListDates = [
  {
    name: "date_ini",
    type: "datetime-local",
    placeholder: "Fecha inicio",
    required: "Por favor, selecciona fecha de inicio.",
    variant: "outlined"
  },
  {
    name: "date_end",
    type: "datetime-local",
    placeholder: "Fecha fin",
    required: "Por favor, selecciona fecha fin.",
    variant: "outlined"
  }
];

export const handleFormSend = async (
  values: FieldValues,
  type: number,
  dispatch: Dispatch<any>,
  userId?: number,
  auctionId?: number
) => {
  const { categories_id, sub_categories_id, streamer } = values;

  if (!userId) return;

  const logData: CreateAuction = {
    ...values,
    user_id: +userId,
    categories_id: +categories_id,
    sub_categories_id: +sub_categories_id,
    streamer: streamer === 1,
    type
  };

  if (!auctionId) {
    return await createAuction(logData)
      .then(auction => {
        if (auction?.id) {
          successful("Creación de subasta exitosa");
          dispatch(updateCurrentAuctionStep(1));
          const payload = { ...auction };
          dispatch(updateCurrentAuction(payload));
          document.getElementsByName("Etapa2")?.forEach(e => {
            e.click();
          });
        } else throw new Error("Hubo un error al crear la subasta");
      })
      .catch(error => {
        fail("Hubo un error al crear la subasta");
      });
  } else {
    return await updateAuction(logData as UpdateAuction, auctionId)
      .then(auction => {
        if (auction?.id) {
          successful("Actualización de subasta exitosa");
          dispatch(updateCurrentAuctionStep(2));
          const payload = { ...auction };
          dispatch(updateCurrentAuction(payload));
          document.getElementsByName("Etapa2")?.forEach(e => {
            e.click();
          });
        } else throw new Error("Hubo un error al actualizar la subasta");
      })
      .catch(error => {
        fail("Hubo un error al actualizar la subasta");
      });
  }
};

export const handleFormSend2 = async (
  values: FieldValues,
  dispatch: Dispatch<any>,
  auctionId?: number
) => {
  const formData = new FormData();
  formData.append("description", values.description);
  formData.append("condition", values.condition);
  formData.append("terms_cond", "true");
  formData.append("prima", values.prima);
  //@ts-ignore
  formData.append("access", +values.access);
  if (values.url_logo_1[0]) formData.append("url_logo_1", values.url_logo_1[0]);
  if (values.url_logo_2[0]) formData.append("url_logo_2", values.url_logo_2[0]);
  if (values.url_logo_3[0]) formData.append("url_logo_3", values.url_logo_3[0]);

  // TODO: update auction ID when available
  return await updateAuction(formData as UpdateAuction, auctionId)
    .then(auction => {
      if (auction?.id) {
        successful("Actualización de subasta exitosa");
        dispatch(updateCurrentAuctionStep(2));
        const payload = { ...auction };
        dispatch(updateCurrentAuction(payload));
        document.getElementsByName("Etapa3")?.forEach(e => {
          e.click();
        });
      } else throw new Error("Hubo un error al actualizar la subasta");
    })
    .catch(error => {
      fail("Hubo un error al actualizar la subasta");
    });
};

export const validationRules = {
  streamer: {
    validate: (value: number | "") =>
      value !== "" || "Por favor, seleccione uno"
  },
  title: {
    required: "Por favor, escriba un titulo.",
    minLength: {
      value: 4,
      message: "Por favor ingrese al menos 4 caracteres."
    }
  },
  type: {
    required: "Por favor, seleccione un tipo de subasta."
  }
};

export const validationRulesTwo = {
  subcategory: {
    validate: (value: number) =>
      value !== 0 || "Por favor, seleccione una subcategoría."
  }
};
