import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { RightBidsStyledProps as Props } from "./RightBids.types";

const { palette, fonts, shadows } = variables;

const Styles = styled.div<Props>`
  height: 100%;

  .RightBids {
    &__component {
      &--divider {
        border-bottom: 1px solid ${palette.dividerColor};
      }
    }

    &__container {
      position: relative;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;

      &--header {
        display: flex;
        flex-direction: column;
        padding: 8px;
        padding-bottom: 0;
        width: 100%;
        border-top-right-radius: 16px;

        @media ${devices.desktop} {
          padding: 16px;
        }
      }

      &--body {
        display: flex;
        flex-direction: column;
        padding: 8px;
        height: 100%;
        max-height: 100%;
        overflow: hidden;
        justify-content: flex-start;

        @media ${devices.desktop} {
          padding: 16px;
        }
      }
    }

    &__title {
      font-size: 14px;
      font-family: ${fonts["Poppins-Lt"]};

      @media ${devices.laptop} {
        font-size: 16px;
      }

      @media ${devices.desktop} {
        font-size: 18px;
      }
    }

    &__subtitle {
      font-size: 22px;
      font-family: ${fonts["Poppins-Lt"]};

      @media ${devices.laptop} {
        font-size: 16px;
      }

      @media ${devices.desktop} {
        font-size: 18px;
      }
    }

    &__span {
      background-color: ${palette.primary};
      display: block;
      border-radius: 12px;
      text-align: center;
      width: 60%;
      margin-top: 4px;
      padding: 4px 0;
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.white};
      box-shadow: ${shadows.shadowCard};
      font-size: 12px;

      @media ${devices.laptopL} {
        font-size: 16px;
      }

      &__full {
        width: 100%;
        background-color: ${palette.palidGreen};
        color: ${palette.blackCharcoal};
        font-size: 14px;
        margin: 8px 0;
        font-family: ${fonts["Poppins-Rg"]};

        @media ${devices.laptopL} {
          font-size: 18px;
        }

        &-0 {
          background-color: ${palette.blackCharcoal};
          color: ${palette.white};
        }

        &-1 {
          background-color: ${palette.primaryBackgroundLight};
        }

        &-2 {
          background-color: ${palette.chipWarning};
        }

        &-3 {
          background-color: ${palette.three};
          color: ${palette.white};
        }

        &-4 {
          background-color: ${palette.four};
        }

        &-5 {
          background-color: ${palette.statusFail};
          color: ${palette.white};
        }
      }

      &__right {
        align-self: flex-end;
        background-color: ${palette.otherOffers};
        color: ${palette.dark};
        border: 1px solid ${palette.otherOffers};
      }
    }

    &--container {
      &-relative {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        flex: 1;
        min-height: 300px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
      }
    }
  }
`;

export default Styles;
