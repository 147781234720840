// import CONSTANTS from "config/constants";
import { variables } from "config/variables";
import styled from "styled-components";

import { SidebarStyledProps as Props } from "./Sidebar.types";

const { fonts, palette } = variables;

const SidebarStyled = styled.nav<Props>`
  top: 0;
  left: 0;
  width: 300px;
  height: 100vh;
  flex: 1;
  color: ${palette.primaryBackgroundLight};
  border: 1px solid ${palette.primaryBackgroundLight};
  overflow: hidden;
  font-family: ${fonts["Poppins-Lt"]};

  * > h2 {
    font-family: ${fonts["Poppins-Bd"]};
    font-size: 14px;
    line-height: 20px;
    color: inherit;
  }

  * > h3 {
    font-size: 12px;
    line-height: 16px;
    color: inherit;
  }

  * > h4 {
    font-size: 10px;
    line-height: 16px;
    color: inherit;
  }

  .Sidebar {
    &__active {
      color: ${palette.white};
      font-family: ${fonts["Poppins-Sb"]};
    }

    &__avatar {
      border: 1px solid ${palette.primary};
      padding: 2px;
      margin-right: 12px;
      background-color: ${palette.white};
    }

    &__listButton {
      &:focus,
      &:hover {
        color: ${palette.primary};
        transition: opacity 0.4s, transform 0.4s;
        opacity: 1;
        transform: translate3d(0, 0.2rem, 0);
      }

      position: relative;
      text-decoration: none;

      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        margin: 0 24px;
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: ${palette.primary};
        transform: scaleX(0);
        transition: transform 0.4s ease;
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px 0;
      gap: 8px;
    }

    &__divider {
      width: 100%;
      height: 1px;
      background-color: ${palette.primaryBackgroundLight};
      margin: 8px 0;
    }

    &__icon {
      margin: 24px 0;
      cursor: pointer;

      &--container {
        padding-bottom: 20px;
      }
    }

    &__sectionContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 20px 0;
    }

    &__sectionContainerFooter {
      position: relative;
    }

    &__footerList {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: absolute;
      top: 480px;
      left: 28px;

      @media screen and (max-width: 912px) {
        top: 1060px;
      }

      @media screen and (max-width: 820px) {
        top: 880px;
      }

      @media screen and (max-width: 768px) {
        top: 720px;
      }
    }

    &__footerListTitle {
      padding-bottom: 8px;
    }

    &__iconFooter {
      display: flex;
      gap: 6px;
    }

    &__list {
      &--icon {
        min-width: 36px;
      }
    }
  }
`;

export const drawerStyle = {
  width: "300px",
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: "300px",
    boxSizing: "border-box",
    backgroundColor: `${palette.blackTable}`,
    color: `${palette.primaryLight}`,
    borderColor: `${palette.primary}`,
    overflowX: "hidden"
  }
};

export default SidebarStyled;
