import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { variables } from "config/variables";

const { fonts } = variables;

export const Styles = styled(MenuItem)(() => ({
  ".MuiTypography-root": {
    fontFamily: fonts["Poppins-El"],
    fontSize: "14px"
  }
}));
