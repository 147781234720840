import React from "react";

import TextFieldStyled from "./TextField.styles";
import { TextFieldProps as Props } from "./TextField.types";

const TextField: React.FC<Props> = props => {
  const { fieldState, field, config, className, style } = props;

  return (
    <TextFieldStyled
      {...field}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      type={config.type}
      label={config.label}
      variant={config.variant}
      size={config.size}
      margin={config.margin}
      fullWidth={config.fullWidth}
      focused={config.focused}
      InputProps={config.InputProps}
      className={className}
      multiline={config.multiline}
      rows={config.rows ?? 1}
      placeholder={config.placeholder ?? ""}
      style={style}
      disabled={config.disabled}
    />
  );
};

export default TextField;
