import { useAppSelector } from "hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { blockUnblockClient, fetchClientComments } from "./interviews.service";
import { approveOrRejectSeller } from "./interviews.service";
import { fetchAllClients, fetchClientInfo } from "./interviews.service";
import { fetchInterviews } from "./interviews.service";
import { fetchAllAuctionHouses } from "./interviews.service";

export const useFetchAllAuctionHouses = () => {
  const { user } = useAppSelector(state => state.authReducer);

  return useQuery(["auction-houses"], () => fetchAllAuctionHouses(), {
    enabled: user?.rol === 10,
    staleTime: 15 * 1000 * 60
  });
};

export const useFetchInterviews = (take = 50, skip = 0) => {
  const { user } = useAppSelector(state => state.authReducer);

  return useQuery(["interviews"], () => fetchInterviews(take, skip), {
    enabled: user?.rol === 10,
    staleTime: 15 * 1000 * 60
  });
};

export const useAllClients = (take = 50, skip = 0) => {
  const { user } = useAppSelector(state => state.authReducer);

  return useQuery(["all-clients"], () => fetchAllClients(take, skip), {
    enabled: user?.rol === 10,
    staleTime: 15 * 1000 * 60
  });
};

export const useFetchClientInfo = (id?: number) => {
  const { user } = useAppSelector(state => state.authReducer);

  return useQuery([id, "client"], () => fetchClientInfo(id), {
    enabled: user?.rol === 10 && !!id,
    staleTime: 15 * 1000 * 60
  });
};

export const useFetchClientComments = (id?: number) => {
  const { user } = useAppSelector(state => state.authReducer);

  return useQuery([id, "client-comments"], () => fetchClientComments(id), {
    enabled: user?.rol === 10 && !!id,
    staleTime: 15 * 1000 * 60
  });
};

export const useApproveOrRejectSellerMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(approveOrRejectSeller, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("interviews");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("interviews");
    }
  });
};

export const useBlockUnBlockMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(blockUnblockClient, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("all-clients");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("all-clients");
    }
  });
};
