import { variables } from "config/variables";
import styled from "styled-components";

import { devices } from "../../../config/devices";

const { shadows } = variables;

const Styles = styled.div`
  .MySeller {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &--button {
        display: flex;
        justify-content: center;
        margin-top: 2em;
      }

      &--form {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 24px;
        width: 100%;

        label {
          font-size: 20px;
          font-variant: all-petite-caps;
        }

        input:after {
          border-bottom: 1px solid #c3c3c3 !important;
        }

        @media ${devices.laptop} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }

      &--image {
        display: flex;
        justify-content: center;
      }
    }

    &__image {
      &--src {
        width: 240px;
        max-height: 240px;
        align-self: center;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: ${shadows.shadowCard};
        object-fit: cover;
        object-position: center right;
        padding: 8px;
        margin: 16px auto;
        border-radius: 50%;
        pointer-events: none;

        @media ${devices.tablet} {
          margin: 24px auto;
        }
      }

      &--skeleton {
        width: 240px;
        height: 240px;
      }
    }

    &__form {
      &--profile {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.5rem;

        @media ${devices.laptop} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          width: 100%;
        }
      }
    }
  }
`;

export default Styles;
