import SearchIcon from "@mui/icons-material/Search";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { DatePicker } from "antd";
import type { DatePickerProps } from "antd";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import React from "react";
import { useGetAllCountries } from "services/calendar/calendar.service.hooks";

import Styles from "./TableFilters.styles";
import { Search, StyledInputBase } from "./TableFilters.styles";
import { SearchIconWrapper } from "./TableFilters.styles";
import { TableFiltersProps as Props } from "./TableFilters.types";

const TableFilters: React.FC<Props> = props => {
  const { setQuery, setCountry, setStatus } = props;
  const { setDate, setAuctionType } = props;
  const { query, status, country, sellerUser } = props;
  const { auctionType } = props;
  const { data: countries, isLoading } = useGetAllCountries();

  const handleQueryChange = (event: any) => {
    setQuery(event.target.value);
  };

  const handleChangeCountry = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangeAuctionType = (event: SelectChangeEvent) => {
    setAuctionType(event.target.value as string);
  };

  const handleChangeDate: DatePickerProps["onChange"] = (_, dateString) => {
    setDate(dateString);
  };

  const renderDateFilters = () => {
    return (
      <DatePicker
        onChange={handleChangeDate}
        className="TableFilters__datePicker"
      />
    );
  };

  const renderCountryFilters = () => {
    return (
      <Select
        labelId="countryId"
        id="countryId"
        value={country}
        label="País"
        onChange={handleChangeCountry}
        sx={{ backgroundColor: "white" }}
      >
        {countries?.map((country: any) => (
          <MenuItem value={country?.id} key={country?.id}>
            {country?.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderAuctionTypeFilters = () => {
    return (
      <Select
        labelId="statusId"
        id="statusId"
        value={auctionType}
        label="Tipo de subasta"
        onChange={handleChangeAuctionType}
        defaultValue="0"
        sx={{ backgroundColor: "white" }}
      >
        <MenuItem value={"1"} key={1}>
          Subasta en vivo
        </MenuItem>
        <MenuItem value={"2"} key={2}>
          Subasta cronometrada
        </MenuItem>
      </Select>
    );
  };

  const renderStatusFilters = () => {
    return (
      <Select
        labelId="statusId"
        id="statusId"
        value={status}
        label="Estado"
        onChange={handleChangeStatus}
        defaultValue="0"
        sx={{ backgroundColor: "white" }}
      >
        <MenuItem value={"0"} key={0}>
          Todos los Estados
        </MenuItem>
        <MenuItem value={"Pendiente"} key={1}>
          Pendiente
        </MenuItem>
        <MenuItem value={"Cobrada"} key={2}>
          Cobrada
        </MenuItem>
      </Select>
    );
  };

  const renderSearchInput = () => {
    return (
      <Search>
        <SearchIconWrapper>
          <SearchIcon sx={{ opacity: 0.6 }} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          value={query}
          onChange={handleQueryChange}
        />
      </Search>
    );
  };

  return (
    <Styles className="TableFilters">
      {isLoading && <CircularProgress />}
      {renderSearchInput()}
      <div className="TableFilters__filtersContainer">
        {renderDateFilters()}
        {renderAuctionTypeFilters()}
        {!sellerUser ? renderCountryFilters() : null}
        {!sellerUser ? renderStatusFilters() : null}
      </div>
    </Styles>
  );
};

TableFilters.defaultProps = {};

export default TableFilters;
