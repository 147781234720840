import HomeIcon from "@mui/icons-material/Home";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { Breadcrumbs, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import useGlobals from "context/globals/globals.hooks";
import React, { useCallback, useState } from "react";

import Wishes from "./Wishes";
import { WishesContainerProps as Props } from "./Wishes.types";

const WishesContainer: React.FC<Props> = props => {
  const [currentWish, setCurrentWish] = useState<number | undefined>(undefined);
  const { selectedSubIndex, setSelectedIndex } = useGlobals();
  const { setSelectedSubIndex } = useGlobals();

  const renderBreadCrumb = () => {
    return (
      <div className="mb-8 flex justify-between" role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/admin"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => {
              setSelectedIndex(1);
              setSelectedSubIndex(0);
            }}
          >
            <PersonPinIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Clientes
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => setCurrentWish(undefined)}
          >
            {selectedSubIndex === 0
              ? "Solicitudes de deseos"
              : "Notificar deseos"}
          </Link>
          {currentWish && (
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {`Deseo - ${currentWish}`}
            </Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  };

  const renderContent = useCallback(() => {
    switch (selectedSubIndex) {
      case 0:
        return (
          <Wishes setCurrentWish={setCurrentWish} currentWish={currentWish} />
        );
      default:
        return (
          <Wishes setCurrentWish={setCurrentWish} currentWish={currentWish} />
        );
    }
  }, [currentWish, selectedSubIndex]);

  return (
    <section className="flex flex-col">
      {renderBreadCrumb()}
      {renderContent()}
    </section>
  );
};

WishesContainer.defaultProps = {};

export default WishesContainer;
