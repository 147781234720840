import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { devices } from "config/devices";
import styled from "styled-components";

import { variables } from "../../config/variables";

const { fonts, palette } = variables;

const Styles = styled.div`
  .MyBids {
    &__container {
      margin-top: 24px;
      font-family: "Poppins Rg";

      @media ${devices.mobileL} {
        padding-left: 32px;
        padding-right: 32px;
      }

      @media ${devices.tablet} {
        padding-left: 128px;
        padding-right: 128px;
      }

      @media ${devices.laptopL} {
        padding-left: 256px;
        padding-right: 256px;
      }

      &--tabs {
        font-size: 16px;
        margin-top: 48px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-family: ${fonts["Poppins-Lt"]};
        text-align: center;
        gap: 8px;
        flex-direction: row;

        @media ${devices.tablet} {
          justify-content: space-around;
          font-size: 18px;
        }
      }

      &--fields {
        padding-left: 48px;
        padding-right: 48px;
        margin-top: 48px;
        margin-bottom: 48px;

        @media ${devices.mobileL} {
          padding-left: 64px;
          padding-right: 64px;
          margin-top: 64px;
          margin-bottom: 64px;
        }
      }
    }

    &__title {
      font-family: "Poppins Sb";
      color: ${palette.primary};
      font-size: 19px;
      line-height: 28px;
      text-align: center;

      @media ${devices.mobileL} {
        font-size: 28px;
      }
    }

    &__text {
      &--primary {
        color: ${palette.primary};
      }

      &--default {
        color: ${palette.gray.primary};
      }
    }

    &__background {
      background-color: ${palette.custom};
    }
  }
`;

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: palette.blackCharcoal,
    color: palette.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: palette.neutro.custom
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));

export default Styles;
