import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import TextField from "components/Globals/TextField/TextField";
import React from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";

import { addStaggered } from "../../../services/Auctioners/Auctioners.service";
import { useGetAuctionById } from "../../../services/Auctioners/Auctioners.service.hooks";
import { fail } from "../../../utils/Swal";
import ListPrices from "../ListPrices/ListPrices";
import { formList } from "./AddStaggered.helper";
import Styles from "./AddStaggered.styles";
import { AddStaggeredProps } from "./AddStaggered.types";

function AddStaggered(props: AddStaggeredProps) {
  const { auctionId } = props;
  const { data: auctionData, refetch } = useGetAuctionById(auctionId);
  const { prices } = auctionData ?? {};

  const { control, handleSubmit, reset } = useForm();

  const handleFormSend = async (values: FieldValues) => {
    let errorMessage = "Hubo un error al actualizar la subasta";
    try {
      const hasSamePrice = prices?.some(a => a?.price === +values.price);
      const hasIncrement = prices?.some(a => a?.offert === +values.offert);
      if (hasSamePrice || hasIncrement) {
        errorMessage = "No puedes agregar dos incrementos con el mismo valor";
        throw new Error(errorMessage);
      }
      //@ts-ignore
      return await addStaggered({
        ...values,
        auction_id: +auctionId,
        status: true
      }).then(() => {
        reset();
        refetch();
      });
    } catch (error) {
      fail(errorMessage);
      return null;
    }
  };

  return (
    <Styles className="AddStaggered">
      <div className="AddStaggered__separator--components w-100 divide-x">
        <form onSubmit={handleSubmit(handleFormSend)}>
          <h2 className="AddStaggered__title">Incrementos de puja</h2>
          <div className="grid grid-cols-2 gap-6 w-100">
            {formList.map((item, idx) => (
              <Controller
                name={item.label}
                control={control}
                rules={item.validationRules}
                defaultValue=""
                key={idx}
                render={({ field, fieldState }) => (
                  <TextField
                    field={field}
                    fieldState={fieldState}
                    config={{
                      type: item.type,
                      label: item.placeholder,
                      variant: "standard",
                      margin: "dense",
                      fullWidth: true,
                      focused: true,
                      placeholder: item._placeholder
                    }}
                  />
                )}
              />
            ))}
          </div>
          <div className="AddStaggered__container--button">
            <ButtonLarge type="submit" variant="outlined">
              Continuar
            </ButtonLarge>
          </div>
        </form>

        <div className="AddStaggered__container--prices">
          <ListPrices prices={prices ?? []} toDelete={true} />
        </div>
      </div>
    </Styles>
  );
}

export default AddStaggered;
