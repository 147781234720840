import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { CalendarStyledProps as Props } from "./Calendar.types";
const { palette, fonts } = variables;

const CalendarStyled = styled.div<Props>`
  min-height: 80vh;
  margin-bottom: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  margin: 0px auto;
  margin-top: 4em;

  @media screen and (max-width: ${devices.laptopL}) {
    max-width: 1200px;
  }

  .Calendar {
    &__title--container {
      width: 96%;
      display: flex;
      justify-content: start;
    }
    &__title {
      font-size: 1.5rem;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      font-weight: bold;
      letter-spacing: 0.1rem;
      display: flex;
      align-items: start;
      font-variant: all-small-caps;

      @media ${devices.tablet} {
        font-size: 1.875rem;
      }

      @media ${devices.laptop} {
        font-size: 2.5rem;
      }

      @media ${devices.desktop} {
        font-size: 32px;
      }
    }

    &__description {
      font-family: ${fonts["Poppins-El"]};
      font-size: 14px;
      color: ${palette.blackCharcoal};
      width: 56%;
      margin-top: 24px;

      @media screen and (max-width: 768px) {
        width: 80%;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    &__content {
      margin: 64px 0;
      display: flex;
      align-items: flex-start;
      width: 96%;
      gap: 25px;

      @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        gap: 32px;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }

    &__auctions {
      width: 80%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
  }
`;

export default CalendarStyled;
