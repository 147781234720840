import { variables } from "config/variables";
import styled from "styled-components";

import { NotificationBellStyledProps as Props } from "./NotificationBell.types";

const { palette } = variables;

const NotificationBellStyled = styled.div<Props>`
  position: relative;
  z-index: 99;

  .NotificationBell {
    &__tag {
      position: absolute;
      top: 0;
      right: 0;
      margin: -8px -8px 0 0;
      border-radius: 50%;
      width: 24px;
      background-color: ${palette.primary};
      color: ${palette.white};
      border: 1px solid white;
      font-size: 12px;
      padding: 2px 0;
    }
  }
`;

export default NotificationBellStyled;
