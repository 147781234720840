import api, { buildHeaders } from "../Api";
import { SendMessage, UserChat } from "./userChat.service.types";
import { UserChatDetail } from "./userChat.service.types";

//@ts-ignore
const SERVER_URL = process.env["REACT_APP_SERVER_URL"];
const BASE_URL = `${SERVER_URL}conversation`;

export const fetchUserChats = async (query: string): Promise<UserChat[]> => {
  return await api
    .get(`/conversation?search=${query}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchUserChatById = async (
  id?: number,
  pageParam?: string
): Promise<UserChatDetail> => {
  return await api
    .get(`${BASE_URL}/${id}?take=200?skip=${pageParam ?? 0}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const postSendMessage = async (messageBody: SendMessage) => {
  const { id, data } = messageBody;
  await api.post(`${BASE_URL}/sendMessage/${id}`, data, {
    headers: await buildHeaders()
  });
};

export const postCreateChat = async (id: number) => {
  await api.post(
    `${BASE_URL}/`,
    { userId: id },
    { headers: await buildHeaders() }
  );
};
