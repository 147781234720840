import Pusher from "pusher-js";

export const pusher = new Pusher("ab3d5955933be814ebea", {
  cluster: "us2"
});

// export const authPusher = new Pusher("ab3d5955933be814ebea", {
//   cluster: "us2",
//   forceTLS: true,
//   authEndpoint: `${SERVER_URL}broadcast`,
//   auth: {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token ? `Bearer ${token}` : "",
//       Accept: "application/json"
//     }
//   }
// });
