import { FormHelperText, InputBase, InputLabel } from "@mui/material";
import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts } = variables;

const InputStyles = styled(InputBase)(props => ({
  // width: "55%",
  "label + &": {
    marginTop: "16px"
  },
  "& .MuiInputBase-input": {
    marginTop: "4px",
    borderRadius: 16,
    position: "relative",
    backgroundColor: `${palette["white-hex"]}`,
    border: `1px solid ${palette.primary}`,
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition:
      "transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    fontFamily: `${fonts["Poppins-El"]}`,
    letterSpacing: "0.4px",
    "&:focus": {
      borderRadius: 16,
      borderColor: `${palette.primaryLight}`,
      boxShadow: `0 0 0 0.2rem ${palette.gray.second4}`
    }
  }
}));

const InputLabelStyles = styled(InputLabel)(() => ({
  fontFamily: `${fonts["Poppins-Md"]}`,
  color: `${palette.primary} !important`,
  fontSize: "18px",
  letterSpacing: "0.4px",
  fontWeight: 400
}));

const FormHelperTextStyles = styled(FormHelperText)(() => ({
  fontFamily: `${fonts["Poppins-Rg"]}`
}));

const FormControlStyles = {
  // width: "300px",
  [`@media screen and ${devices.laptopL}`]: {
    // width: "100%"
    width: "56%"
  }
};

export {
  InputStyles,
  InputLabelStyles,
  FormHelperTextStyles,
  FormControlStyles
};
