import styled from "styled-components";

import { devices } from "../../../config/devices";
import { variables } from "../../../config/variables";

const { palette, fonts } = variables;

const Styles = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 8px;

  &:hover {
    cursor: pointer;
    transform: scale(1.016);
    color: ${palette.primary};
  }

  .NavItem {
    &__icon {
      color: ${palette.primary};
      fill: ${palette.primary};
      height: 32px;
      // width: 32px;
      font-size: 22px;
      display: flex;
      align-items: center;
    }

    &__icon-buttons {
      display: flex;
      justify-content: space-between;
      width: 24%;
      min-width: 224px;
      align-items: center;

      /* TODO: config */
      @media ${devices.laptop} {
      }

      &__text {
        font-size: 14px;
        clear: both;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        font-family: ${fonts["Poppins-El"]};
      }
    }
  }
`;

export default Styles;
