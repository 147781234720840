import React, { useState } from "react";
import { Button, Popconfirm } from "antd";

import InputModal from "components/InputModal";
import {
  useDeleteSubcategory,
  useEditSubcategory
} from "services/Categories/categories.service.hooks";
import { Subcategory } from "services/Categories/categories.service.types";

type SubcategoryItemProps = {
  subcategory: Subcategory;
};

const SubcategoryItem = ({ subcategory }: SubcategoryItemProps) => {
  const [isEditSubcategoryModalOpen, setIsEditSubcategoryModalOpen] =
    useState(false);

  const { mutate: editSubcategory } = useEditSubcategory();
  const { mutate: deleteSubcategory } = useDeleteSubcategory();

  const { name, id } = subcategory;

  return (
    <>
      <div className="p-3 border-[1px] rounded-lg flex justify-between items-center">
        <span>{name}</span>
        <div className="flex items-center">
          <Button onClick={() => setIsEditSubcategoryModalOpen(true)}>
            Editar
          </Button>
          <Popconfirm
            placement="topLeft"
            title="Seguro desea eliminar?"
            onConfirm={() => deleteSubcategory(id)}
            okText="Si"
            cancelText="No"
          >
            <Button>Eliminar</Button>
          </Popconfirm>
        </div>
      </div>
      <InputModal
        title="Editar subcategoría"
        open={isEditSubcategoryModalOpen}
        onConfirm={async (newName: string) => {
          setIsEditSubcategoryModalOpen(false);
          await editSubcategory({ id, newName });
        }}
        onCancel={() => setIsEditSubcategoryModalOpen(false)}
        initialValue={name}
        placeholder="Nombre"
      />
    </>
  );
};

export default SubcategoryItem;
