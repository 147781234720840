import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { updateCurrentAuction } from "redux/actions/auctionActions";

import { offerAuction, reuseAuction } from "./Auctioners.service";

export const useOfferAuction = () => {
  const queryClient = useQueryClient();

  return useMutation(offerAuction, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("offer");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("offer");
    }
  });
};

export const useReuseAuction = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(reuseAuction, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("my-auctions");
      await queryClient.cancelQueries("auctions");
    },
    onSuccess: response => {
      const { data, code } = response;
      if (typeof code !== undefined && code >= 400) return;
      dispatch(updateCurrentAuction(data));
      document.getElementsByName("CreateAuction")?.forEach(e => {
        e.click();
      });
    },
    onError: e => {
      fail("Ocurrió un problema al reutilizar la subasta");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("my-auctions");
      queryClient.invalidateQueries("auctions");
    }
  });
};
