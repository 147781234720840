import styled from "styled-components";

import { variables } from "../../config/variables";
import { SellerProfileStyledProps as Props } from "./SellerProfile.types";

const { fonts, palette } = variables;

const SellerProfileStyled = styled.div<Props>`
  min-height: 100vh;
  width: 88%;
  margin: 48px auto;

  .SellerProfile {
    &__container {
      width: 88%;
      margin: 48px auto;

      &--tabs {
        margin-top: 64px;
      }
    }

    &__comments {
      margin: 48px 0;
    }

    &__items {
      &-card {
        width: 23.8%;
      }

      &-content {
        margin-top: 64px;
      }

      &-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
      }
    }

    &__title {
      font-size: 24px;
      font-family: ${fonts["Poppins-Lt"]};
      margin-left: 1%;
      margin-bottom: 32px;
    }

    &__label {
      font-size: 14px;
      font-family: ${fonts["Poppins-Lt"]};
      line-height: 24px;
      margin: 16px 8px;
    }

    &__form {
      width: 64%;
      display: flex;
      flex-direction: column;
    }

    &__button {
      margin: 32px 0;
      align-self: flex-end;
    }
  }
`;

export const style = {
  display: "flex",
  borderRadius: 4,
  borderColor: palette.primary,
  marginTop: 3.2
};

export default SellerProfileStyled;
