import { Tab } from "@headlessui/react";
import { Box, FormControlLabel, Radio } from "@mui/material";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import InputRadio from "components/Globals/InputRadio/InputRadio";
import SelectCustom from "components/Globals/Select/Select";
import SelectMenuItem from "components/Globals/SelectMenuItem/SelectMenuItem";
import SkeletonInput from "components/Globals/SkeletonComponent/SkeletonInput/SkeletonInput";
import TextField from "components/Globals/TextField/TextField";
import TextFieldFile from "components/Globals/TextFieldFile/TextFieldFile";
import EmptyState from "components/emptyState/EmptyState";
import { useAppSelector } from "hooks";
import React, { useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useCategories } from "services/Categories/categories.service.hooks";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import { useGetAllCountries } from "services/calendar/calendar.service.hooks";

import AddPrima from "../AddPrima/AddPrima";
import { primaInitialData } from "../AddPrima/AddPrima.helpers";
import { Prima } from "../AddPrima/AddPrima.types";
import AddItem from "../addItem/AddItem";
import AddStaggered from "../addStaggered/AddStaggered";
import { formListDates } from "./CreateAuction.helper";
import { validationRules, validationRulesTwo } from "./CreateAuction.helper";
import { handleFormSend, handleFormSend2 } from "./CreateAuction.helper";
import Styles from "./CreateAuction.styles";

const CreateAuction = (props: any) => {
  const { user } = useAppSelector(state => state.authReducer);
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const dispatch = useDispatch();
  const { id: auctionId, prima } = currentAuction ?? {};
  const { data: Auction, isLoading: isLoadingDataFormOne } =
    useGetAuctionById(auctionId);
  const { data: countries, isLoading: isLoadingCountryFormOne } =
    useGetAllCountries();

  const prePrima = prima ?? primaInitialData;
  const [isError, setIsError] = useState(false);
  const [primaData, setPrimaData] = useState<Prima[]>(prePrima);
  const [type, setType] = useState(Auction?.type ?? 1);
  const [accessType, setAccessType] = useState(Number(Auction?.access ?? 1));

  const { control, watch, handleSubmit } = useForm();

  const { data: categories, isLoading: isLoadingFormOne } = useCategories();
  const watchCategoriesId = watch("categories_id");

  const emptyComponent = (
    <EmptyState customTitle="Completa la primera etapa antes." />
  );

  //@ts-ignore
  const { subcategories: subCategories } =
    categories?.find(
      (category: any) => category?.id === Number(watchCategoriesId)
    ) ??
    categories?.[0] ??
    [];

  const renderAccessType = () =>
    isLoadingFormOne || isLoadingDataFormOne ? (
      <SkeletonInput />
    ) : (
      <Box display="flex" alignItems="center">
        <label style={{ marginRight: "1em", fontSize: "15px", width: "170px" }}>
          Tipo de acceso
        </label>
        <Controller
          name="access"
          control={control}
          defaultValue={Number(Auction?.access ?? 1)}
          render={({ field, fieldState }) => (
            <InputRadio
              field={field}
              fieldState={fieldState}
              config={{
                label: "Tipo de acceso",
                row: true
              }}
              value={accessType}
              onChange={e => {
                setAccessType(parseInt(e.target.value));
              }}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Acceso libre"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Acceso por lista"
              />
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="Acceso por tarjeta"
              />
              <FormControlLabel
                value={4}
                control={<Radio />}
                label="Acceso por código"
              />
            </InputRadio>
          )}
        />
      </Box>
    );

  const renderTabs = () => {
    return (
      <Tab.List className="CreateAuction__container--tabs">
        <Tab
          className={({ selected }) =>
            `CreateAuction__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
          name="Etapa1"
        >
          Etapa 1
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
        <Tab
          className={({ selected }) =>
            `CreateAuction__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
          name="Etapa2"
        >
          Etapa 2
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
        <Tab
          className={({ selected }) =>
            `CreateAuction__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
          name="Etapa3"
        >
          Etapa 3
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
        <Tab
          className={({ selected }) =>
            `CreateAuction__component--tabs ${
              selected ? "text-primary" : "text-black"
            }`
          }
          name="Etapa4"
        >
          Etapa 4
          <hr className="w-[180px] h-[4px] primary" />
        </Tab>
      </Tab.List>
    );
  };

  const handleForm = async (values: FieldValues) => {
    await handleFormSend(values, type, dispatch, user.id, currentAuction?.id);
  };

  const handleForm2 = async (values: FieldValues) => {
    const formattedPrima = primaData.map(item => {
      const { prima } = item ?? {};
      const val =
        prima && `${prima}`.includes("%")
          ? +`${prima}`.replace("%", "") / 100
          : undefined;

      return { ...item, prima: val };
    });

    const allValues = {
      ...values,
      prima: JSON.stringify(formattedPrima),
      access: accessType
    };
    await handleFormSend2(allValues, dispatch, currentAuction?.id);
  };

  const renderFormOne = () => {
    const renderInputNameAndCountry = () =>
      isLoadingFormOne || isLoadingDataFormOne || isLoadingCountryFormOne ? (
        <>
          <SkeletonInput />
          <SkeletonInput />
        </>
      ) : (
        <>
          <Controller
            name="title"
            control={control}
            rules={validationRules.title}
            defaultValue={Auction?.title ?? ""}
            render={({ field, fieldState }) => (
              <TextField
                field={field}
                fieldState={fieldState}
                config={{
                  type: "text",
                  label: "Titulo",
                  variant: "standard",
                  margin: "dense",
                  fullWidth: true,
                  focused: true,
                  placeholder: "Ejemplo: Arte Sudamericano"
                }}
              />
            )}
          />

          {countries && countries?.length > 0 ? (
            <Controller
              name="country_id"
              control={control}
              defaultValue={Auction?.country_id ?? 1}
              render={({ field, fieldState }) => (
                <SelectCustom
                  field={field}
                  fieldState={fieldState}
                  config={{
                    label: "Paises"
                  }}
                >
                  {countries?.map((country: any) => (
                    <SelectMenuItem value={country?.id} key={country?.id}>
                      {country?.name}
                    </SelectMenuItem>
                  ))}
                </SelectCustom>
              )}
            />
          ) : null}
        </>
      );

    const renderInputDate = () =>
      isLoadingFormOne || isLoadingDataFormOne ? (
        <>
          <SkeletonInput />
          <SkeletonInput />
        </>
      ) : (
        formListDates.map((item, idx) => (
          <Controller
            key={idx}
            name={item.name}
            control={control}
            rules={{
              required: item?.required
            }}
            defaultValue={idx === 0 ? Auction?.date_ini : Auction?.date_end}
            render={({ field, fieldState }) => (
              <TextField
                field={field}
                fieldState={fieldState}
                config={{
                  type: item.type,
                  label: item.placeholder,
                  variant: "standard",
                  fullWidth: true,
                  focused: true
                }}
              />
            )}
          />
        ))
      );

    const renderInputStream = () =>
      isLoadingFormOne || isLoadingDataFormOne ? (
        <>
          <SkeletonInput />
          <SkeletonInput />
        </>
      ) : (
        <>
          <Controller
            name="streamer"
            control={control}
            rules={validationRules.streamer}
            defaultValue={Auction?.streamer ? 1 : 0}
            render={({ field, fieldState }) => (
              <SelectCustom
                field={field}
                fieldState={fieldState}
                config={{
                  label: "Stremeable"
                }}
              >
                <SelectMenuItem value={1}>Si</SelectMenuItem>
                <SelectMenuItem value={0}>No</SelectMenuItem>
              </SelectCustom>
            )}
          />
          <Controller
            name="date"
            control={control}
            defaultValue={Auction?.date ?? ""}
            render={({ field, fieldState }) => (
              <TextField
                field={field}
                fieldState={fieldState}
                config={{
                  type: "datetime-local",
                  label: "Fecha y Hora",
                  variant: "standard",
                  fullWidth: true,
                  focused: true
                }}
              />
            )}
          />
        </>
      );

    const renderCategories = () =>
      isLoadingFormOne || isLoadingDataFormOne ? (
        <>
          <SkeletonInput />
          <SkeletonInput />
        </>
      ) : (
        <>
          {categories && categories?.length > 0 && (
            <Controller
              name="categories_id"
              control={control}
              defaultValue={Auction?.categories_id ?? 1}
              render={({ field, fieldState }) => (
                <SelectCustom
                  field={field}
                  fieldState={fieldState}
                  config={{
                    label: "Categoria principal"
                  }}
                >
                  {categories?.map((category: any) => (
                    <SelectMenuItem value={category?.id} key={category?.id}>
                      {category?.name}
                    </SelectMenuItem>
                  ))}
                </SelectCustom>
              )}
            />
          )}
          {subCategories?.length > 0 && (
            <Controller
              name="sub_categories_id"
              control={control}
              defaultValue={Auction?.sub_categories_id ?? 1}
              rules={validationRulesTwo.subcategory}
              render={({ field, fieldState }) => (
                <SelectCustom
                  field={field}
                  fieldState={fieldState}
                  config={{
                    label: "Sub Categoria"
                  }}
                >
                  <SelectMenuItem value={0}>
                    Selecciona una opción
                  </SelectMenuItem>
                  {subCategories?.map((categorie: any) => (
                    <SelectMenuItem value={categorie?.id} key={categorie?.id}>
                      {categorie?.name}
                    </SelectMenuItem>
                  ))}
                </SelectCustom>
              )}
            />
          )}
        </>
      );

    const renderInputType = () =>
      isLoadingFormOne || isLoadingDataFormOne ? (
        <SkeletonInput />
      ) : (
        <Box display="flex" alignItems="center">
          <label
            style={{ marginRight: "1em", fontSize: "15px", width: "170px" }}
          >
            Tipo de subasta
          </label>
          <Controller
            name="auctionType"
            control={control}
            defaultValue={Auction?.type ?? 1}
            render={({ field, fieldState }) => (
              <InputRadio
                field={field}
                fieldState={fieldState}
                config={{
                  label: "Tipo de subasta",
                  row: true
                }}
                value={type}
                onChange={e => {
                  setType(parseInt(e.target.value));
                }}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="En vivo"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Cronometrada"
                />
              </InputRadio>
            )}
          />
        </Box>
      );

    return (
      <>
        {/* {resetAuction()} */}
        {/* pickAuctionType() */}
        <form
          onSubmit={handleSubmit(handleForm)}
          className="CreateAuction__container--form"
        >
          <div
            className="CreateAuction__container"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {renderInputType()}
          </div>
          <div className="CreateAuction__container--fields">
            {renderInputNameAndCountry()}
          </div>
          <div className="CreateAuction__container--fields">
            {type === 2 ? renderInputDate() : renderInputStream()}
          </div>
          <div className="CreateAuction__container--fields">
            {renderCategories()}
          </div>
          <div className="CreateAuction__container--button">
            <ButtonLarge type="submit" variant="contained">
              Continuar
            </ButtonLarge>
          </div>
        </form>
      </>
    );
  };

  const renderFormTwo = () => {
    if (!currentAuction?.id) {
      return emptyComponent;
    }

    return (
      <>
        <form
          onSubmit={handleSubmit(handleForm2)}
          className="CreateAuction__container--form"
        >
          <div className="CreateAuction__container--fields">
            <Controller
              name="description"
              control={control}
              defaultValue={Auction?.description ?? ""}
              render={({ field, fieldState }) => (
                <TextField
                  field={field}
                  fieldState={fieldState}
                  config={{
                    type: "text",
                    label: "Descripción",
                    variant: "standard",
                    margin: "dense",
                    fullWidth: true,
                    focused: true,
                    multiline: true,
                    rows: 2,
                    placeholder:
                      "Ej: Esta subasta en vivo presenta obras de arte sudamericano. Tenemos una gran selección de óleos originales, litografías, serigrafías, pinturas, esculturas y mucho más de los principales artistas modernos y contemporáneos del mundo. Gracias por elegirnos. Estamos aquí para ayudarle. Que el martillo caiga a tu favor."
                  }}
                />
              )}
            />

            <Controller
              name="condition"
              control={control}
              defaultValue={Auction?.condition ?? ""}
              render={({ field, fieldState }) => (
                <TextField
                  field={field}
                  fieldState={fieldState}
                  config={{
                    type: "text",
                    label: "Términos y Condiciones",
                    variant: "standard",
                    margin: "dense",
                    fullWidth: true,
                    focused: true,
                    multiline: true,
                    rows: 2,
                    placeholder:
                      "Ej: Formas de pago, envio y embalaje, compra, pago adeudado, oferta ganadora, garantias, etc."
                  }}
                />
              )}
            />
          </div>
          <div className="CreateAuction__container--fields-images">
            <Controller
              name="url_logo_1"
              control={control}
              defaultValue={{}}
              render={({ field }) => (
                <TextFieldFile
                  field={field}
                  config={{ label: "Imagen de presentación 1 (Logo)" }}
                  image={Auction?.url_logo_1}
                  imagenAction={true}
                />
              )}
            />

            <Controller
              name="url_logo_2"
              control={control}
              defaultValue={{}}
              render={({ field }) => (
                <TextFieldFile
                  field={field}
                  config={{ label: "Imagen de presentación 2 (Logo)" }}
                  image={Auction?.url_logo_2}
                  imagenAction={true}
                />
              )}
            />

            <Controller
              name="url_logo_3"
              control={control}
              defaultValue={{}}
              render={({ field }) => (
                <TextFieldFile
                  field={field}
                  config={{ label: "Imagen de presentación 3 (Logo)" }}
                  image={Auction?.url_logo_3}
                  imagenAction={true}
                />
              )}
            />
          </div>

          <div className="mt-4 mr-auto ml-auto">{renderAccessType()}</div>
          <AddPrima
            data={primaData}
            setData={setPrimaData}
            isError={isError}
            setIsError={setIsError}
          />
          <div className="CreateAuction__container--button">
            <ButtonLarge
              type="submit"
              variant="contained"
              loading={isLoadingDataFormOne}
            >
              Continuar
            </ButtonLarge>
          </div>
        </form>
      </>
    );
  };

  return (
    <Styles>
      <Tab.Group>
        {renderTabs()}
        <Tab.Panels>
          <Tab.Panel>{renderFormOne()}</Tab.Panel>
          <Tab.Panel>{renderFormTwo()}</Tab.Panel>
          <Tab.Panel>
            {currentAuction?.id ? (
              <AddStaggered auctionId={currentAuction?.id} />
            ) : (
              emptyComponent
            )}
          </Tab.Panel>
          <Tab.Panel>
            {currentAuction?.id ? (
              <AddItem auctionId={currentAuction?.id} />
            ) : (
              emptyComponent
            )}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Styles>
  );
};
export default CreateAuction;
