import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, shadows, fonts } = variables;

const Styles = styled.div`
  .AuctionButtons {
    &__adminButton {
      width: 64%;
      min-width: 120px;
    }

    &__buttonsContainer {
      height: 184px;
      width: 564px;
    }

    &__container {
      &--global {
        flex: 0 0 210px;
        display: flex;
        border: 2px solid ${palette.whiteDividerColor};
        padding: 16px 8px;
        box-shadow: ${shadows.shadowCard};
        bottom: 0;
        right: 0;
        background-color: ${palette.white};
        z-index: 999;
        display: flex;
        gap: 8px;

        @media ${devices.laptop} {
          flex: 0 0 210px;
          padding: 16px;
        }

        &-noOwner {
          justify-content: flex-end;
          padding-top: 56px;
        }
      }

      &--right {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        &-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 16px;

          @media ${devices.laptop} {
            width: 80%;
            min-width: 70%;
          }

          @media ${devices.laptopL} {
            min-width: 60%;
            width: 75%;
          }

          @media ${devices.desktop} {
            min-width: 60%;
            width: 70%;
          }
        }

        &-noOwner {
          min-width: 480px;
          width: auto;

          @media ${devices.laptop} {
            min-width: 65%;
            width: 75%;
          }

          @media ${devices.laptopL} {
            min-width: 50%;
            width: 70%;
          }

          @media ${devices.desktop} {
            min-width: 50%;
            width: 60%;
          }
        }
      }

      &--left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 4px;
        max-height: 160px;

        @media (min-width: 1200px) {
          width: 240px;
        }

        @media (min-width: 1600px) {
          width: 300px;
        }
      }

      &--actions {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
      }

      &--price {
        display: flex;
        gap: 4px;
        width: 100%;
        justify-content: flex-end;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
          0 1px 2px 0 rgba(0, 0, 0, 0.06);
        height: 40px;
        align-items: center;
        border: 2px solid ${palette.whiteDividerColor};
        border-radius: 8px;
        padding: 8px 16px;
        position: relative;
        height: auto;

        @media ${devices.laptopL} {
          gap: 8px;
        }
      }
    }

    &__component {
      &--span {
        background-color: ${palette.blackCharcoal};
        border: 2px solid ${palette.primary};
        color: ${palette.white};
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 40px;
        border-radius: 8px;
        box-shadow: ${shadows.shadowCard};
        font-size: 24px;
        font-family: ${fonts["Poppins-Sb"]};

        @media ${devices.laptopL} {
          font-size: 32px;
        }
      }

      &--alert {
        color: ${palette.white};
        position: absolute;
        left: 8px;
        bottom: 8px;
      }

      &--button {
        width: 100%;
        font-family: ${fonts["Poppins-Md"]};
        font-size: 14px;
        letter-spacing: 0.6px;
        text-transform: uppercase;

        @media ${devices.laptop} {
          font-size: 16px;
        }

        @media ${devices.laptopL} {
          font-size: 20px;
        }

        &-icon {
          font-size: 32px;
        }

        &-success {
          background-color: ${palette.three};
        }
      }

      &--icon {
        color: ${palette.white};
        font-size: 14px;

        @media ${devices.laptop} {
          font-size: 16px;
        }

        &-secondary {
          color: ${palette.primary};
        }
      }

      &--timer {
        background-color: ${palette.white};
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 8px;
        height: 32px;
        width: 100%;
        border: 1px solid ${palette.dividerColor};
        border-radius: 12px;
        box-shadow: ${shadows.shadowCard};
        margin: 0 auto 8px auto;

        &-text {
          font-size: 14px;
          font-family: ${fonts["Poppins-Lt"]};
          margin: 16px 4%;
        }
      }
    }
  }
`;

export default Styles;
