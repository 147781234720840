import ArticleIcon from "@mui/icons-material/Article";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import React from "react";

import Styles from "./AddItemByBatch.styles";
import { AddItemByBatchProps as Props } from "./AddItemByBatch.types";

const AddItemByBatch: React.FC<Props> = props => {
  const { uploadType, setUploadType } = props;

  return (
    <Styles className="AddItemByBatch">
      <Box sx={{ display: "flex", justifyItems: "flex-start" }}>
        <BottomNavigation
          showLabels
          value={uploadType}
          onChange={(_, newValue) => {
            setUploadType(newValue);
          }}
          sx={{ gap: 4, borderBottom: 1, borderBottomColor: "hsl(0, 0%, 90%)" }}
        >
          <BottomNavigationAction
            label="Agregar lotes en conjunto"
            icon={<UploadFileIcon />}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              width: 240
            }}
          />
          <BottomNavigationAction
            label="Agregar un único lote"
            icon={<ArticleIcon />}
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              width: 240
            }}
          />
        </BottomNavigation>
      </Box>
    </Styles>
  );
};

AddItemByBatch.defaultProps = {};

export default AddItemByBatch;
