import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import { useAppSelector } from "hooks";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { addFavorite } from "services/Auctioners/Auctioners.service";
import { useGetMyFavorites } from "services/Auctioners/Auctioners.service.hooks";
import { useGetFavoriteById } from "services/Auctioners/Auctioners.service.hooks";
import { useGetSellerData } from "services/Auth/Auth.services.hooks";

import ListPrices from "../ListPrices/ListPrices";
import Styles from "./AuctionDetails.styles";
import { AuctionDetailsProps } from "./AuctionDetails.types";

const imagePlaceHolder =
  "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png";

const AuctionDetails = (props: AuctionDetailsProps) => {
  const { auctionData, prices } = props ?? {};
  const { currentAuction } = useAppSelector(state => state.auctionsReducer);
  const { prima: primas } = currentAuction ?? {};
  const { condition, terms_cond, user_id, id } = auctionData ?? {};

  const { data } = useGetFavoriteById(id);
  const { data: responseFavorite } = data ?? {};
  const { data: seller, isLoading } = useGetSellerData(user_id);
  const { refetch } = useGetMyFavorites();
  const { name_business, location, photo, logo } = seller ?? {};
  const image = photo ?? logo;

  const [isFavorite, setIsFavorite] = useState(false);

  const renderConditions = () => {
    return (
      <>
        <hr className="AuctionDetails__divider" />
        <h2 className="AuctionDetails__title">Condiciones</h2>
        <h2 className="AuctionDetails__subtitle">
          {condition ? parse(condition) : `Vendedor premium 19.75%`}
        </h2>
      </>
    );
  };

  const renderPrima = () => {
    return (
      <>
        <hr className="AuctionDetails__divider" />
        <h2 className="AuctionDetails__title">Prima</h2>
        {primas?.map(item => {
          const { prima = "0", rangoInferior, rangoSuperior } = item ?? {};
          if (rangoInferior === undefined) return <></>;
          const primaVal = `${+prima * 100}%`;
          const text = rangoSuperior
            ? `${primaVal} desde $${rangoInferior} hasta un máximo de $${rangoSuperior}`
            : `${primaVal} desde $${rangoInferior} sin límite.`;
          return <p>{text}</p>;
        })}
        <hr className="AuctionDetails__divider" />
      </>
    );
  };

  const renderTerms = () => {
    return (
      <>
        <h2 className="AuctionDetails__title mb-4">Términos y Condiciones</h2>
        <h2 className="AuctionDetails__subtitle">
          {terms_cond !== "true" && !!terms_cond
            ? parse(terms_cond)
            : `All items start at just $1!? We have Watches, Jewelry, Gems, Coins,
            Memorabilia, Art, and much more to win!! Bid early and Bid often
            with SAA!!All items start at just $1!? We have Watches, Jewelry,
            Gems, Coins, Memorabilia, Art, and much more to win!! Bid early and
            Bid often with SAA!!All items start at just $1!? We have Watches,
            Jewelry, Gems, Coins, Memorabilia, Art, and much more to win!! Bid
            early and Bid often with SAA!!`}
        </h2>
      </>
    );
  };

  const handleAddFavorite = async () => {
    setIsFavorite(!isFavorite);
    await addFavorite(id, { type: "PERSON" });
    refetch();
  };

  const handleNavigate = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/auctionProfile/${user_id}`);
  };

  const renderUserImage = () => {
    return (
      <div className="AuctionDetails__user-data">
        <img
          className="AuctionDetails__image"
          alt="userLogo"
          src={image ? image : imagePlaceHolder}
          onClick={handleNavigate}
        />
        <div className="AuctionsDetails__user-data__info">
          <h2 className="AuctionDetails__title">{name_business ?? ""}</h2>
          <h2 className="AuctionDetails__subtitle">
            {location ? `${location}` : `Camarillo, CA, United States`}
          </h2>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (responseFavorite) setIsFavorite(responseFavorite);
  }, [id, responseFavorite]);

  const renderContent = () => {
    return (
      <div className="AuctionDetails__content">
        <div className="AuctionDetails__container">
          {renderUserImage()}
          <div className="AuctionDetails__buttons-container">
            <Button
              variant={isFavorite ? "outlined" : "contained"}
              rounded="true"
              size="medium"
              onClick={handleAddFavorite}
              disabled={isFavorite}
            >
              Seguir
            </Button>
            <Button variant="outlined" rounded="true">
              Preguntar
            </Button>
          </div>
          <div className="AuctionDetails__extra-data">
            {renderConditions()}
            {renderPrima()}
            {renderTerms()}
          </div>
        </div>
        {prices && (
          <div className="AuctionDetails__actions-container">
            <ListPrices prices={prices} />
          </div>
        )}
      </div>
    );
  };

  return (
    <Styles className="AuctionDetails">
      {isLoading && <CircularProgress />}
      {renderContent()}
    </Styles>
  );
};

export default AuctionDetails;
