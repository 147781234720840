import {
  FeaturedSeller,
  Item
} from "services/Auctioners/Auctioners.service.types";
import { WinnedAuction } from "services/Auctioners/Auctioners.service.types";

import api, { buildHeaders } from "../Api";
import { EditCommissionPayload, WinnedLotes } from "./Bids.types";
//@ts-ignore
const SERVER_URL = process.env["REACT_APP_SERVER_URL"];
const BASE_URL = `${SERVER_URL}winners`;

// Admin
export async function editCommission(payload: EditCommissionPayload) {
  const { user_id, commission } = payload;
  return await api.post(
    `/admi/commission/${user_id}`,
    { commission },
    {
      headers: await buildHeaders()
    }
  );
}

export async function markCommissionAsPayed(auctionId?: number) {
  return await api.post(
    `/admi/winners/commission/charged/`,
    { auction_id: auctionId },
    {
      headers: await buildHeaders()
    }
  );
}

export const fetchSellerWinnedLotes = async (
  type = "1",
  date = "",
  query = "",
  limit = 100,
  skip = 0
): Promise<WinnedAuction[]> => {
  const url = `/seller/auctions/?type=${type}&search=${query}&limit=${limit}&skip=${skip}&date=${date}`;

  return await api
    .get(url, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchAdminWinnedLotes = async (
  type = "1",
  date = "",
  query = "",
  status_commission = "0",
  countryId = "1",
  limit = 100,
  skip = 0
): Promise<WinnedAuction[]> => {
  const url = `/admi/winners/?type=${type}&search=${query}&status_commission=${status_commission}&country_id=${countryId}&limit=${limit}&skip=${skip}&date=${date}`;

  return await api
    .get(url, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchSellerWinnedLotesById = async (
  auctionId?: number,
  query = "",
  limit = 100,
  skip = 0
): Promise<WinnedLotes[]> => {
  const url = `/seller/auctions/${auctionId}?search=${query}&limit=${limit}&skip=${skip}`;

  return await api
    .get(url, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchAdminWinnedLotesById = async (
  auctionId?: number,
  query = "",
  status_commission = "0",
  countryId = "0",
  limit = 100,
  skip = 0
): Promise<WinnedLotes[]> => {
  const url = `/admi/winners/${auctionId}/?search=${query}&status_commission=${status_commission}&country_id=${countryId}&limit=${limit}&skip=${skip}`;

  return await api
    .get(url, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

// Seller
export const fetchWinnedLotes = async (): Promise<WinnedLotes[]> => {
  return await api
    .get(`${BASE_URL}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export async function recalculateWinner(ItemId: number) {
  return await api
    .post(
      `${BASE_URL}/recalculate`,
      {
        item_id: ItemId
      },
      { headers: await buildHeaders() }
    )
    .catch(error => console.error(error));
}

export async function receivedItem(ItemId: number) {
  return await api
    .post(
      `${BASE_URL}/received`,
      { item_id: ItemId },
      { headers: await buildHeaders() }
    )
    .catch(error => console.error(error));
}

export const fetchSellLotes = async (): Promise<Item[]> => {
  return await api
    .get(`${SERVER_URL}seller/items`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const fetchFeaturedSellers = async (
  take = 10,
  skip = 0
): Promise<FeaturedSeller[]> => {
  const url = `/seller/featured/resume?take=${take}&skip=${skip}`;

  return await api
    .get(url)
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
};
