import AddCardIcon from "@mui/icons-material/AddCard";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import React, { useState } from "react";
import Card from "react-credit-cards";
import { useTokenizeCard } from "services/Auth/Auth.services.hooks";
import { useGetUserExtraData } from "services/Auth/Auth.services.hooks";
import { fail } from "utils/Swal";

import "react-credit-cards/es/styles-compiled.css";
import { formatCreditCardNumber } from "../MyPay.helper";
import { formatCVC, formatExpirationDate } from "../MyPay.helper";
import Styles from "./AddCreditCard.styles";
import { AddCreditCardProps as Props } from "./AddCreditCard.types";

const AddCreditCard: React.FC<Props> = props => {
  const { modalCreditCard, setModalCreditCard } = props;
  const publicKey = "TEST-569b1bd1-f4a9-4fd7-8389-9b2ae8072d16";
  // const { control, handleSubmit } = useForm();
  // @ts-ignore
  const mp = new MercadoPago(publicKey);

  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [documentType, setDocumentType] = useState("DNI");
  const [issuer, setIssuer] = useState("");
  const [focused, setFocused] = useState("");

  const { mutateAsync, reset } = useTokenizeCard();
  const { refetch } = useGetUserExtraData();
  const [isLoading, setIsLoading] = useState(false);

  const openCloseModalCreditCard = () => {
    reset();
    setModalCreditCard(!modalCreditCard);
  };

  const FormHandler = async (values: any) => {
    values.preventDefault();
    setIsLoading(true);

    const formData = [...values.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});
    const { name, expiry, identificationNumber } = formData;

    try {
      const cardForm = await mp.createCardToken({
        cardNumber: number?.replaceAll(" ", ""),
        cardholderName: name,
        cardExpirationMonth: expiry.slice(0, 2),
        cardExpirationYear: `20${expiry.slice(3, 5)}`,
        securityCode: cvc,
        identificationType: documentType,
        identificationNumber: identificationNumber,
        device: "1",
        installments: "Cuotas",
        issuerId: "1056"
      });
      const { id } = cardForm ?? {};
      await mutateAsync(id);
      refetch();
      openCloseModalCreditCard();
    } catch (error) {
      console.error(error);
      fail("Hubo un error al agregar la dirección");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallback = ({ issuer }: any, isValid: boolean) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = ({ target }: any) => {
    setFocused(target.name);
  };

  const handleInputChange = (e: any) => {
    const { target } = e ?? {};
    const { value, name } = target ?? {};

    if (name === "number") {
      const number = formatCreditCardNumber(value) ?? "";
      setNumber(number);
    } else if (name === "expiry") {
      const expiry = formatExpirationDate(value) ?? "";
      setExpiry(expiry);
    } else if (name === "cvc") {
      const cvc = formatCVC(value) ?? "";
      setCvc(cvc);
    } else if (name === "name") {
      setName(value);
    }
  };

  return (
    <CustomModal
      isOpen={modalCreditCard}
      onClose={() => setModalCreditCard(!modalCreditCard)}
      className="AddCreditCard__Modal"
      ariaLabelledby="Agregar tarjetas de crédito"
    >
      <Styles className="AddCreditCard">
        <div className="mt-4">
          <h1>Obtenga la aprobación más rápido con una tarjeta registrada</h1>
          <p className="max-w-lg mx-auto text-center text-gray-600 mt-4">
            Esto nos ayudará a verificar su elegibilidad para ofertar y le dará
            una mejor oportunidad de obtener la aprobación para ofertar, pero no
            se le cobrará a su tarjeta.
          </p>
          <h3 className="text-center mb-4 mt-4">
            Aceptamos todas las tarjetas de crédito
          </h3>
          <div className="AddCreditCard__content">
            <div className="w-2/5 mt-4">
              <Card
                number={number}
                name={name}
                expiry={expiry}
                cvc={cvc}
                focused={focused}
                callback={handleCallback}
              />
            </div>

            <form
              onSubmit={FormHandler}
              id="form-checkout"
              className="w-2/5 flex flex-col align-center"
            >
              <div className="form-group">
                <TextField
                  id="form-checkout__cardholderName"
                  type="text"
                  name="name"
                  value={name}
                  placeholder="Fabi Martinez"
                  label="Nombre de la tarjeta"
                  variant="standard"
                  className="AdminClientListRequests__textField"
                  sx={{ width: "100%", marginBottom: 1 }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  autoFocus
                  required
                />
              </div>
              <div className="form-group">
                <TextField
                  id="form-checkout__cardNumber"
                  type="text"
                  name="number"
                  value={number}
                  placeholder="4111 XXXX XXXX XXXX"
                  label="Número de tarjeta"
                  variant="standard"
                  className="AdminClientListRequests__textField"
                  sx={{ width: "100%", marginBottom: 1 }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  required
                />
              </div>

              <div className="form-group">
                <TextField
                  id="form-checkout__expirationDate"
                  type="text"
                  name="expiry"
                  value={expiry}
                  placeholder="MM/YY"
                  label="Fecha de vencimiento"
                  variant="standard"
                  className="AdminClientListRequests__textField"
                  sx={{ width: "48%", marginBottom: 1 }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  required
                />
                <TextField
                  id="form-checkout__securityCode"
                  type="text"
                  value={cvc}
                  name="cvc"
                  placeholder="123"
                  label="CVC"
                  variant="standard"
                  className="AdminClientListRequests__textField ml-2"
                  sx={{ width: "48%", marginBottom: 1 }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  required
                />
              </div>

              <div className="form-group">
                <InputLabel
                  id="document-type-label"
                  sx={{ width: "100%", marginTop: 1 }}
                >
                  Tipo de documento *
                </InputLabel>
                <Select
                  id="form-checkout__identificationType"
                  type="text"
                  value={documentType}
                  label="Tipo de documento"
                  onChange={e => setDocumentType(e.target.value)}
                  variant="standard"
                  className="AdminClientListRequests__textField"
                  sx={{ width: "100%", marginBottom: 1 }}
                  required
                >
                  <MenuItem value={"DNI"}>DNI</MenuItem>
                  <MenuItem value={"C.E"}>C.E</MenuItem>
                  <MenuItem value={"RUC"}>RUC</MenuItem>
                  <MenuItem value={"Otro"}>Otro</MenuItem>
                </Select>
              </div>

              <div className="form-group">
                <TextField
                  id="form-checkout__identificationNumber"
                  type="text"
                  name="identificationNumber"
                  placeholder="74XXXXX"
                  label="Número del documento"
                  variant="standard"
                  className="AdminClientListRequests__textField"
                  sx={{ width: "100%", marginBottom: 1 }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  required
                />
              </div>
              <div className="form-group">
                <TextField
                  id="form-checkout__cardholderEmail"
                  type="email"
                  name="cardholderEmail"
                  placeholder="usuario@gprueba.com"
                  label="Correo electrónico"
                  variant="standard"
                  className="AdminClientListRequests__textField"
                  sx={{ width: "100%", marginBottom: 1 }}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  required
                />
                <input
                  id="MPHiddenInputToken"
                  name="MPHiddenInputToken"
                  type="hidden"
                ></input>
                <input
                  id="MPHiddenInputPaymentMethod"
                  name="MPHiddenInputPaymentMethod"
                  type="hidden"
                  value="visa"
                ></input>
                <input
                  id="MPHiddenInputProcessingMode"
                  name="MPHiddenInputProcessingMode"
                  type="hidden"
                  value="aggregator"
                ></input>
                <input
                  id="MPHiddenInputMerchantAccountId"
                  name="MPHiddenInputMerchantAccountId"
                  type="hidden"
                ></input>
              </div>
              <input type="hidden" name="issuer" value={issuer} />
              <div className="form-actions">
                <ButtonLarge
                  type="submit"
                  className="hover:bg-hprimary mt-4 w-full pt-2 pb-2"
                  variant="contained"
                  size="large"
                  width="100%"
                  loading={isLoading}
                  startIcon={<AddCardIcon />}
                >
                  Agregar tarjeta de crédito
                </ButtonLarge>
              </div>
            </form>
          </div>
        </div>
      </Styles>
    </CustomModal>
  );
};

AddCreditCard.defaultProps = {};

export default AddCreditCard;
