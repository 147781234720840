import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { RegisterModalStyledProps as Props } from "./RegisterModal.types";

const { fonts, palette } = variables;

const RegisterModalStyled = styled.div<Props>`
  .RegisterModal {
    &__textContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 72%;
      margin: 0 auto;
      gap: 8px;
    }

    &__passwordCheck {
      width: 80%;
      margin: 0 auto;
    }

    &__container {
      &--components {
        display: flex;
        justify-content: space-between;
        margin: 0;
      }

      &--image {
        display: none;

        @media ${devices.tablet} {
          display: block;
          width: 440px;
          min-height: 700px;
          background-image: url("/images/img-register.png");
          background-size: cover;
          background-attachment: fixed;
          background-position: center;
          border-radius: 16px;
        }
      }

      &--logo {
        height: 32px;
        margin: auto;
      }

      &--form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 36px;
        margin: 40px 24px;

        @media ${devices.tablet} {
          width: 50%;
        }

        @media ${devices.laptop} {
          width: 420px;
        }

        &-header {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        &-button {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: center;

          @media ${devices.tablet} {
            width: 100%;
            padding: 0 24px;
          }
        }
      }

      &--checkbox {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }

    &__component {
      &__image {
        height: 100%;
      }

      &--form {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &-control {
          width: auto;
          margin: auto;

          @media ${devices.mobileM} {
            width: 100%;
          }

          @media ${devices.laptop} {
            width: 320px;
          }
        }
      }

      &--input {
        font-family: ${fonts["Poppins-El"]};
        font-size: 18px;
        font-weight: 540;
      }

      &--error {
        font-family: ${fonts["Poppins-El"]};
        font-size: 16px;
        font-weight: 640;
        margin-left: 14px;
        margin-top: 0;
        color: ${palette.error};
        letter-spacing: 0.8px;
        text-align: center;
        margin-bottom: 12px;
      }
    }

    &__text {
      font-family: ${fonts["Poppins-El"]};

      &--register {
        line-height: 1.43;
        font-size: 14px;
        text-align: center;

        &-action {
          cursor: pointer;
        }
      }

      &--title {
        text-align: center;
        font-weight: 540;
        font-size: 20px;
        word-spacing: 1.6px;
        font-family: ${fonts["Poppins-Md"]};

        @media ${devices.laptop} {
          font-size: 24px;
        }
      }

      &--subtitle {
        display: block;
        font-family: ${fonts["Poppins-Md"]};

        @media ${devices.laptop} {
          display: inline;
        }
      }

      &--checkbox {
        line-height: 24px;
        font-size: 14px;
        font-weight: 320;
        font-family: ${fonts["Poppins-El"]};

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`;

export default RegisterModalStyled;
