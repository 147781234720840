import { variables } from "config/variables";
import styled from "styled-components";

import { CurrencyStyledProps as Props } from "./Currency.types";
const { palette, shadows, fonts } = variables;

const CurrencyStyled = styled.div<Props>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  * > h3 {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 16px !important;
    text-decoration: underline;
    text-underline-offset: 1.5px;
  }

  .Currency {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &__list {
      width: 100%;
      display: flex;
      gap: 16px;
      align-items: flex-start;
      flex-wrap: wrap;
    }

    &__contentForm {
      background-color: ${palette.white};
      border-radius: 4px;
      padding: 16px 32px;
      margin-right: 16px;
      width: 32%;
      min-width: 32%;
      border-top: 1px solid ${palette.primary};
      box-shadow: ${shadows.shadowCard};
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
  }
`;

export default CurrencyStyled;
