import { variables } from "config/variables";
import styled from "styled-components";

import { ErrorComponentStyledProps as Props } from "./ErrorComponent.types";

const { fonts, palette } = variables;

const ErrorComponentStyled = styled.div<Props>`
  .ErrorComponent {
    &__component {
      &--error {
        font-family: ${fonts["Poppins-El"]};
        font-size: 12px;
        font-weight: 540;
        margin-left: 14px;
        margin-top: 0;
        color: ${palette.error};
        letter-spacing: 0.8px;
      }
    }
  }
`;

export default ErrorComponentStyled;
