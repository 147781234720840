import { variables } from "config/variables";
import styled from "styled-components";

import { SellerPresentationStyledProps as Props } from "./SellerPresentation.types";

const { shadows, fonts, palette } = variables;

const SellerPresentationStyled = styled.div<Props>`
  display: flex;
  width: 48%;
  margin: 24px;
  gap: 32px;

  .SellerPresentation {
    &__image {
      height: 144px;
      border-radius: 12px;
      box-shadow: ${shadows.shadowElevationMedium};
    }

    &__content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__title {
      font-size: 32px;
      font-family: ${fonts["Poppins-Rg"]};
    }

    &__section {
      display: flex;
      width: 80%;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  * > h3 {
    font-size: 16px;
    font-family: ${fonts["Poppins-Rg"]};
    color: ${palette.blackCharcoal};
  }

  * > h4 {
    font-size: 14px;
    font-family: ${fonts["Poppins-Rg"]};
    color: ${palette.blackCharcoal};
  }
`;

export default SellerPresentationStyled;
