//import { Notifications } from "@mui/icons-material";
import { Menu, Divider } from "@mui/material";
import { Button } from "@mui/material";
// import Button from "components/Globals/Button/Button";
import NotificationCard from "components/NotificationCard/NotificationCard";
import useGlobals from "context/globals/globals.hooks";
import React, { useEffect, useState } from "react";
import { RiNotification2Fill } from "react-icons/ri";
import history from "routes/history";
import { useFetchNotifications } from "services/notifications/notifications.service.hooks";

import Styles from "./NotificationBell.styles";
import { NotificationBellProps as Props } from "./NotificationBell.types";

const NotificationBell: React.FC<Props> = props => {
  const { openNotifications: open, setOpenNotifications: setOpen } =
    useGlobals();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data: notificationList } = useFetchNotifications(10);

  const notificationCount =
    notificationList?.reduce(
      (val, notification) => (val = val + (!notification?.readed ? 1 : 0)),
      0
    ) ?? 0;

  const handleClick = () => {
    setAnchorEl(document.getElementById("NotificationBell"));
  };

  const handleClose = () => {
    setOpen(!open);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) handleClick();
  }, [open]);

  return (
    <Styles className="NotificationBell" id="NotificationBell">
      <RiNotification2Fill />
      {notificationCount > 0 && (
        <span className="NotificationBell__tag">
          {notificationCount >= 10 ? "10+" : notificationCount}
        </span>
      )}

      <Menu
        id="basic-menu"
        className="NotificationBell__menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        {notificationList?.map((notification, key) => (
          <NotificationCard notification={notification} key={key} />
        ))}
        <Divider />
        <Button
          className="NotificationBell__button"
          onClick={() => {
            history.push("/panel/notificaciones");
          }}
          sx={{
            display: "flex",
            margin: "auto",
            textTransform: "uppercase",
            marginTop: "8px",
            fontWeight: "bold",
            fontSize: "12px"
          }}
        >
          Ver todas las notificaciones
        </Button>
      </Menu>
    </Styles>
  );
};

NotificationBell.defaultProps = {};

export default NotificationBell;
