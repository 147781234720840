import { variables } from "config/variables";
import styled from "styled-components";

import { ForgotPasswordStyledProps as Props } from "./ForgotPassword.types";
const { fonts, palette } = variables;

const ForgotPasswordStyled = styled.div<Props>`
  .ForgotPassword {
    &__helperText {
      font-family: ${fonts["Poppins-Md"]};
      color: ${palette.colorDegradedTree};
      width: 80%;
      margin: 0 auto 24px auto;
    }

    &--error {
      font-family: ${fonts["Poppins-El"]};
      font-size: 16px;
      font-weight: 640;
      margin-left: 14px;
      margin-top: 0;
      color: ${palette.error};
      letter-spacing: 0.8px;
      text-align: center;
      margin-bottom: 12px;
    }
  }
`;

export default ForgotPasswordStyled;
