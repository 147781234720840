import { DashboardAdminNavbar } from "components/Dashboard/DashboardAdminNavbar/DashboardAdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useState } from "react";

import AdminDashboard from "./AdminDashboard";
import Styles from "./AdminDashboard.styles";
import { AdminDashboardContainerProps as Props } from "./AdminDashboard.types";

const AdminDashboardContainer: React.FC<Props> = props => {
  const [, setSidebarOpen] = useState(false);

  return (
    <Styles className="AdminDashboard__container">
      <Sidebar />
      <section className="AdminDashboard__content">
        <DashboardAdminNavbar onSidebarOpen={() => setSidebarOpen(true)} />
        <AdminDashboard />;
      </section>
    </Styles>
  );
};

AdminDashboardContainer.defaultProps = {};

export default AdminDashboardContainer;
