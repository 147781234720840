import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React from "react";

import { CustomImageConfig } from "./Item.screen.types";

export function srcsetHelper(
  image: string,
  width: number,
  height: number,
  rows = 1,
  cols = 1
) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`
  };
}

const imagePlaceHolder =
  "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png";

export default function CustomImageList(config: CustomImageConfig) {
  const { photos, setCurrentPhoto } = config;

  return (
    <ImageList
      sx={{
        width: 440,
        height: 340,
        // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
        transform: "translateZ(0)"
      }}
      rowHeight={240}
      gap={1}
      className="Item__image-list"
    >
      {photos?.map(item => {
        const cols = 1;
        const rows = 1;
        const src = item?.photo ? item?.photo : imagePlaceHolder;
        return (
          <ImageListItem
            key={src}
            cols={cols}
            rows={rows}
            onClick={() =>
              setCurrentPhoto(item?.photo ? item?.photo : imagePlaceHolder)
            }
          >
            <img
              {...srcsetHelper(src, 250, 200, rows, cols)}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        );
      })}
      <></>
    </ImageList>
  );
}
