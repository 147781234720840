import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { variables } from "config/variables";

import { SelectMenuItemStyledProps as Props } from "./SelectMenuItem.types";

const { fonts } = variables;

const SelectMenuItemStyled = styled(MenuItem)((props: Props) => ({
  ".MuiButtonBase-root": {
    fontFamily: fonts["Poppins-El"],
    fontSize: "14px"
  }
}));

export default SelectMenuItemStyled;
