import { devices } from "config/devices";
import { palette } from "config/variables";
import styled from "styled-components";

export const DashboardLayoutRoot = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: "96px",
  backgroundColor: `${palette.white}`,
  [`@media screen and ${devices.tablet}`]: {
    paddingTop: "180px"
  }
}));

export const DashboardStyles = {
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%"
};
