import { FormControl, RadioGroup } from "@mui/material";
import React from "react";

import { FormControlStyles } from "./InputRadio.styles";
import { FormHelperTextStyles } from "./InputRadio.styles";
import { InputRadioProps as Props } from "./InputRadio.types";

const InputRadio: React.FC<Props> = props => {
  const { field, fieldState, children, config, onChange, value } = props;

  return (
    <>
      <FormControl
        // fullWidth
        error={fieldState.invalid}
        sx={FormControlStyles}
        variant="standard"
      >
        <RadioGroup
          row={config.row}
          id={`${field?.name}-radio`}
          {...field}
          onChange={onChange}
          value={value}
        >
          {children}
        </RadioGroup>
        <FormHelperTextStyles>{fieldState.error?.message}</FormHelperTextStyles>
      </FormControl>
    </>
  );
};

InputRadio.defaultProps = {};

export default InputRadio;
