import styled from "styled-components";

// Config
import { devices } from "../../config/devices";
import { variables } from "../../config/variables";

const { palette, fonts } = variables;

const Styles = styled.div`
  background-color: ${palette.neutro.custom};

  .Item {
    &__container {
      display: flex;
      flex-direction: column;
      padding-top: 32px;
      width: 96%;
      margin: 0 2%;
      background-color: ${palette["white-hex"]};
    }

    &__images-container {
      display: flex;
      justify-content: space-between;
      width: 88%;
      margin: 32px auto;
    }

    &__section {
      width: 88%;
      margin: 0 auto;
    }

    &__description {
      max-width: 48%;
    }

    &__description-section {
      width: 44%;
      margin: 0 6%;
    }

    &__section-user {
      padding: 16px 16px 16px 0;
      width: 88%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    &__user-section {
      width: 32%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        0 2px 2px 0 rgba(0, 0, 0, 0.06); */
    }

    &__user-data {
      margin: 0 16px 16px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__image-section {
      width: 88%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 16px;

      /*  @media px${devices.laptop} {
        gap: 80px;
      } */
      /* 
      @media ${devices.laptopL} {
        gap: 160px;
      } */

      @media ${devices.desktop} {
        gap: 240px;
      }
    }

    &__image-list {
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }

    &__sectionTitle {
      font-size: 24px;
      font-family: ${fonts["Poppins-Lt"]};
      margin: 16px 8px;
    }

    &__info-title {
      font-size: 34px;
      font-family: ${fonts["Poppins-Th"]};
      margin: 16px 8px;
    }

    &__image {
      width: 564px;
      border-radius: 8px;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      padding: 8px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      position: relative;
      margin: 8px;

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
          0 2px 2px 0 rgba(0, 0, 0, 0.06);
      }
    }
    &__divider {
      width: 88%;
      height: 1px;
      background-color: ${palette.dividerColor};
      margin: 16px auto;
    }

    &__item-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 30%;
      margin: 16px;
    }

    &__items-container {
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &__info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      flex: 1;
      height: 340px;
      padding: 16px;
      border-radius: 8px;
    }

    &__text {
      font-size: 14px;
      font-family: ${fonts["Poppins-Lt"]};
      margin: 0 8px;
    }

    &__bid-section {
      /* flex: 1; */
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 8px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      border: 1px solid ${palette.primary};
      padding: 16px;
      justify-content: space-around;
    }

    &__input {
      width: 64%;
      margin-right: 16px;
    }

    &__button {
      width: 32%;
      height: 46px;

      &--secondary {
        background-color: ${palette.primaryLight};
        color: #fff;
        font-family: ${fonts["Poppins-Lt"]};
        padding-top: 0.75rem /* 12px */;
        padding-bottom: 0.75rem /* 12px */;
        padding-left: 1rem /* 16px */;
        padding-right: 1rem /* 16px */;
        border-radius: 0.25rem /* 4px */;
        border-width: 1px;
        border-color: ${palette.primary};
      }
    }

    &__user-image {
      width: 128px;
      height: 128px;
      border-radius: 8px;
      border: 1px solid ${palette.dividerColor};
      margin: 16px 16px 16px 0;
      object-fit: cover;
      transition: all 0.3s ease-in-out;
      padding: 2px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
      position: relative;

      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }

    &__buttons-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__buttons {
      background-color: ${palette.primary};
      color: ${palette.white};
      padding: 8px 0;
      width: 120px;
      margin-right: 16px;
      border-radius: 8px;
      font-size: 14px;
      font-family: ${fonts["Poppins-Lt"]};

      &--primary {
        background-color: ${palette.primary};
        color: ${palette.white};
      }

      &--secondary {
        background-color: ${palette.white};
        color: ${palette.primary};
        border: 1px solid ${palette.primary};
      }

      &:hover {
        transform: scale(1.05);
      }
    }
  }
`;

export default Styles;
