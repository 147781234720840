import { axiosDefault, buildHeaders } from "../Api";
import { Country } from "./calendar.service.types";

export const getCalendarAuctions = async (
  initDate: string,
  endDate: string,
  auctionType?: number,
  skip = 0,
  take = 50,
  country?: number,
  activeLivestreamRef?: boolean,
  bestValue?: boolean
) => {
  return await axiosDefault
    .get(
      `/home/calendar?skip=${skip}&take=${take}&InitDate=${initDate}&EndDate=${endDate}
      &type=${auctionType}&country_id=${country}&livestream=${
        activeLivestreamRef ? 1 : undefined
      }&topRates=${bestValue ? 1 : undefined}`,
      { headers: await buildHeaders() }
    )
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const getAllCountries = async (): Promise<Country[]> => {
  return await axiosDefault
    .get(`/home/countries`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};
