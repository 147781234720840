import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import SkeletonInput from "components/Globals/SkeletonComponent/SkeletonInput/SkeletonInput";
import TextField from "components/Globals/TextField/TextField";
import TextFieldFile from "components/Globals/TextFieldFile/TextFieldFile";
import { handleValueDefault } from "containers/user/Profile.helpers";
import React from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useGetSellerData } from "services/Auth/Auth.services.hooks";

import { updateSellerInfo } from "../../../redux/actions/authActions";
import { addSellerInfo } from "../../../services/Auth/Auth.service";
import { fail, successful } from "../../../utils/Swal";
import Styles from "./MySeller.styles";
import { mySellerForm } from "./mySeller.helper";

const MySeller = () => {
  const dispatch = useDispatch();

  const { user = {} } = useSelector(
    //@ts-ignore
    state => state.authReducer
  );
  const { data: seller, refetch, isLoading } = useGetSellerData(user?.id);

  const { control, handleSubmit } = useForm();

  const formHandler = (values: FieldValues) => {
    const formData = new FormData();
    mySellerForm.map(item => formData.append(item.label, values[item.label]));
    formData.append("user_id", user.id);
    formData.delete("url_RRSS");
    formData.delete("url_web");
    formData.delete("logo");
    if (values.logo[0]) {
      formData.append("logo", values.logo[0]);
    }
    if (values.url_web) {
      formData.append("url_web", values.url_web);
    }
    if (values.url_RRSS) {
      formData.append("url_RRSS", values.url_RRSS);
    }

    return addSellerInfo(formData as any)
      .then(response => {
        successful("Actualización de datos exitosa");
        refetch();
        dispatch(updateSellerInfo(response.data));
      })
      .catch(error => {
        console.error(error);
        fail("Hubo un error al actualizar sus datos");
      });
  };

  return (
    <Styles className="MySeller">
      <form
        className="MySeller__container"
        onSubmit={handleSubmit(formHandler)}
      >
        <div className="MySeller__container--form">
          {mySellerForm.map((item, idx) => {
            const { label, placeholder, type, rules } = item ?? {};

            if (isLoading)
              return (
                <div key={idx}>
                  <SkeletonInput />
                </div>
              );

            return (
              <Controller
                key={idx}
                name={label}
                control={control}
                rules={rules}
                defaultValue={seller?.[label] ?? handleValueDefault(type)}
                render={({ field, fieldState }) =>
                  type === "file" ? (
                    <TextFieldFile
                      field={field}
                      config={{ label: placeholder }}
                      imagenAction={false}
                    />
                  ) : (
                    <TextField
                      field={field}
                      fieldState={fieldState}
                      config={{
                        type: type,
                        label: placeholder,
                        variant: "standard",
                        margin: "dense",
                        fullWidth: true,
                        focused: true
                      }}
                    />
                  )
                }
              />
            );
          })}
        </div>

        {/* <div className="MySeller__container--image">{renderImage()}</div> */}
        <div className="MySeller__container--button">
          <ButtonLarge
            type="submit"
            className=" hover:bg-hprimary "
            variant="contained"
          >
            Actualizar
          </ButtonLarge>
        </div>
      </form>
    </Styles>
  );
};
export default MySeller;
