export const formList = [
  {
    label: "price",
    type: "number",
    placeholder: "Precio",
    _placeholder: "1000",
    validationRules: {
      required: "Añade un precio."
    }
  },
  {
    label: "offert",
    type: "number",
    placeholder: "Incremento",
    _placeholder: "100",
    validationRules: {
      required: "Añade un incremento."
    }
  }
];
