import HomeIcon from "@mui/icons-material/Home";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { Breadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import useGlobals from "context/globals/globals.hooks";
import React, { useCallback } from "react";

import Currency from "./Currency";
import { CurrencyContainerProps as Props } from "./Currency.types";

const CurrencyContainer: React.FC<Props> = props => {
  const { selectedSubIndex, setSelectedIndex } = useGlobals();
  const { setSelectedSubIndex } = useGlobals();

  const renderBreadCrumb = () => {
    return (
      <div className="mb-8 flex justify-between" role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/admin"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => {
              setSelectedIndex(4);
              setSelectedSubIndex(0);
            }}
          >
            <PersonPinIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Moneda
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => {}}
          >
            <MonetizationOnIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Manejar monedas
          </Link>
        </Breadcrumbs>
      </div>
    );
  };

  const renderContent = useCallback(() => {
    switch (selectedSubIndex) {
      case 0:
        return <Currency />;
      default:
        return <Currency />;
    }
  }, [selectedSubIndex]);

  return (
    <section className="flex flex-col w-full">
      {renderBreadCrumb()}
      {renderContent()}
    </section>
  );
};

CurrencyContainer.defaultProps = {};

export default CurrencyContainer;
