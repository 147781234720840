import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, ListItemButton, ListItemIcon } from "@mui/material";
import { ListItemText, Tooltip } from "@mui/material";
import { Avatar } from "@mui/material";
import { Collapse, SwipeableDrawer, List } from "@mui/material";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React, { useState } from "react";
import history from "routes/history";

import Styles, { drawerStyle } from "./Sidebar.styles";
import { SidebarProps as Props } from "./Sidebar.types";

const Sidebar: React.FC<Props> = props => {
  const { user } = useAppSelector(state => state.authReducer);
  const { name, surname } = user ?? {};
  const { rol } = user ?? {};
  const { selectedIndex, selectedSubIndex } = useGlobals();
  const { setSelectedIndex, setSelectedSubIndex } = useGlobals();

  const [openDrawer, setOpenDrawer] = useState(true);
  const [openList, setOpenList] = useState(true);
  const hide = true;

  const handleClick = (idx: number, url: string) => {
    setOpenList(!openList);
  };

  const renderItem = (
    idx: number,
    text: string,
    url: string,
    subItems?: string[]
  ) => {
    return (
      <>
        <ListItemButton
          onClick={event => {
            handleClick(idx, url);
            setSelectedSubIndex(0);
            setSelectedIndex(idx);
          }}
          sx={{ padding: "12px 24px" }}
          key={idx}
          className="Sidebar__listButton"
        >
          <ListItemIcon className="Sidebar__list--icon">
            <ArrowRightIcon
              sx={{ color: "hsl(27, 100%, 50%)" }}
              className={selectedIndex === idx ? "Sidebar__active" : ""}
              fontSize="medium"
            />
          </ListItemIcon>
          <ListItemText>
            <h3 className={selectedIndex === idx ? "Sidebar__active" : ""}>
              {text}
            </h3>
          </ListItemText>
        </ListItemButton>
        {selectedIndex === idx &&
          subItems?.map((subItem, subIndex) => (
            <Collapse in={openList} timeout="auto" unmountOnExit key={subIndex}>
              <List
                component="div"
                disablePadding
                onClick={() => setSelectedSubIndex(subIndex)}
              >
                <ListItemButton sx={{ pl: 6 }}>
                  <ListItemIcon>
                    <ArrowRightIcon
                      sx={{ color: "hsl(27, 100%, 50%)" }}
                      className={
                        selectedSubIndex === subIndex ? "Sidebar__active" : ""
                      }
                      fontSize="small"
                    />
                  </ListItemIcon>
                  <h4
                    className={
                      selectedSubIndex === subIndex ? "Sidebar__active" : ""
                    }
                  >
                    {`${subItem}`}
                  </h4>
                </ListItemButton>
              </List>
            </Collapse>
          ))}
      </>
    );
  };

  const renderAdminRoutes = () => {
    if (rol !== 10) return null;
    return (
      <>
        {renderItem(0, "Calendario", "/admin/calendario", [
          "Semanal",
          "Mensual",
          "Cantidad de subastas"
        ])}
        {renderItem(1, "Clientes", "/admin/clientes", [
          "Solicitudes de vendedores",
          "Listado de clientes",
          "Listado de casas de subasta"
        ])}
        {renderItem(2, "Resultados", "/admin/resultados", [
          "Subastas en vivo",
          "Subastas cronometradas",
          "Ecommerce"
        ])}
        {renderItem(3, "Deseos", "/admin/wishes", ["Solicitudes de deseos"])}
        {renderItem(4, "Monedas / Convertidor", "/admin/monedas-convertidor", [
          "Manejar monedas"
        ])}
        {renderItem(5, "Categorías/Marcas", "/admin/categories")}
      </>
    );
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenDrawer(open);
    };

  return (
    <Styles className="Sidebar">
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        className="Sidebar__drawer"
        sx={drawerStyle}
        variant="persistent"
      >
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          sx={drawerStyle}
          subheader={
            <div className="Sidebar__header">
              <div className="Sidebar__header-text">
                <div className="Sidebar__icon--container">
                  <Tooltip title="Ir a inicio">
                    <img
                      className="Sidebar__icon"
                      src="/images/whiteLogo.png"
                      alt="logo"
                      width={220}
                      onClick={() => history.push("/admin/")}
                    />
                  </Tooltip>
                </div>

                <div className="Sidebar__sectionContainer">
                  <Avatar
                    alt="iconPersonSideBar"
                    src="/images/LiveLogoRounded.png"
                    className="Sidebar__avatar"
                  />
                  <div>
                    <h2>{`Admin ${name ?? "-"}`}</h2>
                    <h4>{surname ?? ""}</h4>
                  </div>
                  {!hide && (
                    <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                      <MenuIcon
                        sx={{
                          color: "hsl(27, 100%, 50%)",
                          right: 0,
                          top: 2
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          }
        >
          <div className="Sidebar__divider" />
          {renderAdminRoutes()}
        </List>
      </SwipeableDrawer>
    </Styles>
  );
};

Sidebar.defaultProps = {};

export default Sidebar;
