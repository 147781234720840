import { variables } from "config/variables";
import styled from "styled-components";

import { IvsPlayerStyledProps as Props } from "./IvsPlayer.types";

const { palette, fonts, shadows } = variables;

const IvsPlayerStyled = styled.div<Props>`
  width: 100%;
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${palette.white};
  box-shadow: ${shadows.shadowCard};
  border-radius: 8px;
  padding: 12px;

  * > label {
    color: ${palette.blackCharcoal};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 16px;
    margin: 4px 0;
  }

  * > select,
  input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${palette.primary};
    padding: 12px 4px;

    &:focus {
      border-color: ${palette.primary};
    }
  }

  .IvsPlayer {
    &__videos {
      position: relative;
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    &__subscriber {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 240px;
      z-index: 10;
    }

    &__publisher {
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 10px;
      left: 10px;
      z-index: 100;
      border: 3px solid white;
      border-radius: 3px;
    }
  }
`;

export default IvsPlayerStyled;
