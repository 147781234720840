import { useQuery } from "react-query";
import { useIsLogged } from "services/Auth/Auth.services.hooks";

import { getAllCountries, getCalendarAuctions } from "./calendar.service";

export const useGetCalendarAuctions = (
  initDate: string,
  endDate: string,
  auctionType?: number,
  skip = 0,
  take = 50,
  country?: number,
  activeLivestreamRef?: boolean,
  bestValue?: boolean
) => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(
    [
      "calendar",
      initDate,
      endDate,
      auctionType,
      skip,
      take,
      country,
      activeLivestreamRef,
      bestValue
    ],
    () =>
      getCalendarAuctions(
        initDate,
        endDate,
        auctionType,
        skip,
        take,
        country,
        activeLivestreamRef,
        bestValue
      ),
    {
      enabled: isAuthenticated && !!initDate && !!endDate,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener las subastas");
      }
    }
  );
};

export const useGetAllCountries = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["countries"], () => getAllCountries(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los países registrados");
    }
  });
};
