import ChatIcon from "@mui/icons-material/Chat";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import InfoIcon from "@mui/icons-material/Info";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { CONSTANTS } from "config/constants";
import { variables } from "config/variables";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import dayjs from "dayjs";
import { useAppSelector } from "hooks";
import React from "react";
import { recalculateWinner } from "services/Bids/Bids.service";
import { receivedItem } from "services/Bids/Bids.service";
import { useFetchWinnedLotes } from "services/Bids/Bids.services.hooks";
import { WinnedLotes } from "services/Bids/Bids.types";
import { useCreateChat } from "services/userChat/userChat.service.hooks";
import { fail } from "utils/Swal";

import Styles from "./UserBids.styles";
import { UserBidsProps as Props } from "./UserBids.types";
const { palette } = variables;

const { IMAGE_PLACEHOLDER } = CONSTANTS;

const UserBids: React.FC<Props> = props => {
  const { mutateAsync, reset } = useCreateChat();
  const { data: itemList = [], refetch } = useFetchWinnedLotes();
  const { user } = useAppSelector(state => state.authReducer);
  const { name, rol, id } = user ?? {};

  const handleNavigate = (itemId: number, auctionId: number) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/item/${auctionId}/${itemId}`);
  };

  const rejectItem = async (itemId: number, userId: number) => {
    if (id === userId) {
      await recalculateWinner(itemId);
      refetch();
    }
  };

  const checkReceivedItem = async (itemId: number, userId: number) => {
    if (id === userId) {
      await receivedItem(itemId);
      refetch();
    }
  };

  const handleCreateChat = async (uid: number) => {
    try {
      mutateAsync(uid);
      reset();
      window.scrollTo({ top: 0, behavior: "smooth" });
      window.open(`/panel/chat`);
      //TODO: set current chat id on redux
    } catch (error) {
      fail("Error al crear el chat con el usuario");
    }
  };

  const renderActions = (winner: WinnedLotes) => {
    const { item, user_id: winnerId } = winner ?? {};
    const { user_id } = item ?? {};

    return (
      <>
        <Tooltip title="Información del lote">
          <InfoIcon
            fontSize="medium"
            sx={{
              marginRight: 1,
              color: palette.primary,
              cursor: "pointer"
            }}
            onClick={() => handleNavigate(winner?.item_id, item?.auction_id)}
          />
        </Tooltip>
        <Tooltip title="Chat entre comprador y vendedor">
          <ChatIcon
            fontSize="medium"
            sx={{
              marginRight: 1,
              color: palette.chipSuccess,
              cursor: "pointer"
            }}
            onClick={() => handleCreateChat(winnerId)}
          />
        </Tooltip>
        <Tooltip title="Recalcular nuevo ganador del lote">
          <DoDisturbIcon
            fontSize="medium"
            sx={{
              color: palette.chipOrange,
              marginRight: 1,

              cursor: "pointer"
            }}
            onClick={() => rejectItem(winner?.item_id, user_id)}
          />
        </Tooltip>
        {rol === 5 && (
          <Tooltip title="Marcar lote como entregado">
            <CheckCircleOutlineIcon
              fontSize="medium"
              sx={{
                color: palette.buttonColor,
                cursor: "pointer"
              }}
              onClick={() => checkReceivedItem(winner?.item_id, user_id)}
            />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <Styles className="UserBids">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small">Imagen</StyledTableCell>
              <StyledTableCell size="small" align="left">
                Número de lote
              </StyledTableCell>
              <StyledTableCell align="left">Nombre del lote</StyledTableCell>
              <StyledTableCell align="left">Ganador</StyledTableCell>
              <StyledTableCell align="left">Precio pagado</StyledTableCell>
              <StyledTableCell align="left">Precio base</StyledTableCell>
              <StyledTableCell align="left">Precios estimados</StyledTableCell>
              <StyledTableCell align="left">Fecha de compra</StyledTableCell>
              <StyledTableCell align="right" size="small" width={16}>
                Estado
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Acciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemList?.map((winner, key) => {
              const { item, status } = winner ?? {};
              const { images } = item ?? {};
              const { photo } = images?.[0] ?? {};
              const isDelivered = status === 1;
              const Status = isDelivered ? "Entregado" : "Pendiente";

              return (
                <StyledTableRow key={`${winner.item_id}-${key}`}>
                  <StyledTableCell component="th" scope="row">
                    <Avatar
                      alt="Lote Image"
                      src={photo ?? IMAGE_PLACEHOLDER}
                      variant="rounded"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${item?.auction_id}A-${winner?.item_id}I`}
                  </StyledTableCell>
                  <StyledTableCell align="left">{item?.name}</StyledTableCell>
                  <StyledTableCell align="left">{name}</StyledTableCell>
                  <StyledTableCell align="left">
                    {`$${winner?.price}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`$${item?.price_base}`}
                  </StyledTableCell>
                  <StyledTableCell align="left">{`$${item?.price_estimated1} - $${item?.price_estimated2}`}</StyledTableCell>
                  <StyledTableCell align="left">
                    {dayjs(winner?.updated_at).format("DD/MM/YYYY HH:mm a")}
                  </StyledTableCell>
                  <StyledTableCell align="right" size="small">
                    <Chip
                      label={Status}
                      variant={isDelivered ? "filled" : "outlined"}
                      color={isDelivered ? "success" : "primary"}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right" size="small">
                    {renderActions(winner)}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Styles>
  );
};

UserBids.defaultProps = {};

export default UserBids;
