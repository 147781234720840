import { Box, CircularProgress, styled } from "@mui/material";
import { variables } from "config/variables";

const { palette } = variables;

const CircularProgressStyles = styled(CircularProgress)(() => ({
  width: "300px",
  height: "300px",
  color: palette.primary
}));

const Container = styled(Box)(() => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  height: "100vh"
}));

const Styles = styled(Box)(() => ({
  position: "fixed",
  display: "block",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: `${palette.pageDisabled}`,
  zIndex: 2
}));

export { CircularProgressStyles, Container, Styles };
