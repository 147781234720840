import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CustomModal from "components/CustomModal/CustomModal";
import React from "react";

import Styles from "./ModalError.styles";
import { ModalErrorProps as Props } from "./ModalError.types";

const ModalError: React.FC<Props> = props => {
  const { modalError, setModalError } = props;

  return (
    <CustomModal
      onClose={() => setModalError(!modalError)}
      isOpen={modalError}
      ariaLabelledby="Subasta no inicializada"
    >
      <Styles className="ModalError">
        <div className="ModalError__component">
          <ReportGmailerrorredIcon fontSize="large" />
          <h1 className="ModalError__title">Subasta no inicializada</h1>
        </div>
      </Styles>
    </CustomModal>
  );
};

ModalError.defaultProps = {};

export default ModalError;
