import { variables } from "config/variables";
import styled from "styled-components";

import { NotifyWishesStyledProps as Props } from "./NotifyWishes.types";
const { fonts, palette, shadows } = variables;

const NotifyWishesStyled = styled.div<Props>`
  * > h3 {
    font-size: 16px;
    font-family: ${fonts["Poppins-Lt"]};
    line-height: 24px;
    margin-bottom: 8px;
  }

  .NotifyWishes {
    &__link {
      color: ${palette.primaryLight};
      text-decoration: underline;
      display: block;

      &:hover {
        cursor: pointer;
      }
    }

    &__cell {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
    }
  }
`;

export default NotifyWishesStyled;

export const categoryListStyle = {
  width: "100%",
  minWidth: 650,
  bgcolor: "white",
  borderTop: 1,
  borderColor: `${palette.primary}`,
  boxShadow: `${shadows.shadowCard}`
};
