import React from "react";

// Styles
import Styles from "./SelectComponent.styles";

const SelectComponent = (props: any) => {
  const { selectList, errors, register } = props ?? {};
  const { label, placeholder, required, options } = selectList ?? {};

  return (
    <Styles className="SelectComponent">
      <label className="SelectComponent__label" htmlFor="label">
        {placeholder}
      </label>
      <select
        className={`SelectComponent__input ${
          errors.label
            ? "border-red-500 ring-1 ring-red-500"
            : "focus:border-primary focus:ring-1 focus:ring-primary"
        }`}
        name={label}
        defaultValue={options?.[0]?.id}
        placeholder={placeholder}
        {...register(label, { required })}
      >
        {options?.map((value: any) => (
          <option key={value.id} value={value.id}>
            {value.name}
          </option>
        ))}
      </select>
    </Styles>
  );
};

export default SelectComponent;
