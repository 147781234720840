import { Player } from "@lottiefiles/react-lottie-player";
import { Typography } from "@mui/material";
import React from "react";

import Styles from "./EmptyState.styles";

import noDataFile from "./noData.json";

function EmptyState({ customTitle = "No se han encontrado resultados" }) {
  return (
    <Styles className="EmptyState">
      <Player
        autoplay
        keepLastFrame
        src={noDataFile}
        className="EmptyState__container"
        style={{ height: "200px", width: "200px" }}
      />
      <Typography className="EmptyState__text">{customTitle}</Typography>
    </Styles>
  );
}

export default EmptyState;
