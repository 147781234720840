import { Avatar } from "@mui/material";
import Rating from "@mui/material/Rating";
import { variables } from "config/variables";
import dayjs from "dayjs";
import parse from "html-react-parser";
import React from "react";

import Styles from "./ReviewCard.styles";
import { ReviewCardProps as Props } from "./ReviewCard.types";

const { palette } = variables;
const defaultImage = "/images/LiveLogoRounded.png";

const ReviewCard: React.FC<Props> = props => {
  const { review, className } = props;
  const { review_user, comment, updated_at, tittle, score, user } =
    review ?? {};
  const { name, city, country, photo, logo } = review_user ?? user ?? {};
  const clientImage = photo ?? logo ?? defaultImage;

  return (
    <Styles className={`ReviewCard ${className}`}>
      <div className="ReviewCard__userData">
        <Avatar
          src={clientImage}
          alt="iconPersonSideBar"
          className="ClientInfoDetails__clientInfo__avatar"
          sx={{ width: 56, height: 56, marginBottom: 2 }}
        />
        <h3>{name ?? ""}</h3>
        <h4>{`${country?.slice(0, 3) ?? "Argentina"}, ${
          city?.slice(0, 2) ?? "Buenos Aires"
        }`}</h4>
      </div>
      <div className="ReviewCard__textContent">
        <div className="ReviewCard__header">
          <Rating
            name="read-only"
            value={score}
            precision={0.5}
            readOnly
            style={{ color: palette.primary }}
          />
          <h4>{dayjs(updated_at).format("MMM DD, YYYY - HH:mm A")}</h4>
        </div>
        <h3>{tittle}</h3>
        <h4>{parse(comment)}</h4>
      </div>
    </Styles>
  );
};

ReviewCard.defaultProps = {};

export default ReviewCard;
