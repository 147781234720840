import styled from "styled-components";

import { IvsClientPlayerStyledProps as Props } from "./IvsClientPlayer.types";

const IvsClientPlayerStyled = styled.div<Props>`
  width: 80%;
  height: 220px;
  max-height: 220px;
  margin: 0 auto 12px auto;
  border-radius: 4px;

  .IvsClientPlayer {
  }
`;

export default IvsClientPlayerStyled;
