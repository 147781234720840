import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

// Reducers
import { auctionsReducer } from "../reducer/auctionsReducer";
import { authReducer } from "../reducer/authReducer";
import { chatReducer } from "../reducer/chatReducer";

const reducers = combineReducers({
  authReducer,
  auctionsReducer,
  chatReducer
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["authReducer"]
};

const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  middleware: [thunk]
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
