import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { ModalStyledProps as Props } from "./CustomModal.types";

const ModalStyled = styled.div<Props>``;

const { palette } = variables;

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  bgcolor: "background.paper",
  border: `1px solid ${palette.primaryLight}`,
  boxShadow: "16px",
  borderRadius: 4,
  padding: "16px",
  width: "90%",
  justifyContent: "center",
  [`@media screen and ${devices.tablet}`]: {
    padding: "32px",
    width: "auto"
  },
  "&:focus-visible": {
    outline: "none"
  }
};

export default ModalStyled;
