import InputBase from "@mui/material/InputBase";
import { styled as muiStyled } from "@mui/material/styles";
import { variables } from "config/variables";
import styled from "styled-components";

import { TableFiltersStyledProps as Props } from "./TableFilters.types";
const { palette, shadows } = variables;

const TableFiltersStyled = styled.div<Props>`
  background-color: ${palette.blackTable};
  margin-bottom: 4px;
  border-radius: 4px;
  box-shadow: ${shadows.shadowCard};
  display: flex;
  padding: 8px 0;
  height: 64px;
  align-items: center;
  justify-content: space-between;

  .ant-picker-input > input {
    color: ${palette.blackTable};
    font-size: 16px;
    font-weight: 500;
  }

  .ant-picker-suffix {
    color: ${palette.blackTable};
    opacity: 1;
  }

  .TableFilters {
    &__datePicker {
      background-color: ${palette.white};
      border-radius: 4px;
      width: 160px;
    }

    &__searchContainer {
      width: 50%;
    }

    &__filtersContainer {
      display: flex;
      gap: 16px;
      margin-right: 8px;
      height: 96%;
    }
  }
`;

export const Search = muiStyled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: palette.white,
  marginLeft: 0,
  width: "100%",
  height: "80%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto"
  }
}));

export const SearchIconWrapper = muiStyled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

export const StyledInputBase = muiStyled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      width: "50ch",
      "&:focus": {
        width: "64ch"
      }
    }
  }
}));

export default TableFiltersStyled;
