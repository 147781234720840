import { variables } from "config/variables";
import styled from "styled-components";

import { ModalErrorStyledProps as Props } from "./ModalError.types";

const { palette, fonts } = variables;

const ModalErrorStyled = styled.div<Props>`
  .ModalError {
    &__component {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${palette.error};
    }

    &__title {
      color: ${palette.error};
      font-family: ${fonts["Poppins-Lt"]};
      font-size: 22px;
    }
  }
`;

export default ModalErrorStyled;
