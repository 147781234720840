import { Box, Drawer } from "@mui/material";
import React from "react";

import { items } from "./DashboardSidebar.helper";
import { DashboardSidebarStyles } from "./DashboardSidebar.styles";
import { DashboardSidebarProps } from "./DashboardSidebar.types";

export const DashboardSidebar = (props: DashboardSidebarProps) => {
  const { open, onClose } = props;

  const content = (
    <>
      <Box sx={DashboardSidebarStyles.BoxStyles.variant1}>
        <div>
          <Box sx={{ p: 3 }}>
            <Box sx={DashboardSidebarStyles.BoxStyles.variant2}></Box>
          </Box>

          <Box sx={DashboardSidebarStyles.BoxStyles.variant3}>
            {items.map((item, index) => (
              <div key={index}>{item.title}</div>
            ))}
          </Box>
        </div>
      </Box>
    </>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...DashboardSidebarStyles.DrawerStyles.variant1
        }
      }}
      sx={DashboardSidebarStyles.DrawerStyles.variant2}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};
