import { useAppSelector } from "hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { fetchAdminAllWishes, postNewWish } from "./wishes.service";
import { fetchWishDetails, editWish } from "./wishes.service";

export const usePostNewWish = () => {
  const queryClient = useQueryClient();

  return useMutation(postNewWish, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("notifications");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("notifications");
    }
  });
};

export const useEditWish = () => {
  const queryClient = useQueryClient();

  return useMutation(editWish, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("notifications");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("notifications");
    }
  });
};

export const useFetchWishDetails = (wishId?: number) => {
  const { user } = useAppSelector(state => state.authReducer);

  return useQuery(["wish", wishId], () => fetchWishDetails(wishId), {
    enabled: user?.rol === 10 && !!wishId,
    staleTime: 15 * 1000 * 60
  });
};

export const useFetchAllWishes = (take = 100, skip = 0) => {
  const { user } = useAppSelector(state => state.authReducer);

  return useQuery(["wish"], () => fetchAdminAllWishes(take, skip), {
    enabled: user?.rol === 10,
    staleTime: 15 * 1000 * 60
  });
};
