import HomeIcon from "@mui/icons-material/Home";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import { Breadcrumbs, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import useGlobals from "context/globals/globals.hooks";
import React, { useCallback } from "react";

import Styles from "./AdminClientList.styles";
import { AdminClientListProps as Props } from "./AdminClientList.types";
import AdminClientListRequests from "./AdminClientListRequests/AdminClientListRequests";
import AdminClientsList from "./AdminClientsList/AdminClientsList";
import AuctionHousesList from "./AuctionHousesList/AuctionHousesList";

const AdminClientList: React.FC<Props> = props => {
  const { selectedSubIndex, setSelectedIndex } = useGlobals();
  const { setSelectedSubIndex, selectedClient, setSelectedClient } =
    useGlobals();

  const renderBreadCrumb = () => {
    return (
      <div className="AdminClientList__breadCrumb" role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            href="/admin"
          >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Home
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => {
              setSelectedIndex(1);
              setSelectedSubIndex(0);
            }}
          >
            <PersonPinIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Clientes
          </Link>
          <Link
            underline="hover"
            sx={{ display: "flex", alignItems: "center" }}
            color="inherit"
            onClick={() => setSelectedClient(undefined)}
          >
            {selectedSubIndex === 0
              ? "Solicitudes de vendedores"
              : selectedSubIndex === 1
              ? "Listado de clientes"
              : "Listado de casas de subasta"}
          </Link>
          {selectedClient && (
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {`Cliente - ${selectedClient}`}
            </Typography>
          )}
        </Breadcrumbs>
      </div>
    );
  };

  const renderContent = useCallback(() => {
    switch (selectedSubIndex) {
      case 0:
        return <AdminClientListRequests />;
      case 1:
        return <AdminClientsList />;
      case 2:
        return <AuctionHousesList />;
      default:
        return <AdminClientListRequests />;
    }
  }, [selectedSubIndex]);

  return (
    <Styles className="AdminClientList">
      {renderBreadCrumb()}
      {renderContent()}
    </Styles>
  );
};

AdminClientList.defaultProps = {};

export default AdminClientList;
