import { yupResolver } from "@hookform/resolvers/yup";
import { FormControl, InputLabel } from "@mui/material";
import { Auth } from "aws-amplify";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import ErrorComponent from "components/Globals/ErrorComponent/ErrorComponent";
import InputBase from "components/Globals/InputBase/InputBase";
import useAuth from "context/auth/auth.hooks";
import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import PasswordChecklist from "react-password-checklist";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { resetAuctionsRedux } from "redux/actions/auctionActions";
import history from "routes/history";

import { messages } from "../RegisterModal/RegisterModal.helpers";
import { schema } from "./ForgotPassword.helpers";
import Styles from "./ForgotPassword.styles";
import { ForgotPasswordProps as Props } from "./ForgotPassword.types";

const ForgotPassword: React.FC<Props> = props => {
  const { handleModal } = props;
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pass, setPass] = useState("");
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { signIn, authError } = useAuth();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  const handleLogin = async (values: FieldValues) => {
    const { email, password } = values;

    try {
      await signIn(email, password).catch(error => {
        throw new Error(error.message);
      });
      if (!authError) {
        queryClient.invalidateQueries("user");
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries("user-data");
        queryClient.invalidateQueries("seller");
        reset();
        handleModal();
        const route = "/panel/mi-perfil";
        history.push(route);
        return;
      }
      throw new Error(authError?.message);
    } catch (error) {
      setIsError(true);
      fail("Error al conectarse");
    } finally {
      setIsLoading(false);
    }
  };

  const verifyCode = async (values: FieldValues) => {
    dispatch(resetAuctionsRedux());
    try {
      setIsLoading(true);
      const { verify, password } = values;
      await Auth.forgotPasswordSubmit(values.email, verify, password);
      handleLogin(values);
    } catch (error) {
      //console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const sendCode = async (values: FieldValues) => {
    try {
      if (!values.email) throw new Error("Debes completar el campo de correo.");
      setIsLoading(true);
      await Auth.forgotPassword(values.email);
      setStep(1);
    } catch (error) {
      // console.log(
      //   "🚀 ~ file: ForgotPassword.tsx:33 ~ sendCode ~ error:",
      //   error
      // );
    } finally {
      setIsLoading(false);
    }
  };

  const renderHelperTexts = () => {
    return (
      <div className="RegisterModal__textContainer">
        <p className="RegisterModal__text RegisterModal__text--register">
          ¿Aún no tienes una cuenta?.{" "}
          <span
            id="GoToLogin"
            onClick={handleModal}
            className="RegisterModal__text--register-action text-primary"
          >
            Regístrate
          </span>
        </p>
      </div>
    );
  };

  const renderFirstStep = () => {
    return (
      <form
        onSubmit={handleSubmit(sendCode)}
        className="RegisterModal__component--form"
      >
        <FormControl
          variant="standard"
          className="RegisterModal__component--form-control"
        >
          <InputLabel
            shrink
            htmlFor="email"
            className="RegisterModal__component--input"
          >
            Correo electrónico
          </InputLabel>
          <InputBase
            id="email"
            placeholder="Ingrese correo electrónico"
            {...register("email")}
            error={!!errors.email}
          />
          <ErrorComponent
            validation={!!errors.email}
            content={errors.email?.message}
          />
        </FormControl>
        <div className="RegisterModal__container--form-button">
          <ButtonLarge type="submit" variant="contained" loading={isLoading}>
            Enviar correo de verificación
          </ButtonLarge>
        </div>
        {renderHelperTexts()}
      </form>
    );
  };

  const renderSecondStep = () => {
    return (
      <form
        onSubmit={handleSubmit(verifyCode)}
        className="RegisterModal__component--form"
      >
        <FormControl
          variant="standard"
          className="RegisterModal__component--form-control"
        >
          <InputLabel
            shrink
            htmlFor="verify"
            className="RegisterModal__component--input"
          >
            Código de verificación
          </InputLabel>
          <InputBase
            id="verify"
            placeholder="Ingrese el código de verificación"
            {...register("verify")}
            error={!!errors.verify}
            type="number"
          />
          <ErrorComponent
            validation={!!errors.verify}
            content={errors.verify?.message}
          />
        </FormControl>

        <FormControl
          variant="standard"
          className="RegisterModal__component--form-control"
        >
          <InputLabel
            shrink
            htmlFor="password"
            className="RegisterModal__component--input"
          >
            Contraseña
          </InputLabel>
          <InputBase
            id="password"
            placeholder="Ingrese contraseña"
            {...register("password")}
            type="password"
            error={!!errors.password}
            onChange={e => setPass(e.currentTarget.value)}
            autoComplete="new-password"
          />
          <ErrorComponent
            validation={!!errors.password}
            content={errors.password?.message}
          />
        </FormControl>

        <PasswordChecklist
          rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
          minLength={8}
          value={pass}
          valueAgain={pass}
          onChange={isValid => setIsValid(isValid)}
          messages={messages}
          className="RegisterModal__passwordCheck"
          iconSize={12}
          invalidColor="#B0C4D8"
          style={{ lineHeight: 1, paddingBottom: 1 }}
        />

        <div className="RegisterModal__container--form-button">
          <ButtonLarge
            type="submit"
            variant="contained"
            loading={isLoading}
            disabled={!isValid}
          >
            Recuperar Contraseña
          </ButtonLarge>
        </div>
        {renderHelperTexts()}
      </form>
    );
  };

  return (
    <Styles className="ForgotPassword">
      <p className="ForgotPassword__helperText">
        Nota: Se le enviará un correo electrónico con el código de verificación
        el cual deberá ingresar para continuar con el flujo de recuperar
        contraseña.
      </p>
      {(isError || authError) && (
        <p className="ForgotPassword--error">
          Credenciales incorrectas o el usuario no fue verificado
        </p>
      )}
      {step === 0 ? renderFirstStep() : renderSecondStep()}
    </Styles>
  );
};

ForgotPassword.defaultProps = {};

export default ForgotPassword;
