import dayjs from "dayjs";
import React from "react";
import { createRoot } from "react-dom/client";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import App from "./App";
import "dayjs/locale/es";
import "antd/dist/antd.css";
import "./index.css";
dayjs.locale("es");

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
