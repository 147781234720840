import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { palette, fonts } = variables;

const Styles = styled.div`
  .AddStaggered {
    &__title {
      font-size: 16px;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      color: ${palette["primary"]};
      font-weight: 600;
      letter-spacing: 0.8px;
      margin-bottom: 16px;

      @media ${devices.tablet} {
        margin-bottom: 24px;
      }

      @media ${devices.laptop} {
        font-size: 18px;
      }

      @media ${devices.laptopL} {
        font-size: 20px;
      }

      @media ${devices.desktop} {
        font-size: 24px;
      }
    }

    &__container {
      display: flex;
      gap: 64px;
      flex-direction: column;

      &--button {
        display: flex;
        padding: 24px 0;
        justify-content: center;
      }

      &--prices {
        padding-left: 24px;
        height: 100%;
      }
    }

    &__button {
      &:hover {
        background-color: ${palette.primaryLight};
        color: ${palette.white};
      }
    }

    &__separator {
      &--components {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 24px;
      }
    }
  }
`;

export default Styles;
