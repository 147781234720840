import React from "react";
import { Swiper } from "swiper/react";

import Styles from "./CarouselCustom.styles";
import { CarouselCustomProps } from "./CarouselCustom.types";

const CarouselCustom = (props: CarouselCustomProps) => {
  const { children, height, width } = props;

  return (
    <Styles height={height} width={width}>
      <Swiper {...props} className="mySwiper">
        {children}
      </Swiper>
    </Styles>
  );
};

export default CarouselCustom;
