import styled from "styled-components";

import { AdminClientListRequestsStyledProps as Props } from "./AdminClientListRequests.types";

const AdminClientListRequestsStyled = styled.div<Props>`
  .AdminClientListRequests {
  }
`;

export default AdminClientListRequestsStyled;
