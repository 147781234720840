import { variables } from "config/variables";
import DataTable from "react-data-table-component";
import styled from "styled-components";

import { TableStyledProps as Props } from "./Table.types";

const { fonts, palette } = variables;

const TableStyled = styled(DataTable)((props: Props) => ({
  ".rdt_TableHeadRow": {
    color: `${palette["primaryLight"]}`,
    fontSize: "16px",
    borderBottom: "none",
    fontFamily: `${fonts["Poppins-El"]}`
  },
  ".rdt_TableRow": {
    cursor: "auto",
    fontSize: "16px",
    fontFamily: `${fonts["Poppins-El"]}`
  }
}));

export default TableStyled;
