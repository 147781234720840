import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ForumIcon from "@mui/icons-material/Forum";
import NotificationBell from "components/NotificationBell/NotificationBell";
const settings = ["Mi cuenta", "Salir"];

const MENU = [
  {
    icon: <AccountBalanceIcon size="small" />,
    title: "Subastas",
    to: "/panel/subastas"
  },
  {
    icon: <NotificationBell />,
    title: "Notificaciones"
    // to: "/panel/notificaciones"
  },
  {
    icon: <ForumIcon />,
    title: "Mis chats",
    to: "/panel/chat"
  }
];

export { settings, MENU };
