import Layout from "components/Layout/Layout";
import React from "react";

import Notifications from "./Notifications";
import { NotificationsContainerProps as Props } from "./Notifications.types";

const NotificationsContainer: React.FC<Props> = props => {
  return (
    <Layout>
      <Notifications />
    </Layout>
  );
};

NotificationsContainer.defaultProps = {};

export default NotificationsContainer;
