import { CalendarToday, Whatshot } from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Box, Tooltip, Typography, Divider } from "@mui/material";
import CustomModal from "components/CustomModal/CustomModal";
import TextField from "components/Globals/TextField/TextField";
import { CONSTANTS } from "config/constants";
import { useAppSelector } from "hooks";
import React, { useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { updateCurrentAuction } from "redux/actions/auctionActions";
import { useReuseAuction } from "services/Auctioners/Auctioners.service.mutation.hooks";
import { ReuseAuction } from "services/Auctioners/Auctioners.service.types";
import { useGetSellerData } from "services/Auth/Auth.services.hooks";
import { fail, successful } from "utils/Swal";

import history from "../../../routes/history";
import Button from "../../Globals/Button/Button";
import { formListDates } from "../createAuction/CreateAuction.helper";
import { getNumberOfDays } from "./AuctionCard.helpers";
import Styles, { modalStyles } from "./AuctionCard.styles";
import { AuctionCardProps } from "./AuctionCard.types";

const { IMAGE_PLACEHOLDER } = CONSTANTS;

const helper = (state: number, value?: number, streamer?: boolean) => {
  if (state === 3 || value === undefined || value > 0) {
    return <p className="AuctionCard__float__text__variant">VENCIDO</p>;
  }

  return state !== 2 ? (
    <>
      <div className="AuctionCard__float__content--icon">
        <CalendarToday fontSize="small" />
      </div>
      <p className="AuctionCard__float__text">{-value} días restantes</p>
    </>
  ) : (
    <p className="AuctionCard__float__text__variant">
      {streamer && <CameraAltIcon fontSize="small" />}
      <Whatshot fontSize="small" /> EN VIVO
    </p>
  );
};

function AuctionCard(props: AuctionCardProps) {
  const { auction } = props;
  const { url_logo_1, url_logo_2, url_logo_3 } = auction ?? {};
  const dispatch = useDispatch();

  const { title, date, date_ini, date_end, streamer } = auction ?? {};
  const { id, user_id, access, state, reuse } = auction ?? {};
  const { user } = useAppSelector(state => state.authReducer);
  const { data: seller } = useGetSellerData(user_id) ?? {};
  const { mutateAsync, reset } = useReuseAuction();
  const [openReuseModal, setOpenReuseModal] = useState(false);
  const { logo } = seller ?? {};
  const isOwner = user_id === user?.id;
  const dateAuction = date || date_ini;
  const days = getNumberOfDays(dateAuction);

  const { control, handleSubmit } = useForm();

  const handleNavigate = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    window.open(`/auctionProfile/${user_id}`);
  };

  const handleForm = async (values: FieldValues) => {
    try {
      const payload: ReuseAuction = {
        auctionId: `${id}`,
        ...values
      };
      await mutateAsync(payload);
      reset();
      setOpenReuseModal(false);
      successful("La Subasta se creó con éxito");
    } catch (error) {
      fail("Ocurrió un problema al reutilizar la subasta");
    }
  };

  const renderInputDate = () => {
    if (!streamer)
      return (
        <Controller
          name="date"
          control={control}
          defaultValue={date ?? ""}
          render={({ field, fieldState }) => (
            <TextField
              field={field}
              fieldState={fieldState}
              config={{
                type: "datetime-local",
                label: "Fecha y Hora",
                variant: "standard",
                fullWidth: true,
                focused: true
              }}
            />
          )}
        />
      );

    return (
      <>
        <Controller
          key={0}
          name={formListDates[0].name}
          control={control}
          rules={{
            required: formListDates[0]?.required
          }}
          defaultValue={date_ini}
          render={({ field, fieldState }) => (
            <TextField
              field={field}
              fieldState={fieldState}
              config={{
                type: formListDates[0].type,
                label: formListDates[0].placeholder,
                variant: "standard",
                fullWidth: true,
                focused: true
              }}
            />
          )}
        />
        <Divider sx={{ marginBottom: 2 }} />
        <Controller
          key={1}
          name={formListDates[1].name}
          control={control}
          rules={{
            required: formListDates[1]?.required
          }}
          defaultValue={date_end}
          render={({ field, fieldState }) => (
            <TextField
              field={field}
              fieldState={fieldState}
              config={{
                type: formListDates[1].type,
                label: formListDates[1].placeholder,
                variant: "standard",
                fullWidth: true,
                focused: true
              }}
            />
          )}
        />
      </>
    );
  };

  const reuseAuction = () => {
    return (
      <CustomModal
        isOpen={openReuseModal}
        onClose={() => setOpenReuseModal(!openReuseModal)}
        ariaLabelledby="Modal para resetear el current auction"
      >
        <Box sx={modalStyles}>
          <Typography
            variant="h6"
            sx={{ color: "hsl(204, 19%, 26%)", marginBottom: 2 }}
          >
            Seleccione el horario de la subasta
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "hsla(204, 19%, 26%, 0.7)", marginBottom: 2 }}
          >
            Una vez seleccionada la fecha, los lotes que no fueron subastados
            serán reutilizados en una nueva subasta, la cual podrá editar en el
            siguiente paso.
          </Typography>
          <form onSubmit={handleSubmit(handleForm)}>
            {renderInputDate()}
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "100%", marginTop: 4 }}
            >
              Continuar
            </Button>
          </form>
        </Box>
      </CustomModal>
    );
  };

  return (
    <Styles className="AuctionCard">
      <div className="AuctionCard__float">
        <div className="AuctionCard__float__content">
          {helper(state, days, streamer)}
        </div>
      </div>
      <div className="AuctionCard__container">
        <div className="AuctionCard__image-container--main">
          <img
            className="AuctionCard__image AuctionCard__image--main"
            src={url_logo_1 ? url_logo_1 : IMAGE_PLACEHOLDER}
            alt="Logo1"
          />
        </div>
        <div className="AuctionCard__image-container--secondary">
          <img
            className="AuctionCard__image AuctionCard__image--secondary"
            src={url_logo_2 ? url_logo_2 : IMAGE_PLACEHOLDER}
            alt="Logo2"
          />
          <img
            className="AuctionCard__image AuctionCard__image--secondary"
            src={url_logo_3 ? url_logo_3 : IMAGE_PLACEHOLDER}
            alt="Logo3"
          />
        </div>
      </div>
      <div className="AuctionCard__info-container">
        <div className="AuctionCard__logo">
          <img
            className="AuctionCard__image AuctionCard__image--main"
            src={logo ? logo : IMAGE_PLACEHOLDER}
            alt="UserLogo"
            onClick={handleNavigate}
          />
        </div>
        <div className="AuctionCard__info__text">
          <Tooltip title={title}>
            <h3 className="AuctionCard__title">{title}</h3>
          </Tooltip>
          <Tooltip title={`Comienza ${date ?? date_ini}`}>
            <h4 className="AuctionCard__starts">{`Comienza ${
              date ?? date_ini
            }`}</h4>
          </Tooltip>
        </div>
        <div>
          <Button
            className="AuctionCard__button"
            variant="text"
            rounded="false"
            onClick={() => {
              dispatch(updateCurrentAuction(auction));
              history.push(`/auction/${id}`);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            size="large"
            endIcon={<ArrowForwardIcon />}
          >
            {days !== 0 ? "Ver Catalogo" : "Realice su oferta"}
          </Button>
          {reuse && isOwner ? (
            <p
              className="AuctionCard__link"
              onClick={() => setOpenReuseModal(true)}
            >
              Reutilizar subasta
            </p>
          ) : null}
          {access === 2 && isOwner ? (
            <Link to={`/applicantList/${id}`} className="AuctionCard__link">
              Ver lista de aplicantes
            </Link>
          ) : null}
        </div>
      </div>
      {reuseAuction()}
    </Styles>
  );
}

export default AuctionCard;
