export const formList = [
  {
    label: "name",
    type: "text",
    placeholder: "Nombre",
    _placeholder: "Lote 01: Caja antigua barroca",
    validationRules: {
      required: "Añade un precio."
    }
  },
  {
    label: "price_base",
    type: "number",
    placeholder: "Precio base",
    _placeholder: "1000",
    validationRules: {
      required: "Añade un precio."
    }
  },
  {
    label: "price_estimated1",
    type: "number",
    placeholder: "Precio estimado menor",
    _placeholder: "1200",
    validationRules: {
      required: "Añade un precio."
    }
  },
  {
    label: "price_estimated2",
    type: "number",
    placeholder: "Precio estimado mayor",
    _placeholder: "1500",
    validationRules: {
      required: "Añade un precio."
    }
  },
  {
    label: "photo",
    type: "file",
    placeholder: "Imagen 1",
    validationRules: {
      required: "Sube una imagen."
    }
  },
  {
    label: "photo2",
    type: "file",
    placeholder: "Imagen 2"
  },
  {
    label: "photo3",
    type: "file",
    placeholder: "Imagen 3"
  },
  {
    label: "photo4",
    type: "file",
    placeholder: "Imagen 4"
  },
  {
    label: "description",
    type: "text",
    placeholder: "Descripción",
    _placeholder: "Escribe aquí una breve descripción del lote a subastar",
    validationRules: {
      required: "Se necesita agregar una descripción al lote."
    }
  }
];

export const selectList = {
  label: "status",
  placeholder: "Estado",
  validationRules: {
    required: "Selecciona una opcion.",
    validate: (value: number) =>
      value !== 0 || "Por favor, seleccione una opcion."
  },
  options: [
    { id: 0, name: "Escoge una opcion" },
    { id: 1, name: "Activo" },
    { id: 2, name: "Inactivo" }
  ]
};
