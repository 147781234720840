import styled from "styled-components";

import { SkeletonInputStyledProps as Props } from "./SkeletonInput.types";

const SkeletonInputStyled = styled.div<Props>`
  .SkeletonInput {
    &__component {
      &--label {
        font-size: 12px;
        width: 50%;
      }
      &--input {
        font-size: 24px;
      }
    }
  }
`;

export default SkeletonInputStyled;
