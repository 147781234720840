import swal from "sweetalert";

export const successful = (text = "") => {
  swal({
    title: "!Hecho!",
    text: text === "" ? "Creado Correctamente" : text,
    icon: "success",
    timer: 2000,
    buttons: [false]
  });
};

export const fail = (text = "") => {
  swal({
    title: "!Error!",
    text: text === "" ? "Algo salio mal, intentelo de nuevo" : text,
    icon: "error",
    timer: 2000,
    buttons: [false]
  });
};

export const confirm = (text = "") => {
  return swal({
    title: "Estas seguro de esta acción?",
    text: text ?? "Una vez eliminado, el lote no podrá recuperarse",
    icon: "warning",
    buttons: true,
    dangerMode: true
  });
};

export const advice = (title = "", text = "") => {
  swal(title, text);
};
