import { variables } from "config/variables";
import styled from "styled-components";

import { AdminDashboardStyledProps as Props } from "./AdminDashboard.types";

const { palette } = variables;

const AdminDashboardStyled = styled.div<Props>`
  display: flex;
  gap: 0;
  align-items: flex-start;
  background-color: ${palette.whiteBackground};

  .AdminDashboard {
    display: flex;
    width: 100%;
    min-height: 100vh;
    margin: 64px 0;
    padding: 32px 0;

    &__content {
      width: 100%;
      margin-left: 320px;
      display: flex;
      flex-direction: column;
    }
  }
`;

export default AdminDashboardStyled;
