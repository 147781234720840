import { Link, List, ListItem } from "@mui/material";
import { ListItemText, Menu, Tooltip } from "@mui/material";
import React, { useState } from "react";

import { HoverNavItemStyles, Styles } from "./HoverNavItem.styles";
import { HoverNavItemProps } from "./HoverNavItem.types";

const HoverNavItem = (props: HoverNavItemProps) => {
  const { category, children } = props;
  const { subcategories } = category;
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Tooltip title={`Ver ${children}`} onClick={handleOpenUserMenu}>
        <Link
          color="inherit"
          noWrap
          variant="body2"
          sx={HoverNavItemStyles.LinkStyles}
        >
          {children}
        </Link>
      </Tooltip>

      <Menu
        sx={HoverNavItemStyles.MenuStyles}
        id="menu-bar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Styles>
          <div className="Menu__container">
            <div className="Menu__list">
              <List dense={true}>
                {subcategories.length ? (
                  subcategories.map(sub => (
                    <ListItem key={sub.id}>
                      <ListItemText primary={sub.name} />
                    </ListItem>
                  ))
                ) : (
                  <div className="text-gray-700 flex items-center justify-center p-3 px-5">
                    No hay subcategorias
                  </div>
                )}
              </List>
            </div>
          </div>
        </Styles>
      </Menu>
    </>
  );
};

export default HoverNavItem;
