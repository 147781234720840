import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { AddPrimaStyledProps as Props } from "./AddPrima.types";
const { palette, fonts } = variables;

const AddPrimaStyled = styled.div<Props>`
  margin-top: 24px;
  margin-bottom: 40px;

  & > h1 {
    color: ${palette.primary};
    font-family: ${fonts["Poppins-Lt"]};
    font-size: 22px;
    margin-top: 20px;
  }

  &,
  * > h3 {
    color: ${palette.blackTable};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 16px;
  }

  & > p {
    color: ${palette.grey};
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 14px;
    text-align: justify;
  }

  .AddPrima {
    &__title {
      font-size: 16px;
      text-align: center;
      font-family: ${fonts["Poppins-El"]};
      color: ${palette["primary"]};
      font-weight: 600;
      letter-spacing: 0.8px;
      margin-bottom: 16px;

      @media ${devices.tablet} {
        margin-bottom: 24px;
      }

      @media ${devices.laptop} {
        font-size: 18px;
      }

      @media ${devices.laptopL} {
        font-size: 20px;
      }

      @media ${devices.desktop} {
        font-size: 24px;
      }
    }

    &__table {
      width: 48%;

      &--disabled {
        background-color: ${palette.pageDisabled};
        border-radius: 8px;
      }
    }

    &__tablesContainer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      gap: 16px;

      @media ${devices.laptopL} {
        gap: 20%;
      }
    }
  }
`;

export default AddPrimaStyled;
