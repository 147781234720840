import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useState } from "react";

import Styles, { IconStyle } from "./InputBase.styles";
import { InputBaseProps as Props } from "./InputBase.types";

const InputBase: React.FC<Props> = React.forwardRef((props, ref) => {
  const { type, onChange, autoComplete } = props;
  const [hidden, setHidden] = useState(true);

  // workaround to avoid chrome autofill register form.
  const [readOnly, setReadOnly] = useState(
    autoComplete === "new-password" || autoComplete === "off"
  );

  switch (type) {
    case "password":
      return (
        <Styles
          {...props}
          ref={ref}
          onChange={onChange}
          type={hidden ? "password" : "text"}
          autoComplete={autoComplete}
          readOnly={readOnly}
          onFocus={() => setReadOnly(false)}
          endAdornment={
            hidden ? (
              <VisibilityIcon
                sx={IconStyle}
                onClick={() => setHidden(!hidden)}
              />
            ) : (
              <VisibilityOffIcon
                sx={IconStyle}
                onClick={() => setHidden(!hidden)}
              />
            )
          }
        />
      );

    default:
      return <Styles {...props} ref={ref} />;
  }
});

InputBase.defaultProps = {};

export default InputBase;
