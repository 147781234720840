const clients = [
  {
    date: "Oct 01, 2021",
    title: "Fine Art & Antiques",
    author: "Live Auction | by Simpson Galleries",
    src: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/banner-1.jpg",
    link: "https://dev.livesubastas.com/panel/subastas"
  },
  {
    date: "Oct 01, 2022",
    title: "Fine Art & Antiques",
    author: "Live Auction | by Simpson Galleries",
    src: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/banner-2.jpg",
    link: "https://dev.livesubastas.com/registro-vendedor"
  },
  {
    date: "Oct 01, 2022",
    title: "Fine Art & Antiques",
    author: "Live Auction | by Simpson Galleries",
    src: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/banner-3.jpg",
    link: null
  }
];

const images = [
  "https://images.liveauctioneers.com/static/mail/images/features/20190107_lh.jpg?quality=70"
];

const auctioneers = [
  {
    img: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/PATRICIONNN.png",
    to: "",
    name: "Patricio Dragui"
  },
  {
    img: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/PATRICIONN.png",
    to: "",
    name: "Patricion"
  },
  {
    img: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/logotipo+bottero+N.png",
    to: "",
    name: "Bottero"
  },
  {
    img: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/lkcurvaN.png",
    to: "",
    name: "SouthAmerica Art"
  },
  {
    img: "https://dev-livesubastas.s3.us-east-1.amazonaws.com/images/static/KantiguedaesN.png",
    to: "",
    name: "K Antiguedades"
  }
];

export { clients, images, auctioneers };
