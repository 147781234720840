import { IoSearch } from "react-icons/io5";
import React from "react";

import Styles from "./SearchBar.styles";
import { SearchBarProps as Props } from "./SearchBar.types";

// TODO: Pending
const SearchBar: React.FC<Props> = props => {
  return (
    <Styles className={`SearchBar`}>
      <div className="SearchBar__component">
        <label className="SearchBar__component--label">
          <input
            className="placeholder:text-gray-400 bg-white border border-gray-300 shadow-sm focus:outline-none focus:border-primary hover:border-primary sm:text-sm SearchBar__component--input"
            placeholder="Buscar aquí..."
            type="text"
          />
          <span className="absolute right-0 inset-y-0 flex items-center pr-4">
            <IoSearch className="h-6 w-6 fill-primary" />
          </span>
        </label>
      </div>
    </Styles>
  );
};

SearchBar.defaultProps = {};

export default SearchBar;
