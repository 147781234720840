import React, { useState } from "react";
import { Button, Popconfirm } from "antd";

import BrandModal from "./BrandModal";
import { Brand } from "services/Categories/categories.service.types";
import {
  useCategories,
  useDeleteBrand,
  useEditBrand
} from "services/Categories/categories.service.hooks";

type BrandItemProps = {
  brand: Brand;
  options: any;
};

const BrandItem = ({ brand, options }: BrandItemProps) => {
  const [isEditBrandModalOpen, setIsEditBrandModalOpen] = useState(false);

  const { data: categories } = useCategories();
  const { mutate: editBrand } = useEditBrand();
  const { mutate: deleteBrand } = useDeleteBrand();

  const { name, sub_Category, id, sub_category_id } = brand;

  return (
    <>
      <div className="p-3 border-[1px] rounded-lg flex justify-between items-center">
        <div className="grid grid-cols-2  grow">
          <span>{name}</span>
          <span>{sub_Category}</span>
        </div>
        <div className="flex items-center flex-none ">
          <Button onClick={() => setIsEditBrandModalOpen(true)}>Editar</Button>
          <Popconfirm
            placement="topLeft"
            title="Seguro desea eliminar?"
            onConfirm={() => deleteBrand(id)}
            okText="Si"
            cancelText="No"
          >
            <Button>Eliminar</Button>
          </Popconfirm>
        </div>
      </div>
      <BrandModal
        title="Editar marca"
        key={isEditBrandModalOpen ? "open" : "close"}
        isOpen={isEditBrandModalOpen}
        options={options}
        initialValue={{
          name,
          subcategoryId: sub_category_id,
          categoryId:
            categories?.find(c =>
              c.subcategories.find(s => s.id === sub_category_id)
            )?.id ?? -1
        }}
        onCancel={() => setIsEditBrandModalOpen(false)}
        onConfirm={({ name, subcategoryId }) => {
          setIsEditBrandModalOpen(false);
          editBrand({
            id,
            name,
            subcategoryId
          });
        }}
      />
    </>
  );
};

export default BrandItem;
