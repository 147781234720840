import styled from "styled-components";

import { variables } from "../../../config/variables";

const { palette, fonts } = variables;

const Styles = styled.div`
  display: flex;
  flex-wrap: wrap;

  .ListFavorites {
    &__container {
      display: flex;
      width: 88%;
      align-items: center;
      align-self: flex-start;
      margin: 0 0 16px 16px;
    }

    &__label {
      display: block;
      font-size: 20px;
      font-family: ${fonts["Poppins-El"]};
      color: ${palette.primary};
      padding: 0 24px;
    }

    &__input {
      font-family: Poppins Rg;
      font-size: 16px;
      font-weight: 320;
      line-height: 24px;
      letter-spacing: 1.2px;
    }
  }
`;

export default Styles;
