import ListAuctions from "components/Auctioners/listAuctions/ListAuctions";
import CalendarFilters from "components/CalendarFilters/CalendarFilters";
import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import { useGetCalendarAuctions } from "services/calendar/calendar.service.hooks";
import { useGetAllCountries } from "services/calendar/calendar.service.hooks";

import Styles from "./Calendar.styles";
import { CalendarProps as Props } from "./Calendar.types";

const Calendar: React.FC<Props> = props => {
  const todayDate = new Date();
  const previous = new Date(todayDate.getTime());
  previous.setDate(todayDate.getDate() - 30);
  const dateLast30Days = dayjs(previous);
  const defaultDates = [
    moment(dateLast30Days.format("YYYY-MM-DD")),
    moment(dayjs().format("YYYY-MM-DD"))
  ];
  const [country, setCountry] = useState<number | undefined>(1);
  const [selectedDate, setSelectedDate] = useState<string[]>([
    defaultDates[0].toString(),
    defaultDates[1].toString()
  ]);
  const [auctionType, setAuctionType] = useState<number | undefined>();
  const [activeLivestream, setActiveLivestream] = useState<
    boolean | undefined
  >();
  const [bestValue, setBestValue] = useState<boolean | undefined>();
  const { data: countries } = useGetAllCountries();
  const { data } = useGetCalendarAuctions(
    dayjs(selectedDate[0]).format("YYYY-MM-DD"),
    dayjs(selectedDate[1]).format("YYYY-MM-DD"),
    auctionType,
    0,
    50,
    country,
    activeLivestream,
    bestValue
  );

  return (
    <Styles className="Calendar">
      <div className="Calendar__title--container">
        <h4 className="Calendar__title">Calendario de subastas</h4>
      </div>
      <div className="Calendar__content">
        <CalendarFilters
          setSelectedDate={setSelectedDate}
          defaultDates={defaultDates}
          setAuctionType={setAuctionType}
          auctionType={auctionType}
          countries={countries}
          country={country}
          setCountry={setCountry}
          activeLivestream={activeLivestream}
          setActiveLivestream={setActiveLivestream}
          bestValue={bestValue}
          setBestValue={setBestValue}
        />
        <ListAuctions auctionList={data} className="Calendar__auctions" />
      </div>
    </Styles>
  );
};

Calendar.defaultProps = {};

export default Calendar;
