import styled from "styled-components";

import { AdminClientListStyledProps as Props } from "./AdminClientList.types";

const AdminClientListStyled = styled.div<Props>`
  width: 100%;

  .AdminClientList {
    &__breadCrumb {
      margin-bottom: 16px;
    }
  }
`;

export default AdminClientListStyled;
