import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Alert } from "@mui/material";
import Button from "components/Globals/Button/Button";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import TextField from "components/Globals/TextField/TextField";
import TextFieldFile from "components/Globals/TextFieldFile/TextFieldFile";
import UploadMultipleFileImages from "components/UploadMultipleFileImages/UploadMultipleFileImages";
import parse from "html-react-parser";
import React, { useRef, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateCurrentAuctionStep } from "redux/actions/auctionActions";
import { updateCurrentAuction } from "redux/actions/auctionActions";
import history from "routes/history";
import { uploadItemsByBatch } from "services/Auctioners/Auctioners.service";
import { addPhotoToItem } from "services/Auctioners/Auctioners.service";
import { addItemService } from "services/Auctioners/Auctioners.service";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import { useMyAuctions } from "services/Auctioners/Auctioners.service.hooks";
import { Item, ItemForm } from "services/Auctioners/Auctioners.service.types";
import { fail, successful } from "utils/Swal";

import ItemCard from "../itemCard/ItemCard";
import { formList } from "./AddItem.helper";
import Styles from "./AddItem.styles";
import { AddItemProps } from "./AddItem.types";
import AddItemByBatch from "./AddItemByBatch/AddItemByBatch";

function AddItem(props: AddItemProps) {
  const { auctionId } = props;
  const {
    data: auctionData,
    refetch: refetchAuction,
    isLoading: isLoadingAuction
  } = useGetAuctionById(auctionId);

  const { refetch } = useMyAuctions();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(isLoadingAuction);
  const resetInputFile = useRef<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { control, handleSubmit, reset } = useForm();
  const { date, date_ini, items } = auctionData ?? {};
  const hasStarted = new Date(date_ini ?? date ?? "") < new Date();
  const [uploadType, setUploadType] = useState(0);
  const [uploadWarnings, setUploadWarnings] = useState("");

  const handleNavigate = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push(`/auction/${auctionId}`);
  };

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      const file = fileInputRef.current?.files?.[0];
      if (file) formData.append("lots_import", file);
      await uploadItemsByBatch(auctionId, formData)
        .then(response => {
          setUploadWarnings(response);
          refetchAuction();
          successful("Los lotes fueron cargados correctamente");
        })
        .catch(error => {
          fail(error.error);
        });
    } catch (error) {
      fail(error.error);
    }
  };

  const handleFormSend = async (values: FieldValues) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("description", values.description);
      formData.append("name", values.name);
      //@ts-ignore
      formData.append("price_base", +values.price_base);
      //@ts-ignore
      formData.append("price_estimated1", +values.price_estimated1);
      //@ts-ignore
      formData.append("price_estimated2", +values.price_estimated2);
      //@ts-ignore
      formData.append("status", 1);
      if (values.photo[0]) formData.append("photo", values.photo[0]);

      const response = await addItemService(auctionId, formData as ItemForm);
      if (values.photo2[0]) {
        const tempFormData = new FormData();
        tempFormData.append("photo", values.photo2[0]);
        await addPhotoToItem(response?.id, tempFormData);
      }
      if (values.photo3[0]) {
        const tempFormData = new FormData();
        tempFormData.append("photo", values.photo3[0]);
        await addPhotoToItem(response?.id, tempFormData);
      }

      if (values.photo4[0]) {
        const tempFormData = new FormData();
        tempFormData.append("photo", values.photo4[0]);
        await addPhotoToItem(response?.id, tempFormData);
      }

      document.getElementById("reset")?.click();
      setIsLoading(false);
      reset();
      resetInputFile.current = true;
      refetchAuction();
      successful("Se agregó el lote correctamente");
    } catch (error) {
      fail(error.message ?? "Hubo un error al agregar el lote");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSend = () => {
    refetch();
    if (hasStarted) dispatch(updateCurrentAuction(undefined));
    successful("Se guardaron los lotes correctamente");
    dispatch(updateCurrentAuctionStep(0));
    handleNavigate();
  };

  const renderButton = () => {
    const total = auctionData?.lots ? auctionData?.lots + 1 : null;
    return total;
  };

  const renderItemContainer = () => (
    <div className="AddItem__itemsSection">
      <h2 className="AddItem__title">Lotes cargados</h2>
      <div className="AddItem__items-container">
        {items?.map((item: Item, idx: number) => (
          <ItemCard item={item} auctionId={auctionId} key={idx} />
        ))}
      </div>
    </div>
  );

  const handleOnClick = () => {
    fileInputRef.current?.click();
  };

  const renderBatchSection = () => {
    return (
      <div className="AddItem__batchSection">
        <h3 className="AddItem__subTitle">Agregar lotes en conjunto</h3>
        <div className="AddItem__buttonSection">
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileUpload}
          />
          <Button
            startIcon={<UploadFileIcon />}
            rounded="false"
            onClick={handleOnClick}
            variant="contained"
            sx={{ borderRadius: 2, width: 200 }}
            loading={isLoadingAuction || isLoading}
          >
            Subir archivo
          </Button>
          <Button
            startIcon={<FileDownloadIcon />}
            rounded="false"
            onClick={() =>
              window.open(
                `https://dev.api.livesubastas.com/templates/template_lots.xlsx`
              )
            }
            variant="outlined"
            sx={{ borderRadius: 2, width: 200 }}
            loading={isLoadingAuction || isLoading}
          >
            Descargar plantilla
          </Button>
        </div>

        <p className="AddItem__paragraph">
          Al descargar la plantilla necesitaras usar un programa de Excel 2010,
          formato XLSX y otros tipos de formatos como CSV y texto delimitado son
          aceptados. Los precios de reserva no son soportados.
        </p>
      </div>
    );
  };

  const renderUploadByBatch = () => {
    return (
      <div>
        <div>
          <Alert severity="info">
            <ul>
              <li>
                Tener en cuenta que al subir un archivo comprimido con las
                imágenes de los lotes, este solo puede estar en formato .zip,
                los demás formatos no son aceptados temporalmente.
              </li>
              <li>
                Para subir un archivo comprimido con imágenes, lleven la
                nomenclatura #lote_#posición.png - ejemplo (1_1.png), los
                formatos aceptados son .jpg, .jpeg .png, .webp, .gif y .svg.
              </li>
              <li>
                Una vez arrastradas o seleccionadas las imágenes, los nombres de
                estas se verán reflejadas en el recuadro de arriba, una vez
                listas, presione en subir imágenes para la carga de las mismas.
              </li>
            </ul>
          </Alert>
        </div>
        <div className="AddItem__batchContainer">
          {renderBatchSection()}
          {items && items?.length > 0 && (
            <UploadMultipleFileImages
              auctionId={auctionId}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </div>
      </div>
    );
  };

  const renderErrorsTable = () => {
    return (
      <div className="AddItem__warningSection">
        <h3 className="AddItem__subTitle mb-2">Avisos de errores</h3>
        <p className="opacity-60 text-red-600 text-base">
          {parse(uploadWarnings.replaceAll("/n", "<br/>"))}
        </p>
      </div>
    );
  };

  const renderUploadSingle = () => {
    return (
      <form onSubmit={handleSubmit(handleFormSend)} name="reset">
        <input
          type="reset"
          name="reset"
          id="reset"
          className="AddItem__reset"
        />
        <div className="AddItem__form--item">
          {formList.map((item, index) => (
            <Controller
              name={item.label}
              control={control}
              rules={item.validationRules}
              defaultValue=""
              key={index}
              render={({ field, fieldState }) => {
                return item.type === "file" ? (
                  <TextFieldFile
                    field={field}
                    config={{ label: item.placeholder }}
                    imagenAction={false}
                  />
                ) : (
                  <TextField
                    field={field}
                    fieldState={fieldState}
                    config={{
                      type: item.type,
                      label: item.placeholder,
                      variant: "standard",
                      margin: "dense",
                      fullWidth: true,
                      focused: true,
                      placeholder: item._placeholder,
                      multiline: item.label === "description",
                      rows: item.label === "description" ? 2 : 1
                    }}
                  />
                );
              }}
            />
          ))}
        </div>
        <div className="AddItem__container--button">
          <ButtonLarge type="submit" id="submit" variant="outlined">
            {`Agregar Lote ${renderButton() ?? " "}`}
          </ButtonLarge>
          <ButtonLarge
            type="button"
            id="finish"
            variant="contained"
            onClick={handleSend}
          >
            Terminar creación
          </ButtonLarge>
        </div>
      </form>
    );
  };

  return (
    <Styles className="AddItem">
      {isLoading && <CircularProgress />}
      <h2 className="AddItem__title">Carga de lotes</h2>
      <AddItemByBatch uploadType={uploadType} setUploadType={setUploadType} />
      {uploadType !== 0 ? renderUploadSingle() : renderUploadByBatch()}
      {uploadType === 0 && uploadWarnings?.length > 1 && renderErrorsTable()}
      {uploadType === 0 && (
        <div className="AddItem__container--button">
          <ButtonLarge
            type="button"
            id="finish"
            variant="contained"
            onClick={handleSend}
            disabled={!!uploadWarnings && uploadWarnings?.length !== 0}
          >
            Terminar creación
          </ButtonLarge>
        </div>
      )}
      {renderItemContainer()}
    </Styles>
  );
}

export default AddItem;
