import { ROL } from "config/roles.config";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const RouteAdmin = ({ component: Component, ...props }) => {
  const { user } = useSelector(state => state.authReducer);

  return (
    <Route
      {...props}
      render={props =>
        user?.rol === ROL.ADMIN ? (
          <Component {...props} />
        ) : (
          <Redirect to="/admin/login" />
        )
      }
    />
  );
};
export default RouteAdmin;
