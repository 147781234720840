/* TODO: Pending */
export const imagePlaceHolder =
  "https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder-1024x512.png";

export const selectList = {
  label: "ChangeType",
  placeholder: "Tipo de cambio",
  required: false,
  options: [
    { id: "USD", name: "Dolares Americanos" },
    { id: "PEN", name: "Nuevos Soles" },
    { id: "ARS", name: "Peso Argentino" }
  ]
};

export const add10SecondsToTimer = (currentTime: number) => {
  const TEN_SECONDS_IN_MS = 10 * 1000;
  const CURRENT_TIME_IN_MS = currentTime * 1000;
  const NOW_IN_MS = new Date().getTime();
  return TEN_SECONDS_IN_MS + CURRENT_TIME_IN_MS + NOW_IN_MS;
};
