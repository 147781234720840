import { TypingIndicator, Message } from "@chatscope/chat-ui-kit-react";
import { Avatar as CAvatar } from "@chatscope/chat-ui-kit-react";
import { VoiceCallButton } from "@chatscope/chat-ui-kit-react";
import { VideoCallButton, EllipsisButton } from "@chatscope/chat-ui-kit-react";
import { ChatContainer, MessageList } from "@chatscope/chat-ui-kit-react";
import { MessageInput, ConversationHeader } from "@chatscope/chat-ui-kit-react";
import Avatar from "@mui/material/Avatar";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAppSelector } from "hooks";
import React, { useRef, useState } from "react";
import { useSendMessage } from "services/userChat/userChat.service.hooks";
import { useFetchUserChatById } from "services/userChat/userChat.service.hooks";

import { chatStyles } from "../UserConversationList/UserConversationList.styles";
import Styles, { colorChat, colorHeader } from "./UserChatContainer.styles";
import { UserChatContainerProps as Props } from "./UserChatContainer.types";
import { idDocHelper } from "./UserChatHelper";

dayjs.extend(relativeTime);

const UserChatContainer: React.FC<Props> = props => {
  const { data, isLoading, isFetched, refetch, isError } =
    useFetchUserChatById();
  const { user } = useAppSelector(state => state.authReducer);
  const { mutateAsync, reset } = useSendMessage();
  const { user_two, replies } = data ?? {};
  const { name: userName } = user_two ?? {};
  const lastDateMessage = dayjs(replies?.[replies.length - 1]?.created_at)
    .from(new Date())
    .replace("days ago", "días atrás");

  const { currentChatId } = useAppSelector(state => state.chatReducer);

  const [messageInputValue, setMessageInputValue] = useState("");
  const [fileInputValue, setFileInputValue] = useState<File | undefined>();
  const inputFileRef = useRef(null);

  const sendMessageHandler = async () => {
    try {
      const formData = new FormData();
      formData.append("reply", messageInputValue);
      if (fileInputValue) formData.append("image", fileInputValue);

      await mutateAsync({ id: currentChatId, data: formData });
      setMessageInputValue("");
      setFileInputValue(undefined);
      reset();
    } catch (error) {
      throw new Error("Error at send message");
    }
  };

  const renderChatHeader = () => {
    return (
      <ConversationHeader style={colorHeader}>
        <ConversationHeader.Back />
        <CAvatar>
          <Avatar sx={chatStyles}>{userName?.slice(0, 1)}</Avatar>
        </CAvatar>
        <ConversationHeader.Content
          userName={userName}
          info={lastDateMessage}
          className="UserChatContainer__content-header"
        />
        <ConversationHeader.Actions>
          <VoiceCallButton disabled style={colorChat} />
          <VideoCallButton disabled style={colorChat} />
          <EllipsisButton orientation="vertical" disabled style={colorChat} />
        </ConversationHeader.Actions>
      </ConversationHeader>
    );
  };

  if (isLoading && !isFetched) return <CircularProgress />;

  if (!data && !isLoading && !isError) refetch();

  return (
    <Styles className="UserChatContainer">
      <input
        type="file"
        id="imgupload"
        ref={inputFileRef}
        style={{ display: "none" }}
        onChange={e => {
          setFileInputValue(e.target.files?.[0]);
        }}
      />
      <ChatContainer>
        {renderChatHeader()}
        <MessageList
          typingIndicator={
            <TypingIndicator
              content={fileInputValue?.name ?? lastDateMessage}
            />
          }
        >
          {replies?.map((replyObject, idx) => {
            const { user_id, file_url, reply = "", id } = replyObject ?? {};
            const isDoc = file_url && idDocHelper(file_url);
            const isMine = user_id === user?.id;
            const direction = isMine ? "outgoing" : "incoming";
            const senderName = isMine ? user.name : userName;
            const text = reply.match(/^(.*) descargar el archivo de:/)?.[1];
            const url =
              reply.match(/descargar el archivo de: (.*)$/)?.[1] ??
              file_url ??
              "";
            const hasUrl = url?.length > 1;

            return (
              <>
                {!isDoc && file_url ? (
                  <Message
                    key={`${idx}a`}
                    type={"image"}
                    model={{
                      direction: direction,
                      sentTime: "just now",
                      payload: {
                        src: file_url,
                        alt: `${senderName} image`,
                        width: "240px"
                      },
                      position: "normal"
                    }}
                    title={reply}
                  />
                ) : (
                  <Message
                    key={`${id}-${idx}`}
                    type={"html"}
                    model={{
                      sender: senderName,
                      direction: direction,
                      position: "normal",
                      sentTime: "just now"
                    }}
                  >
                    <Message.CustomContent>
                      {hasUrl || isDoc ? (
                        <>
                          <p
                            className={`UserChatContainer__text UserChatContainer__text--${direction}`}
                          >
                            {text}
                          </p>
                          <p
                            className={`UserChatContainer__link UserChatContainer__link--${direction}`}
                            onClick={() => window.open(url ?? file_url)}
                          >
                            {url ?? file_url}
                          </p>
                        </>
                      ) : (
                        <p
                          className={`UserChatContainer__text UserChatContainer__text--${direction}`}
                        >
                          {reply}
                        </p>
                      )}
                    </Message.CustomContent>
                  </Message>
                )}
              </>
            );
          })}
        </MessageList>

        <MessageInput
          placeholder="Escribe tu mensaje aquí..."
          value={messageInputValue}
          onChange={val => setMessageInputValue(val)}
          onSend={sendMessageHandler}
          onAttachClick={e => {
            //@ts-ignore
            inputFileRef.current.click();
          }}
        />
      </ChatContainer>
    </Styles>
  );
};

UserChatContainer.defaultProps = {};

export default UserChatContainer;
