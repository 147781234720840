import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

import { SearchBarStyledProps as Props } from "./SearchBar.types";

const { fonts } = variables;

const SearchBarStyled = styled.div<Props>`
  .SearchBar {
    &__component {
      display: none;

      @media ${devices.laptop} {
        display: block;
        width: 24%;
        margin: 0 8px;
        min-width: 200px;
      }

      @media ${devices.laptopL} {
        width: 100%;
        margin: 0 16px;
        min-width: 400px;
      }

      @media ${devices.desktop} {
        min-width: 600px;
      }

      &:hover {
        cursor: pointer;
      }

      &--label {
        position: relative;
        display: block;
        width: 545px;
      }

      &--input {
        display: block;
        border-radius: 25px;
        width: 100%;
        padding: 8px 12px 8px 25px;
        font-family: ${fonts["Poppins-Rg"]};
        letter-spacing: 0.4px;
        font-size: 12px;
        height: 39.46px;
      }
    }
  }
`;

export default SearchBarStyled;
