import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { fonts } = variables;

const Styles = styled.div`
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .EmptyState {
    &__text {
      font-size: 16px;
      font-family: ${fonts["Poppins-Lt"]};
      margin: 32px 0;
      text-align: center;

      @media ${devices.mobileL} {
        font-size: 20px;
      }

      @media ${devices.tablet} {
        font-size: 24px;
      }
    }
  }
`;

export default Styles;
