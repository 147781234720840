import { FormHelperText } from "@mui/material";
import React from "react";

import Styles from "./ErrorComponent.styles";
import { ErrorComponentProps as Props } from "./ErrorComponent.types";

const ErrorComponent: React.FC<Props> = props => {
  const { validation, content } = props;

  if (!validation) return null;

  return (
    <Styles className="ErrorComponent">
      <FormHelperText className="ErrorComponent__component--error">
        {content ?? "* Campo requerido"}
      </FormHelperText>
    </Styles>
  );
};

ErrorComponent.defaultProps = {};

export default ErrorComponent;
