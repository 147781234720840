import React, { useState } from "react";
import { Button, Collapse } from "antd";

import CategoryItem from "./CategoryItem";
import InputModal from "components/InputModal";
import {
  useCategories,
  useCreateCategory
} from "services/Categories/categories.service.hooks";

const { Panel } = Collapse;

const Categories: React.FC = () => {
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);

  const { data: categories, isLoading } = useCategories();
  const { mutate: createCategory } = useCreateCategory();

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="my-5 text-xl">Categorias</h1>
        <Button onClick={() => setIsAddCategoryModalOpen(true)}>
          Crear categoría
        </Button>
      </div>

      {categories?.length ? (
        <div className="w-full bg-red-50">
          <Collapse className="w-full" defaultActiveKey={["1"]} accordion>
            {categories.map(c => (
              <Panel header={c.name} key={c.id}>
                <CategoryItem category={c} />
              </Panel>
            ))}
          </Collapse>
        </div>
      ) : isLoading ? (
        <p>Cargando...</p>
      ) : (
        <p>No hay categorías</p>
      )}

      <InputModal
        title="Crear categoría"
        open={isAddCategoryModalOpen}
        onConfirm={async newName => {
          setIsAddCategoryModalOpen(false);
          await createCategory(newName);
        }}
        onCancel={() => setIsAddCategoryModalOpen(false)}
        placeholder="Nombre"
      />
    </>
  );
};

export default Categories;
