import { AccountBox, Logout } from "@mui/icons-material";
import ForumIcon from "@mui/icons-material/Forum";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Box, ListItemIcon, ListItemText } from "@mui/material";
import { IconButton, Menu } from "@mui/material";
import { Toolbar, Tooltip } from "@mui/material";
import MenuItem from "components/Globals/MenuItem/MenuItem";
import useAuth from "context/auth/auth.hooks";
import useGlobals from "context/globals/globals.hooks";
import { useAppSelector } from "hooks";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { logoutSuccess } from "redux/actions/authActions";
import history from "routes/history";
import { successful } from "utils/Swal";

import Button from "../../Globals/Button/Button";
import NavItem from "../../Globals/NavItem/NavItem";
import LoginModal from "../components/LoginModal/LoginModal";
import RegisterModal from "../components/RegisterModal/RegisterModal";
import { MENU } from "./DashboardAdminNavbar.helper";
import { DashboardNavbarRoot } from "./DashboardAdminNavbar.styles";
import { GuestLinksStyles } from "./DashboardAdminNavbar.styles";
import { NavbarStyles } from "./DashboardAdminNavbar.styles";
import { DashboardNavbarProps } from "./DashboardAdminNavbar.types";

export const DashboardAdminNavbar = (props: DashboardNavbarProps) => {
  const { onSidebarOpen, ...other } = props;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { openNotifications, setOpenNotifications } = useGlobals();
  const { cognitoLogout, isAnonymous } = useAuth();

  const { user } = useAppSelector(state => state.authReducer);
  const dispatch = useDispatch();

  const [modalLogin, setModalLogin] = useState(false);
  const [modalRegister, setModalRegister] = useState(false);

  const { reset } = useForm();

  const handleOpenUserMenu = (event: {
    currentTarget: React.SetStateAction<null>;
  }) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    dispatch(logoutSuccess(null));
    successful("Logout exitoso");
    cognitoLogout();
  };

  const handleEdit = () => {
    history.push("/panel/mi-perfil");
  };

  const handleChats = () => {
    history.push("/panel/chat");
  };

  // const handleCalendar = () => {
  //   history.push("/calendario");
  // };

  const handleRedirect = (route?: string) => {
    if (Object.keys(user).length !== 0 && route) history.push(route);
    if (!user?.id) onOpenCloseModalLogin();
    return;
  };

  const onOpenCloseModalLogin = () => {
    setModalLogin(!modalLogin);
    setModalRegister(false);
    reset({
      email: "",
      password: ""
    });
  };

  const onOpenCloseModalRegister = () => {
    setModalRegister(!modalRegister);
    setModalLogin(false);
    reset({
      email: "",
      password: "",
      name: "",
      terms: false
    });
  };

  const guestLinks = () => {
    return (
      <GuestLinksStyles>
        <div className="guestLinksStyle__container">
          <Button
            size="small"
            variant="contained"
            rounded="true"
            onClick={() => onOpenCloseModalLogin()}
          >
            Iniciar Sesión
          </Button>

          <Button
            size="small"
            variant="outlined"
            rounded="true"
            onClick={() => onOpenCloseModalRegister()}
          >
            Crear Cuenta
          </Button>
        </div>
      </GuestLinksStyles>
    );
  };

  const userLinks = () => (
    <>
      <Tooltip title="Abrir configuración">
        <Button
          variant="text"
          rounded="true"
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleOpenUserMenu}
        >
          {`Admin ${user?.name}`}
        </Button>
      </Tooltip>
      <Menu
        sx={NavbarStyles.MenuStyles}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            history.push("/admin/");
          }}
        >
          <ListItemIcon>
            <SupervisorAccountIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={NavbarStyles.MenuItemStyles}>
            Panel de administración
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <AccountBox fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={NavbarStyles.MenuItemStyles}>
            Mi cuenta
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleChats}>
          <ListItemIcon>
            <ForumIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={NavbarStyles.MenuItemStyles}>
            Mis chats
          </ListItemText>
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={NavbarStyles.MenuItemStyles}> Salir </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <DashboardNavbarRoot sx={NavbarStyles.root} {...other}>
        <Toolbar disableGutters sx={NavbarStyles.ToolbarStyles}>
          <IconButton
            onClick={onSidebarOpen}
            sx={NavbarStyles.IconButtonStyles.variant1}
          >
            <MenuIcon fontSize="small" />
          </IconButton>

          <Box sx={NavbarStyles.BoxStyles.variant4}>
            {MENU.map((item, index) => {
              const { title } = item;
              return (
                <NavItem
                  key={index}
                  onClick={() => {
                    if (title !== "Notificaciones") handleRedirect(item?.to);
                    else setOpenNotifications(!openNotifications);
                  }}
                  icon={item?.icon}
                >
                  {item.title}
                </NavItem>
              );
            })}
          </Box>

          {/* <SearchBar /> */}

          <Box sx={NavbarStyles.BoxStyles.variant3}>
            {!isAnonymous ? userLinks() : guestLinks()}
          </Box>
        </Toolbar>
      </DashboardNavbarRoot>

      <RegisterModal
        modalRegister={modalRegister}
        setModalRegister={setModalRegister}
        onOpenCloseModalLogin={onOpenCloseModalLogin}
      />
      <LoginModal
        setModalLogin={setModalLogin}
        modalLogin={modalLogin}
        onOpenCloseModalRegister={onOpenCloseModalRegister}
      />
    </>
  );
};
