import { useAppSelector } from "hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useIsLogged } from "services/Auth/Auth.services.hooks";

import { markNotificationAsRead } from "./notifications.service";
import { fetchNotifications } from "./notifications.service";

export const useFetchNotifications = (take = 5, skip = 0) => {
  const { isAuthenticated } = useIsLogged();
  const { user } = useAppSelector(state => state.authReducer);
  const { id } = user ?? {};

  return useQuery(["notifications"], () => fetchNotifications(take, skip), {
    enabled: !!id && isAuthenticated,
    staleTime: 15 * 1000 * 60
  });
};

export const useMarkNotificationAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(markNotificationAsRead, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("notifications");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("notifications");
    }
  });
};
