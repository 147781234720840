import IvsPlayer from "components/IvsPlayer/IvsPlayer";
import AdminDashboard from "containers/AdminDashboard/AdminDashboard.container";
import ApplicantList from "containers/ApplicantList/ApplicantList.container";
import Calendar from "containers/Calendar/Calendar.container";
import Home from "containers/Home";
import Notifications from "containers/Notifications/Notifications.container";
import SellerProfileContainer from "containers/SellerProfile/SellerProfile.container";
import UserChat from "containers/UserChat/UserChat.container";
import Wishes from "containers/Wishes/Wishes.container";
import Auctioners from "containers/auctioners/Auctioners";
import RegisterSeller from "containers/auctioners/RegisterSeller";
import Auction from "containers/auctions/Auction";
import Favorites from "containers/favoritesScreen/Favorites.screen";
import Item from "containers/itemScreen/Item.screen";
import LiveAuction from "containers/liveAuction/LiveAuction";
import Login from "containers/login/Login";
import MyBids from "containers/mybids/MyBids";
import Profile from "containers/user/Profile";
import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import RouteAdmin from "./PrivateRoutes/RouteAdmin";
import RouteSeller from "./PrivateRoutes/RouteSeller";
import RouteUser from "./PrivateRoutes/RouteUser";
import history from "./history";

const RouterComponent = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/registro-vendedor" component={RegisterSeller} />
        <Route exact path={`/auction/:id`} component={Auction} />
        <Route exact path={`/live-auction/:id`} component={LiveAuction} />
        <Route exact path={`/live-auction/stream/:id`} component={IvsPlayer} />
        <Route exact path={`/item/:auctionId/:id`} component={Item} />
        <Route
          exact
          path={`/auctionProfile/:id`}
          component={SellerProfileContainer}
        />
        <Route exact path="/panel/subastas" component={Auctioners} />
        <Route exact path="/calendario" component={Calendar} />
        <Route exact path="/panel/deseos" component={Wishes} />
        <Route exact path="/panel/mis-pujas" component={MyBids} />

        {/* Private Registered Routes */}
        <RouteUser exact path="/panel/mi-perfil" component={Profile} />
        <RouteUser exact path="/panel/subastas/:edit" component={Auctioners} />
        <RouteUser exact path="/panel/favoritos" component={Favorites} />
        <RouteUser exact path="/panel/chat" component={UserChat} />
        <RouteUser
          exact
          path="/panel/notificaciones"
          component={Notifications}
        />

        {/* Private Seller Routes */}
        <RouteSeller exact path="/seller" component={<h2>HOLA</h2>} />
        <RouteSeller
          exact
          path="/applicantList/:id"
          component={ApplicantList}
        />
        {/* Private admin Routes */}
        <Route exact path="/admin/login" component={Login} />
        <RouteAdmin exact path="/admin/" component={AdminDashboard} />
      </Switch>
    </Router>
  );
};
export default RouterComponent;
