import { Dispatch, SetStateAction } from "react";
import { checkAuctionStatus } from "services/Auctioners/Auctioners.service";
import { checkAuctionAccessCode } from "services/auctionAccess/auctionAccess.service";
import swal from "sweetalert";
import { fail } from "utils/Swal";

export const handleGetAccessCode = async (
  setHasAccess: Dispatch<SetStateAction<boolean>>,
  realCode?: string,
  auctionId?: number
) => {
  await swal("Escribe el código de acceso:", {
    content: "input",
    buttons: true
  } as any)
    .then(async value => {
      if (value === "") return;
      if (value !== realCode)
        throw new Error("Código incorrecto, intenta nuevamente");
      await checkAuctionAccessCode(auctionId, value).then(status => {
        if (status === 200) {
          setHasAccess(true);
        } else throw new Error("Código incorrecto, intenta nuevamente");
      });
    })
    .catch(e => {
      fail(e.message);
    });
};

export const handleDirectNavigate = async (id?: number) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  window.open(
    `/live-auction/${id}`,
    "_blank",
    "resizable=no,scrollbars=no,location=no, menubar=no,width=1836,height=1080,popup"
  );
};

export const handleNavigate = async (id?: number, state?: number) => {
  await checkAuctionStatus(id)
    .then(data => {
      if (data.status === 200 && data.state === 2) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        window.open(
          `/live-auction/${id}`,
          "_blank",
          "resizable=no,scrollbars=no,location=no, menubar=no,width=1536,height=1080,popup"
        );
      }
    })
    .catch(() =>
      fail("La subasta ha finalizado o esta por empezar. Vuelva a intentarlo.")
    );
};
