import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import React, { useState } from "react";
import { useGetUserExtraData } from "services/Auth/Auth.services.hooks";
import { PaymentData } from "services/Auth/Auth.services.types";

import AddCreditCard from "./AddCreditCard/AddCreditCard";
import Styles from "./MyPay.styles";

const visa = "/images/icons/visa.svg";
const master = "/images/icons/mastercard.svg";
const dinners = "/images/icons/dinners.svg";
const amex = "/images/icons/amex.svg";

const MyPay = () => {
  const [modalCreditCard, setModalCreditCard] = useState(false);
  const { data, isLoading } = useGetUserExtraData();
  const { payment: paymentData = [] } = data ?? {};

  const openCloseModalCreditCard = () => {
    setModalCreditCard(!modalCreditCard);
  };

  const renderCardThumbnail = (paymentId: string) => {
    switch (paymentId) {
      case "master":
        return master;
      case "visa":
        return visa;
      case "amex":
        return amex;
      case "dinners":
        return dinners;
      default:
        return visa;
    }
  };

  const renderCard = (card: PaymentData) => {
    const { id, payment_method, issuer } = card ?? {};
    const { id: paymentId, payment_type_id } = payment_method ?? {};

    return (
      <div className="MyPay__payment__item" key={id}>
        <div className="MyPay__payment__item__cell">
          <div>
            <h3 className="MyPay__payment__item-title text-primary">
              {issuer?.name ?? ""}
            </h3>
            <p>{payment_type_id === "credit_card" ? "Crédito" : "Débito"}</p>
          </div>

          <img
            src={renderCardThumbnail(paymentId)}
            alt={paymentId}
            className="MyPay__payment__item__cell--img"
          />
        </div>
        <div className="MyPay__payment__item__cell">
          <p
            className="MyPay__payment__item-address"
            style={{
              backgroundColor: "rgba(0,0,0,.87)",
              padding: "2px 5px",
              borderRadius: 6,
              color: "white"
            }}
          >
            **** **** **** {card.last_four_digits}
          </p>
          {/* <p
            className="MyPay__payment__item-address"
            style={{ color: "rgba(0,0,0,.87)" }}
          >
            {`${card.expiration_month}/25`}
          </p> */}
        </div>
      </div>
    );
  };

  const renderPayments = () => {
    return (
      <div className="MyPay__payment-container">
        <h2 className="MyPay__payment__title">Mis tarjetas</h2>
        <div className="MyPay__payment">
          {paymentData?.map(payment => renderCard(payment))}
        </div>
      </div>
    );
  };

  return (
    <Styles className="MyPay">
      {isLoading && <CircularProgress />}
      {!data ? (
        <h4 className="text-[17px]">
          No se encontró ninguna tarjeta en el archivo, agregue una hoy para
          aumentar sus posibilidades de ser aprobado para ofertar.
        </h4>
      ) : (
        renderPayments()
      )}
      <div className="MyPay__container--button">
        <ButtonLarge
          onClick={() => openCloseModalCreditCard()}
          type="submit"
          className="hover:bg-hprimary "
          variant="contained"
        >
          Añade una tarjeta de crédito
        </ButtonLarge>
      </div>
      <AddCreditCard
        modalCreditCard={modalCreditCard}
        setModalCreditCard={setModalCreditCard}
      />
    </Styles>
  );
};
export default MyPay;
