import React, { useEffect, useState } from "react";
import { Input, Modal } from "antd";

interface InputModalProps {
  open: boolean;
  onConfirm: (value: string) => void;
  onCancel: () => void;
  initialValue?: string;
  title: string;
  placeholder?: string;
}

const InputModal = ({
  open,
  onConfirm,
  onCancel,
  title,
  initialValue,
  placeholder
}: InputModalProps) => {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(initialValue ?? "");
  }, [initialValue, open]);

  return (
    <Modal
      title={title}
      open={open}
      onOk={() => onConfirm(text)}
      onCancel={onCancel}
      okButtonProps={{ disabled: !text }}
    >
      <Input
        className="!p-2 w-full"
        type="text"
        value={text}
        onChange={e => setText(e.target.value)}
        placeholder={placeholder}
      />
    </Modal>
  );
};

export default InputModal;
