import { CONSTANTS } from "config/constants";
// import dayjs from "dayjs";
import { Price } from "services/Auctioners/Auctioners.service.types";

const { INITIAL_TIME } = CONSTANTS;

export const getNextOffer = (
  currentPrice = 0,
  ActualPrice = 0,
  prices?: Price[]
) => {
  const bidItem =
    prices?.find(
      (price, idx) =>
        +price?.price > currentPrice && currentPrice < prices?.[idx + 1]?.price
    ) ?? prices?.[0];
  const currentValue = ActualPrice > currentPrice ? ActualPrice : currentPrice;
  return +currentValue + +(bidItem?.offert ?? 0);
};

export const getTimeRemain = (
  finalize?: string,
  remainTime?: number,
  timeZone?: string
) => {
  if (!finalize) return remainTime ?? INITIAL_TIME;
  const currentDate = new Date();
  const currentDateFormatted = currentDate.toLocaleString("en-US", {
    timeZone
  });
  const secA = new Date(finalize).getTime();
  const secB = new Date(currentDateFormatted).getTime();
  const timeLeft = Math.trunc(Math.round((secA - secB) / 1000) * 1000);
  return timeLeft > 0 ? timeLeft : 0;
};
