import { variables } from "config/variables";
import styled from "styled-components";

import { ReviewCardStyledProps as Props } from "./ReviewCard.types";

const { fonts, palette } = variables;

const ReviewCardStyled = styled.div<Props>`
  width: 64%;
  padding: 32px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin: 32px 0;
  border-bottom: 1px solid ${palette.palidGray};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 120px;
  font-family: ${fonts["Poppins-Rg"]};
  color: ${palette.colorDegradedTree};

  * > h3 {
    font-size: 16px;
    font-family: ${fonts["Poppins-Lt"]};
    line-height: 24px;
    margin-bottom: 8px;
  }

  * > h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .ReviewCard {
    &__header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 32px;
      padding: 16px 0;
    }

    &__textContent {
      width: 80%;
      height: 100%;
    }
  }
`;

export default ReviewCardStyled;
