import React, { useState } from "react";
import { Cascader, Input, Modal } from "antd";

type BrandModalProps = {
  isOpen: boolean;
  onConfirm: ({
    name,
    subcategoryId
  }: {
    name: string;
    subcategoryId: number;
  }) => void;
  onCancel: () => void;
  options: any;
  title: string;
  initialValue?: {
    name: string;
    categoryId: number;
    subcategoryId: number;
  };
};

const BrandModal = ({
  isOpen,
  onConfirm,
  onCancel,
  options,
  title,
  initialValue
}: BrandModalProps) => {
  const [newBrandName, setNewBrandName] = useState(initialValue?.name ?? "");
  const [selectedSubcategory, setSelectedSubcategory] = useState<
    number | undefined
  >(initialValue?.subcategoryId);

  return (
    <Modal
      title={title}
      open={isOpen}
      onOk={() => {
        if (selectedSubcategory)
          onConfirm({
            name: newBrandName,
            subcategoryId: selectedSubcategory
          });
      }}
      onCancel={onCancel}
      okButtonProps={{
        disabled: !newBrandName || !selectedSubcategory
      }}
    >
      <div className="flex flex-col gap-4">
        <Input
          className="!p-2 w-full"
          type="text"
          value={newBrandName}
          onChange={e => setNewBrandName(e.target.value)}
          placeholder="Nombre"
        />
        <Cascader
          className="!w-full"
          key={isOpen ? "open" : "close"}
          options={options}
          onChange={e => {
            setSelectedSubcategory(e[1] as number);
          }}
          onClear={() => setSelectedSubcategory(undefined)}
          placeholder="Subcategoría"
          defaultValue={
            initialValue
              ? [initialValue.categoryId, initialValue.subcategoryId]
              : undefined
          }
        />
      </div>
    </Modal>
  );
};

export default BrandModal;
