import FlatList from "flatlist-react";
import React, { useState } from "react";
import { Item } from "services/Auctioners/Auctioners.service.types";

import { useGetMyFavorites } from "../../../services/Auctioners/Auctioners.service.hooks";
import EmptyState from "../../emptyState/EmptyState";
import ItemCard from "../itemCard/ItemCard";
import Styles from "./ListFavorites.styles";

function ListFavorites() {
  const { data } = useGetMyFavorites();
  const { data: auctionList } = data ?? {};
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Styles className="ListFavorites">
      <div className="ListFavorites__container">
        <label className="ListFavorites__label" htmlFor="label">
          Buscar
        </label>
        <input
          value={searchTerm}
          className={`shadow appearance-none border rounded w-full
        py-3 px-3 text-gray-700 leading-tight focus:outline-none
        focus:shadow-outline focus:border-primary focus:ring-1
        focus:ring-primary ListFavorites__input `}
          onChange={e => {
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <FlatList
        list={auctionList}
        renderItem={(item: Item, idx: number) => (
          <ItemCard item={item} auctionId={"favoritos"} key={idx} />
        )}
        renderWhenEmpty={() => <EmptyState />}
        sortBy={["updated_at"]}
        sortDescending
        searchBy="title"
        searchTerm={searchTerm}
      />
    </Styles>
  );
}

export default ListFavorites;
