import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

// Config
import { ROL } from "../../config/roles.config";

const RouteSeller = ({ component: Component, ...props }) => {
  const { user } = useSelector(state => state.authReducer);

  return (
    <Route
      {...props}
      render={props =>
        user?.rol === ROL.SELLER ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};
export default RouteSeller;
