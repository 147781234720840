import { variables } from "config/variables";
import styled from "styled-components";

import { UserConversationListStyledProps as Props } from "./UserConversationList.types";

const { palette, shadows } = variables;

const UserConversationListStyled = styled.div<Props>`
  .UserConversationList {
  }
`;

export const chatStyles = {
  borderColor: palette.dividerColor,
  color: palette.primaryLight,
  borderWidth: 1,
  backgroundColor: "white"
};

export const selectedChat = {
  backgroundColor: palette.primaryLight,
  borderWidth: 1,
  borderColor: palette.primary,
  borderTop: 1,
  shadowBox: shadows.shadowCard
};

export const chatStyle = {
  borderWidth: 1,
  borderColor: palette.primary,
  shadowBox: shadows.shadowCard
};

export default UserConversationListStyled;
