import { devices } from "config/devices";
import styled from "styled-components";

import { variables } from "../../../config/variables";
import { CustomButtonStyleProps } from "./CustomButton.types";

const { palette, fonts } = variables;

const Styles = styled.button<CustomButtonStyleProps>`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid
    ${props => (props?.color !== "secondary" ? palette.primary : palette.white)};
  border-radius: 16px;
  padding: 4px 8px;
  min-width: 160px;
  width: 100%;
  background-color: ${props =>
    props?.color === "secondary" ? palette.white : props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props?.disabled ? 0.6 : 1)};

  &:hover {
    transform: ${props => (props?.disabled ? "scale(1.00)" : "scale(1.02)")};
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.06);
  }

  .Button {
    &__text {
      font-size: 12px;
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.primary};

      @media ${devices.laptop} {
        font-size: 16px;
      }

      &__secondary {
        color: ${palette.white};
      }
    }

    &__container {
      display: flex;
      min-width: 160px;
      align-items: center;
      gap: 8px;
    }
  }
`;

export default Styles;
