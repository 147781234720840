import UploadIcon from "@mui/icons-material/Upload";
import Button from "components/Globals/Button/Button";
import React, { useState, useEffect } from "react";
import { isUrl } from "utils/validations.utils";

import Styles from "./TextFieldFile.styles";
import { StateProps, TextFieldFileProps as Props } from "./TextFieldFile.types";

const TextFieldFile: React.FC<Props> = props => {
  const { field, config, image, imagenAction } = props;
  const textFieldValue = field.value;
  const [selectedImage, setSelectedImage] = useState<StateProps | undefined>(
    undefined
  );

  useEffect(() => {
    if (textFieldValue && textFieldValue.length > 0 && !isUrl(textFieldValue)) {
      field.onChange(textFieldValue);
      setSelectedImage(textFieldValue?.[0]);
    }
    if (textFieldValue === "") {
      setSelectedImage(undefined);
    }
  }, [field, textFieldValue]);

  // This function will be triggered when the file field change
  const imageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      field.onChange(e.target.files);
      setSelectedImage(e.target.files[0]);
    }
  };

  const renderSource = () => {
    if (selectedImage !== undefined && selectedImage)
      return URL.createObjectURL(selectedImage);
    return image;
  };

  const renderImage = () =>
    (selectedImage || image) && (
      <div
        className={`TextFieldFile__container--image ${
          imagenAction && "TextFieldFile__container--imageAuction"
        }`}
      >
        {renderSource() && (
          <img
            src={renderSource()}
            alt={config.label}
            className="TextFieldFile__component--image"
          />
        )}
      </div>
    );

  return (
    <Styles className="TextFieldFile">
      <label htmlFor={field.name} className="TextFieldFile__text--label">
        {config.label}
      </label>
      <div className="TextFieldFile__container--wrapper">
        <Button
          className="TextFieldFile__component--button"
          size="large"
          variant="outlined"
          startIcon={<UploadIcon />}
        >
          {selectedImage || image ? "Subir otra imagen" : "Subir imagen"}
        </Button>
        <input
          type="file"
          name={field.name}
          id={field.name}
          className="TextFieldFile__container--wrapper-input"
          ref={field.ref}
          onChange={imageChange}
          accept=".jpg, .jpeg, .png"
        />
      </div>
      {renderImage()}
    </Styles>
  );
};

TextFieldFile.defaultProps = {};

export default TextFieldFile;
