import React from "react";

import { FeaturedSeller } from "services/Auctioners/Auctioners.service.types";

type FeaturedActioneersProps = {
  auctioneer: FeaturedSeller;
};

const FeaturedAuctioneer = ({ auctioneer }: FeaturedActioneersProps) => {
  const { logo } = auctioneer;

  return (
    <div className="py-6 overflow-hidden rounded-[10px] flex items-center justify-center">
      <img src={logo} alt="Logo subastador" className="max-h-full" />
    </div>
  );
};

export default FeaturedAuctioneer;
