import useGlobals from "context/globals/globals.hooks";
import { useQuery } from "react-query";
import { useIsLogged } from "services/Auth/Auth.services.hooks";

import { fail } from "../../utils/Swal";
import {
  fetchAdminWinnedLotesById,
  fetchFeaturedSellers
} from "./Bids.service";
import { fetchAdminWinnedLotes } from "./Bids.service";
import { fetchSellLotes, fetchWinnedLotes } from "./Bids.service";
import { fetchSellerWinnedLotesById } from "./Bids.service";
import { fetchSellerWinnedLotes } from "./Bids.service";

// Seller
export const useFetchSellerWinnedLotes = (
  type = "1",
  date = "",
  query = "",
  limit = 100,
  skip = 0
) => {
  const { isAuthenticated } = useIsLogged();

  return useQuery(
    ["seller-bids", date, type, query, limit, skip],
    () => fetchSellerWinnedLotes(type, date, query, limit, skip),
    {
      enabled: isAuthenticated,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener los lotes ganados del usuario");
      }
    }
  );
};

export const useFetchSellerWinnedLotesById = (
  query = "",
  limit = 100,
  skip = 0
) => {
  const { isAuthenticated } = useIsLogged();
  const { currentAuction } = useGlobals();
  const { id: auctionId } = currentAuction ?? {};

  return useQuery(
    ["seller-bids", auctionId, query, limit, skip],
    () => fetchSellerWinnedLotesById(auctionId, query, limit, skip),
    {
      enabled: isAuthenticated && !!auctionId,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener los lotes ganados del usuario");
      }
    }
  );
};

// Admin
export const useFetchAdminWinnedLotes = (
  type = "1",
  date = "",
  query = "",
  status_commission = "0",
  countryId = "1",
  limit = 100,
  skip = 0
) => {
  const { isAuthenticated } = useIsLogged();

  return useQuery(
    [
      "admin-bids",
      date,
      type,
      query,
      status_commission,
      countryId,
      limit,
      skip
    ],
    () =>
      fetchAdminWinnedLotes(
        type,
        date,
        query,
        status_commission,
        countryId,
        limit,
        skip
      ),
    {
      enabled: isAuthenticated,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener los lotes ganados del usuario");
      }
    }
  );
};

export const useFetchAdminWinnedLotesById = (
  query = "",
  status_commission = "0",
  countryId = "0",
  limit = 100,
  skip = 0
) => {
  const { isAuthenticated } = useIsLogged();
  const { currentAuction } = useGlobals();
  const { id: auctionId } = currentAuction ?? {};

  return useQuery(
    ["admin-bids", auctionId, query, status_commission, countryId, limit, skip],
    () =>
      fetchAdminWinnedLotesById(
        auctionId,
        query,
        status_commission,
        countryId,
        limit,
        skip
      ),
    {
      enabled: isAuthenticated && !!auctionId,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener los lotes ganados del usuario");
      }
    }
  );
};

export const useFetchWinnedLotes = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["bids"], () => fetchWinnedLotes(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los lotes ganados del usuario");
    }
  });
};

export const useFetchSellLotes = () => {
  const { isAuthenticated } = useIsLogged();
  return useQuery(["sellerBids"], () => fetchSellLotes(), {
    enabled: isAuthenticated,
    staleTime: 15 * 1000 * 60,
    onError: () => {
      fail("Hubo un error al obtener los lotes ganados del usuario");
    }
  });
};

export const useFeaturedSellers = (take = 10, skip = 0) => {
  return useQuery(
    ["featured-sellers"],
    () => fetchFeaturedSellers(take, skip),
    {
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener los subastadores destacados");
      }
    }
  );
};
