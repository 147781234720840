import { devices } from "config/devices";
import { variables } from "config/variables";
import styled from "styled-components";

const { fonts } = variables;

const Styles = styled.div`
  .MuiTypography-root {
    font-family: ${fonts["Poppins-Rg"]};
    font-size: 16px;

    @media ${devices.mobileL} {
      font-size: 14px;
    }
    @media ${devices.tablet} {
      font-size: 16px;
    }
  }

  .Menu {
    &__container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__list {
      display: flex;
    }
  }
`;

const HoverNavItemStyles = {
  MenuStyles: {
    mt: "43px",
    ".MuiMenu-paper": {
      width: "100%"
    },
    boxShadow: "none!important"
  },

  LinkStyles: {
    p: 1,
    flexShrink: 0,
    fontFamily: fonts["Poppins-Rg"],
    fontSize: 16,
    textDecoration: "none"
  }
};

export { Styles, HoverNavItemStyles };
