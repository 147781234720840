import { AddReview } from "containers/SellerProfile/SellerProfile.types";
import axiosDefault, { buildHeaders } from "services/Api";
import { SellerProfile } from "services/Auth/Auth.services.types";

import { fail } from "../../utils/Swal";
import { AuctionAccess, ReuseAuction } from "./Auctioners.service.types";
import { AuctionCategory } from "./Auctioners.service.types";
import { Comment, UpdateAuction } from "./Auctioners.service.types";
import { OfferData, SellerReview } from "./Auctioners.service.types";
import { Auction, Item } from "./Auctioners.service.types";
import { AddStaggered, CreateAuction } from "./Auctioners.service.types";
import { ItemForm } from "./Auctioners.service.types";

export async function getAuctionCategories(): Promise<AuctionCategory[]> {
  return await axiosDefault
    .get(`admi/sellers/categories`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function setCategoryToAuctionHouse(
  auctionId?: number,
  categoryId?: string
): Promise<Auction> {
  return await axiosDefault
    .post(
      `admi/sellers/categories`,
      { user_id: auctionId, category_id: categoryId },
      {
        headers: await buildHeaders()
      }
    )
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function toggleFeaturedToAuctionHouse(
  auctionId?: number
): Promise<Auction> {
  const url = `admi/sellers/featured/${auctionId}`;
  return await axiosDefault
    .post(
      url,
      {},
      {
        headers: await buildHeaders()
      }
    )
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function uploadImagesByZip(
  auctionId?: number,
  data?: FormData
): Promise<Auction> {
  return await axiosDefault
    .post(`admi/auction/${auctionId}/importLots/images/zip`, data, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function uploadImagesByBatch(
  auctionId?: number,
  data?: FormData
): Promise<Auction> {
  return await axiosDefault
    .post(`admi/auction/${auctionId}/importLots/images`, data, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function uploadItemsByBatch(
  auctionId?: number,
  data?: FormData
): Promise<string> {
  return await axiosDefault
    .post(`admi/auction/${auctionId}/importLots`, data, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.warning;
    })
    .catch(error => console.error(error));
}

export async function createAuction(data: CreateAuction): Promise<Auction> {
  return await axiosDefault
    .post(`admi/auction`, data, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function updateAuction(
  data: UpdateAuction,
  auction_id?: number
): Promise<Auction> {
  return await axiosDefault
    .post(`admi/auction/update/${auction_id}`, data, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function getToggleStreamerState(auctionId: string) {
  return await axiosDefault
    .get(`/auction/offert/stream/status/${auctionId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function getMyAuctions() {
  return await axiosDefault
    .get(`auction/myauctions/seller`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export const getMyAuctionsAccess = async (
  status: number
): Promise<AuctionAccess[]> => {
  return await axiosDefault
    .get(`auction/access/user/myaccess?status=${status}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const getAllAuctions = async (
  status?: number,
  limit?: number,
  skip?: number
): Promise<Auction[]> => {
  const url = status
    ? `auction?status=${status}&limit=${limit}&skip=${skip}`
    : `auction`;
  return await axiosDefault
    .get(url)
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const getAuctionById = async (id?: number): Promise<Auction> => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return await axiosDefault
    .get(`auction/${id}?timeZone=${localTimeZone}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const getAuctionCommentsById = async (
  id?: number
): Promise<Comment[]> => {
  return await axiosDefault
    .get(`auction/comment/${id}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export async function addStaggered(data: AddStaggered) {
  return await axiosDefault
    .post(`admi/auction/price`, data, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const response = error?.response;
      const message = response?.data?.error;
      fail(message);
    });
}

export const reuseAuction = async (data: ReuseAuction): Promise<any> => {
  const { auctionId, ...rest } = data;
  return await axiosDefault
    .post(`admi/auction/reuse/${auctionId}`, rest, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const response = error?.response;
      const message = response?.data?.error;
      fail(message);
    });
};

export async function removePrice(id: number) {
  return await axiosDefault
    .delete(`admi/auction/price/${id}`, {
      headers: await buildHeaders()
    })
    .catch(error => console.error(error));
}

export async function removeItemService(id: number) {
  return await axiosDefault
    .delete(`admi/auction/item/${id}`, {
      headers: await buildHeaders()
    })
    .catch(error => console.error(error));
}

export async function addItemService(id: number, data: ItemForm) {
  return await axiosDefault
    .post(`admi/auction/${id}`, data, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function addPhotoToItem(id: number, photo: FormData) {
  return await axiosDefault
    .post(`admi/auction/item/${id}`, photo, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function addFavorite(id?: number, data?: { type: string }) {
  return await axiosDefault
    .post(`favorites/${id}`, data, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function getMyFavorites() {
  return await axiosDefault
    .get(`favorites/items`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function getFavoriteById(id?: number) {
  return await axiosDefault
    .get(`favorites/isFavorite/${id}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export const getItemById = async (id: number): Promise<Item> => {
  return await axiosDefault
    .get(`auction/item/${id}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export async function offerAuction(data: OfferData) {
  return await axiosDefault
    .post(`auction/offert`, data, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(() => fail("Ocurrió un error al realizar la puja"));
}

export async function getOfferAuction(id?: number, limit?: number) {
  if (!id) return Promise<void>;

  return await axiosDefault
    .get(`auction/offert/${id}?limit=${limit}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function finalizeProduct(itemId: number) {
  return await axiosDefault
    .post(`auction/offert/itemInactive/${itemId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function createComment(comment: string, id: string, type: number) {
  return await axiosDefault
    .post(
      `auction/comment/${id}`,
      {
        comment,
        type
      },
      { headers: await buildHeaders() }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function finalizeAuction(auctionId: string) {
  return await axiosDefault
    .post(`auction/offert/finalize/${auctionId}`, {
      headers: await buildHeaders()
    })
    .catch(error => console.error(error));
}

export async function addTimeToItem(auctionId: number, itemId: number) {
  return await axiosDefault
    .post(
      `auction/offert/addSeconds/${auctionId}`,
      {
        item_id: itemId
      },
      { headers: await buildHeaders() }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

/* TODO: Get error */
export const getCurrentItem = async (
  auctionId?: number,
  signal?: AbortSignal
): Promise<Item> => {
  try {
    const response = await axiosDefault.get(
      `auction/offert/current/${auctionId}`,
      {
        headers: await buildHeaders(),
        signal
      }
    );
    return response.data.data;
  } catch (err) {
    throw new Error(err);
  }
};

export async function getSellerInfo(sellerId?: number): Promise<SellerProfile> {
  return await axiosDefault
    .get(`seller/${sellerId}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function getSellerItems(): Promise<Item[]> {
  return await axiosDefault
    .get(`seller/items`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function getSellerReviews(
  sellerId?: number
): Promise<SellerReview[]> {
  return await axiosDefault
    .get(`seller/reviews/${sellerId}`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export async function addReviewService(body: AddReview) {
  return await axiosDefault
    .post(`seller/reviews`, body, { headers: await buildHeaders() })
    .catch(error => console.error(error));
}

export async function pauseLoteService(itemId?: number) {
  return await axiosDefault
    .post(`/auction/offert/itemPause/${itemId}`, {
      headers: await buildHeaders()
    })
    .catch(error => console.error(error));
}

export async function checkAuctionStatus(auctionId?: number) {
  try {
    const response = await axiosDefault.get(
      `/auction/offert/status/${auctionId}`,
      { headers: await buildHeaders() }
    );

    return response?.data;
  } catch (e: any) {
    const response = e.response.data;
    throw new Error(response.error);
  }
}

export async function rejectBidService(itemId?: number, auctionId?: number) {
  return await axiosDefault
    .post(
      `auction/offert/regret`,
      { item_id: itemId, auction_id: auctionId },
      { headers: await buildHeaders() }
    )
    .then(response => {
      return response.data;
    })
    .catch(() => fail("Ocurrió un error al realizar la puja"));
}
