import { createSlice } from "@reduxjs/toolkit";

import { AuctionSlice } from "./redux.types";

const initialState: AuctionSlice = {
  currentAuction: undefined,
  currentItem: undefined,
  currentPrice: "00",
  lastBid: undefined,
  lastComment: undefined,
  timer: -1,
  createAuctionStep: 0,
  accessCode: ""
};

export const auctionsSlice = createSlice({
  name: "auctions",
  initialState,
  reducers: {
    updateCurrentAuction: (state, action) => {
      state.currentAuction = { ...action.payload };
    },
    updateCurrentPrice: (state, price) => {
      state.currentPrice = price.payload;
    },
    updateLastBid: (state, lastBid) => {
      state.lastBid = { ...lastBid.payload };
    },
    updateTimer: (state, timer) => {
      state.timer = timer.payload;
    },
    updateCurrentAuctionStep: (state, action) => {
      state.createAuctionStep = action.payload;
    },
    updateLastComment: (state, action) => {
      state.lastComment = action.payload;
    },
    updateAccessCode: (state, action) => {
      state.accessCode = action.payload;
    },
    resetAuctionsRedux: state => {
      state.currentAuction = undefined;
      state.currentItem = undefined;
      state.currentPrice = "00";
      state.lastBid = undefined;
      state.timer = -1;
      state.createAuctionStep = 0;
    }
  }
});
