import { Tab } from "@headlessui/react";
import CreateAuction from "components/Auctioners/createAuction/CreateAuction";
import ListAuctions from "components/Auctioners/listAuctions/ListAuctions";
import ListAuctionsBuyer from "components/Auctioners/listAuctionsBuyer/ListAuctionsBuyer";
import Layout from "components/Layout/Layout";
import { useAppSelector } from "hooks";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAllAuctions } from "services/Auctioners/Auctioners.service.hooks";
import { useMyAuctions } from "services/Auctioners/Auctioners.service.hooks";

import Styles from "./Auctioners.styles";

const Auctioners = () => {
  //@ts-ignore
  const { edit } = useParams();

  const { user } = useAppSelector(state => state.authReducer);

  const { data: myAuctionList } = useMyAuctions();
  const { data: allAuctionList } = useAllAuctions();

  const renderTabs = () => {
    return (
      <Tab.List className="Auctioners__container--tabs">
        <Tab
          className={({ selected }) =>
            `Auctioners__tabs ${selected ? "text-primary" : "text-black"}`
          }
        >
          Todas las subastas
        </Tab>
        <Tab
          className={({ selected }) =>
            `Auctioners__tabs ${selected ? "text-primary" : "text-black"}`
          }
        >
          Mis subastas
        </Tab>
        {user && user.rol === 5 ? (
          <>
            <Tab
              className={({ selected }) =>
                `Auctioners__tabs ${selected ? "text-primary" : "text-black"}`
              }
              style={{ textAlign: "start" }}
            >
              Mis subastas como vendedor
            </Tab>
            <Tab
              className={({ selected }) =>
                `Auctioners__tabs ${selected ? "text-primary" : "text-black"}`
              }
              name="CreateAuction"
              tabIndex={1}
              id="CreateAuction"
              style={{
                border: "1px solid text-primary",
                borderRadius: "20px",
                padding: "7px 18px",
                fontSize: "15px",
                backgroundColor: "#f77302",
                color: "white"
              }}
            >
              Crear nueva subasta
            </Tab>
          </>
        ) : null}
      </Tab.List>
    );
  };

  useEffect(() => {
    if (edit) {
      document.getElementsByName("CreateAuction")?.forEach(e => {
        e.click();
      });
    }
  }, [edit]);

  return (
    <Layout>
      <Styles className="Auctioners">
        <div className="Auctioners__container">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 className="Auctioners__title">Subastas</h1>
          </div>
          <Tab.Group>
            {renderTabs()}
            <Tab.Panels className="Auctioners__container--fields">
              <Tab.Panel>
                <ListAuctions auctionList={allAuctionList} />
              </Tab.Panel>
              <Tab.Panel>
                <ListAuctionsBuyer auctionList={myAuctionList} />
              </Tab.Panel>
              {user && user.rol === 5 ? (
                <>
                  <Tab.Panel>
                    <ListAuctions auctionList={myAuctionList} />
                  </Tab.Panel>
                  <Tab.Panel>
                    <CreateAuction />
                  </Tab.Panel>
                </>
              ) : null}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Styles>
    </Layout>
  );
};
export default Auctioners;
