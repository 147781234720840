import { variables } from "config/variables";
import styled from "styled-components";

import { devices } from "../../../config/devices";

const { palette, fonts } = variables;

const Styles = styled.div`
  // padding: 24px 4px;
  // border-top: 1px solid ${palette.dividerColor};

  .form {
    &--fields {
      display: grid;
      gap: 24px;
      width: 100%;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @media ${devices.laptop} {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      .MuiFormControl-root
        .MuiFormControl-fullWidth
        .css-11h5yrn-MuiFormControl-root {
        width: 55% !important;
      }
    }
  }

  .CreateAuction {
    &__white {
      color: white;
    }

    &__container {
      &--tabs {
        font-size: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-family: ${fonts["Poppins-Sb"]};
        text-align: center;
        gap: 8px;
        opacity: 0.8;

        @media ${devices.tablet} {
          justify-content: space-around;
          font-size: 18px;
          margin: 3% 0px 0 0%;
        }
      }

      &--fields-images {
        display: flex;
        gap: 0px;
        width: 90%;

        @media ${devices.tablet} {
          margin: 0 11%;
        }
      }

      &--fields {
        display: grid;
        gap: 24px;
        width: 100%;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        padding: 0 12px;

        @media ${devices.tablet} {
          margin: 0 11%;
        }

        @media ${devices.laptop} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        label {
          font-family: Poppins Md;
          color: hsl(27, 100%, 50%) !important;
          -webkit-letter-spacing: 0.4px;
          -moz-letter-spacing: 0.4px;
          -ms-letter-spacing: 0.4px;
          letter-spacing: 0.4px;
          font-weight: 400;
          font-variant: all-petite-caps;
        }

        span {
          color: black;
          font-family: "Poppins Lt";
        }
      }

      &--button {
        display: flex;
        padding: 24px 0;
        justify-content: center;
      }

      &--input {
        margin-top: 4px;
      }

      &--divider {
        margin-bottom: 16px;
      }

      &--form {
        display: flex;
        gap: 24px;
        flex-direction: column;
        margin-top: 4em;

        label {
          font-size: 20px;
          font-variant: all-petite-caps;
        }
      }
    }

    &__component {
      &--button {
        width: 40%;
        font-size: 14px;

        @media ${devices.mobileL} {
          font-size: 16px;
        }
      }

      &--tabs {
        font-size: 16px;
        text-align: center;
        font-family: ${fonts["Poppins-El"]};
        font-weight: 600;
        letter-spacing: 0.4px;
        text-decoration: none;
        display: inline-block;
        border-bottom: 4px;
        letter-spacing: 0.8px;

        @media ${devices.mobileL} {
          font-size: 16px;
        }

        @media ${devices.laptop} {
          font-size: 18px;
        }

        @media ${devices.desktop} {
          font-size: 24px;
        }
      }

      &--label {
        font-size: 14px;
        font-family: ${fonts["Poppins-Md"]};
        color: ${palette.primaryLight};
        font-weight: 400;
        letter-spacing: 0.4px;
        margin-top: 8px;
      }
    }
  }
`;

export const ModalStyles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  .ModalStyles {
    &__resetContainer {
      width: 64%;
      display: flex;
      justify-content: space-around;
      gap: 16px;
    }

    &__text {
      &--title {
        font-size: 18px;
        text-align: center;
        font-family: ${fonts["Poppins-El"]};
        font-weight: 540;
        letter-spacing: 0.8px;

        @media ${devices.tablet} {
          font-size: 20px;
        }

        @media ${devices.laptop} {
          font-size: 24px;
        }

        @media ${devices.laptopL} {
          font-size: 32px;
        }
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      @media ${devices.laptop} {
        gap: 32px;
      }

      &--buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 16px;

        @media ${devices.laptop} {
          gap: 24px;
        }
      }
    }

    &__component {
      &--button {
        width: 100%;
        font-size: 14px;

        @media ${devices.mobileL} {
          width: 80%;
          font-size: 16px;
        }
      }
    }
  }
`;

export default Styles;
