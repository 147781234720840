import styled from "styled-components";

import { devices } from "../../../config/devices";

const Styles = styled.div`
  .MyProfile {
    &__container {
      &--button {
        display: flex;
        justify-content: center;
        padding: 24px 0;
      }
    }

    &__form {
      &--profile {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 24px;
        width: 100%;

        label {
          font-size: 20px;
          font-variant: all-petite-caps;
        }

        input:after {
          border-bottom: 1px solid #c3c3c3 !important;
        }

        @media ${devices.laptop} {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
      }
    }
  }
`;

export default Styles;
