import { Skeleton } from "@mui/material";
import React from "react";

import Styles from "./SkeletonInput.styles";
import { SkeletonInputProps as Props } from "./SkeletonInput.types";

const SkeletonInput: React.FC<Props> = props => {
  return (
    <Styles className="SkeletonInput">
      <Skeleton variant="text" className="SkeletonInput__component--label" />
      <Skeleton variant="text" className="SkeletonInput__component--input" />
    </Styles>
  );
};

SkeletonInput.defaultProps = {};

export default SkeletonInput;
