import styled from "styled-components";

import { AdminClientsListStyledProps as Props } from "./AdminClientsList.types";

const AdminClientsListStyled = styled.div<Props>`
  .AdminClientsList {
  }
`;

export default AdminClientsListStyled;
