import { useMutation, useQuery, useQueryClient } from "react-query";
import { useIsLogged } from "services/Auth/Auth.services.hooks";

import { showAuctionApplies } from "./auctionAccess.service";
import { manageAuctionAccess } from "./auctionAccess.service";
import { fetchAuctionAccessCode } from "./auctionAccess.service";
import { getAuctionCodeAccess } from "./auctionAccess.service";
import { fetchAuctionAccess } from "./auctionAccess.service";

export const useFetchAuctionAccess = (auctionId?: number) => {
  const { isAuthenticated } = useIsLogged();

  return useQuery(
    ["auctionAccess", auctionId],
    () => fetchAuctionAccess(auctionId),
    {
      enabled: !!auctionId && isAuthenticated,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener los el tipo de acceso");
      }
    }
  );
};

export const useFetchAuctionApplies = (auctionId?: number) => {
  const { isAuthenticated } = useIsLogged();

  return useQuery(
    ["auctionApplies", auctionId],
    () => showAuctionApplies(auctionId),
    {
      enabled: !!auctionId && isAuthenticated,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener las solicitudes de acceso");
      }
    }
  );
};

export const useFetchAuctionAccessCode = (auctionId?: number) => {
  const { isAuthenticated } = useIsLogged();

  return useQuery(
    ["auctionAccessCode", auctionId],
    () => fetchAuctionAccessCode(auctionId),
    {
      enabled: !!auctionId && isAuthenticated,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener el código de acceso");
      }
    }
  );
};

export const useGetAuctionCodeAccess = (auctionId?: number) => {
  const { isAuthenticated } = useIsLogged();

  return useQuery(
    ["getAuctionCodeAccess", auctionId],
    () => getAuctionCodeAccess(auctionId),
    {
      enabled: !!auctionId && isAuthenticated,
      staleTime: 15 * 1000 * 60,
      onError: () => {
        fail("Hubo un error al obtener el código de acceso");
      }
    }
  );
};

export const useManageAuctionAccess = () => {
  const queryClient = useQueryClient();

  return useMutation(manageAuctionAccess, {
    // When mutate is called:
    onMutate: async () => {
      // Cancel any outgoing refetch (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries("manageAuctionAccess");
    },
    onSettled: () => {
      // Always refetch after error or success:
      queryClient.invalidateQueries("manageAuctionAccess");
    }
  });
};
