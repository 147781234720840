import FolderZipIcon from "@mui/icons-material/FolderZip";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Tooltip } from "@mui/material";
import Button from "components/Globals/Button/Button";
import React, { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { uploadImagesByZip } from "services/Auctioners/Auctioners.service";
import { uploadImagesByBatch } from "services/Auctioners/Auctioners.service";
import { useGetAuctionById } from "services/Auctioners/Auctioners.service.hooks";
import { successful } from "utils/Swal";

import Styles from "./UploadMultipleFileImages.styles";
import { UploadMultipleFileImagesProps as Props } from "./UploadMultipleFileImages.types";

const UploadMultipleFileImages: React.FC<Props> = props => {
  const { auctionId, isLoading, setIsLoading } = props;
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const { refetch: refetchAuction } = useGetAuctionById(auctionId);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const files = acceptedFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const handleOnClick = () => {
    fileInputRef.current?.click();
  };

  const handleZipUpload = async () => {
    try {
      const formData = new FormData();
      const file = fileInputRef.current?.files?.[0];
      if (file) formData.append("zip", file);
      setIsLoading(true);
      await uploadImagesByZip(auctionId, formData)
        .then(() => {
          refetchAuction();
          setIsLoading(false);
          successful("Los lotes fueron cargados correctamente");
        })
        .catch(error => {
          setIsLoading(false);
          fail(error.error);
        });
    } catch (error) {
      setIsLoading(false);
      fail(error.error);
    }
  };

  const handleFileUpload = useCallback(async () => {
    try {
      const formData = new FormData();
      setIsLoading(true);
      if (acceptedFiles && acceptedFiles.length > 0) {
        for (let i = 0; i < acceptedFiles.length; i++) {
          formData.append("images[]", acceptedFiles[i]);
        }
      }
      await uploadImagesByBatch(auctionId, formData)
        .then(() => {
          refetchAuction();
          setIsLoading(false);
          successful("Las imágenes fueron cargadas correctamente");
        })
        .catch(error => {
          setIsLoading(false);
          fail(error.error);
        });
    } catch (error) {
      setIsLoading(false);
      fail(error.error);
    }
  }, [acceptedFiles, auctionId, refetchAuction, setIsLoading]);

  return (
    <Styles className="UploadMultipleFileImages">
      <div
        {...getRootProps({ className: "dropzone" })}
        className="UploadMultipleFileImages__container"
      >
        <input
          {...getInputProps()}
          accept=".jpg, .png, .jpeg, .webp, .svg, .gif"
        />
        <h4 className="UploadMultipleFileImages__subTitle">
          Arrastra y suelta las imágenes aquí, o haz click sobre esta zona para
          seleccionarlas. Las imágenes deben tener el siguiente formato:
        </h4>
        <h5 className="UploadMultipleFileImages__bold">
          #lote_#posición.png - ejemplo (1_1.png)
        </h5>
        <aside>
          <h4 className="UploadMultipleFileImages__subTitle">Archivos:</h4>
          <ul className="UploadMultipleFileImages__paragraph">{files}</ul>
        </aside>
      </div>
      <div className="UploadMultipleFileImages__buttonsContainer">
        <Tooltip title="Para subir un archivo comprimido con imágenes, debes de tener en cuenta que todas las imágenes estén en el mismo formato, lleven la nomenclatura como en el ejemplo 1_1.png. ">
          <Button
            startIcon={<FolderZipIcon />}
            rounded="false"
            onClick={handleOnClick}
            variant="outlined"
            sx={{
              borderRadius: 2,
              width: 200
            }}
            loading={isLoading}
          >
            Subir comprimido
          </Button>
        </Tooltip>
        <Tooltip title="Una vez arrastradas o seleccionadas las imágenes, los nombres de estas se verán reflejadas en el recuadro de arriba, una vez listas, presione en subir imágenes para la carga de las mismas.">
          <Button
            startIcon={<UploadFileIcon />}
            rounded="false"
            onClick={handleFileUpload}
            variant="contained"
            sx={{
              borderRadius: 2,
              width: 200
            }}
            loading={isLoading}
            disabled={acceptedFiles?.length === 0}
          >
            Subir imágenes
          </Button>
        </Tooltip>
      </div>

      <input
        type="file"
        accept=".zip"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleZipUpload}
      />
    </Styles>
  );
};

UploadMultipleFileImages.defaultProps = {};

export default UploadMultipleFileImages;
