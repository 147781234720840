import CustomModal from "components/CustomModal/CustomModal";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import { useAppSelector } from "hooks";
import React from "react";
import { useGetUserExtraData } from "services/Auth/Auth.services.hooks";

import Styles from "./ModalSuccess.styles";
import { ModalSuccessProps as Props } from "./ModalSuccess.types";

const ModalSuccess: React.FC<Props> = props => {
  const { modalSuccess, setModalSuccess } = props;
  const { user } = useAppSelector(state => state.authReducer);
  const { name, surname } = user ?? {};

  const { data } = useGetUserExtraData();
  const { payment: paymentData = [], address: addressData = [] } = data ?? {};

  const renderPaymentData = () => {
    if (paymentData?.length === 0) {
      return (
        <div className="ModalSuccess__dataContainer">
          <p>Datos de tarjeta: Sin definir</p>
        </div>
      );
    }
    const lastPaymentInfo = paymentData?.[paymentData?.length - 1];
    const { name, address, card_number, city, country } = lastPaymentInfo ?? {};
    const { expiration, postal_code, state, apt } = lastPaymentInfo ?? {};

    return (
      <div className="ModalSuccess__dataContainer">
        <h3>{name}</h3>
        <p>{address}</p>
        <p>{`${card_number.slice(0, 4)} XXXX XXXX ${card_number.slice(12)}`}</p>
        <p>{`${expiration} `}</p>
        <p>{`${country} - ${city}`}</p>
        <p>{`${state} - ${postal_code}`}</p>
        <p>{`${apt} `}</p>
      </div>
    );
  };

  const renderAddressData = () => {
    if (addressData?.length === 0) {
      return (
        <div className="ModalSuccess__dataContainer">
          <p>Datos de dirección: Sin definir</p>
        </div>
      );
    }
    const lastAddressInfo = addressData?.[addressData?.length - 1];
    const { address, city, country, postal_code, state, apt } =
      lastAddressInfo ?? {};

    return (
      <div className="ModalSuccess__dataContainer">
        <h3>{name}</h3>
        <p>{address}</p>
        <p>{`${country} - ${city}`}</p>
        <p>{`${state} - ${postal_code}`}</p>
        <p>{`${apt} `}</p>
      </div>
    );
  };

  return (
    <CustomModal
      onClose={() => setModalSuccess(!modalSuccess)}
      isOpen={modalSuccess}
      ariaLabelledby="Notificación de subasta exitosa"
    >
      <Styles className="ModalSuccess">
        <img
          className="ModalSuccess__image"
          src="/images/logo.png"
          alt="Logo LiveSubastas"
        />
        <h1>{`Gracias por registrarte a la subasta, ${name} ${
          surname ?? ""
        }`}</h1>
        <p>
          Un correo electrónico será enviado tan pronto como la casa de subastas
          apruebe su registro. No se olvide de seguir la casa de subastas para
          obtener alertas cuando se enumeran nuevas subastas.
        </p>
        {renderPaymentData()}
        {renderAddressData()}
        <div className="ModalSuccess__buttons">
          <ButtonLarge
            variant="contained"
            size="large"
            onClick={() => setModalSuccess(false)}
          >
            Confirmar registro
          </ButtonLarge>
        </div>
      </Styles>
    </CustomModal>
  );
};

ModalSuccess.defaultProps = {};

export default ModalSuccess;
