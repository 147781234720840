import * as yup from "yup";

export const schema = yup.object().shape({
  email: yup
    .string()
    .email("Correo electrónico inválido")
    .required("Campo requerido."),
  password: yup
    .string()
    .min(8, "Contraseña tiene un mínimo de 8 caracteres.")
    .matches(/^(?=.*[A-Z])/, "Debe contener 1 mayúscula.")
    .matches(/^(?=.*[a-z])/, "Debe contener 1 minúscula.")
    .matches(/^(?=.*[0-9]{2})/, "Debe contener 2 números.")
    .matches(
      /.*[!@#$%^&*(),.?":{}|<>].*/,
      "La contraseña debe tener un carácter especial (@ * , . -)"
    )
    .required()
});
