import { variables } from "config/variables";
import styled from "styled-components";

import { NotificationsStyledProps as Props } from "./Notifications.types";

const { palette, fonts } = variables;

const NotificationsStyled = styled.div<Props>`
  min-height: 80vh;
  margin-bottom: -100px;
  display: flex;
  margin-top: 24px;

  & > h2 {
    font-family: ${fonts["Poppins-Lt"]};
    color: ${palette.blackCharcoal};
  }

  * > p {
    font-family: ${fonts["Poppins-Rg"]};
  }

  .Notifications {
    &__short {
      width: 100%;
      max-width: 480px;
      height: 100%;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }

    &__details {
      padding: 32px;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      align-items: center;
    }

    &__image {
      width: 256px;
      height: 256px;
      border-radius: 50%;
      padding: 4px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.06);
    }

    &__content {
      display: flex;
      flex-direction: column;
      margin: 32px 0;
      width: 100%;
      gap: 16px;
    }

    &__title {
      font-size: 32px;
      font-family: ${fonts["Poppins-Lt"]};
      color: ${palette.blackCharcoal};
    }

    &__time {
      font-family: ${fonts["Poppins-Rg"]};
      color: ${palette.blackCharcoal};
    }
  }

  /* Media queries */
  @media screen and (max-width: 768px) {
    .Notifications__short {
      max-width: 100%;
    }
    .Notifications__content {
      flex-direction: column;
      width: 100%;
    }
    .Notifications__image {
      width: 200px;
      height: 200px;
    }
    .Notifications__title {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 480px) {
    .Notifications__image {
      width: 150px;
      height: 150px;
    }
    .Notifications__title {
      font-size: 20px;
    }
    .Notifications__time {
      font-size: 14px;
    }
  }
`;

export default NotificationsStyled;
