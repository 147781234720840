import { MenuItem, Select } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { DatePicker } from "antd";
import React from "react";

import Styles from "./CalendarFilters.styles";
import { CalendarFiltersProps as Props } from "./CalendarFilters.types";

const { RangePicker } = DatePicker;

const CalendarFilters: React.FC<Props> = props => {
  const { setSelectedDate, defaultDates, auctionType, setAuctionType } = props;
  const { countries, country, setCountry, activeLivestream, bestValue } = props;
  const { setActiveLivestream, setBestValue } = props;

  const divider = <hr className="CalendarFilters__divider" />;

  const renderDateRange = () => {
    return (
      <div className="CalendarFilters__actionsContainer">
        <RangePicker
          onChange={(_, date) => setSelectedDate(date)}
          defaultValue={[defaultDates[0], defaultDates[1]]}
          allowClear={false}
          className="CalendarFilters__rangePicker"
        />
      </div>
    );
  };

  const sectionOne = (
    <>
      <h3 className="CalendarFilters__section-title">
        Selecciona un rango de fechas
      </h3>
      {renderDateRange()}
    </>
  );

  const sectionTwo = (
    <>
      <h3 className="CalendarFilters__section-title">Tipo de subasta</h3>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={auctionType === 1}
              onChange={() => {
                if (auctionType !== 1) setAuctionType(1);
                else setAuctionType(undefined);
              }}
            />
          }
          label="En vivo"
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={auctionType === 2}
              onChange={() => {
                if (auctionType !== 2) setAuctionType(2);
                else setAuctionType(undefined);
              }}
            />
          }
          label="Cronometrada"
        />
      </FormGroup>
    </>
  );

  const sectionThree = (
    <>
      <h3 className="CalendarFilters__section-title">Filtrar por país</h3>
      <Select
        labelId="country"
        id="country"
        value={country}
        label="País"
        onChange={e => setCountry(+e.target.value)}
        size="small"
      >
        {countries?.map((country, idx) => (
          <MenuItem value={country.id} key={idx}>
            {country.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );

  const sectionFor = (
    <FormGroup>
      <h3 className="CalendarFilters__section-title">Audio / Video</h3>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={activeLivestream}
            onChange={() => setActiveLivestream(!activeLivestream)}
          />
        }
        label="Livestream activo"
      />
      {divider}
      <h3 className="CalendarFilters__section-title">Con mejor valoración</h3>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={bestValue}
            onChange={() => setBestValue(!bestValue)}
          />
        }
        label="Mejor valoración"
      />
    </FormGroup>
  );

  return (
    <Styles className="CalendarFilters">
      {sectionOne}
      {divider}
      {sectionTwo}
      {divider}
      {sectionThree}
      {divider}
      {sectionFor}
    </Styles>
  );
};

CalendarFilters.defaultProps = {};

export default CalendarFilters;
