import api, { buildHeaders } from "../Api";
import { CurrencyPayload, Currency } from "./currency.service.types";

export const fetchCurrencies = async (): Promise<Currency[]> => {
  return await api
    .get(`/auction/currency`, { headers: await buildHeaders() })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export const postNewCurrency = async (payload: CurrencyPayload) => {
  await api.post(`admi/auction/currency`, payload, {
    headers: await buildHeaders()
  });
};

export const putCurrency = async (payload: CurrencyPayload) => {
  await api.patch(`admi/auction/currency`, payload, {
    headers: await buildHeaders()
  });
};

export const deleteCurrency = async (currencyId?: number) => {
  await api.delete(`admi/auction/currency/${currencyId}`, {
    headers: await buildHeaders()
  });
};
