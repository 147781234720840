import styled from "styled-components";

import { AuctionHousesListStyledProps as Props } from "./AuctionHousesList.types";

const AuctionHousesListStyled = styled.div<Props>`
  .AuctionHousesList {
  }
`;

export default AuctionHousesListStyled;
