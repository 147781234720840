import { variables } from "config/variables";
import styled from "styled-components";

const { fonts } = variables;

const ModalEndStyled = styled.div`
  .ModalEnd {
    &__image {
      width: 24rem;
    }

    &__title {
      font-size: 28px;
      font-family: ${fonts["Poppins-Md"]};
      margin: 16px 0;
    }

    &__center {
      text-align: center;
    }
  }
`;

export default ModalEndStyled;
