import styled from "@emotion/styled";
import { AppBar } from "@mui/material";
import { devices } from "config/devices";
import { variables } from "config/variables";

const { palette, fonts } = variables;

const DashboardNavbarRoot = styled(AppBar)(() => ({
  backgroundColor: palette.white,
  color: palette.dark
}));

const NavbarStyles = {
  root: {
    backgroundColor: "primary.800",
    // paddingTop: "8px"
    boxShadow: "none!important"
  },
  ToolbarStylesRoot: {
    justifyContent: "space-between",
    overflowX: "auto",
    padding: "0 32px",
    display: "flex",
    // height: "100%",
    maxHeight: 238,
    width: "calc(100% - 40px)",
    maxWidth: 1340,
    margin: "0px auto"
  },
  LinkStyles: {
    p: 1,
    flexShrink: 0,
    fontFamily: fonts["Poppins-El"],
    textDecoration: "none"
  },
  ToolbarStyles: {
    display: "flex",
    height: "80px",
    maxHeight: 238,
    width: "calc(100% - 40px)",
    maxWidth: 1500,
    margin: "0px auto",
    minHeight: "35!important",
    left: 0,
    px: 2,
    justifyContent: "space-between",
    alignItems: "center",
    [`@media screen and ${devices.laptopL}`]: {
      minHeight: 54
    },
    [`@media screen and ${devices.tablet}`]: {
      minHeight: 40
    }
  },
  BoxStylesRoot: {
    // backgroundColor: "#f9f9f9",
    // borderTop: "1px solid #ece9e9"
  },
  IconButtonStyles: {
    variant1: {
      display: "inline-flex",
      [`@media screen and ${devices.tablet}`]: {
        display: "none"
      }
    }
  },
  variant2: { p: 0 },
  SpanStyles: { marginRight: 10 },
  BoxStyles: {
    variant1: {
      width: "208px!important",
      px: 2,
      display: "block",
      cursor: "pointer",
      [`@media screen and ${devices.tablet}`]: {
        width: "192px"
      }
    },
    variant2: { flexGrow: 1 },
    variant3: {
      flexGrow: 0,
      // width: "210px",
      display: "flex",
      justifyContent: "center",
      [`button`]: {
        fontSize: 12,
        padding: "4px 10px"
      }
    },
    variant4: {
      display: "flex",
      height: "100%",
      maxHeight: 238,
      minHeight: 35,
      width: "calc(100% - 40px)",
      maxWidth: 1340,
      margin: "0px auto",
      justifyContent: "center",
      [`@media screen and ${devices.tablet}`]: {
        display: "flex",
        gap: "4px"
      },
      [`@media screen and ${devices.laptop}`]: {
        gap: "27px"
      },
      [`@media screen and ${devices.laptopL}`]: {
        gap: "27px"
      },
      [`button`]: {
        flexDirection: "row",
        [`span`]: {
          marginRight: "5px"
        }
      }
    }
  },
  MenuStyles: {
    mt: "40px",
    boxShadow: "none!important",
    [`ul`]: {
      paddingTop: 0,
      paddingBottom: 0,
      [`li`]: {
        margin: "5px 0"
      },
      [`li:last-child`]: {
        borderTop: "1px solid #ece9e9",
        color: "#ff7300"
      }
    }
  },
  MenuItemStyles: {
    fontFamily: fonts["Poppins-El"]
  }
};

const GuestLinksStyles = styled.div`
  .guestLinksStyle {
    &__container {
      display: flex;
      gap: 4px;

      button {
        font-size: 14px;
        box-shadow: none;
        font-family: ${fonts["Poppins-Rg"]};
        height: 41px;
        width: 141px;
        border-radius: 25px;
      }

      .button-login {
        margin-right: 1em;
      }
    }
  }
`;

export { NavbarStyles, DashboardNavbarRoot, GuestLinksStyles };
