import api, { buildHeaders } from "../Api";
import { CardData, DirectionData, UserExtraData } from "./Auth.services.types";
import { SignInResponse, User } from "./Auth.services.types";
import { LoginData, ProfileData } from "./Auth.services.types";
import { RegisterData, SellerData } from "./Auth.services.types";
import { SellerInfoData } from "./Auth.services.types";
import { RegisterResponse } from "./Auth.services.types";

export async function login(data: LoginData): Promise<SignInResponse> {
  try {
    const response = await api.post(`auth/login`, data);
    return response?.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function logout() {
  return await api
    .post(`auth/logout`, { headers: await buildHeaders() })
    .catch(error => console.error(error));
}

export async function register(data: RegisterData): Promise<RegisterResponse> {
  try {
    const response = await api.post(`auth/register`, data);
    return response?.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function registerSeller(data: SellerData) {
  return await api
    .post(`/interviews`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function updateUserService(data: ProfileData) {
  return await api
    .post(`auth/update`, data, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function addUserAddress(data: DirectionData) {
  return await api
    .post(`auth/address`, data, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function addUserPayment(data: CardData) {
  return await api
    .post(`auth/payment`, data, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export async function addSellerInfo(data: SellerInfoData) {
  return await api
    .post(`seller`, data, { headers: await buildHeaders() })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export const getUserData = async (): Promise<User> => {
  return await api
    .get(`auth/user-profile`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error(error);
      throw new Error(error);
    });
};

export const getUserDataById = async (id?: number): Promise<User> => {
  return await api
    .get(`auth/user/${id}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export async function getSellerData(userId?: number) {
  return await api
    .get(`seller/${userId}`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data;
    })
    .catch(error => console.error(error));
}

export const getUserExtraData = async (): Promise<UserExtraData> => {
  return await api
    .get(`auth/payment-address`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
};

export async function getCurrencyTypes() {
  return await api
    .get(`auction/currency`, {
      headers: await buildHeaders()
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => console.error(error));
}

export const tokenizeCard = async (token: string) => {
  return await api.post(
    `auth/payment`,
    { token },
    { headers: await buildHeaders() }
  );
};
