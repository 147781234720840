import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DescriptionIcon from "@mui/icons-material/Description";
import EventIcon from "@mui/icons-material/Event";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Button from "components/Globals/Button/Button";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import EmptyState from "components/emptyState/EmptyState";
import { CONSTANTS } from "config/constants";
import { StyledTableRow } from "containers/mybids/MyBids.styles";
import { StyledTableCell } from "containers/mybids/MyBids.styles";
import useGlobals from "context/globals/globals.hooks";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { downloadWishPDF } from "services/wishes/wishes.service";
import { changeWishState } from "services/wishes/wishes.service";
import { useEditWish } from "services/wishes/wishes.service.hooks";
import { useFetchWishDetails } from "services/wishes/wishes.service.hooks";
import { useFetchAllWishes } from "services/wishes/wishes.service.hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { fail, successful } from "utils/Swal";

import { iconStyle } from "../AdminClientList/ClientInfoDetails/ClientInfoDetails.styles";
import NotifyWishes from "./NotifyWishes/NotifyWishes";
import Styles from "./Wishes.styles";
import { WishesProps as Props } from "./Wishes.types";

const { IMAGE_PLACEHOLDER } = CONSTANTS;

const Wishes: React.FC<Props> = props => {
  const { currentWish, setCurrentWish } = props;
  const { setSelectedClient, setSelectedSubIndex, setSelectedIndex } =
    useGlobals();
  const { mutateAsync, reset } = useEditWish();
  const { data: wishesList, isLoading, refetch } = useFetchAllWishes();
  const {
    data: wishDetails,
    isLoading: isLoadingWish,
    refetch: refetch2
  } = useFetchWishDetails(currentWish);
  const [action, setAction] = useState<"DETALLES" | "NOTIFICAR">("DETALLES");
  const [content, setContent] = useState(wishDetails?.description);

  const handleChangeState = async (wishId?: number) => {
    try {
      await changeWishState(wishId);
      refetch();
      refetch2();
    } catch (error) {
      fail("Ocurrió un error al intentar cambiar el estado del deseo.");
    }
  };

  const handleEdit = async () => {
    const payload = { ...wishDetails, description: content };
    try {
      mutateAsync(payload);
      reset();
      successful("Hecho");
    } catch (error) {
      fail("Error al actualizar el contenido.");
    }
  };

  const handleDownloadPDF = async (wishId?: number) => {
    try {
      await downloadWishPDF(wishId);
    } catch (error) {
      fail("Ocurrió un error al intentar descargar el documento.");
    }
  };

  const renderWishDetails = () => {
    const { created_at, status, user, files, id } = wishDetails ?? {};
    const { name, surname, id: uid } = user ?? {};

    return (
      <div className="Wishes__clientInfo">
        <h3 className="Wishes__title">Detalles del deseo</h3>
        {typeof wishDetails === "undefined" ? (
          <div className="p-4">
            <EmptyState />
          </div>
        ) : (
          <>
            <div className="Wishes__clientInfo__section">
              <h4>
                <DescriptionIcon sx={iconStyle} />
                Contenido
              </h4>
              <TextField
                variant="outlined"
                onChange={e => setContent(e.target.value)}
                multiline
                rows={2}
                placeholder="Sin descripción"
                value={content}
              />
              <Button onClick={handleEdit}>Editar contenido</Button>
            </div>
            <div className="Wishes__clientInfo__section">
              <h4>
                <LocalShippingIcon sx={iconStyle} />
                Estado
              </h4>
              <p>{status ?? "--"}</p>
            </div>
            <div className="Wishes__clientInfo__section">
              <h4>
                <EventIcon sx={iconStyle} />
                Fecha de petición
              </h4>
              <p>
                {created_at
                  ? dayjs(created_at).format("DD/MM/YYYY HH:mm a")
                  : "--:--"}
              </p>
            </div>
            <div className="Wishes__clientInfo__section">
              <h4>
                <AccountCircleIcon sx={iconStyle} />
                Usuario de la petición
              </h4>
              <p
                className="Wishes__link"
                onClick={() => {
                  setSelectedClient(uid);
                  setSelectedIndex(1);
                  setSelectedSubIndex(1);
                }}
              >
                {`${name ?? ""} ${surname ?? ""}`?.slice(0, 60) ?? ""}
              </p>
            </div>
            <div className="Wishes__clientInfo__section">
              <Swiper
                height={80}
                width={232}
                slidesPerView={1}
                spaceBetween={16}
                scrollbar={{ draggable: true }}
                navigation={false}
              >
                {files?.map(file => {
                  const { url_image, id } = file ?? {};
                  return (
                    <SwiperSlide>
                      <img
                        className="Wishes__image"
                        src={url_image ?? IMAGE_PLACEHOLDER}
                        alt={`${id}`}
                        key={id}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="Wishes__clientInfo__section">
              <Button
                variant="contained"
                size="small"
                sx={{ width: "40%", alignSelf: "center" }}
                startIcon={<PictureAsPdfIcon />}
                onClick={() => handleDownloadPDF(id)}
              >
                Descargar PDF
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderTable = () => {
    return (
      <TableContainer component={Paper} sx={{ maxWidth: 870 }}>
        <Table sx={{ minWidth: 650 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell size="small" align="left">
                Título
              </StyledTableCell>
              <StyledTableCell align="left">Usuario</StyledTableCell>
              <StyledTableCell align="center">Estado</StyledTableCell>
              <StyledTableCell align="left">Fecha de creación</StyledTableCell>
              <StyledTableCell align="center">Acciones</StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Ver detalles
              </StyledTableCell>
              <StyledTableCell align="center" size="medium">
                Notificar
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {wishesList?.map((item, key) => {
              const { description, status, id, user } = item ?? {};
              const { created_at } = item ?? {};
              const { name, surname } = user ?? {};
              const pendant = status === "Pendiente";

              return (
                <StyledTableRow key={`${id}-${key}`}>
                  <StyledTableCell align="left">
                    {(description ?? "").slice(0, 10)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {`${name ?? ""} ${surname ?? ""}`?.slice(0, 30) ?? ""}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Chip
                      label={status}
                      variant={!pendant ? "filled" : "outlined"}
                      color={!pendant ? "success" : "info"}
                    />
                  </StyledTableCell>

                  <StyledTableCell align="left">
                    {created_at
                      ? dayjs(created_at).format("DD/MM/YYYY HH:mm a")
                      : "--:--"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="Marcar como enviado">
                      <Button
                        variant="contained"
                        rounded="true"
                        size="small"
                        startIcon={<CheckCircleIcon />}
                        onClick={() => handleChangeState(id)}
                        disabled={!pendant}
                      >
                        Revisar
                      </Button>
                    </Tooltip>
                  </StyledTableCell>

                  <StyledTableCell align="center" size="small">
                    <Button
                      variant="text"
                      rounded="true"
                      size="small"
                      startIcon={<VisibilityIcon />}
                      onClick={() => {
                        setCurrentWish(id);
                        setAction("DETALLES");
                      }}
                    >
                      Ver detalles
                    </Button>
                  </StyledTableCell>

                  <StyledTableCell align="center" size="small">
                    <Button
                      variant="contained"
                      rounded="true"
                      size="small"
                      startIcon={<NotificationsActiveIcon />}
                      onClick={() => {
                        setCurrentWish(id);
                        setAction("NOTIFICAR");
                      }}
                    >
                      Notificar
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  useEffect(() => {
    const { description } = wishDetails ?? {};
    if (description) setContent(description);
  }, [wishDetails]);

  return (
    <Styles className="Wishes">
      {(isLoading || isLoadingWish) && <CircularProgress />}
      {renderTable()}
      {action === "DETALLES" ? (
        renderWishDetails()
      ) : (
        <NotifyWishes
          setCurrentWish={setCurrentWish}
          currentWish={currentWish}
        />
      )}
    </Styles>
  );
};

Wishes.defaultProps = {};

export default Wishes;
