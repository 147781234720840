import React from "react";

import Styles from "./ProgressComponent.styles";
import { ProgressComponentProps as Props } from "./ProgressComponent.types";

const ProgressComponent: React.FC<Props> = props => {
  const { value, max } = props;

  return (
    <Styles className="ProgressComponent">
      <progress value={value} max={max} />
    </Styles>
  );
};

ProgressComponent.defaultProps = {};

export default ProgressComponent;
