import { Button } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

import { devices } from "../../../config/devices";
import { variables } from "../../../config/variables";
import { ButtonStyledProps as Props } from "./Button.types";

const { palette, fonts } = variables;

export const Styles = styled(Button)((props: Props) => ({
  color: palette.primary,
  borderColor: palette.primary,
  width: props.width ? props.width : "auto",
  opacity: props.disabled ? 0.6 : 1,
  textTransform: "none",
  borderRadius: "16px",
  fontSize: "8px",
  fontFamily: `${fonts["Poppins-El"]}`,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  padding: "4px 10px",
  [`@media screen and ${devices.tablet}`]: {
    fontSize: "14px"
  },
  [`@media screen and ${devices.laptop}`]: {
    fontSize: "16px"
  },
  "&:hover, &.Mui-focusVisible": {
    borderColor: palette.primary,
    color: palette.white,
    backgroundColor: palette.primary
  },
  "&.Mui-active": {
    boxShadow: `0px 0px 0px 14px ${alpha(palette.primaryLight, 0.16)}`
  },
  "&.MuiButton-contained": {
    color: palette.white,
    backgroundColor: palette.primary
  },
  "&.MuiButton-endIcon": {
    display: "none",
    [`@media screen and ${devices.mobileL}`]: {
      display: "inherit"
    }
  }
}));

export const CircularProgressStyled = {
  color: "inherit"
};
