import { authSlice } from "../slice/authSlice";

export const {
  loginSuccess,
  logoutSuccess,
  updateUser,
  updateUserDirections,
  updateUserPayments,
  updateSellerInfo,
  updateUserCurrency,
  resetAuthRedux
} = authSlice.actions;
