import EditOffIcon from "@mui/icons-material/EditOff";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { IconButton, TextField, Tooltip } from "@mui/material";
import ButtonLarge from "components/Globals/ButtonLarge/ButtonLarge";
import CircularProgress from "components/Globals/CircularProgress/CircularProgress";
import React, { useEffect, useState } from "react";
import { useCreateCurrency } from "services/currency/currency.service.hooks";
import { useUpdateCurrency } from "services/currency/currency.service.hooks";
import { useFetchCurrencies } from "services/currency/currency.service.hooks";
import { Currency as ICurrency } from "services/currency/currency.service.types";
import { CurrencyPayload } from "services/currency/currency.service.types";

import Styles from "./Currency.styles";
import { CurrencyProps as Props } from "./Currency.types";
import CurrencyCard from "./CurrencyCard/CurrencyCard";
import { checkStyles } from "./CurrencyCard/CurrencyCard.styles";

const Currency: React.FC<Props> = props => {
  const { data: currencyList, isLoading } = useFetchCurrencies();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [code, setCode] = useState("");
  const {
    mutateAsync,
    isLoading: isLoadingCreate,
    reset
  } = useCreateCurrency();
  const {
    mutateAsync: updateCurrency,
    isLoading: isLoadingUpdate,
    reset: resetPut
  } = useUpdateCurrency();

  const [currentCurrency, setCurrentCurrency] = useState<ICurrency | undefined>(
    undefined
  );

  const handleResetState = () => {
    setCode("");
    setName("");
    setPrice("");
    setCurrentCurrency(undefined);
  };

  const handleFormSend = async () => {
    try {
      const payload: CurrencyPayload = {
        name,
        code,
        price: +price
      };
      if (currentCurrency) {
        payload.currency_id = currentCurrency.id;
        updateCurrency(payload).catch();
        resetPut();
        handleResetState();
      } else {
        mutateAsync(payload).catch();
        reset();
      }
    } catch (error) {
      fail("Ocurrió un problema al realizar el manejo de moneda");
    }
  };

  const renderCurrencyList = () => {
    return (
      <div className="Currency__list">
        {currencyList?.map(currency => (
          <CurrencyCard
            currency={currency}
            setCurrentCurrency={setCurrentCurrency}
          />
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!currentCurrency) return;
    setName(currentCurrency.name);
    setPrice(`${currentCurrency.price}`);
    setCode(currentCurrency.code);
  }, [currentCurrency]);

  const renderForm = () => {
    return (
      <div className="Currency__contentForm">
        <div className="Currency__title">
          <h3>Agrega o edita una moneda</h3>
          <Tooltip title="Cancelar editar" className="ItemCard__remove">
            <IconButton
              sx={checkStyles}
              onClick={handleResetState}
              disabled={!currentCurrency}
            >
              <EditOffIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>

        <div className="Currency__form">
          <TextField
            key={5345430}
            id="name"
            value={name}
            onChange={val => setName(val.target.value)}
            variant="standard"
            label="Nombre"
          />
          <TextField
            key={11321321}
            id="price"
            value={price}
            onChange={val => setPrice(val.target.value)}
            variant="standard"
            label="Precio"
          />
          <TextField
            key={254354}
            id="code"
            value={code}
            onChange={val => setCode(val.target.value)}
            variant="standard"
            label="Código"
          />
          <ButtonLarge
            startIcon={<MonetizationOnIcon />}
            onClick={handleFormSend}
            id="submit"
            variant="contained"
            size="small"
          >
            {!currentCurrency ? "Agregar moneda" : "Editar moneda"}
          </ButtonLarge>
        </div>
      </div>
    );
  };

  return (
    <Styles className="Currency">
      {(isLoading || isLoadingCreate || isLoadingUpdate) && (
        <CircularProgress />
      )}
      {renderForm()}
      {renderCurrencyList()}
    </Styles>
  );
};

Currency.defaultProps = {};

export default Currency;
