import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import FeaturedAuction from "./FeaturedAuction";
import { Auction } from "services/Auctioners/Auctioners.service.types";

type FeaturedAuctionCarouselProps = {
  auctions: Auction[];
};

const FeaturedAuctionCarousel = ({
  auctions
}: FeaturedAuctionCarouselProps) => {
  return auctions.length > 1 ? (
    <Swiper
      modules={[Navigation]}
      spaceBetween={25}
      slidesPerView={1.5}
      centeredSlides
      centerInsufficientSlides
      watchSlidesProgress
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }}
      className="p-2"
    >
      <img
        className="swiper-button-prev w-[60px] h-[60px]"
        src="/images/home/carousel_prev.svg"
        alt="Botón anterior"
      />
      {auctions?.map((auction, i) => (
        <SwiperSlide className="flex justify-center" key={i}>
          <FeaturedAuction auction={auction} />
        </SwiperSlide>
      ))}
      <img
        className="swiper-button-next w-[60px] h-[60px]"
        src="/images/home/carousel_next.svg"
        alt="Botón siguiente"
      />
    </Swiper>
  ) : (
    <div className="flex justify-center">
      <FeaturedAuction auction={auctions[0]} />
    </div>
  );
};

export default FeaturedAuctionCarousel;
